import React, { useState } from "react";
import FormDeduction from "./FormDeduction";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";

const AddDeduction = () => {
  //state which check open or close modal
  const [open, setOpen] = useState(false);
  //var to navigate another page

  const navigate = useNavigate();
  //close modal function
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  //open modal function when  clicked button

  const handleSuccess = () => {
    setOpen(true);
  };

  return (
    <div>
      <Typography variant="Poster" component="h4" gutterBottom>
        Add New Deduction
      </Typography>
      <FormDeduction handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added new deduction"
        name="Car"
      />
    </div>
  );
};

export default AddDeduction;
