import React from "react";
import { showLocalDate } from "../../../../utilities/functions";

const PunchoutRender = ({ row }) => {
  return (
    <>
      {row.attendances.map((Punchout, index) => (
        <p style={{ borderBottom: "1px solid #F7F9FB" }}>
          {Punchout.punchOut ? showLocalDate(Punchout.punchOut) : ""}
        </p>
      ))}
    </>
  );
};

export default PunchoutRender;
