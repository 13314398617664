
const fields = [
  {
    name: "cid",
    label: "Company Name",
    required: true,
    select: true,
    validationType: "number",
    getData: "companyName",
    data: "companyName",

    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "sid",
    label: "Site Name",
    required: true,
    select: true,
    validationType: "number",
    getData: "siteName",
    data: "siteName",
  },
  {
    name: "eid",
    label: "Employee Name",
    required: true,
    select: true,
    validationType: "number",
    getData: "fullName",
    data: "fullName",
  },
  {
    name: "from",
    label: "From",
    required: true,

    validationType: "string",
    date: true,
  },
  {
    name: "to",
    label: "To",

    required: true,

    validationType: "string",
    date: true,
  },
];
export default fields;
