// React and Hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCompany } from "./Provider";
// UI
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
// custom render
import ActiveCell from "./CompanyTable/ActiveCell";
// style
import "../style.css";
import { useAuth } from "../../services/auth";

const CompanyList = () => {
  let navigate = useNavigate();
  let { user } = useAuth();
  let loc = useLocation();
  var BackPath = loc.pathname;
  const cols = [
    { label: "Code", name: "companyCode", checkbox: true, headTextLeft: true },
    {
      label: "Company Name",
      name: "companyName",
      headTextLeft: true,
      textLeft: true,
    },
    { label: "Subcompanies", name: "subCompaniesCount" },
    { label: "Sites Number", name: "sitesCount" },
    { label: "Employees Number", name: "employeesCount" },
    // { label: "Date Added", name: "webSite"  }, // API doesn't contain date!
    { label: "Company Status", name: "isActive", customRender: ActiveCell },
  ];

  const {
    state: { list: companies, count, totalSize, perPage, page },
    fetchList: fetchCompany,
    dispatch,
  } = useCompany();

  // fetch companies list
  useEffect(() => {
    fetchCompany(perPage, page);
  }, [fetchCompany]);

  // handle detail click
  const handleDetail = (c) => {
    navigate(`CompanyDetails/${c}`);
  };
  // handle search
  const handleSearch = (value) => {
    console.log(value);
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };
  const tabs = [{ title: "Companies", num: totalSize }];
  const [selectedCols, setSelectedCols] = useState("Companies");

  if (!companies) return <div className="loader"></div>;

  return (
    <>
      {/* <Box
        style={{
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Companies
      </Box> */}
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      />

      <CustomTable
        empty={companies?.length === 0 ? true : false}
        cols={cols}
        data={companies}
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          add: () => navigate("/companies/add"),
          //   search: handleSearch,
        }}
        page={page}
        isPagination={true}
        moreOptions={{
          detail: handleDetail,
        }}
        showButton={true}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
      ></CustomTable>
    </>
  );
};

export default React.memo(CompanyList);
