import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMemberShip } from "./Provider";
import { useCar } from "../Car/Provider";
import { useSite } from "../Sites/Provider";
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import ModalBox from "../../ui/ModalBox";
import BrandCarStyle from "../Car/BrandCarStyle";
import ColorCarStyle from "../Car/ColorCarStyle";
import CategoryStyle from "../Car/CategoryStyle";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";
import { handleErrorResponse } from "../../utilities/functions";

const DetailMemberShip = () => {
  let navigate = useNavigate();
  let theme = useTheme();

  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const {
    state: { selected: site },
    fetchOne: getSiteInfo,
  } = useSite();
  const TitleStyle = {
    p: theme.dimensions.padding / 4,
    color: theme.color.PaperlabelColor,
  };
  const TitleStyle2 = {
    p: theme.dimensions.padding / 4,
  };
  const TitleStyle3 = {
    p: theme.dimensions.padding / 4,
    color: theme.color.PaperlabelColor,
    textDecoration: "underline",
  };

  const {
    state: { selected: membership },
    fetchOne: getMemberInfo,
  } = useMemberShip();
  const {
    state: { list: cars, listColor: colors, listMaker: makers },
    fetchList: getCarslist,
    fetchListColor: getColors,
    fetchListMaker: getMakers,
    dispatch,
    remove: deleteCar,
    edit: editCar,
  } = useCar();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getMemberInfo(id);
  }, [getMemberInfo, id]);

  const tabs = [{ title: "Cars", num: cars?.length }];
  const [selectedCols, setSelectedCols] = useState("Cars");
  const [reload, setreload] = useState(false);
  const [error, setError] = useState(null);
  const getCols = (name) => {
    switch (name) {
      case "Cars":
        return [
          {
            label: "Car Plate",
            name: "plate",
            headTextLeft: true,
            textLeft: true,
            editable: true,
          },
          {
            label: "Car Brand",
            name: "carMakerId",
            customRender: BrandCarStyle,
            headTextLeft: true,
            editable: true,
            type: makers,
          },
          {
            label: "Car Color",
            name: "carColorId",
            customRender: ColorCarStyle,
            editable: true,
            type: colors,
          },
          {
            label: "Category",
            name: "categoryId",
            customRender: CategoryStyle,
            editable: true,
          },
        ];

        break;
    }
  };
  const getInfo = (data) => {
    switch (data) {
      case "companyName":
        return site?.company?.companyName ? site?.company?.companyName : "";
      case "siteName":
        return site?.siteName ? site?.siteName : "";
      default:
        break;
    }
  };
  const getData = (name) => {
    switch (name) {
      case "Cars":
        return cars;
        break;
    }
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await deleteCar(id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (e, ID) => {
    delete e[0].color;
    delete e[0].id;
    delete e[0].maker;
    delete e[0].member;
    try {
      await editCar(e[0], ID);
      handleSuccess();
      getCarslist(id);
    } catch (error) {
      setError({ title: handleErrorResponse(error.status) });

      getCarslist(id);
    }
  };
  useEffect(() => {
    getCarslist(id);
  }, [getCarslist, id, reload]);
  useEffect(() => {
    if (membership) getSiteInfo(membership?.siteId);
  }, [getSiteInfo, membership]);
  useEffect(() => {
    getColors();
  }, [getColors]);
  useEffect(() => {
    getMakers();
  }, [getMakers]);
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Membership Details
      </Typography>
      <Paper
        elevation={0}
        // square
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
          background: theme.color.PaperbgColor,
          width: "90%",
        }}
      >
        <Typography variant="Poster" component="h4" gutterBottom>
          Membership Information
        </Typography>
        <Grid container>
          {fields.map((f) => {
            if (f.editOnly) return;
            return (
              <Grid item xs={2} sm={4} md={4} mt={2} sx={{ fontSize: "14px" }}>
                <span style={{ color: theme.color.PaperlabelColor }}>
                  {f.label} :{" "}
                </span>
                <span>{membership?.[f?.name] && membership?.[f?.name]}</span>
                {getInfo(f?.viewlabel)}
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate(`Update`)}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      </Paper>

      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      ></TabsList>
      {error ? (
        <Alert severity="error" sx={{ m: 3 }}>
          <ErrorsList error={error} />
        </Alert>
      ) : (
        ""
      )}
      <CustomTable
        empty={cars?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        // moreOptions={{
        //   detail: handleDetail,
        // }}

        actions={{
          selectedCols: true,
          inTableEdit: true,
          edit: handleEdit,
          delete: handleDelete,
        }}
        filters={{
          add: () => {
            navigate(`/membership/MembershipDetails/AddCar/${id}`);
          },
          search: (e) => {
            console.log(e);
          },
        }}
        dispatch={dispatch}
        showButton={true}
        rowsPerPage={10}
        title={"Cars"}
        isPagination={false}
      ></CustomTable>
      <ModalBox
        open={open}
        operation="edit"
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
        name={`${selectedCols}`}
      />
    </>
  );
};

export default DetailMemberShip;
