import { Button } from "@mui/material";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  width: fit-content;
  margin: 10px auto;

  button {
    margin: 0 10px;
    color: white;
    font-size: 11px;
  }
`;

function EmployeeActions({ row, actions, id, editing ,setEditing }) {
  const handleEdit = (id) => {
    editing && actions.edit(id);
  };
  const handleDelete = (id) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Are you sure?</h1>
              <p>You want to delete?</p>
              <Button
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: "10px",
                }}
                onClick={() => {
                  actions.delete(id);
                  onClose();
                }}
              >
                Yes, Delete it!
              </Button>
              <Button
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  margin: "10px",
                }}
                onClick={onClose}
              >
                No
              </Button>
            </div>
          );
        },
      });
  };

  return (
    <ButtonWrapper>
      {row?.user === null ? (
        <>
          {actions.edit && (
            <Button
              onClick={() => {
                actions.inTableEdit ? setEditing(id) : actions.edit(id);
              }}
              style={{ backgroundColor: "#FFC555", width: "150px" }}
            >
              Edit
            </Button>
          )}
          {actions.delete && (
            <Button
              onClick={() => {
                actions.delete(id);
              }}
              style={{ background: "#FF4747" }}
            >
              Delete
            </Button>
          )}
        </>
      ) : (
        <>
          {actions.edit && (
            <Button
              onClick={() => {
                actions.inTableEdit ? setEditing(id) : actions.edit(id);
              }}
              style={{ backgroundColor: "#FFC555", width: "150px" }}
            >
              Edit
            </Button>
          )}
        </>
      )}
    </ButtonWrapper>
  );
}

export default EmployeeActions;
