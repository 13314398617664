import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
//styled
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ErrorsList from "../../ui/ErrorsList";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useSite } from "../Sites/Provider";
import { useMemberShip } from "./Provider";
import {
  CalDate,
  handleErrorResponse,
  showDate,
} from "../../utilities/functions";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

const locales = ["en-gb"];

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const GridGroup = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 42px;
`;
// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape({
  mobile: Yup.string()
    .max(10, "mobile number must be less than 10num")
    .required("require field"),
  memberName: Yup.string().required("require field"),
  balance: Yup.number()
    .min(0, "balance must be greater than 0")
    .required("require field"),
  expireDate: Yup.date(),
  isActive: Yup.boolean(),
});
const FormMemberShip = ({ handleSuccess, membership }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  const [error, seterror] = useState(null);
  const [locale, setLocale] = useState("en-gb");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: site },
    fetchOne: getSiteInfo,
  } = useSite();
  const {
    state: { filter },
    edit,
  } = useMemberShip();
  useEffect(() => {
    getSiteInfo(filter?.siteId);
  }, [getSiteInfo, membership]);
  const onSubmit = async (values) => {
    delete values.companyName;
    if (values.balance < 0) {
      seterror({ title: "Balance must be equal or greater than 0" });
    } else {
      !values.expireDate
        ? (values.expireDate = CalDate(membership?.expireDate))
        : (values.expireDate = CalDate(values.expireDate));
      try {
        await edit(values, id);
        handleSuccess();
        seterror(null);
      } catch (error) {
        seterror({ title: handleErrorResponse(error.status, "Member Ship") });
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Card
          sx={{
            p: theme.dimensions.padding,
            mt: theme.dimensions.mt,
            mb: theme.dimensions.mb,
          }}
        >
          <Typography variant="Poster" component="h4" mb={3} gutterBottom>
            Membership Details
          </Typography>
          {error ? (
            <Alert severity="error" sx={{ m: 3 }}>
              <ErrorsList error={error} />
            </Alert>
          ) : (
            ""
          )}
          <GridGroup>
            {fields.map((field, index) => {
              if (field.viewOnly) return null;
              const inputProps = { ...field.inputProps };

              if (index === 0) inputProps.autoFocus = true;
              inputProps.type = field.type;
              return (
                <>
                  {field.disable && (
                    <div>
                      <Typography variant="body2" component="h6" gutterBottom>
                        {field.label}
                      </Typography>

                      <Box
                        sx={{
                          borderBottom: "1px solid gray",
                          width: "290px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {field.label === "Company Name"
                          ? site?.company?.companyName
                          : site?.siteName}
                      </Box>
                    </div>
                  )}
                  {!field.switch && !field.date && !field.disable && (
                    <Box key={index}>
                      <TextField
                        error={!!errors[field.name]}
                        sx={{ width: theme.dimensions.width }}
                        size="small"
                        label={!field.file && field.label}
                        inputProps={{
                          ...register(field?.name),
                        }}
                        fullWidth
                        type={field.validationType}
                        defaultValue={membership?.[field.name]}
                        helperText={errors[field.name]?.message}
                      />
                    </Box>
                  )}
                  {field.date && (
                    <LocalizationProvider
                      key={field.name}
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}
                    >
                      <div components={["DatePicker"]}>
                        <DatePicker
                          defaultValue={dayjs(membership?.[field.name])}
                          label={field.label}
                          onChange={(date) => setValue(field.name, date)}
                          error={!!errors[field.name]}
                          sx={{ width: theme.dimensions.width }}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              helperText: (
                                <p style={{ color: "#d32f2f" }}>
                                  {errors[field.name]?.message}
                                </p>
                              ),
                              size: "small",
                            },
                          }}
                        />
                      </div>
                    </LocalizationProvider>
                  )}
                  {field.switch && (
                    <Box key={index}>
                      <FormGroup>
                        <FormLabel
                          component="legend"
                          sx={{
                            mt: theme.dimensions.mt,
                          }}
                        >
                          {field.label}
                        </FormLabel>
                        <FormControlLabel
                          sx={{
                            mt: theme.dimensions.mt,
                          }}
                          control={
                            <Switch
                              color="success"
                              defaultChecked={membership?.[field.name]}
                            />
                          }
                          {...register(field.name)}
                          label="Active"
                        />
                      </FormGroup>
                    </Box>
                  )}
                </>
              );
            })}
          </GridGroup>
          <ButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                ml: theme.dimensions.ml,
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: theme.color.grayBackground,
                color: theme.color.dark,
                "&:hover": { background: `${theme.color.gray50}` },
              }}
            >
              Discard
            </Button>
          </ButtonWrapper>
        </Card>
      </form>
    </>
  );
};

export default React.memo(FormMemberShip);
