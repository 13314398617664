import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import fields from "./fields";
import styled from "styled-components";
import { useCompany } from "../../../Companies/Provider";
import { useSite } from "../../../Sites/Provider";
import { useEmployees } from "../../../employee/Provider";
import { useVendor } from "../../Vendors/Provider";
import { useMemory } from "../Provider";

/**form  */
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterial } from "../../Materials/Provider";
import { BiTrash } from "react-icons/bi";
import { CalDate } from "../../../../utilities/functions";

import ErrorsList from "../../../../ui/ErrorsList";
import dayjs from "dayjs";
import { useAuth } from "../../../../services/auth";

import "dayjs/locale/de";
import "dayjs/locale/en-gb";

const locales = ["en-gb"];
//const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape({
  typeId: Yup.number().required("select memo type"),
  companyId: Yup.number().required("select company"),
  siteId: Yup.number(),
  employeeId: Yup.number(),
  vendorId: Yup.number(),
  memoDate: Yup.date(),
  leadTime: Yup.number("must be number"),
  notes: Yup.string(),
  details: Yup.array().of(
    Yup.object().shape({
      stockMaterialId: Yup.number().required("select material"),
      quantity: Yup.number().min(1, "must be equal or greater than 1"),
      price: Yup.number(),
    })
  ),
});

const GridGroup = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  grid-template-columns: 1fr 1fr 1fr;
gap:5px
  grid-template-rows: 70px;
  grid-column-gap: 10px;
`;
const GridGroup2 = styled.div`
  display: grid;
  padding: 20px 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 50%;
  grid-column-gap: 10px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormMemo = ({ handleSuccess, memo }) => {
  const { role, adminOn } = useAuth();

  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  const { name } = useParams();
  const [locale, setLocale] = useState("en-gb");

  const [companyID, setCompanyID] = useState(memo ? memo?.company?.id : 0);
  const [siteId, setSiteId] = useState(memo ? memo?.siteId : "");
  const [materials, setMaterials] = useState(
    memo
      ? memo?.viewDetails
      : Array(1)
          .fill(0)
          .map(() => ({ stockMaterialId: 0, quantity: 0, price: 0 }))
  );
  const [setMaterialId] = useState();
  const [error, setError] = useState({ title: "" });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      vendorId: memo?.vendorId ? memo?.vendorId : 0,
      employeeId: memo?.employee?.id ? memo?.employee?.id : 0,
      siteId: memo?.site?.id ? memo?.site?.id : 0,
    },
  });

  /**get companies */
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  /**get resources from memory provider */
  const {
    state: { typeList },
    fetchTypeList,
    create,
    edit,
  } = useMemory();
  /**get resources from site provider */
  const {
    state: { Names: Sites },
    fetchSiteNames: fetchSites,
  } = useSite();
  /**get resources from employee provider */
  const {
    state: { Names: Employees },
    fetchNames: fetchEmployees,
  } = useEmployees();
  /**get vendors */
  const {
    state: { list: listVendors },
    fetchList: fetchVendors,
  } = useVendor();
  /**get materials */
  const {
    state: { list: listMaterials },
    fetchList: fetchMaterials,
  } = useMaterial();

  /**invoke companies  */
  useEffect(() => {
    if (role === "Admin") fetchCompany();
  }, [fetchCompany, role]);

  /**invoke types */
  useEffect(() => {
    fetchTypeList();
  }, [fetchTypeList]);
  /**invoke Sites */
  useEffect(() => {
    if (role === "Admin") {
      fetchSites(companyID, 0);
    } else if (role === "Company") {
      fetchSites(adminOn?.id, 0);
    }
  }, [companyID, adminOn?.id, fetchSites, role]);
  /**invoke Employees */
  useEffect(() => {
    if (role === "Admin") {
      if (siteId) fetchEmployees(companyID, siteId);
    } else if (role === "Company") {
      if (siteId) fetchEmployees(adminOn?.id, siteId);
    }
  }, [siteId, adminOn?.id, companyID, fetchEmployees, role]);
  /**invoke vendors */
  useEffect(() => {
    if (role === "Admin") {
      fetchVendors({ companyId: -1 });
    } else if (role === "Company") {
      fetchVendors({ companyId: adminOn?.id });
    }
  }, [companyID, adminOn?.id, role, fetchVendors]);
  /**invoke materials */
  useEffect(() => {
    if (role === "Admin") {
      fetchMaterials({ companyId: -1, categoryId: 0 });
    } else if (role === "Company") {
      fetchMaterials({ companyId: adminOn?.id, categoryId: 0 });
    }
  }, [companyID, adminOn?.id, role, fetchMaterials]);

  const getData = (name) => {
    switch (name) {
      case "companyId":
        return companies;

      case "typeId":
        return typeList;
      case "siteId":
        return Sites;

      case "employeeId":
        return Employees;

      case "vendorId":
        return listVendors;
      default:
        return [];
    }
  };
  /**value of item */
  const getValue = (name) => {
    switch (name) {
      case "companyId":
        return "name";
      case "typeId":
        return "description";
      case "siteId":
        return "name";
      case "employeeId":
        return "name";
      case "vendorId":
        return "name";
      default:
        break;
    }
  };
  useEffect(() => {
    if (role === "Company") setValue("companyId", adminOn?.id);
  }, [role, setValue, adminOn?.id]);

  /**handle Submit */
  const onSubmit = async (values) => {
    setError({ title: "" });
    values.memoDate
      ? (values.memoDate = CalDate(values.memoDate))
      : (values.memoDate = memo?.memoDate);

    try {
      if (memo) {
        await edit(id, values);
      } else {
        await create(values);
      }
      handleSuccess();
    } catch (error) {
      setError({ title: error.errors.message });
    }
  };

  const removeMaterial = (index) => {
    setMaterials(materials.filter((m, i) => i !== index));
  };

  const DetailSection = () => {
    if (materials.length === 0) return;

    return materials.map((material, index) => (
      <Box sx={{ ml: "25px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl
            sx={{
              width: theme.dimensions.width,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <span style={{ fontSize: "12px" }}>Materials</span>
            <Select
              sx={{
                mt: theme.dimensions.mt,
              }}
              onChange={(e) => setMaterialId(e.target.value)}
              inputProps={{
                ...register(`details.${index}.stockMaterialId`),
              }}
              defaultValue={material?.stockMaterialId}
              size="small"
            >
              {listMaterials.map((material, index) => (
                <MenuItem key={index} value={material.id}>
                  {material.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: theme.dimensions.width,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
              ml: theme.dimensions.ml,
            }}
          >
            <span style={{ fontSize: "12px" }}>Quantity</span>
            <TextField
              sx={{
                mt: theme.dimensions.mt,
              }}
              defaultValue={1}
              type="number"
              size="small"
              inputProps={{
                ...register(`details.${index}.quantity`),
              }}
            />
          </FormControl>
          <FormControl
            sx={{
              width: theme.dimensions.width,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
              ml: theme.dimensions.ml,
            }}
            error={!!errors[`details.${index}.price`]}
            helperText={errors[`details.${index}.price`]?.message}
          >
            <span style={{ fontSize: "12px" }}>Price</span>
            <TextField
              type="number"
              sx={{
                mt: theme.dimensions.mt,
              }}
              defaultValue={material?.price}
              size="small"
              inputProps={{
                ...register(`details.${index}.price`),
              }}
              slotProps={{
                textField: {
                  error: errors[`details.${index}.price`]?.message,
                  helperText: (
                    <p style={{ color: "#d32f2f" }}>
                      {errors[`details.${index}.price`]?.message}
                    </p>
                  ),
                  size: "small",
                },
              }}
            />
          </FormControl>

          <BiTrash
            onClick={() => removeMaterial(index)}
            style={{ margin: "15px" }}
          />
        </Box>
      </Box>
    ));
  };
  const addMaterial = () => {
    setMaterials((materials) => {
      materials.push({
        stockMaterialId: 0,
        quantity: 0,
        price: 0,
      });
      return [...materials];
    });
  };
  useEffect(() => {
    if (role === "Admin") {
      setValue("companyId", 0);
    }
  }, [role, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <GridGroup>
          {fields?.map((f, index) => (
            <Box key={index}>
              {role === "Company" && f.name === "companyId" && (
                <Box>
                  <span style={{ fontSize: "12px" }}>Company</span>

                  <Box
                    sx={{
                      borderBottom: "1px solid gray",
                      width: "290px",
                      borderRadius: "8px",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {adminOn?.companyName}
                  </Box>
                </Box>
              )}
              {f.select && (
                <FormControl
                  error={!!errors[f.name]}
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,

                    display:
                      f.name === "vendorId" && name !== "In"
                        ? "none"
                        : null || (f.name === "siteId" && name === "In")
                        ? "none"
                        : null ||
                          (f.name === "typeId" && "none") ||
                          (f.name === "employeeId" && name === "In")
                        ? "none"
                        : null || (role === "Company" && f.name === "companyId")
                        ? "none"
                        : null ||
                          (role === "Admin" &&
                            f.name === "companyId" &&
                            name === "In")
                        ? "none"
                        : null,
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {f.label}
                  </span>
                  <Select
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                    onChange={
                      f.name === "companyId"
                        ? (e) => setCompanyID(e.target.value)
                        : f.name === "siteId"
                        ? (e) => {
                            setSiteId(e.target.value);
                          }
                        : null
                    }
                    inputProps={
                      f.name !== "companyId" && {
                        ...register(f?.name),
                        defaultValue: memo?.[f.name],
                      }
                    }
                    disabled={f.name === "siteId" && !companyID && siteId !== 0}
                    defaultValue={memo && memo?.[f.name]}
                    size="small"
                  >
                    {getData(f.name).map((item, index) => (
                      <MenuItem value={item.id}>
                        {item?.[getValue(f.name)]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          ))}
        </GridGroup>
        <GridGroup2>
          {fields.map((f, index) => (
            <Box key={index}>
              {f.date && (
                <FormControl components={["DatePicker"]}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <span style={{ fontSize: "12px", marginBottom: "5px" }}>
                      {f.label}
                    </span>
                    <DatePicker
                      error={!!errors[f.name]}
                      sx={{
                        width: theme.dimensions.width,
                      }}
                      onChange={(date) => setValue(f.name, date)}
                      defaultValue={memo && dayjs(memo?.[f.name])}
                      slotProps={{
                        textField: {
                          required: f.required,
                          error: errors[f.name]?.message,
                          helperText: (
                            <p style={{ color: "#d32f2f" }}>
                              {errors[f.name]?.message}
                            </p>
                          ),
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
              {!f.select && !f.date && (
                <FormControl>
                  <span style={{ fontSize: "12px", marginBottom: "5px" }}>
                    {f.label}
                  </span>
                  <TextField
                    name={f.name}
                    error={!!errors[f.name]}
                    helperText={errors[f.name]?.message}
                    defaultValue={memo?.[f.name]}
                    fullWidth
                    required
                    sx={{
                      width: theme.dimensions.width,
                    }}
                    inputProps={{
                      ...register(f?.name),
                      required: f.required,
                    }}
                    size="small"
                  ></TextField>
                </FormControl>
              )}
            </Box>
          ))}
        </GridGroup2>
        <Box sx={{ display: "flex" }}>
          <Box>{DetailSection()}</Box>
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              margin: "15px",
              cursor: "pointer",
            }}
          >
            <p onClick={addMaterial}>+ Add more materials</p>
          </div>
        </Box>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            {memo ? <>Update</> : <>Add</>} Memo
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default FormMemo;
