import React from "react";

const EmployeeZones = ({ row }) => {
  return (
    <div>
      {row.roles.map((role, index) => (
        <div style={{ marginBottom: "10px" }} key={index}>
          {role?.zones ? (
            <>
              {role?.zones?.map((zone, i) => (
                <span>
                  {zone?.name}
                  {","}
                </span>
              ))}
            </>
          ) : (
            <p>
              <em>All</em>
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default EmployeeZones;
