// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Material UI
import { Button, Typography, useTheme } from "@mui/material";
// Custom UI
import CustomTable from "../../../ui/CustomTable";
import TabsList from "../../../ui/TabsList";
// Third Party Components
import FilterForm from "./FilterForm";
// Providers
import { useMaterial } from "./Provider";
// Custom Render
import CategoryCell from "./Table/CategoryCell";
import CompanyCell from "./Table/CompanyCell";

function List() {
  let navigate = useNavigate();
  let theme = useTheme();

  const {
    state: { list: listMaterials, FilterData, totalSize },
    remove: removeMaterial,
    fetchList: fetchMaterials,
    dispatch,
  } = useMaterial();
  const [selectedCols, setSelectedCols] = useState("Materials");
  const [filterClick, setFilterClick] = useState(false);
  const [vState, setVState] = useState(false);

  const tabs = [{ title: "Materials", num: totalSize }];
  const getCols = () => {
    return [
      { label: "Material Name", name: "name" },
      { label: "Material Number", name: "number" },
      { label: "Material Company", name: "company", customRender: CompanyCell },
      { label: "Start Quantity", name: "startQuantity" },
      { label: "Current Quantity", name: "currentQuantity" },
      { label: "Unit", name: "unit" },
      { label: "Description", name: "description" },
    ];
  };

  const getData = () => {
    return listMaterials;
  };

  const getActions = () => {
    return {
      selectedCols: true,
      edit: (id) => {
        navigate("/store/materials/edit/" + id);
      },
      delete: (e) => {},
      check: (v) => v.length > 0,
      vState: vState,
      setVState: setVState,
    };
  };

  const handleDetail = (id) => {
    navigate(`detail/${id}`);
  };

  const handleClick = () => {
    // return handleExportExcel();
  };
  return (
    <>
      <Typography variant="Poster" component="h4">
        Select Category or Unit
      </Typography>

      <FilterForm setFilterClick={setFilterClick}></FilterForm>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
        {/* {filterClick && (
          <Button
            sx={{
              border: `1px solid ${theme.color.Screamingreen}`,
              color: theme.color.Screamingreen,
              mt: "5px",
              textTransform: "capitalize",
              "&:hover": { background: `${theme.color.gray50}` },
            }}
            onClick={handleClick}
          >
            Export to Excel
          </Button>
        )} */}
      </div>

      <CustomTable
        empty={getData(selectedCols)?.length === 0 ? true : false}
        cols={getCols()}
        data={getData()}
        actions={getActions()}
        // showButton={true}
        // moreOptions={{
        //   detail: handleDetail,
        // }}
        filters={{
          add: () => navigate("add"),
        }}
        dispatch={dispatch}
        isPagination={false}
      ></CustomTable>
    </>
  );
}

export default List;
