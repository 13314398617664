import React from "react";
import { showLocalDate } from "../../../utilities/functions";

function FingerPrintCell({ row, id }) {
  if (!row?.fingerPrintDate) return "not specified";
  return (
    <div>
      Updated at:{" "}
      {row?.fingerPrintDate ? showLocalDate(row?.fingerPrintDate) : ""}
    </div>
  );
}

export default FingerPrintCell;
