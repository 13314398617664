const Filter = [
  {
    name: "companyId",
    label: "Company Name",
    required: true,
    select: true,
    companyName: "name",
  },
];
export default Filter;
