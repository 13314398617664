import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCompany } from "./Provider";
import { useNavigate } from "react-router-dom";
// components
import FormCompany from "./FormCompany";
import ModalBox from "../../ui/ModalBox";

const EditCompany = () => {
  const navigate = useNavigate();
  let params = useParams();
  const id = params.id;
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompany();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchCompany(id);
  }, [id, fetchCompany]);
  const handleSuccess = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  return (
    <div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Edit Company
      </div>
      <div>
        <FormCompany company={company} handleSuccess={handleSuccess} />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="edit"
        text="The update operation has been completed successfully.. You modified the company profile "
        name={company?.companyName}
      />
    </div>
  );
};

export default React.memo(EditCompany);
