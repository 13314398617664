import { Typography } from "@mui/material";
import React, { useState } from "react";
import FormWhiteIps from "./FormWhiteIps";
import { useNavigate } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";

const AddWhiteIps = () => {
  const [open, setOpen] = useState(false);
  //var to navigate another page

  const navigate = useNavigate();
  // close modal function

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  //open modal function

  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Add IP
      </Typography>
      <FormWhiteIps handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added new White IP"
        name="White IP"
      />
    </>
  );
};

export default AddWhiteIps;
