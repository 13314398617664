import { Grid, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import fields from "./Form/fields";
import { useParams } from "react-router-dom";
import { useMemory } from "./Provider";
import TabsList from "../../../ui/TabsList";
import CustomTable from "../../../ui/CustomTable";

const DetailMemo = () => {
  let theme = useTheme();

  const { id } = useParams();
  const {
    state: { selected: Memo },
    fetchOne,
  } = useMemory();

  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id]);
  const tabs = [{ title: "View Details", num: Memo?.viewDetails?.length }];
  const [selectedCols, setSelectedCols] = useState();

  const col = [
    {
      label: "Material Name",
      headTextLeft: true,
      textLeft: true,
      name: "materialName",
    },
    {
      label: "Quantity",
      name: "quantity",
    },
    { label: "Price", name: "price" },
  ];
  if (!Memo) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Memo Details
      </Typography>
      <Paper
        elevation={0}
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
          background: theme.color.PaperbgColor,
          width: "90%",
        }}
      >
        <Typography variant="Poster" component="h4" gutterBottom>
          Memo Information
        </Typography>

        <Grid container>
          {fields.map((f, index) => {
            if (f.editOnly) return;
            return (
              <Grid
                item
                xs={2}
                sm={4}
                md={4}
                mt={2}
                key={index}
                sx={{ fontSize: "14px" }}
              >
                <span style={{ color: theme.color.PaperlabelColor }}>
                  {f.label}:{" "}
                </span>
                <span>
                  {f.viewNameObject
                    ? Memo?.[f.viewNameObject]?.[f.viewName]
                    : Memo?.[f.viewName]}
                </span>
              </Grid>
            );
          })}
        </Grid>
      </Paper>

      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      ></TabsList>

      <CustomTable
        empty={Memo?.viewDetails?.length === 0}
        cols={col}
        data={Memo?.viewDetails}
        isPagination={false}
      ></CustomTable>
    </>
  );
};

export default DetailMemo;
