import React from "react";

const ZonesCell = ({ row }) => {
  return (
    <div>
      {row.roles?.map((role, index) => {
        return (
          <div
            style={{
              marginBottom: "10px",
              height: "30px",
            }}
            key={index}
          >
            {role?.zones ? (
              role?.zones?.map((zone, index) => (
                <span key={index}>
                  {zone?.name} {" , "}
                </span>
              ))
            ) : (
              <em>All</em>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ZonesCell;
