const addRoleFields = [
  {
    name: "roleId",
    label: "Role",
    data: "Roles",
    error: "roleId",
    registerName: "roleId",
    width: "300px",
    select:true,
    xs: 4.5,
    required: true,
  },
  {
    name: "hourlyRate",
    label: "Hourly Rate",
    registerName: "hourlyRate",
    type: "number"
  },
];
export default addRoleFields;
