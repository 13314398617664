const Filter = [
  {
    name: "companyId",
    label: "Company Name",
    required: true,
    select: true,
    selectData: "Companies",
    selectValueSelector: "id",
    selectSelector: "name",
  },
  {
    name: "categoryId",
    label: "Category Name",
    select: true,
    selectData: "MaterialCategories",
    selectValueSelector: "id",
    selectSelector: "name",
  },
  {
    name: "unit",
    label: "Unit",
    select: true,
    selectData: "unit",
    selectSelector: "desription",
    selectValueSelector: "desription",
  },
];
export default Filter;
