import React from "react";
import { Box, Grid, Typography, useTheme, Paper, Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import DotColorful from "../../../icons/DotColorful.svg";
// import DotColorless from "../../../icons/DotColorless.svg";
import { useNavigate } from "react-router-dom";
import fields from "../fields";
import { useAuth } from "../../../services/auth";

function BoxInfo({ subCompany = [] }) {
  // const labelsCol1 = [
  //   { label: "Company Name", name: "company", secondName: "companyName" },
  //   { label: "Sub Company Name", name: "subCompanyName" },
  // ];
  // const labelsCol2 = [{ label: "Sub Company Status", name: "isActive" }];

  const navigate = useNavigate();
  const theme = useTheme();
  const { role, adminOn } = useAuth();
  //   Styles
  // const TitleStyle = {
  //   p: theme.dimensions.padding / 4,
  //   color: theme.color.PaperlabelColor,
  // };
  // const TitleStyle2 = {
  //   p: theme.dimensions.padding / 4,
  // };
  const getData = (data) => {
    switch (data) {
      case "companyName":
        return subCompany?.company?.companyName
          ? subCompany?.company?.companyName
          : "";
      default:
        break;
    }
  };
  return (
    <Paper
      elevation={0}
      // square
      sx={{
        p: theme.dimensions.padding,
        mt: theme.dimensions.mt,
        mb: theme.dimensions.mb,
        background: theme.color.PaperbgColor,
        width: "90%",
      }}
    >
      <Typography variant="Poster" component="h4" gutterBottom>
        Sub Company Information
      </Typography>
      <Grid container>
        {fields.map((f, index) => {
          if (f.editOnly) return;
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              mt={2}
              key={index}
              sx={{ fontSize: "14px" }}
            >
              <span style={{ color: theme.color.PaperlabelColor }}>
                {f.label} :{" "}
              </span>
              <span>
                {subCompany?.[f?.name] && subCompany?.[f?.name]}
                {getData(f.viewLabel2)}
                {f.name === "isActive" &&
                  (subCompany?.isActive ? (
                    <span style={{ color: "#4AA785" }}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: 10, mr: 0.2 }}
                      />
                      Active
                    </span>
                  ) : (
                    <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: 10, mr: 0.2 }}
                      />
                      Inactive
                    </span>
                  ))}
              </span>
            </Grid>
          );
        })}
      </Grid>

      {role === "Admin" && (
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      )}
      {role === "Company" && (
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      )}
    </Paper>
  );
}

export default BoxInfo;
