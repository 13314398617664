import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../../services/request";

const Context = createContext();

export function useMaterial() {
  return useContext(Context);
}

const initState = {
  list: [],
  FilterData: { companyId: 0, categoryId: 0, unit: null },
  ExportExcelList: [],
  countExcel: 0,
  selected: null,
  count: 1,
  page: 1,
  perPage: 10,
  totalSize: 0,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_FilterData":
      return { ...state, FilterData: { ...action.payload } };
    case "set_ExportExcelList":
      return { ...state, ExportExcelList: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_countExcel":
      return { ...state, countExcel: action.payload };
    case "set_countExcelVoid":
      return { ...state, countVoidExcel: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_totalSize":
      return { ...state, totalSize: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function MaterialProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (data) => {
      let headers = {
        categoryId: data?.categoryId ? data?.categoryId : 0,
      };
      if (data.unit) headers.unit = data.unit;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Stock/MaterialList/${data?.companyId}`,
        null,
        {},
        true,
        headers
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_list",
        payload: resData.data || [],
      });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_count",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSize",
        payload: resData.data.length || 0,
      });
    },
    [req, state.page, state.perPage]
  );
  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Stock/MaterialInfo/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "Stock/AddMaterial",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
          // console.log(resData, "resData");
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Stock/UpdateMaterial/${id}`,
            data,
            { method: "PUT" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Stock/DeleteMaterial/${id}`,
            null,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        fetchOne,
        edit,
        create,
        remove,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
