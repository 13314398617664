import React from "react";

const TotalAmount = ({ row }) => {
  return (
    <div>
      <div style={{ paddingBottom: "10px" }}>{row?.totalAmount}</div>
    </div>
  );
};

export default TotalAmount;
