const detailsFields = [
  {
    name: "companyName",
    label: "Company Name",
    viewLabel: "company",
    viewLabel2: "companyName",
  },
  {
    name: "isActive",
    label: "Site Zone Status",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "siteName",
    label: "Site Name",
    viewLabel: "site",
    viewLabel2: "siteName",
  },
  {
    name: "title",
    label: "Site Zone Name",
    viewLabel: "title",
  },
];

export default detailsFields;
