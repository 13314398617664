import React from "react";

function WebLinkCell({ row }) {
  return (
    <>
      {row?.weblink === "" ? (
        <span style={{ color: "gray" }}>not specified</span>
      ) : (
        row?.weblink
      )}
    </>
  );
}

export default WebLinkCell;
