import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useWebContent } from "./provider";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import fields from "./fields";
import styled from "styled-components";
import { ShowPrice } from "../../utilities/Enum";
import { useSite } from "../Sites/Provider";

const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormWebContent = ({ handleSuccess, WebContentInfo }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: webContent },
    setWebContent,
  } = useWebContent();
  const {
    state: { selected: site },
  } = useSite();

  useEffect(() => {
    console.log(site, "site");
  });
  const onSubmit = async (values) => {
    try {
      await setWebContent(id, values);
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          Web Content Details
        </Typography>
        <div>
          {fields?.map((f, index) =>
            !f.switch ? (
              <>
                {f.select ? (
                  <>
                    <FormControl
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      size="small"
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        htmlFor="demo-simple-select"
                      >
                        {f.label}
                        {" *"}
                      </InputLabel>
                      <Select
                        label={f.label}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        error={!!errors[f.name]?.message}
                        inputProps={{
                          defaultValue:
                            site.country === "Saudi Arabia" ||
                            site.address === "Dubai"
                              ? 1
                              : WebContentInfo?.showPrice,
                          ...register(f?.name),
                        }}
                      >
                        {ShowPrice?.map((c, i) => (
                          <MenuItem key={i} value={c.id}>
                            {c.desription}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        htmlFor="demo-simple-select"
                        sx={{ color: "#d32f2f" }}
                      >
                        {errors[f.name]?.message}
                      </FormHelperText>
                    </FormControl>
                  </>
                ) : (
                  <div style={{ marginBottom: "20px" }}>
                    <TextField
                      key={index}
                      sx={{
                        width: theme.dimensions.width * 2,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      type={f.type}
                      error={!!errors[f.name]}
                      size="small"
                      inputProps={{
                        ...register(f?.name),
                        defaultValue: WebContentInfo?.[f.name],
                      }}
                      multiline={f.name != "caption" ? true : false}
                      rows={3}
                      maxRows={10}
                      required={f.required}
                      label={f.label}
                    />
                  </div>
                )}
              </>
            ) : (
              <Box>
                <FormGroup>
                  <FormLabel
                    component="legend"
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                  >
                    {f.label}
                  </FormLabel>
                  <FormControlLabel
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                    control={
                      <Switch
                        color="success"
                        defaultChecked={
                          WebContentInfo
                            ? WebContentInfo?.showOfficialPrice
                            : true
                        }
                      />
                    }
                    {...register(f?.name)}
                    label="Active"
                  />
                </FormGroup>
              </Box>
            )
          )}
        </div>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Save Web Content
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default FormWebContent;
