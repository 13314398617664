import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function usePayroll() {
  return useContext(Context);
}

const initState = {
  list: [],
  listEmployeesPayroll: [],
  listPayrollExcel: [],
  selected: null,
  selectedfile: null,
  filter: {
    companyId: 0,
    year: new Date().getFullYear(),
  },
  count: 1,
  countPayrollExcel: 0,
  page: 1,
  perPage: 10,
  totalSize: 0,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_listPayrollExcel":
      return { ...state, listPayrollExcel: [...action.payload] };
    case "set_list_employees_payroll":
      return { ...state, listEmployeesPayroll: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_countPayrollExcel":
      return { ...state, countPayrollExcel: action.payload };
    case "set_countEmployeePayroll":
      return { ...state, countEmployeePayroll: action.payload };
    case "set_filter":
      return { ...state, filter: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_totalSize":
      return { ...state, totalSize: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function PayrollProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchPayrollCompanies = useCallback(
    async (
      cid = state.filter.companyId,
      year = state.filter.year,
      page = state.page,
      perPage = state.perPage
    ) => {
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Payroll/PayrollCompanyList/${cid}, ${year}`,
        null,
        {},
        true,
        headersData
      );
      // console.log(resData, "resDataCompanies");
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data || [] });
      dispatch({ type: "set_count", payload: resData.data.totalPages || 1 });
      dispatch({ type: "set_totalSize", payload: resData.data.length || 0 });
    },
    [req]
  );
  const fetchPayrollEmployees = useCallback(
    async (pcid = 0, page = state.page, perPage = state.perPage) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      const resData = await req(
        `Payroll/PayrollEmployeeList/${pcid}`,
        null,
        {},
        true,
        headersData
      );
      // console.log(resData, "resDataEmployees");
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_list_employees_payroll",
        payload: resData.data.payrollEmployees || [],
      });
      // dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_count",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSize",
        payload: resData.data.totalSize || 0,
      });
    },
    [req]
  );
  const fetchPayrollEmployeesExcel = useCallback(
    async (pid, page, perPage) => {
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;

      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Payroll/PayrollEmployeeList/${pid}`,
        null,
        {},
        true,
        headersData
      );
      if (state.countPayrollExcel == 0) {
        dispatch({
          type: "set_countPayrollExcel",
          payload: resData.data.totalPages,
        });
      }
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_page", payload: page });
      state.listPayrollExcel.push(resData?.data?.payrollEmployees);
    },
    [req]
  );

  const fetchOne = useCallback(
    (peid = 0) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(
          `Payroll/PayrollDetail/${peid}`,
          null,
          {},
          true
        );
        // dispatch({ type: "set_selected", payload: resData.data });
        dispatch({
          type: "set_selected",
          payload: { rows: resData.data },
        });
        // console.log(resData, "info");
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  const create = useCallback(
    async (cid) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Payroll/Create/${cid}`,
            {},
            { method: "PUT" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const close = useCallback(
    async (pcid) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Payroll/Close/${pcid}`,
            {},
            { method: "PUT" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
          // console.log(resData, "resDatapayroll");
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const salaryPaid = useCallback(
    async (peid) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Payroll/SalaryPaid/${peid}`,
            {},
            { method: "PUT" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
          // console.log(resData, "salary");
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Site/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Site/Delete/${id}`,
            null,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: resData.data });
          // console.log(resData, "delete");
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchPayrollCompanies,
        fetchPayrollEmployees,
        fetchPayrollEmployeesExcel,
        fetchOne,
        edit,
        create,
        close,
        salaryPaid,
        remove,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
