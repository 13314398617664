import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
// import { create as createVendor } from "./Vendors/Provider";

const Context = createContext();

export function useStore() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  count: 1,
  page: 1,
  perPage: 10,
  totalSize: 0,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "status":
      return { ...state, status: action.payload };
    case "set_totalSize":
      return { ...state, totalSize: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
