import { Box } from "@mui/material";
import React from "react";

function UserRole({row, i}) {
  return (
    <Box style={{paddingTop :'5px', paddingBottom: '5px'}}>
      Employee
      <br /> Company Name: <strong>{row?.company?.companyName}</strong>
    </Box>
  );
}

export default UserRole;
