import { Route } from "react-router-dom";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import Employees from "./Employees";
import EmployeesList from "./EmployeesList";
import InfoEmployee from "./InfoEmployee/InfoEmployee";



export default (
  <Route path="employees" exact element={<Employees />}>
    <Route index element={<EmployeesList />} />
    <Route path="EmployeeDetails/:id" element={<InfoEmployee />}/>
    <Route path="add/:id" element={<AddEmployee />} />
    <Route path="edit/:id" element={<EditEmployee />} />

  </Route>
);
