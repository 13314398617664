import { useCallback } from "react";
import { apiUrl as baseUrl } from "../config";
import { useAuth } from "./auth";
var refreshing = false;
let commandsQueue = [];

export const useRequest = () => {
  const auth = useAuth();

  const queue = (...params) => {
    if (refreshing)
      return new Promise((resolve, reject) => {
        const command = () => {
          queue(...params).then(resolve, reject);
        };
        commandsQueue.push(command);
      });

    // var data;
    return new Promise(async (resolve, reject) => {
      try {
        if (refreshing) await new Promise((r) => setTimeout(r, 2000));
        if (params[3]) await checkToken();

        const res = await request(...params);

        if (res.status === 401) {
          if (auth) {
            auth.signout();
            window.location.replace(`/login?from=${window.location.pathname}`);
          }
          reject({ status: res.status, errors: "Unauthorised Access" });
        } else if (res.status === 403) {
          window.location.replace("/no-access");
          reject({ status: res.status, errors: "Access Denied" });
        } else if (!res.ok) {
          const eData = await res.json();
          reject({ status: res.status, errors: eData });
        }
        const resData = await (res.status !== 204 ? res.json() : {});
        resolve(resData);
      } catch (error) {
        reject(error);
      }
    });
  };
  return useCallback(queue, [auth]);
};

export default function request(
  url,
  data = null,
  options = {},
  withAuth = false,
  headers = {}
) {
  const defaultOptions = {
    method: data ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    referrerPolicy: "no-referrer",
    redirect: "follow",
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin",
    ...options,
  };
  try {
    if (data)
      if (defaultOptions.headers["Content-Type"] === "application/json")
        defaultOptions.body = JSON.stringify(data);
      else defaultOptions.body = data;
    if (withAuth)
      defaultOptions.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("tokens"))?.accessToken
      }`;
    return fetch(`${baseUrl}/${url}`, defaultOptions);
  } catch (error) {
    throw new Error(error);
  }
}

function checkToken() {
  return new Promise(async (resolve, reject) => {
    var Response = JSON.parse(localStorage.getItem("tokens"));
    if (!Response.accessToken) resolve();
    var duration =
      new Date(Response.expiration).getTime() -
      new Date(Response.issuedAt).getTime();
    var endDate = Response.localIssueAt + duration;
    var endDateWithoutMin = endDate - 6 * 60 * 1000;
    if (Date.now() > endDateWithoutMin) {
      refreshing = true;
      try {
        await refreshToken({
          accessToken: Response.accessToken,
          refreshToken: Response.refreshToken,
        });
        resolve();
      } catch (error) {
        reject(error);
      } finally {
        refreshing = false;
        commandsQueue.forEach((command) => command());
        commandsQueue = [];
      }
    }
    resolve();
  });
}

async function refreshToken(tokens) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request(`Auth/refreshToken`, {
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
      });
      const { data } = await res.json();
      if (data?.accessToken)
        //  window.localStorage.setItem("user", JSON.stringify(res.data.user));
        window.localStorage.setItem(
          "tokens",
          JSON.stringify({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            expiration: data.expiration,
            issuedAt: data.issuedAt,
            localIssueAt: Date.now(),
          })
        );
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
