import React from "react";

function DescriptionCell({ row }) {
  return (
    <>
      {row?.description === "" ? (
        <span style={{ color: "gray" }}>not specified</span>
      ) : (
        row?.description
      )}
    </>
  );
}

export default DescriptionCell;
