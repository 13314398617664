import React from 'react'

const NotesRender = ({row}) => {
  return (
    <>
    {row.payments.map((pay, index) => (
      <p style={{borderBottom:"1px solid #F7F9FB"}}>{pay.notes}</p>
    ))}
  </>
  )
}

export default NotesRender
