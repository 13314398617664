import React, { useEffect } from "react";
import FormTicket from "./FormTicket";
import { useSite } from "../Provider";

const AddTicket = ({ ticket }) => {
  const { dispatch } = useSite();
  useEffect(() => {
    // Clearing
    dispatch({ type: "set_div1", payload: [] });
    dispatch({ type: "set_div2", payload: [] });
    dispatch({ type: "set_div3", payload: [] });
    dispatch({ type: "set_div4", payload: [] });
  }, [dispatch]);
  // const navigate = useNavigate();
  // const [open, setOpen] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  //   navigate(-1);
  // };
  // const handleSuccess = () => {
  //   setOpen(true);
  // };
  return (
    <>
      <FormTicket
        ticket={ticket}
        // handleSuccess={handleSuccess}
      />
      {/* <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added ticket printing divisions "
      /> */}
    </>
  );
};

export default React.memo(AddTicket);
