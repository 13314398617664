const theme = {
  color: {
    primary: "#2A3F54",
    primaryBg: "#F6EEFF",
    pink: "#E328AF",
    rose: "#ca90bb",
    blueSky: "#5ECFFF",
    blue: "#4a5be6",
    green: "#38a8af",
    gray50: "#f5f5f5",
    gray100: "#eee",
    gray200: "#ddd",
    gray300: "#ccc",
    gray400: "#999",
    gray500: "#666",
    grayLight: "#a5a5a5",
    gray: "#424242",
    grayText: "#8f8f8f",
    grayBg: "#eee",
    dark: "#202020",
    red: "#DC3472",
    white: "#fff",
    PaperbgColor: "#F7F9FB",
    PaperlabelColor: "rgba(0, 0, 0, 0.4)",
    grayBackground: "rgba(0, 0, 0, 0.05)",
    blackBackground: "#1C1C1C",
    redBackground: "#FF4747",
    redHoverBackground: "#FF4738",
    black: "#000",
    titleColor: "rgba(0, 0, 0, 0.4)",
    Nyanza: "#D9FED7",
    Screamingreen: "#77AA74",
  },
  fonts: {
    popins: "'Poppins', sans-serif",
    openSans: "'Open Sans', sans-serif",
    size: 14,
  },
  palette: {
    mode: "light",
  },
  dimensions: {
    mt: 1,
    mb: 1,
    ml: 2,
    mr: 2,
    width: 300,
    padding: 3,
  },
};

export default theme;
