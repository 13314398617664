const fields = [
  {
    name: "companyName",
    label: "Company Name",
    data: "subCompany",
    disabled: true,
    value: "companyName",
    hasValue: true,
    viewLabel: "company",
    viewLabel2: "companyName",
  },
  {
    name: "siteIds",
    label: "Sub Company Sites",
    data: "sites",
    required: true,
    select: true,
    editOnly: true,
    validationType: "array",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
      {
        type: "nullable",
        params: ["required"],
      },
    ],
  },
  {
    name: "subCompanyName",
    label: "Sub Company Name",
    data: "subCompany",
    required: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "isActive",
    label: "Sub Company Status",
    data: "company",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
