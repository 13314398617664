//react components
import { Typography } from "@mui/material";
import React, { useState } from "react";
//react router

import { useNavigate } from "react-router-dom";
//dashbord components

import ModalBox from "../../ui/ModalBox";
//form gate component
import FormGate from "./FormGate";

const AddGate = () => {
  //state which check open or close modal
  const [open, setOpen] = useState(false);
  //var to navigate another page

  const navigate = useNavigate();
  // close modal function

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  //open modal function

  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom
      >
        Add New Gate
      </Typography>
      <FormGate handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added new Gate"
        name="Gate"
      />
    </>
  );
};

export default React.memo(AddGate);
