import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Yup
import * as Yup from "yup";
import { Typography } from "@mui/material";
import ModalBox from "../../ui/ModalBox";
import FormRole from "./FormRole";

const AddRole = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // yup schema
  const formSchema = Yup.object().shape({
    roleId: Yup.string().required(),
    hourlyRate: Yup.number()
      .required("this field is required")
      .min(0)
      .max(2147483647),
  });

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };

  return (
    <div>
      <Typography variant="Poster" component="h3" gutterBottom>
        Add Site Role
      </Typography>
      <div>
        <FormRole handleSuccess={handleSuccess} formSchema={formSchema} />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added a new site role "
      />
    </div>
  );
};

export default React.memo(AddRole);
