import {
  Alert,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
//styled
import styled from "styled-components";
//forms and validation it
import fields from "./fields";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { Box } from "@mui/system";
import {
  CarCategoryType,
  CarColorType,
  CarMakerType,
} from "../../utilities/Enum";
import e from "../../e.png";
import { useCar } from "./Provider";
import ErrorsList from "../../ui/ErrorsList";
import { handleErrorResponse } from "../../utilities/functions";
// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
const GridGroup1 = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 42px;
  grid-template-rows: 35px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormCar = ({ handleSuccess }) => {
  let navigate = useNavigate();
  const { id } = useParams();
  let theme = useTheme();
  const [error, seterror] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { listColor: colors, listMaker: makers },
    fetchListColor: getColors,
    fetchListMaker: getMakers,
    create,
  } = useCar();
  useEffect(() => {
    getColors();
  }, [getColors]);
  useEffect(() => {
    getMakers();
  }, [getMakers]);
  const onSubmit = async (values) => {
    try {
      await create(values);
      handleSuccess();
      seterror(null);
    } catch (error) {
      seterror({ title: handleErrorResponse(error.status, "Car") });
    }
  };
  // fetch data function
  const getData = (name) => {
    switch (name) {
      case "Category":
        return CarCategoryType;
      case "Brand":
        return makers;
      case "Color":
        return colors;
    }
  };
  useEffect(() => {
    setValue("siteMemberId", id);
  }, [id]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography
          variant="Poster"
          component="h4"
          data-testid="CarDetails"
          mb={3}
          gutterBottom
        >
          Car Details
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        ) : (
          ""
        )}
        <GridGroup1>
          {fields?.map((f, i) => {
            if (f.viewOnly) return null;
            const inputProps = { ...f.inputProps };

            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <Box key={i}>
                {f.select && (
                  <>
                    <FormControl
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        {f.label}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={f.name}
                        label={f.label}
                        inputProps={{
                          ...register(f?.name),
                          required: true,
                        }}
                        size="small"
                      >
                        {getData(f.getData)?.map((s, index) => (
                          <MenuItem key={index} value={s.id}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {f.name === "carColorId" && (
                                <div
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "8px",
                                    border: "solid 1px gray",
                                    background: `#${s[f.colors]}`,
                                    marginRight: "10px",
                                  }}
                                ></div>
                              )}
                              {f.name === "carMakerId" && (
                                <div
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <img
                                    src={s[f.URL]}
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              )}
                              <Typography> {s[f.data]}</Typography>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors[f.name]?.message}</FormHelperText>
                    </FormControl>
                  </>
                )}

                {f.name === "plate" && (
                  <>
                    <TextField
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      select={f.select}
                      className={f.class}
                      fullWidth
                      required
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      inputProps={{
                        ...register(f?.name),
                        required: f.required,
                      }}
                      size="small"
                    ></TextField>
                  </>
                )}
              </Box>
            );
          })}
        </GridGroup1>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Add Membership
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default React.memo(FormCar);
