import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ShiftField from "./ShiftField";
import { useReports } from "../Provider";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import CustomTable from "../../../ui/CustomTable";
import RoleStyled from "./CustomRender/RoleStyled";
import PunchInRender from "./CustomRender/PunchInRender";
import PunchoutRender from "./CustomRender/PunchoutRender";
import DurationRender from "./CustomRender/DurationRender";
import AmountRender from "./CustomRender/AmountRender";
import TipRender from "./CustomRender/TipRender";
import TypeRender from "./CustomRender/TypeRender";
import NotesRender from "./CustomRender/NotesRender";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
/**export to excel */
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  StyledXlsx,
  showLocalDate,
  toUtc,
  tolocalDate,
} from "../../../utilities/functions";
import ServicePayments from "./CustomRender/ServicePayments";
import DiscountCars from "./CustomRender/DiscountCars";
import CarCounts from "./CustomRender/CarCounts";
import TotalAmount from "./CustomRender/TotalAmount";

const GridGroup1 = styled.div`
  margin-left: 200px;
  width: 65%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2px;
  gap: 42px;
  margin-bottom: 10px;
`;
const GridGroupData = styled.div`
  margin-left: 20px;
  display: grid;
  grid-template-columns: 180px 150px 150px;
  grid-template-rows: 2px;
  gap: 20px;
  @media Only print {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2px;
    gap: 22px;
  }
`;
const GridGroupData2 = styled.div`
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2px;
  gap: 22px;
  @media Only print {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2px;
    gap: 22px;
  }
`;
const GridGroupCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media Only print {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const ShiftReportDetail = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let theme = useTheme();
  const { id } = useParams();
  const [InfoToExcel, setInfoToExcel] = useState([{}]);
  const [Attendances, setAttendances] = useState();
  const {
    state: { selectedSiteShiftLive: SiteShift },
    fetchSiteShiftLive: getSiteShiftDetail,
  } = useReports();
  useEffect(() => {
    getSiteShiftDetail(id);
  }, [getSiteShiftDetail, id]);
  const attendanceCol = [
    {
      label: "Employee",
      name: "employeeName",
    },
    {
      label: "Role",
      name: "role",
      customRender: RoleStyled,
    },
    {
      label: "Punch In",
      name: "punchIn",
      customRender: PunchInRender,
    },
    {
      label: "Punch Out",
      name: "punchOut",
      customRender: PunchoutRender,
    },

    {
      label: "Duration",
      name: "duration",
      customRender: DurationRender,
    },
  ];
  const ServiceCol = [
    { label: "Title", name: "title" },
    {
      label: "Service Type",
      name: "serviceType",
    },
    { label: "Car Count", name: "carCount" },
    {
      label: "Payments",
      name: "",
      customRender: ServicePayments,
    },
    {
      label: "Discount Cars",
      name: "",
      customRender: DiscountCars,
    },
    {
      label: "Car Count",
      customRender: CarCounts,
    },
    {
      label: "Total Amount",
      customRender: TotalAmount,
    },
  ];
  const PayementCol = [
    {
      label: "Employee",
      name: "employeeName",
    },
    {
      label: "Amount",
      name: "amount",
      customRender: AmountRender,
    },
    {
      label: "Tip",
      name: "tip",
      customRender: TipRender,
    },
    {
      label: "Payment Type",
      name: "paymentType",
      customRender: TypeRender,
    },

    {
      label: "Notes",
      name: "notes",
      customRender: NotesRender,
    },
  ];
  const componentRef = useRef();
  const pageStyle = ` @page {
    size: A4;
  }`;

  const exportToCSV = (dataStyled, fileName) => {
    const wsInfo = XLSX.utils.json_to_sheet(dataStyled, {
      origin: -1,
    });
    wsInfo["!cols"] = [{ width: 30 }, { width: 30 }, { width: 30 }];
    let arr = new Array();
    let arrPay = new Array();

    for (var i = 0; i < SiteShift.employees.length; i++) {
      for (var j = 0; j < SiteShift.employees[i].attendances.length; j++) {
        var obj = {};
        obj = SiteShift.employees[i].attendances[j];
        obj["employee"] = SiteShift.employees[i].employeeName;
        arr.push(obj);
      }
      for (var k = 0; k < SiteShift.employees[i].payments.length; k++) {
        var obj1;
        obj1 = SiteShift.employees[i].payments[k];
        obj1["employee"] = SiteShift.employees[i].employeeName;
        arrPay.push(obj1);
      }
    }
    const ws = XLSX.utils.json_to_sheet(arr, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];
    const ws2 = XLSX.utils.json_to_sheet(arrPay, {
      origin: -1,
    });
    ws2["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];
    var services = new Array();
    for (var j = 0; j < SiteShift?.services?.length; j++) {
      services.push({});

      services.push({
        Services: `${SiteShift?.services[j]?.title}`,
      });
      services.push({
        Services: `Type: ${SiteShift?.services[j]?.serviceType}`,
      });
      services.push({
        Services: `Discount: ${SiteShift?.services[j]?.discountCars}`,
      });
      services.push({
        Services: `Payment Type`,
        Cars: "Car",
        total: "Total",
      });
      for (var i = 0; i <= SiteShift?.services[j]?.payments.length - 1; i++) {
        services.push({
          Services: SiteShift?.services[j]?.payments[i]?.paymentType,
          Cars: SiteShift?.services[j]?.payments[i]?.carCount,
          total: SiteShift?.services[j]?.payments[i]?.totalAmount,
        });
      }
    }
    const ws3 = XLSX.utils.json_to_sheet(services, {
      origin: -1,
    });
    ws3["!cols"] = [
      { width: 50 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsInfo, "Info");
    XLSX.utils.book_append_sheet(wb, ws3, "Services");
    XLSX.utils.book_append_sheet(wb, ws, "Attendances");
    XLSX.utils.book_append_sheet(wb, ws2, "Payments");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // const [Arr, setArr] = useState([]);
  // useEffect(() => {
  //   console.log(SiteShift?.employees, "sss");
  //   setArr(
  //     SiteShift?.employees.attendances?.filter((attendance) => {
  //       if (attendance.punchOut !== "2023-07-31 12:19") return attendance;
  //     })
  //   );
  // });
  const getTransfer = (value) => {
    if (value && value !== 0) {
      return value;
    } else return;
  };

  const getTransferLabel = (value, label) => {
    if (value && value !== 0) {
      return label;
    } else return;
  };

  if (!SiteShift) return;
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            border: `1px solid ${theme.color.Screamingreen}`,
            color: theme.color.Screamingreen,
            mr: "5px",
            textTransform: "capitalize",
            "&:hover": { background: `${theme.color.gray50}` },
          }}
          onClick={(e) => exportToCSV(StyledXlsx(SiteShift), "Export to Excel")}
        >
          Export to Excel
        </Button>
        <ReactToPrint
          pageStyle={pageStyle}
          content={() => componentRef.current}
        >
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <Button
                style={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": { background: `${theme.color.black}` },
                }}
                onClick={handlePrint}
              >
                Print
              </Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      </Box>
      <div ref={componentRef}>
        <div mb={1}>
          <Typography variant="Poster" mb={2} component="h4" gutterBottom>
            Report Shift Information
          </Typography>
        </div>
        <GridGroup1>
          <Typography variant="Poster" component="h5">
            Start Date:{" "}
            {SiteShift?.startDate ? showLocalDate(SiteShift?.startDate) : ""}
          </Typography>
          <Typography variant="Poster" component="h5">
            End Date:{" "}
            {SiteShift?.endDate ? showLocalDate(SiteShift?.endDate) : ""}
          </Typography>
          <Typography variant="Poster" component="h5">
            Created by: {SiteShift?.creator?.employeeName}
          </Typography>
          <Typography variant="Poster" component="h5">
            Closed by: {SiteShift?.closedBy?.employeeName}
          </Typography>
        </GridGroup1>
        <GridGroupCard>
          <Paper
            variant="outlined"
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
              mr: 1,
              background: theme.color.PaperbgColor,
            }}
          >
            <Typography variant="Poster" component="h5" mb={1}>
              Ticket Information
            </Typography>
            <GridGroupData>
              {ShiftField.filter((f, index) => f.objName === "Ticket").map(
                (field, index) => (
                  <div
                    style={{
                      color: theme.color.PaperlabelColor,
                      fontSize: "13px",
                    }}
                  >
                    {field.label.includes("Transfer")
                      ? getTransferLabel(SiteShift?.[field?.name], field.label)
                      : field.label}
                    <span style={{ color: "black" }}>
                      {" "}
                      {field.label.includes("Transfer")
                        ? getTransfer(SiteShift?.[field?.name])
                        : SiteShift?.[field?.name]}
                    </span>
                  </div>
                )
              )}
            </GridGroupData>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
              mr: 1,
              background: theme.color.PaperbgColor,
            }}
          >
            <Typography variant="Poster" component="h5" mb={1}>
              Car Information
            </Typography>
            <GridGroupData2>
              {ShiftField.filter((f, index) => f.objName === "Car").map(
                (field, index) => (
                  <div
                    style={{
                      color: theme.color.PaperlabelColor,
                      fontSize: "13px",
                    }}
                  >
                    {field.label}:{" "}
                    <span style={{ color: "black" }}>
                      {" "}
                      {SiteShift[field?.name]}
                    </span>
                  </div>
                )
              )}
            </GridGroupData2>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
              mr: 1,
              background: theme.color.PaperbgColor,
            }}
          >
            <Typography variant="Poster" component="h5" mb={1}>
              Payment
            </Typography>
            <GridGroupData>
              {ShiftField.filter((f, index) => f.objName === "Payment").map(
                (field, index) => (
                  <div
                    style={{
                      color: theme.color.PaperlabelColor,
                      fontSize: "13px",
                    }}
                  >
                    {field.label}:{" "}
                    <span style={{ color: "black" }}>
                      {" "}
                      {SiteShift?.[field?.name]}
                    </span>
                  </div>
                )
              )}
            </GridGroupData>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
              mr: 1,
              background: theme.color.PaperbgColor,
            }}
          >
            <Typography variant="Poster" component="h5" mb={1}>
              Pre Payment
            </Typography>
            <GridGroupData>
              {ShiftField.filter((f, index) => f.objName === "PrePayment").map(
                (field, index) => (
                  <div
                    style={{
                      color: theme.color.PaperlabelColor,
                      fontSize: "13px",
                    }}
                  >
                    {field.label}:{" "}
                    <span style={{ color: "black" }}>
                      {" "}
                      {SiteShift[field?.name]}
                    </span>
                  </div>
                )
              )}
            </GridGroupData>
          </Paper>
        </GridGroupCard>
        <div>
          <CustomTable
            empty={SiteShift?.services?.length === 0 ? true : false}
            cols={ServiceCol}
            data={SiteShift?.services}
            isPagination={false}
            name="Service"
          ></CustomTable>
        </div>
        <div>
          <CustomTable
            empty={SiteShift?.employees?.payments?.length === 0 ? true : false}
            cols={PayementCol}
            data={SiteShift?.employees}
            isPagination={false}
            name="Employee Payments"
          ></CustomTable>
        </div>
        <div>
          <CustomTable
            empty={SiteShift?.employees?.length === 0 ? true : false}
            cols={attendanceCol}
            data={SiteShift?.employees}
            isPagination={false}
            name="Employee Attendances"
          ></CustomTable>
        </div>
      </div>
    </>
  );
};

export default React.memo(ShiftReportDetail);
