import React from "react";

const Tips = ({ row }) => {
  return (
    <div>
      {row.tips === "" ? (
        ""
      ) : (
        <>
          <div>{row.tips?.cashTips}</div>
          <div>{row.tips?.ccTips}</div>
          <div>0</div>
          <div>{row.tips?.compTips}</div>
          <div style={{ borderTop: "1px dashed black" }}>
            <p>{row?.tips?.serviceTipsSum}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Tips;
