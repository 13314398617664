import React, { useState, useCallback } from "react";
import { useCompany } from "./Provider";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import fields from "./fields";
// material ui
import {
  Box,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  FormGroup,
  Button,
  useTheme,
  Card,
  FormLabel,
  Alert,
} from "@mui/material";
import styled from "styled-components";
// utilities
import { handleResponse } from "../../utilities/functions";
// components
import ErrorsList from "../../ui/ErrorsList";

//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
export const switchStyle = {
  ".css-ahj2mt-MuiTypography-root": {
    fontSize: "14px",
  },
  ".css-julti5-MuiSwitch-root": {
    height: "55px",
    width: "85px",
  },
  ".css-jsexje-MuiSwitch-thumb": {
    width: "26px",
    height: "26px",
  },
  ".css-1yjjitx-MuiSwitch-track": {
    borderRadius: "20px",
  },
  ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase": {
    top: "5px",
    left: "4px",
  },
  ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
    color: "white",
    left: "16px",
  },
  ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
    {
      backgroundColor: "#268B61",
      opacity: "1",
    },
};

const yupSchema = fields.reduce(createYupSchema, {
  email: Yup.string().email("Enter Valid Email"),
  tvaPercent: Yup.number().min(0).max(1),
  tvaNumber: Yup.string(),
  mobile: Yup.string(),
});
const formSchema = Yup.object().shape(yupSchema);

const FormCompany = ({ company, handleSuccess, setCompanyName }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      tvaPercent: company ? company["tvaPercent"] : 0,
    },
  });
  const navigate = useNavigate();
  let theme = useTheme();
  let params = useParams();

  const id = params.id;
  const [error, setError] = useState(null);
  const { create, edit } = useCompany();
  const onSubmit = async (values) => {
    // delete values.id;
    if (values.tvaPercent < 0 || values.tvaPercent > 1) {
      window.scrollTo(0, 0);
      setError({ title: "tva percent must be between zero and one" });
      return;
    }
    try {
      if (company) await edit(id, values);
      else {
        await create(values);
        setCompanyName(values.companyName);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);

      if (e.status === 400) setError(e.errors);
      if (e.status === 409) setError({ title: e.errors.message });
      handleResponse(e.errors.status);
    }
  };
  // Fields functions
  const onChange = useCallback(
    (v, name) => {
      setValue(name, v.target.value);
    },
    [setValue]
  );
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <Typography variant="Poster" component="h4" gutterBottom>
              Company Details
            </Typography>
            {error && (
              <Alert severity="error">
                <ErrorsList error={error} />
              </Alert>
            )}
            <GridGroup>
              {fields.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;
                inputProps.type = f.type;
                return (
                  <Box key={i}>
                    {f.switch ? (
                      <Box>
                        <FormGroup>
                          <FormLabel
                            component="legend"
                            sx={{
                              mt: theme.dimensions.mt,
                            }}
                          >
                            {f.label}
                          </FormLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                defaultChecked={
                                  company ? company[f?.name] : true
                                }
                              />
                            }
                            {...register(f.name)}
                            label="Active"
                          />
                        </FormGroup>
                      </Box>
                    ) : (
                      <TextField
                        label={f.label}
                        name={f.name}
                        error={f.required && !!errors[f.name]}
                        helperText={errors[f.name]?.message}
                        select={f.select}
                        size="small"
                        className={f.class}
                        type={f.type}
                        // step={0.01}
                        fullWidth
                        value={f.select && getValues(f.name)}
                        defaultValue={company && company[f?.name]}
                        onChange={onChange}
                        disabled={f.disabled}
                        required={f.required}
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        inputProps={{
                          ...register(f.name),
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </GridGroup>
            {/* Buttons */}
            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                {company ? "Save" : "Add Company"}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(FormCompany);
