import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

const VoidReason = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default VoidReason;
