import { addDays } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
const fields = [
  {
    name: "companyName",
    label: "Company Name",
    disabled: true,
    value: "companyName",
  },
  {
    name: "siteId",
    label: "Site Name",
    disabled: true,
    select: true,
    validationType: "number",
  },
  {
    name: "fileName",
    label: "File Name",
    file: true,
  },
  {
    name: "startDate",
    label: "Start Date",
    required: true,

    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
    date: true,
  },
  {
    name: "endDate",
    label: "End Date",

    required: true,

    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
    date: true,
  },
  {
    name: "firstReminder",
    label: "First Reminder",
    validationType: "string",
    nullable: true,
    date: true,
  },
  {
    name: "secondReminder",
    label: "Second Reminder",
    validationType: "string",
    nullable: true,
    date: true,
  },
  {
    name: "isActive",
    label: "Contract Status",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
