import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// components
import FormUserAccount from "./FormUserAccount";
import ModalBox from "../../ui/ModalBox";
import { Typography } from "@mui/material";

const AddUserAccount = ({accountType}) => {
  const navigate = useNavigate();
  const [userAccountDisplayName, setUserAccountDisplayName] = useState("x");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <div>
      <Typography variant="Poster" component="h3" gutterBottom>
        Add User Account
      </Typography>
      <div>
        <FormUserAccount
          handleSuccess={handleSuccess}
          setUserAccountDisplayName={setUserAccountDisplayName}
          accountType={accountType}
        />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added new user account "
        name={userAccountDisplayName}
      />
    </div>
  );
};

export default React.memo(AddUserAccount);
