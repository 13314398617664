import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  GateType,
  HourlyServiceType,
  FixedServiceType,
  CarCategoryType,
  RolesEnum,
} from "../../utilities/Enum";
import {
  TransformFromPercent,
  TransformtoPercent,
} from "../../utilities/functions";

function TableInput({
  col,
  data,
  index,
  validations,
  actions,
  date,
  disabled,
}) {
  var TypeTitle;
  switch (col.name) {
    case "typeId":
      TypeTitle = GateType;
      break;
    case "serviceTypeId":
      col.hourly
        ? (TypeTitle = HourlyServiceType)
        : (TypeTitle = FixedServiceType);

      break;
    case "carColorId":
      TypeTitle = col.type;

      break;
    case "categoryId":
      TypeTitle = CarCategoryType;
      break;
    case "carMakerId":
      TypeTitle = col.type;
      break;
    case "roleId":
      TypeTitle = RolesEnum;
      break;
    case "zoneIds":
      TypeTitle = [
        { id: 1, name: "a" },
        { id: 2, name: "b" },
        { id: 3, name: "c" },
      ];
    default:
      TypeTitle = [];
      break;
  }
  const handleCheck = useCallback((v) => {
    let valid = false;
    valid = validations(v);
    setError(!valid);

    if (valid) {
      actions.setVState(false);
      return v;
    } else {
      !actions.vState && actions.setVState(true);
    }
  }, []);
  const [error, setError] = useState(null);
  if (col.name === "isActive") {
    return (
      <FormControlLabel
        control={
          <Switch
            onChange={(v) => (data[index][col.name] = !data[index][col.name])}
            defaultChecked={data[index][col.name]}
            color="success"
          />
        }
        label="Active"
      ></FormControlLabel>
    );
  } else if (
    col.name === "typeId" ||
    col.name === "serviceTypeId" ||
    col.name === "categoryId"
  ) {
    return (
      <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <Select
          sx={{ height: 40 }}
          multiple={col.multiple}
          onChange={(v) => {
            if (v.target.value === 6) {
              data[index]["fixPriceTrial"] = 0;
              data[index]["fixPriceOfficial"] = 0;
            }
            data[index][col.name] = v.target.value;
          }}
          defaultValue={data[index][col.name]}
        >
          {TypeTitle?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (col.name === "carColorId" || col.name === "carMakerId") {
    return (
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Select
          sx={{ height: 40 }}
          onChange={(v) => {
            data[index][col.name] = v.target.value;
          }}
          defaultValue={data[index][col.name]}
        >
          {TypeTitle?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {col.name === "carColorId" ? (
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "8px",
                      border: "solid 1px gray",
                      background: `#${item.hexValue}`,
                      marginRight: "10px",
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={item.makerUrl}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
                <Typography>
                  {" "}
                  {col.name === "carColorId" ? item.colorName : item.makerName}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (col.multiRow) {
    return <>ddd</>;
  }
  return (
    <TextField
      type={col.type ? col.type : "text"}
      name={col.name}
      error={error}
      size="small"
      helperText={error && col.helperText}
      onChange={(v) => {
        col.name === "discount"
          ? (data[index][col.name] = TransformFromPercent(
              v.target.value,
              handleCheck
            ))
          : (data[index][col.name] = col.checkable
              ? v.target.value
              : v.target.value);
      }}
      inputProps={{
        defaultValue:
          col.name === "discount"
            ? TransformtoPercent(data[index][col.name])
            : data[index][col.name],
        style: { width: col.number ? 55 : 185 },
      }}
    />
  );
}

export default TableInput;
