// React
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import Form from "./Form";
// Material UI
import ModalBox from "../../../../ui/ModalBox";
import { Typography } from "@mui/material";

const Add = () => {
  const navigate = useNavigate();
  const [material, setMaterial] = useState("x");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <div>
      <Typography variant="Poster" component="h3" gutterBottom>
        Add Material
      </Typography>
      <div>
        <Form handleSuccess={handleSuccess} setItem={setMaterial} />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added new material "
        name={material}
      />
    </div>
  );
};

export default React.memo(Add);
