// Providers
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Typography,
  Box,
  FormControlLabel,
} from "@mui/material";
import printingFields from "./printingFields";
import { useSite } from "../Provider";

const TicketTable = ({ ticket }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const headStyle = {
    color: "rgba(0, 0, 0, 0.4)",
    fontWeight: "400",
    fontSize: "12px",
    padding: "0px 16px",
    borderRight: "solid 1px rgba(0, 0, 0, 0.1)",
  };
  const flexStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const {
    state: { printingDiv1, printingDiv2, printingDiv3, printingDiv4 },
    dispatch,
  } = useSite();

  const [selectAllDiv1, setSelectAllDiv1] = useState(null);
  const [selectAllDiv2, setSelectAllDiv2] = useState(null);
  const [selectAllDiv3, setSelectAllDiv3] = useState(null);
  const [selectAllDiv4, setSelectAllDiv4] = useState(null);

  useEffect(() => {
    if (printingDiv1?.length === 25) {
      setSelectAllDiv1(true);
      document.getElementsByName("selectalldiv1")[0].defaultChecked = true;
      document.getElementsByName("selectalldiv1")[0].checked = true;
    } else {
      setSelectAllDiv1(false);
      document.getElementsByName("selectalldiv1")[0].defaultChecked = false;
      document.getElementsByName("selectalldiv1")[0].checked = false;
    }
  }, [setSelectAllDiv1, printingDiv1, ticket]);

  useEffect(() => {
    if (printingDiv2?.length === 25) {
      setSelectAllDiv2(true);
      document.getElementsByName("selectalldiv2")[0].defaultChecked = true;
      document.getElementsByName("selectalldiv2")[0].checked = true;
    } else {
      setSelectAllDiv2(false);
      document.getElementsByName("selectalldiv2")[0].defaultChecked = false;
      document.getElementsByName("selectalldiv2")[0].checked = false;
    }
  }, [setSelectAllDiv2, printingDiv2, ticket]);

  useEffect(() => {
    if (printingDiv3?.length === 25) {
      setSelectAllDiv3(true);
      document.getElementsByName("selectalldiv3")[0].defaultChecked = true;
      document.getElementsByName("selectalldiv3")[0].checked = true;
    } else {
      setSelectAllDiv3(false);
      document.getElementsByName("selectalldiv3")[0].defaultChecked = false;
      document.getElementsByName("selectalldiv3")[0].checked = false;
    }
  }, [setSelectAllDiv3, printingDiv3, ticket]);

  useEffect(() => {
    if (printingDiv4?.length === 25) {
      setSelectAllDiv4(true);
      document.getElementsByName("selectalldiv4")[0].defaultChecked = true;
      document.getElementsByName("selectalldiv4")[0].checked = true;
    } else {
      setSelectAllDiv4(false);
      document.getElementsByName("selectalldiv4")[0].defaultChecked = false;
      document.getElementsByName("selectalldiv4")[0].checked = false;
    }
  }, [setSelectAllDiv4, printingDiv4, ticket]);

  // handle Select all printing fields
  const handleSelectAll = (e, name, setSelectAll, dispatchType) => {
    setSelectAll(e.target.checked);

    var ele = document.getElementsByName(name);
    for (var i = 0; i < ele.length; i++) {
      if (e.target.checked) {
        if (!ele[i].checked) ele[i].click();
      } else {
        if (ele[i].checked) ele[i].click();
      }
    }
    if (e.target.checked) {
      dispatch({
        type: dispatchType,
        payload: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25,
        ],
      });
    } else {
      dispatch({
        type: dispatchType,
        payload: [],
      });
    }
  };

  // handle Change printing fields
  const handleChangePrinting = (e, selectedDivArr, dispatchType) => {
    // e.target.name = CompanyName
    // e.target.value = on
    // e.target.id = 1
    if (selectedDivArr?.includes(Number(e.target.id))) {
      dispatch({
        type: dispatchType,
        payload: selectedDivArr
          .filter((field) => Number(field) !== Number(e.target.id))
          .sort((a, b) => a - b),
      });
    } else {
      if (selectedDivArr)
        dispatch({
          type: dispatchType,
          payload: [...selectedDivArr, Number(e.target.id)].sort(
            (a, b) => a - b
          ),
        });
      else {
        dispatch({
          type: dispatchType,
          payload: [Number(e.target.id)].sort((a, b) => a - b),
        });
      }
    }
  };

  return (
    <TableContainer sx={{ width: "100%" }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={headStyle}>
              <Typography variant="body2" component="span">
                Fields
              </Typography>
            </TableCell>
            <TableCell sx={headStyle}>
              <Box sx={flexStyle}>
                <Typography variant="body2" component="span">
                  Division 1
                </Typography>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <Checkbox
                      checked={selectAllDiv1}
                      id={100}
                      onChange={(e) =>
                        handleSelectAll(
                          e,
                          "printingDiv1",
                          setSelectAllDiv1,
                          "set_div1"
                        )
                      }
                      name={"selectalldiv1"}
                      size="small"
                      sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                    />
                  }
                />
              </Box>
            </TableCell>
            <TableCell sx={headStyle}>
              <Box sx={flexStyle}>
                <Typography variant="body2" component="span">
                  Division 2
                </Typography>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <Checkbox
                      checked={selectAllDiv2}
                      id={100}
                      onChange={(e) =>
                        handleSelectAll(
                          e,
                          "printingDiv2",
                          setSelectAllDiv2,
                          "set_div2"
                        )
                      }
                      name={"selectalldiv2"}
                      size="small"
                      sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                    />
                  }
                />
              </Box>
            </TableCell>
            <TableCell sx={headStyle}>
              <Box sx={flexStyle}>
                <Typography variant="body2" component="span">
                  Division 3
                </Typography>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <Checkbox
                      checked={selectAllDiv3}
                      id={100}
                      onChange={(e) =>
                        handleSelectAll(
                          e,
                          "printingDiv3",
                          setSelectAllDiv3,
                          "set_div3"
                        )
                      }
                      name={"selectalldiv3"}
                      size="small"
                      sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                    />
                  }
                />
              </Box>
            </TableCell>
            <TableCell sx={headStyle}>
              <Box sx={flexStyle}>
                <Typography variant="body2" component="span">
                  Division 4
                </Typography>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <Checkbox
                      checked={selectAllDiv4}
                      id={100}
                      onChange={(e) =>
                        handleSelectAll(
                          e,
                          "printingDiv4",
                          setSelectAllDiv4,
                          "set_div4"
                        )
                      }
                      name={"selectalldiv4"}
                      size="small"
                      sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                    />
                  }
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printingFields.map((field, i) => {
            return (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  width: "max-content",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0px 16px",
                  }}
                >
                  {field.description}
                </TableCell>
                {/* DIV  1 */}
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "0px 16px",
                  }}
                >
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        defaultChecked={
                          ticket ? printingDiv1?.includes(field.id) : false
                        }
                        sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                        size="small"
                        {...label}
                        id={field.id}
                        onChange={(e) =>
                          handleChangePrinting(e, printingDiv1, "set_div1")
                        }
                        name={"printingDiv1"}
                      />
                    }
                  />
                </TableCell>
                {/* DIV  2 */}
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "0px 16px",
                  }}
                >
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        defaultChecked={
                          ticket ? printingDiv2?.includes(field.id) : false
                        }
                        sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                        size="small"
                        {...label}
                        id={field.id}
                        onChange={(e) =>
                          handleChangePrinting(e, printingDiv2, "set_div2")
                        }
                        name={"printingDiv2"}
                      />
                    }
                  />
                </TableCell>
                {/* DIV  3 */}
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "0px 16px",
                  }}
                >
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        defaultChecked={
                          ticket ? printingDiv3?.includes(field.id) : false
                        }
                        sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                        size="small"
                        {...label}
                        id={field.id}
                        onChange={(e) =>
                          handleChangePrinting(e, printingDiv3, "set_div3")
                        }
                        name={"printingDiv3"}
                      />
                    }
                  />
                </TableCell>
                {/* DIV  4 */}
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "0px 16px",
                  }}
                >
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        defaultChecked={
                          ticket ? printingDiv4?.includes(field.id) : false
                        }
                        sx={{ color: "rgba(0, 0, 0, 0.2)" }}
                        size="small"
                        {...label}
                        id={field.id}
                        onChange={(e) =>
                          handleChangePrinting(e, printingDiv4, "set_div4")
                        }
                        name={"printingDiv4"}
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TicketTable);
