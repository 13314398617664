import React from "react";
import { showLocalDate, tolocalDate } from "../../../utilities/functions";

const LocalPaidDate = ({ row }) => {
  return (
    <div>
      {row.paidDate ? <>{showLocalDate(row.paidDate)}</> : <>not specific</>}
    </div>
  );
};

export default LocalPaidDate;
