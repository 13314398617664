const fields = [
  {
    name: "companyName",
    label: "Company Name",
    disabled: true,
    select: true,
    value: "companyName",
  },
  {
    name: "siteId",
    label: "Site Name",
    select: true,
    disabled: true,
    validationType: "number",
  },
  {
    name: "title",
    label: "Gate Name",
    required: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "typeId",
    label: "Gate Type",
    required: true,
    select: true,
    validationType: "number",
    getData: "gatesType",
    data: "name",

    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "isActive",
    label: "Gate Status",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
