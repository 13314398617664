import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCompany } from "../Provider";
import BoxInfo from "./BoxInfo";
import TableInfo from "./TableInfo";

function InfoCompany() {
  let params = useParams();
  const id = params.id;
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompany();

  useEffect(() => {
    fetchCompany(id);
  }, [id, fetchCompany]);
  if (!company) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Company Details
      </Typography>
      <BoxInfo company={company} />
      <TableInfo company={company} />
    </>
  );
}

export default InfoCompany;
