import React from "react";

const ServiceStyledType = ({row}) => {
  return (
    <div
      style={{
        backgroundColor: "#EDEDFF",
        color: "#8A8CD9",
        borderRadius: "20px",
        opacity: "0.8",
        display:"inline-block",
        width:"130px",
        padding:"3px 5px",
        textAlign:"center"
        
      }}
    >
      {row.serviceTypeName}
    </div>
  );
};

export default ServiceStyledType;
