// React
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// Material UI
import {
  Alert,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
// Providers
import { useMaterial } from "./Provider";
import { useMaterialCategory } from "../Categories/Provider";
import { useCompany } from "../../Companies/Provider";
// Third Party Components
import Filter from "./Filter";
// Custom UI
import ErrorsList from "../../../ui/ErrorsList";
// Utilities
import { createYupSchema } from "../../../utilities/yupSchemaCreator";
import { Units } from "../../../utilities/Enum";
import { handleResponse } from "../../../utilities/functions";
// Styled Component
import styled from "styled-components";
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// Services
import { useAuth } from "../../../services/auth";

// yup schema
const yupSchema = Filter.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

function FilterForm({ setFilterClick }) {
  const GridGroup1 = styled.div`
    width: fit-content;
    display: grid;
    padding: 20px;
    margin-bottom: 10px;
    grid-template-columns: 325px 325px 150px;
    gap: 10px;
    grid-template-rows: 75px;
    grid-column-gap: 10px;
    background: #f7f9fb;
  `;
  let theme = useTheme();
  const { role, adminOn } = useAuth();
  const [Error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const {
    state: { FilterData },
    fetchList: fetchMaterials,
    dispatch,
  } = useMaterial();
  const {
    state: { list: materialCategories },
    fetchList: fetchMaterialCategories,
  } = useMaterialCategory();
  const {
    state: { Names: companies },
    fetchNames: fetchCompanies,
  } = useCompany();

  useEffect(() => {
    if (role === "Admin") {
      fetchCompanies();
    }
  }, [fetchCompanies, role]);
  useEffect(() => {
    fetchMaterialCategories();
  }, [fetchMaterialCategories]);

  useEffect(() => {
    fetchMaterials(FilterData);
  }, [fetchMaterials]);

  // Fields functions
  const getSelectData = (name) => {
    switch (name) {
      case "Companies":
        return [
          { id: -1, name: "No Company" },
          { id: 0, name: "All Companies" },
          ...companies,
        ];
      case "unit":
        return Units;
      case "MaterialCategories":
        return materialCategories;
      default:
        return;
    }
  };
  const handleChange = (e, name) => {
    switch (name) {
      case "companyId":
        dispatch({
          type: "set_FilterData",
          payload: {
            ...FilterData,
            companyId: e.target.value,
          },
        });
        break;
      case "categoryId":
        dispatch({
          type: "set_FilterData",
          payload: {
            ...FilterData,
            categoryId: e.target.value,
          },
        });
        break;
      case "unit":
        dispatch({
          type: "set_FilterData",
          payload: {
            ...FilterData,
            unit: e.target.value,
          },
        });
        break;
      default:
        break;
    }
  };
  const onSubmit = async (values, e) => {
    try {
      fetchMaterials(values);
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 404) setError({ title: e.errors.message });
      else setError({ title: e.errors.message });
      handleResponse(e.errors.status);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box>
        {Error && (
          <Alert severity="error">
            <ErrorsList error={Error} />
          </Alert>
        )}
      </Box>
      <Box sx={{ background: "#f7f9fb", padding: "20px", mt: 1, width: "85%" }}>
        <GridGroup1>
          {Filter.map((f, index) => (
            <React.Fragment key={index}>
              {role === "Company" && f.name === "companyId"
                ? null
                : f.select && (
                    <FormControl
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>{f.label}</span>
                      <Select
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                        error={!!errors[f.name]}
                        value={FilterData[f.name]}
                        onChange={(e) => handleChange(e, f.name)}
                        inputProps={{
                          ...register(f?.name),
                        }}
                        size="small"
                      >
                        {getSelectData(f.selectData)?.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item[f?.selectValueSelector]}
                          >
                            {item[f?.selectSelector]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
            </React.Fragment>
          ))}
        </GridGroup1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignContent: "start",
            mr: "30px",
            mt: "-30px",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              color: "white",
              ml: 5,
              mt: 2,
              "&:hover": { background: `${theme.color.blackBackground}` },
              textTransform: "capitalize",
            }}
            onClick={() => setFilterClick(true)}
          >
            <FilterList />
            <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default FilterForm;
