// React
import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Material UI
import { Box, Grid, Typography, useTheme, Paper, Button } from "@mui/material";
// Providers
import { useVendor } from "./Provider";
// Fields
import fields from "./fields";

function Details() {
  let params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const id = params.id;
  const {
    state: { selected: vendor },
    fetchList: fetchVendors,
  } = useVendor();

  useEffect(() => {
    fetchVendors(id);
  }, [id, fetchVendors]);
  if (!vendor) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Vendor Details
      </Typography>
      <Paper
        elevation={0}
        // square
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
          background: theme.color.PaperbgColor,
          width: "90%",
        }}
      >
        <Typography variant="Poster" component="h4" gutterBottom>
          Vendor Information
        </Typography>
        <Grid container>
          {fields.map((f, index) => {
            if (f.editOnly) return;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                mt={2}
                key={index}
                sx={{ fontSize: "14px" }}
              >
                <span style={{ color: theme.color.PaperlabelColor }}>
                  {f.label} :{" "}
                </span>
                <span>{vendor?.[f?.name] && vendor?.[f?.name]}</span>
              </Grid>
            );
          })}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default Details;
