import React from "react";

const CarsAll = ({ row }) => {
  return (
    <>
      {row.cars === "" ? (
        ""
      ) : (
        <div>
          <div>{row.cars?.carsCashCount}</div>
          <div>{row.cars?.carsCCCount}</div>
          <div>{row.cars?.carsVoidCount}</div>
          <div>{row.cars?.carsCompCount}</div>
          <div style={{ borderTop: "1px dashed black" }}>
            <p>{row?.cars?.serviceCarsCount}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CarsAll;
