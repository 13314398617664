// React
import React, { useEffect } from "react";
// Hooks
import { useNavigate } from "react-router-dom";
// Styled
import styled from "styled-components";
// MUI
import { Typography } from "@mui/material";
// MUI Icons
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import { useUserAccount } from "../../UserAccount/Provider";
import { useAuth } from "../../../services/auth";

const AccountWrapper = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
`;

function AccountCell({ row, i, renderParams }) {
  let navigate = useNavigate();
  let { role } = useAuth();
  //  const {
  //    state: { selectedUser},
  //    fetchuserAccountInfo,
  //  } = useUserAccount();
  // useEffect(() => {
  //   fetchuserAccountInfo(row.user);
  // }, [row.user]);
  return (
    <AccountWrapper>
      {row.user && (
        <>
          <Typography variant="Poster" component="span" gutterBottom>
            {row?.user?.displayName}
          </Typography>
          {(role === "Site" && row["currentSite"]) ||
          role === "Admin" ||
          role === "SubCompany" ||
          role === "Company" ? (
            <Typography
              variant="Poster"
              component="h4"
              gutterBottom
              sx={{
                cursor: "pointer",
                color: "#FFC555",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => renderParams.openModal(row.user)}
            >
              <RestartAltTwoToneIcon
                sx={{ mr: "1px", mb: "-3px", fontSize: "15px" }}
              />
              Reset Password
            </Typography>
          ) : (
            <strong>no permission to reset password</strong>
          )}
        </>
      )}
      {!row.user && (
        <>
          <Typography variant="Poster" component="span" gutterBottom>
            Not Yet
          </Typography>
          {role !== "Site" ? (
            <Typography
              variant="Poster"
              component="h4"
              gutterBottom
              sx={{
                cursor: "pointer",
                color: "#95A4FC",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                navigate(`/UserAccounts/addUser/${row.id}`);
              }}
            >
              <AddCircleOutlineTwoToneIcon
                sx={{ mr: "1px", mb: "-3px", fontSize: "15px" }}
              />
              Create User
            </Typography>
          ) : (
            <strong>no permission to create user</strong>
          )}
        </>
      )}
    </AccountWrapper>
  );
}

export default AccountCell;
