import React from "react";

function DownloadLink({ row }) {
  return (
    <a style={{ color: "black" }} href={row.contractUrl} target="_blank">
      {row.fileName}
    </a>
  );
}

export default DownloadLink;
