import { Route } from "react-router-dom";

import ListMemberShip from "./ListMemberShip";
import MemberShip from "./MemberShip";
import DetailMemberShip from "./DetailMemberShip";
import AddCar from "../Car/AddCar";
import AddMemberShip from "./AddMemberShip";
import UpdateMembership from "./UpdateMemberShip";
import { RequireAuth } from "../../services/auth";
export default (
  <Route
    path="membership"
    exact
    element={
      <RequireAuth>
        <MemberShip />
      </RequireAuth>
    }
  >
    <Route index element={<ListMemberShip />} />
    <Route path="MembershipDetails/:id" element={<DetailMemberShip />} />
    <Route path="add" element={<AddMemberShip />} />
    <Route path="MembershipDetails/:id/Update" element={<UpdateMembership />} />
    <Route path="MembershipDetails/AddCar/:id" element={<AddCar />} />
  </Route>
);
