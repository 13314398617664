import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";
//import Add_Done from "../../icons/Add_Done.svg";
import EmployeeForm from "./EmployeeForm";
import { useEmployees } from "./Provider";
import styled from "styled-components";
import { useCompany } from "../Companies/Provider";
import { useAuth } from "../../services/auth";

function EditEmployee() {
  const navigate = useNavigate();
  let { id } = useParams();
  let params = useParams();
  const { role, adminOn } = useAuth();

  const [companyName, setCompanyName] = useState(null);
  const [afterUpdate, setafterUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
    // notifySuccess("The company has been created successfully.");
    // setTimeout(() => {
    //   navigate(-1);
    // }, 3000);
  };

  const {
    state: { list: companies },
    fetchList: fetchCompany,
  } = useCompany();

  useEffect(() => {
    if (role === "Admin") {
      fetchCompany();
    }
  }, [fetchCompany]);

  useEffect(() => {
    if (role === "Admin") {
      setCompanyName(
        companies.filter((c) => c.id == id).map((c) => c.companyName)
      );
    } else if (role === "Company") {
      setCompanyName(adminOn?.companyName);
    }
  }, [params]);

  const {
    state: { selected },
    fetchOne,
  } = useEmployees();

  useEffect(() => {
    fetchOne(id);
  }, [id, afterUpdate]);

  if (!selected) return;
  if (selected?.id != params.id) return;
  return (
    <div>
      <h3
        style={{
          fontWeight: "600",
        }}
      >
        Edit Employee
      </h3>
      <div>
        <EmployeeForm
          handleSuccess={handleSuccess}
          employee={selected}
          setafterUpdate={setafterUpdate}
        />
      </div>
      {/* <ToastContainer /> */}
      <ModalBox
        open={open}
        handleClose={handleClose}
        icon="edit"
        text="The update operation has been completed successfully.. You update "
        name={selected?.fullName}
      />
    </div>
  );
}

export default EditEmployee;
