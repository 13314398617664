const ShiftField = [
  {
    objName: "Ticket",
    name: "transferredTickets",
    label: "Transfer Ticket",
  },
  {
    objName: "Ticket",
    name: "transferredTicketsIn",
    label: "Transfer Ticket in",
  },
  {
    objName: "Ticket",
    name: "transferredTicketsOut",
    label: "Transfer Ticket out",
  },
  {
    objName: "Ticket",
    name: "printedTickets",
    label: "Printed Ticket",
  },
  {
    objName: "Ticket",
    name: "printedTicketsIn",
    label: "Printed Ticket In",
  },
  {
    objName: "Ticket",
    name: "printedTicketsOut",
    label: "Printed Ticket out",
  },
  {
    objName: "Ticket",
    name: "remainingTicket",
    label: "Remaining Ticket",
  },
  // {
  //   objName: "Ticket",
  //   name: "firstPrinted",
  //   label: "Fisrt Printed",
  // },
  // {
  //   objName: "Ticket",
  //   name: "lastPrinted",
  //   label: "Last Printed",
  // },
  {
    objName: "Car",
    name: "carsTransferred",
    label: "Transfer",
  },
  {
    objName: "Car",
    name: "remainingCar",
    label: "Remaining Cars",
  },
  {
    objName: "Car",
    name: "carsTransferredIn",
    label: "Transfer In",
  },
  {
    objName: "Car",
    name: "carsTransferredOut",
    label: "Transfer Out",
  },
  {
    objName: "Car",
    name: "carsCheckedIn",
    label: "Checked in",
  },
  {
    objName: "Car",
    name: "carsCheckedOut",
    label: "Check out",
  },

  {
    objName: "Car",
    name: "firstCheckIn",
    label: "First Check In",
  },
  {
    objName: "Car",
    name: "lastCheckIn",
    label: "Last Check In",
  },

  {
    objName: "Payment",
    name: "void",
    label: "Void",
  },
  {
    objName: "Payment",
    name: "comp",
    label: "Company",
  },
  {
    objName: "Payment",
    name: "compCount",
    label: "Company Count",
  },
  {
    objName: "Payment",
    name: "cash",
    label: "Cash",
  },
  {
    objName: "Payment",
    name: "memberCash",
    label: "Member Cash",
  },
  {
    objName: "Payment",
    name: "cashCount",
    label: "Cash Count",
  },
  {
    objName: "Payment",
    name: "cc",
    label: "Credit Car",
  },
  {
    objName: "Payment",
    name: "ccCount",
    label: "Credit Car Count",
  },

  {
    objName: "Payment",
    name: "memberCC",
    label: "Member Credit Car",
  },
  {
    objName: "Payment",
    name: "cashTips",
    label: "Cash Tips",
  },
  {
    objName: "Payment",
    name: "memberCashTips",
    label: "Member Cash Tips",
  },
  {
    objName: "Payment",
    name: "memberNonCashTips",
    label: "Member Non Cash Tip",
  },
  {
    objName: "Payment",
    name: "memberCMSCash",
    label: "Member CMS Cash",
  },
  {
    objName: "Payment",
    name: "memberCMSCC",
    label: "Member CMS CC",
  },
  {
    objName: "Payment",
    name: "memberCMSCashTips",
    label: "Member CMS Cash Tips",
  },
  {
    objName: "Payment",
    name: "memberCMSNonCashTips",
    label: "Member CMS Non Cash Tip",
  },
  {
    objName: "PrePayment",
    name: "preVoid",
    label: "Void",
  },
  {
    objName: "Prepayment",
    name: "preComp",
    label: "Company",
  },
  {
    objName: "PrePayment",
    name: "preCompCount",
    label: "Company Count",
  },
  {
    objName: "PrePayment",
    name: "preCash",
    label: "Cach",
  },
  {
    objName: "PrePayment",
    name: "preCC",
    label: "Credit Car",
  },
  {
    objName: "PrePayment",
    name: "preCCCount",
    label: "Credit Car Count",
  },
  {
    objName: "PrePayment",
    name: "preCashCount",
    label: "Pre Cash Count",
  },
];

export default ShiftField;
