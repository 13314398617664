import { Route } from "react-router-dom";
import ListSecretKeys from "./ListSecretKeys";
import SecretKeys from "./SecretKeys";
import AddSecretKeys from "./AddSecretKeys";
export default (
  <Route path="SecretKeys" exact element={<SecretKeys />}>
    <Route index element={<ListSecretKeys />} />
    <Route path="add/:id" element={<AddSecretKeys />} />
  </Route>
);
