import { Route } from "react-router-dom";
import Payroll from "./Payroll";
import ListPayroll from "./ListPayroll";
import DetailsPayroll from "./DetailsPayroll";
import ListEmployeePayroll from "./ListEmployeePayroll";
import { RequireAuth } from "../../services/auth";

export default (
  <Route
    path="payroll"
    exact
    element={
      <RequireAuth>
        <Payroll />
      </RequireAuth>
    }
  >
    <Route index element={<ListPayroll />} />
    <Route path="PayrollDetails/:peid" element={<DetailsPayroll />} />
    <Route path="EmployeesPayrollList/:pid" element={<ListEmployeePayroll />} />
    <Route
      path="EmployeesPayrollList/:pid/PayrollDetails/:peid"
      element={<DetailsPayroll />}
    />
  </Route>
);
