import React from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const StyledDuration = ({ row }) => {
  return (
    <>
      {row.duration === 0 ? (
        <div style={{ background: "#F7E950" }}>00:00</div>
      ) : Math.floor(row.duration / 60) >= 15 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#E79E9C",
            padding: "3px",
          }}
        >
          <ErrorOutlineOutlinedIcon
            sx={{ color: "#A42A28" }}
            fontSize="small"
          />
          <div
            style={{
              color: "#A42A28",
              marginLeft: "10px",
            }}
          >
            {`${
              Math.floor(row.duration / 60) < 10
                ? `0` + Math.floor(row.duration / 60)
                : Math.floor(row.duration / 60)
            }:${
              Math.floor(row.duration % 60) < 10
                ? `0` + Math.floor(row.duration % 60)
                : Math.floor(row.duration % 60)
            }`}
          </div>
        </div>
      ) : (
        <>{`${
          Math.floor(row.duration / 60) < 10
            ? `0` + Math.floor(row.duration / 60)
            : Math.floor(row.duration / 60)
        }:${
          Math.floor(row.duration % 60) < 10
            ? `0` + Math.floor(row.duration % 60)
            : Math.floor(row.duration % 60)
        }`}</>
      )}
    </>
  );
};

export default StyledDuration;
