import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomTable from "../../ui/CustomTable";
import { useCompany } from "../Companies/Provider";
import "../style.css";
import AccountCell from "../Companies/CompanyTable/AccountCell";
import { useEmployees } from "./Provider";
import TabsList from "../../ui/TabsList";
import SiteCell from "./EmployeeTable/SiteCell";
import FingerPrintCell from "./EmployeeTable/FingerPrintCell";
import EmployeeCell from "./EmployeeTable/EmployeeCell";
import ModalBox from "../../ui/ModalBox";
import PassModal from "../../ui/PassModal";
import { useAuth } from "../../services/auth";
import { FilterList } from "@mui/icons-material";
/** form */
import { useForm } from "react-hook-form";

// Yup
import { yupResolver } from "@hookform/resolvers/yup";
// Yup
import * as Yup from "yup";
const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 325px 325px;
`;
const formSchema = Yup.object().shape({
  companyId: Yup.number(),
  search: Yup.string(),
});
const EmployeesList = () => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { role, adminOn } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const {
    state: { Names: companies },
    fetchNames: fetchCompanies,
  } = useCompany();
  const {
    state: { list: employees, page, perPage, count, length, companyIdAtt },
    fetchList: fetchEmployees,
    dispatch,
    remove,
  } = useEmployees();
  const [companyId, setCompanyId] = useState(0);
  const [open, setOpen] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [employeeForDelete, setEmployeeForDelete] = useState(false);
  const [employeeIdResetPassword, setEmployeeIdResetPassword] = useState(1);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  // handle detail click
  const handleDetail = (c) => {
    var emp = employees.find((emp) => emp.id === c);
    if (role === "Site") {
      if (emp.currentSiteId === adminOn?.id) {
        navigate(`EmployeeDetails/${c}`);
      } else {
        setError("No permission to view this employee details");
      }
    } else navigate(`EmployeeDetails/${c}`);
  };

  // let theme = useTheme();
  // fetch company
  useEffect(() => {
    if (role === "Admin") {
      if (companies?.length === 0) fetchCompanies();
    }
  }, [fetchCompanies]);

  // useEffect(() => {
  //   setCompanyId(0);
  // }, [companies]);
  //fetch employees

  useEffect(() => {
    if (role === "Admin") {
      fetchEmployees(companyIdAtt, 0, page, perPage);
    } else if (role === "Company") {
      fetchEmployees(adminOn?.id, 0, page, perPage);
    } else if (role === "Site") {
      fetchEmployees(0, 0, page, perPage);
    }
  }, [fetchEmployees, page, perPage]);
  const tabs = [{ title: "Employees", num: length }];
  const [selectedCols, setSelectedCols] = useState("Employees");

  const handleDelete = (id) => {
    try {
      remove(employeeForDelete);
    } catch (e) {
      console.log("error", e);
    }
  };

  const getCols = (name) => {
    switch (name) {
      case "Employees":
        return [
          {
            label: "Employee Code",
            name: "employeeCode",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Employee Full Name",
            name: "fullName",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Current Site Name",
            name: "correntSite",
            customRender: SiteCell,
          },
          {
            label: "Fingerprint",
            name: "fingerPrintDate",
            customRender: FingerPrintCell,
          },
          {
            label: "User account",
            name: "userAcount",
            customRender: AccountCell,
          },
        ];

        break;
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Employees":
        return employees;
        break;
    }
  };
  const getFilters = (name) => {
    switch (role) {
      case "Site":
        return {
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          search: (e) => {
            console.log(e);
          },
        };
      default:
        return {
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          add: () => {
            if (role === "Admin" || role === "Site" || role === "SubCompany") {
              if (companyIdAtt === 0) setError("please select one company");
              else navigate("/employees/add/" + companyIdAtt);
            } else if (role === "Company") {
              navigate("/employees/add/" + adminOn?.id);
            }
          },
          search: (e) => {
            console.log(e);
          },
        };
    }
  };
  // Modal Reset Password
  const handleCloseResetPass = () => {
    setOpenResetPass(false);
  };
  const handleOpenResetPass = (user) => {
    setOpenResetPass(true);
    setEmployeeIdResetPassword(user.id);
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };
  const onSubmit = async (values) => {
    try {
      await fetchEmployees(values.companyId, 0, page, perPage, values.search);
    } catch (error) {
      console.log(error, "error");
    }
  };
  if (!companies) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {role === "Admin" && (
        <>
          <Typography variant="Poster" component="h4" mb={2}>
            Select Company
          </Typography>
          <Box mb={1}>
            {error && (
              <Alert
                severity="error"
                sx={{ width: "fit-content", width: "70%" }}
              >
                {error}
              </Alert>
            )}
          </Box>
          <Box
            sx={{
              background: "#f7f9fb",
              padding: "20px",
              width: "80%",
              mb: 3,
            }}
          >
            <GridGroup>
              <Box>
                <FormControl sx={{ minWidth: 120, marginBottom: "20px" }}>
                  <span style={{ fontSize: "12px" }}>Company Name</span>
                  <Select
                    value={companyIdAtt}
                    onChange={(e) => {
                      dispatch({
                        type: "set_CompanyIdAtt",
                        payload: e.target.value,
                      });
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",

                      ...register("companyId"),
                      autoFocus: true,
                    }}
                    sx={{
                      fontSize: "12px",
                      width: "300px",
                      height: "40px",
                      marginTop: "14px",
                    }}
                  >
                    <MenuItem value={0}>
                      <em>All</em>
                    </MenuItem>
                    {companies.map((company) => {
                      return (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{ minWidth: 120, marginBottom: "30px" }}>
                  <span style={{ fontSize: "12px" }}>Search</span>
                  <TextField
                    inputProps={{
                      ...register("search"),
                    }}
                    sx={{ marginTop: "20px" }}
                    size="small"
                  />
                </FormControl>
              </Box>
            </GridGroup>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  color: "white",
                  mr: 5,
                  mb: 2,
                  "&:hover": { background: `${theme.color.blackBackground}` },
                  textTransform: "capitalize",
                }}
              >
                <FilterList />
                <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
              </Button>
            </Box>
          </Box>
        </>
      )}

      {role === "Company" && (
        <>
          <Box
            mb={10}
            sx={{
              background: "#f7f9fb",
              padding: "20px",
              width: "70%",
              p: 5,
            }}
          >
            <GridGroup>
              <Box>
                <span style={{ fontSize: "12px" }}>Company Name</span>
                {/* Adding sub company => !subcompany */}
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    width: "290px",
                    borderRadius: "8px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {adminOn?.companyName}
                </Box>
              </Box>
              <Box>
                <FormControl sx={{ minWidth: 120, marginBottom: "30px" }}>
                  <span style={{ fontSize: "12px" }}>Search</span>
                  <TextField
                    inputProps={{
                      ...register("search"),
                    }}
                    sx={{ marginTop: "20px" }}
                    size="small"
                  />
                </FormControl>
              </Box>
            </GridGroup>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  color: "white",
                  mr: 5,
                  mb: 2,
                  "&:hover": { background: `${theme.color.blackBackground}` },
                  textTransform: "capitalize",
                }}
              >
                <FilterList />
                <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
              </Button>
            </Box>
          </Box>
        </>
      )}

      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      ></TabsList>

      <CustomTable
        empty={employees?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        actions={
          role !== "Site" && {
            emoloyee: true,
            edit: (id) => {
              navigate("/employees/edit/" + id);
            },
            // delete: (e) => {
            //   setOpen(true);
            //   setEmployeeForDelete(e);
            // },
          }
        }
        filters={getFilters(selectedCols)}
        renderParams={{
          openModal: handleOpenResetPass,
        }}
        moreOptions={{
          detail: handleDetail,
        }}
        page={page}
        showButton={true}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
      ></CustomTable>
      <ModalBox
        open={open}
        operation="delete"
        handleClose={handleClose}
        handleOperation={handleDelete}
        text="The delete operation has been completed successfully.."
        name="employee"
      />
      <PassModal
        open={openResetPass}
        type="reset"
        handleClose={handleCloseResetPass}
        handleSuccess={() => setOpenSuccess(true)}
        employeeId={employeeIdResetPassword}
      />
      <ModalBox
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        operation="success"
        text="Password changed successfully.. you can now use the new password"
      />
    </form>
  );
};

export default React.memo(EmployeesList);
