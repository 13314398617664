import React, { useState } from "react";
import FormContract from "./FormContract";
import ModalBox from "../../ui/ModalBox";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const AddContract = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom
      >
        Add New Contract
      </Typography>
      <FormContract labelButton="Add Contract" handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added new contract"
        name="Contract"
      />
    </>
  );
};

export default React.memo(AddContract);
