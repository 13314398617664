const fields = [
  {
    name: "companyId",
    label: "Company Name",
    select: true,
    disabled: true,
    validationType: "number",
  },
  {
    name: "siteId",
    label: "Site Name",
    select: true,
    disabled: true,
    validationType: "number",
  },
  {
    name: "typeId",
    label: "Template Type",
    required: true,
    select: true,
    validationType: "number",
    getData: "temp[lateType",
    data: "name",
  },
  {
    name: "title",
    label: "Template Message Title",
    required: true,
    validationType: "string",
  },

  {
    name: "message",
    label: "Message Content",
    required: true,
    validationType: "string",

    multiline: true,
  },

  {
    name: "isActive",
    label: "Template Status",
    switch: true,
    validationType: "boolean",
  },
];

export default fields;
