import React from "react";

const SubSalaryRender = ({ row }) => {
  return (
    <div>
      {row.sites.map((s, index) => (
        <div style={{ paddingBottom: "10px" }} key={index}>
          {s?.subSalary}
        </div>
      ))}
    </div>
  );
};

export default SubSalaryRender;
