import React from 'react'
import { Outlet } from 'react-router-dom';
import Layout from '../../layout/Layout';

const MemberPlans = () => {
  return (
    <div>
      <Layout><Outlet /></Layout>
    </div>
  )
}

export default React.memo(MemberPlans);
