import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CustomTable from "../../../ui/CustomTable";
import { useNavigate, useParams } from "react-router-dom";
import { useSite } from "../../Sites/Provider";
import ActiveCell from "../../Companies/CompanyTable/ActiveCell";
import TabsList from "../../../ui/TabsList";
import { useAuth } from "../../../services/auth";
import CreatedDate from "../CreatedDate";

function TableInfo({ subCompany = [] }) {
  const navigate = useNavigate();
  let params = useParams();
  const { role, adminOn } = useAuth();
  const id = params.id;
  const [selectedCols, setSelectedCols] = useState("Sites");

  const cols = [
    {
      label: "Site Name",
      name: "siteName",
      textLeft: true,
      headTextLeft: true,
    },
    { label: "Site Country", name: "country" },
    // { label: "Employees Number", name: "subCompaniesCount" },//not returned from api
    { label: "Date Added", name: "createdDate", customRender: CreatedDate },
    { label: "Site Status", name: "isActive", customRender: ActiveCell },
  ];
  const {
    state: { list: sites, page, perPage },
    fetchList: fetchSites,
  } = useSite();

  useEffect(() => {
    if (selectedCols === "Sites" && subCompany?.company.id)
      fetchSites(subCompany?.company.id, page, 100, subCompany?.id);
  }, [id, page, perPage, fetchSites, subCompany]);
  const handleDetail = (c) => {
    navigate(`/sites/SiteDetail/${c}`);
  };
  const tabs = [{ title: "Sites", num: sites?.length }];

  return (
    <Box sx={{ mt: 2 }}>
      {/* Info Table */}
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      ></TabsList>
      {/* Table */}
      <CustomTable
        empty={sites?.length === 0 ? true : false}
        cols={cols}
        data={sites}
        moreOptions={
          role !== "SubCompany" && {
            detail: handleDetail,
          }
        }
        showButton={true}
        isPagination={false}
        rowsPerPage={10}
      ></CustomTable>
    </Box>
  );
}

export default TableInfo;
