import { Route } from "react-router-dom";
import Sites from "./Sites";
import ListSites from "./ListSites";
import DetailSite from "./DetailSite";
import AddSite from "./AddSite";
import UpdateSite from "./UpdateSite";
import AddZone from "../Zones/FormZone/AddZone";
import AddContract from "../Contracts/AddContract";
import AddDisCount from "../DisCounts/AddDisCount";
import AddGate from "../Gates/AddGate";
import AddMemberPlan from "../MemberPlans/AddMemberPlan";
import DetailZone from "../Zones/DetailZone";
import AddRole from "./AddRole";
import UpdateTicket from "./TicketPrintingFields/UpdateTicket";
import TemplateMessAdd from "../TemplateMessage/TemplateMessAdd";
import TemplateMessageUpdate from "../TemplateMessage/TemplateMessageUpdate";
import AddVoidReason from "../VoidReason/AddVoidReason";
import SMSInfo from "../SMS/SMSInfo";
import AddSms from "../SMS/AddSms";
import UpdateSms from "../SMS/UpdateSms";
import AddWebContent from "../WebContent/AddWebContent";
import UpdateWebContent from "../WebContent/UpdateWebContent";
import DetailFixedService from "../Services/FixedService/DetailFixedService";
import DetailHourlyService from "../Services/HourlyService/DetailHourlyService";
import UpdateService from "../Services/FixedService/UpdateService";
import UpdateHourlyService from "../Services/HourlyService/UpdateHourlyService";
import AddService from "../Services/FixedService/AddService";
import AddHourlyService from "../Services/HourlyService/AddHourlyService";

// import AddFixed from "../Zones/FormService/AddFixed";
// import AddHourly from "../Zones/FormService/AddHourly";

export default (
  <Route path="sites" exact element={<Sites />}>
    <Route index element={<ListSites />} />
    <Route path="SiteDetail/:id" element={<DetailSite />} />
    <Route path="SiteDetail/:id/edit" element={<UpdateSite />} />
    {/* <Route path="SiteEdit/:id" element={<UpdateSite />} /> */}
    <Route path="add/:id" element={<AddSite />} />
    <Route
      path="SiteDetail/:id/TicketPriningFields"
      element={<UpdateTicket ticket={true} />}
    />
    <Route path="SiteDetail/:id/AddContract" element={<AddContract />} />
    <Route path="SiteDetail/:id/AddDiscount" element={<AddDisCount />} />
    <Route path="SiteDetail/:id/AddGate" element={<AddGate />} />
    <Route path="SiteDetail/:id/AddMemberPlan" element={<AddMemberPlan />} />
    <Route path="SiteDetail/:id/AddZone" element={<AddZone />} />
    <Route path="SiteDetail/:id/AddRole" element={<AddRole />} />
    <Route path="SiteDetail/:id/AddVoidReason" element={<AddVoidReason />} />
    <Route path="SiteDetail/:id/update" element={<TemplateMessageUpdate />} />
    <Route
      path="SiteDetail/:id/AddTemplateMessage"
      element={<TemplateMessAdd />}
    />
    <Route path="SiteDetail/SiteZoneDetails/:id" element={<DetailZone />} />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid"
      element={<DetailZone />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/FixServiceDetail/:szid"
      element={<DetailFixedService />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/HourlyServiceDetail/:szid"
      element={<DetailHourlyService />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/UpdateService/:id"
      element={<UpdateService />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/UpdateHourlyService/:id"
      element={<UpdateHourlyService />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/AddService/:id"
      element={<AddService />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/AddHourlyService/:id"
      element={<AddHourlyService />}
    />
    <Route path="SiteDetail/:id/smsProvider/:smsId" element={<SMSInfo />} />
    <Route path="SiteDetail/:id/UpdateSmsProvider" element={<UpdateSms />} />
    <Route
      path="SiteDetail/:id/UpdateWebClient"
      element={<UpdateWebContent />}
    />
    <Route path="SiteDetail/:id/add" element={<AddSms />} />
    <Route path="SiteDetail/:id/addWebContent" element={<AddWebContent />} />
    {/* <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/AddFixedService"
      element={<AddFixed />}
    />
    <Route
      path="SiteDetail/:id/SiteZoneDetails/:zid/AddHourlyService"
      element={<AddHourly />}
    /> */}
  </Route>
);
