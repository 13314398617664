import React from "react";

const CarCount = ({ row }) => {
  return (
    <div>
      {row.details.length !== 0 ? (
        <div
          style={{
            borderBottom: "dashed 1px gray",
            marginBottom: "5px",
          }}
        >
          {row.details.map((service, index) => (
            <div style={{ paddingBottom: "5px" }}>{service.carsCount}</div>
          ))}
        </div>
      ) : null}
      <div>{row.carsCount}</div>
    </div>
  );
};

export default CarCount;
