import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";
import FormVoidReason from "./FormVoidReason";
import { Typography } from "@mui/material";

const AddVoidReason = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Add New Reason
      </Typography>
      <FormVoidReason handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added new Void Reason"
        name="Void Reason"
      />
    </>
  );
};

export default AddVoidReason;
