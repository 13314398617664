import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";
import FormMemberShip from "./FormMemberShip";
import { useMemberShip } from "./Provider";

const UpdateMemberShip = () => {
  const [open, setOpen] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    state: { selected: membership },
    fetchOne: getMembershipInfo,
  } = useMemberShip();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  useEffect(() => {
  getMembershipInfo(id);
  }, [id])
 if(!membership) return;
  return (
    <div>
         <Typography variant="Poster" component="h4" gutterBottom
      >
        Edit Membership
      </Typography>
      <FormMemberShip handleSuccess={handleSuccess} membership={membership} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.. You update"
        name="Membership"
      />
    </div>
  );
};

export default React.memo(UpdateMemberShip);
