import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormZone from "./FormZone";
import ModalBox from "../../../ui/ModalBox";
// Yup
import * as Yup from "yup";
import { Typography } from "@mui/material";

const AddZone = () => {
  const navigate = useNavigate();
  const [zoneName, setZoneName] = useState("x");
  const [open, setOpen] = useState(false);

  // yup schema
  const formSchema = Yup.object().shape({
    siteId: Yup.number().min(0),
    title: Yup.string()
      .required("this field is required")
      .max(100, "must be less than or equal to 100 character"),
    isActive: Yup.boolean(),
  });

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };

  return (
    <div>
      <Typography variant="Poster" component="h3" gutterBottom>
        Add Zone
      </Typography>
      <div>
        <FormZone
          handleSuccess={handleSuccess}
          setZoneName={setZoneName}
          formSchema={formSchema}
        />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added a new site zone "
        name={zoneName}
      />
    </div>
  );
};

export default React.memo(AddZone);
