import React from "react";
import Layout from "../../layout/Layout";
import { Outlet } from "react-router-dom";

const Deductions = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default Deductions;
