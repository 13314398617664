// Providers
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// Yup
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box } from "@mui/system";
import {
  TextField,
  Typography,
  Card,
  Button,
  useTheme,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import styled from "styled-components";
// UI
import ErrorsList from "../../ui/ErrorsList";
// Utilities
import { handleResponse } from "../../utilities/functions";
// Data
import fields from "./AddRoleFeilds";
import { useSite } from "./Provider";
import { RolesEnum } from "../../utilities/Enum";
// Style
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormRole = ({ handleSuccess, setZoneName, formSchema }) => {
  let roles = RolesEnum
// providers
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: site },
    fetchOne: fetchSite,
    updateRole
  } = useSite();
  const navigate = useNavigate();
  let theme = useTheme();
  let params = useParams();
  // vars
  const id = params.id;
  const [error, setError] = useState(null);

  // use effects
  useEffect(() => {
    fetchSite(id);
  }, [id, fetchSite]);

  // on submit
  const onSubmit = async (values) => {
    try {
      await updateRole(values , id);
      reset();
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      if (e.status === 409) setError({ title: "Role Already exists" });
      if (e.status === 500) setError({ title: "Internal Server Error" });
      handleResponse(e.status);
    }
  };
  return (
    <>
      <Box>
        {/* Body */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <Typography variant="Poster" component="h4" gutterBottom>
              Role Details
            </Typography>
            <ErrorsList error={error} />
            <GridGroup>
              {fields?.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;
                inputProps.type = f.type;
                return (
                  <Box key={i}>
                    {f.select ? (
                      <FormControl
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        size="small"
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          htmlFor="demo-simple-select"
                        >
                          {f.label}
                          {" *"}
                        </InputLabel>
                        <Select
                          label={f.label}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          value={getValues("roleId")}
                          onChange={(e) => setValue("roleId", e.target.value)}
                          required={true}
                          error={!!errors[f.name]?.message}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          inputProps={{
                            ...register(f?.name),
                          }}
                        >
                          {roles.map((c, i) => (
                            <MenuItem key={i} value={c.roleId}>
                              {c.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          htmlFor="demo-simple-select"
                          sx={{ color: "#d32f2f" }}
                        >
                          {errors[f.name]?.message}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <TextField
                        label={f.label}
                        select={f.select}
                        size="small"
                        type={f.type}
                        name={f.name}
                        helperText={errors[f.name]?.message}
                        error={!!errors[f.name]}
                        fullWidth
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        defaultValue={f.defaultValue}
                        placeholder={f.placeholder}
                        required={f.required}
                        inputProps={{
                          ...register(f?.registerName),
                        }}
                      >
                        {f.select &&
                          roles.map((r, index) => (
                            <MenuItem
                              key={`${r["valueMenu"]}${index}`}
                              value={getValues("roleId")}
                            >
                              {r.value}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  </Box>
                );
              })}
            </GridGroup>
            {/* End Body */}
            {/* Buttons */}
            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                Add Role
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(`/sites/SiteDetail/${id}`)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(FormRole);
