import React, { useState } from "react";
import FilterForm from "./FilterForm";
import { Box } from "@mui/material";
import { useMemory } from "./Provider";
import TabsList from "../../../ui/TabsList";
import CustomTable from "../../../ui/CustomTable";
import CompanyName from "./TableCellRender/CompanyName";
import SiteName from "./TableCellRender/SiteName";
import EmployeeName from "./TableCellRender/EmployeeName";
import Vendor from "./TableCellRender/Vendor";
import MaterialName from "./TableCellRender/MaterialName";
import Quantity from "./TableCellRender/Quantity";
import Price from "./TableCellRender/Price";
import { useNavigate } from "react-router-dom";

const MemoList = () => {
  let navigate = useNavigate();

  /**get resources from memory provider */
  const {
    state: { list: MemoList, totalSize, page, perPage, count },
    remove,
    dispatch,
  } = useMemory();

  const tabs = [{ title: "Memo List", num: totalSize }];

  const [selectedCols, setSelectedCols] = useState("Memo List");
  /**column */
  const cols = [
    {
      label: "Memo Company",
      name: "companyName",
      headTextLeft: true,
      textLeft: true,
      customRender: CompanyName,
    },
    { label: "Sites", name: "siteName", customRender: SiteName },
    {
      label: "Employee",
      name: "employeeName",
      customRender: EmployeeName,
    },
    {
      label: "Vendor",
      name: "vendor",
      customRender: Vendor,
    },
    {
      label: "Memo Date",
      name: "memoDate",
    },
    {
      label: "Lead Time",
      name: "leadTime",
    },
    {
      label: "Notes",
      name: "notes",
    },
    {
      label: "Type",
      name: "typeName",
    },
  ];

  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: page });
  };
  /**handle delete function */
  const handleDelete = async (ID) => {
    try {
      await remove(ID);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (Id, row) => {
    navigate(`/store/memo/${row.typeName}/` + Id);
  };

  return (
    <>
      <FilterForm />
      <Box>
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
        <CustomTable
          empty={MemoList?.length === 0 ? true : false}
          cols={cols}
          data={MemoList}
          moreOptions={{
            detail: (Id) => navigate("detail/" + Id),
          }}
          actions={{
            selectedCols: true,
            // inTableEdit: true,
            edit: handleEdit,
            delete: handleDelete,
          }}
          filters={{
            page: perPage,
            changePageSize: changePageSize,
            add: () => {
              // navigate(`/store/memo/add`);
              console.log("add clickable");
            },
            // search: (e) => {
            //   console.log(e);
            // },
            dropDown: true,
            dropDownData: [
              {
                label: "In Memo",
                fun: () => {
                  navigate("/store/memo/In");
                },
              },
              {
                label: "Out Memo",
                fun: (ID) => {
                  navigate("/store/memo/Out");
                },
              },
              {
                label: "Return In Memo",
                fun: (ID) => {
                  navigate("/store/memo/ReturnIn");
                },
              },
              {
                label: "Return Out Memo",
                fun: (ID) => {
                  navigate("/store/memo/ReturnOut");
                },
              },
            ],
          }}
          dispatch={dispatch}
          count={count}
          page={page}
          showButton={true}
          rowsPerPage={10}
          isPagination={true}
        ></CustomTable>
      </Box>
    </>
  );
};

export default MemoList;
