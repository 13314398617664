const fields = [
  {
    name: "companyId",
    label: "Company Name",
    select: true,
    disabled: true,
    validationType: "number",
  },
  {
    name: "siteId",
    label: "Site Name",
    select: true,
    disabled: true,
    validationType: "number",
  },
  {
    name: "reason",
    label: "Void Reason",
    required: true,
    validationType: "string",
  },

  {
    name: "isActive",
    label: "Template Status",
    switch: true,
    validationType: "boolean",
  },
];

export default fields;
