import { addDays } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { BsSearch, BsQuestionCircle } from "react-icons/bs";

const fields = [
  {
    name: "companyId",
    label: "Company Name",
    disabled: true,
  },
  {
    name: "employeeID",
    label: "Employee Id",
    required: true,
  },
  {
    name: "employeeCode",
    label: "Employee Code",
    icone: true,
    required: true,
  },
  {
    name: "nssfNumber",
    label: "NSSF Number",
    icone: true,
    required: true,
    iconeName: <BsQuestionCircle></BsQuestionCircle>,
  },
  {
    name: "fullName",
    label: "Full Name",
    required: true,
    icone: true,
    iconeName: <BsQuestionCircle></BsQuestionCircle>,
  },
  {
    name: "mobile",
    label: "Mobile Number",
    required: true,
    icone: true,
    iconeName: <BsQuestionCircle></BsQuestionCircle>,
  },
  {
    name: "startDate",
    label: "Start Date",

    validationType: "string",

    date: true,
  },
  {
    switch: true,
    name: "isActive",
    label: "Employee Status",
  },
];

export default fields;
