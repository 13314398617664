import React from "react";

const SitesCurrency = ({ row }) => {
  return (
    <div>
      {row.sites.map((s, index) => {
        return (
          <div key={index} style={{ paddingBottom: "10px" }}>
            {s.site?.siteName}
          </div>
        );
      })}
    </div>
  );
};

export default SitesCurrency;
