const fields = [
  // {
  //   name: "id",
  //   label: "Vendor ID",
  //   value: "id",
  // },
  {
    name: "name",
    label: "Vendor Name",
    value: "name",
  },
  // {
  //   name: "company",
  //   label: "Vendor Company",
  //   value: "company",
  // },
  {
    name: "weblink",
    label: "Vendor WebLink",
    value: "weblink",
  },
  {
    name: "description",
    label: "Description",
    value: "description",
  },
];

export default fields;
