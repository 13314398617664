const fields = [
  {
    name: "amount",
    label: "Amount",
    validationType: "number",
    Number: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "dueDate",
    label: "Due Date",
    validationType: "date",
    date: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
