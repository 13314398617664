import { Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import ModalBox from "../ModalBox";
import { useEmployees } from "../../view/employee/Provider";

const ButtonWrapper = styled.div`
  width: fit-content;
  margin: 10px auto;

  button {
    margin: 0 10px;
    color: white;
    font-size: 11px;
  }
`;
const HourlyRateActions = ({
  data,
  row,
  id,
  actions,
  editing,
  setEditing,
  roleId,
  setRoleId,
}) => {
  let theme = useTheme();

  const [open, setOpen] = useState(false);

  const {
    state: { selected: employee },
    fetchOne: fetchEmployee,
  } = useEmployees();

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (id) => {
    editing && setEditing(false);
    !editing && setOpen(true);
  };

  const handleEdit = (row) => {
    editing &&
      actions.edit(
        data.filter((e) => e.id === id || e.roleId === id || e.siteId === id),
        id
      );
    editing && setEditing(null);
  };
  if (editing === id) {
    return (
      <>
        {actions.edit &&
          row.roles.map(
            (role, index) =>
              role.roleId === roleId && (
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleEdit(id);
                    }}
                    style={{
                      backgroundColor: "black",
                      "&:hover": { background: `${theme.color.black}` },
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    onClick={() => {
                      setEditing(null);
                      fetchEmployee(employee.id);
                      //      editing && setEditing(null);
                      // col[index].customRender = true;
                    }}
                    style={{
                      background: theme.color.gray200,
                      color: "black",
                      "&:hover": { background: `${theme.color.gray200}` },
                    }}
                  >
                    Discard
                  </Button>
                </ButtonWrapper>
              )
          )}
      </>
    );
  }
  return (
    <div>
      {row.roles.map((role, index) => (
        <ButtonWrapper>
          <Button
            onClick={() => {
              actions.inTableEdit ? setEditing(id) : actions.edit(id, row);
              setRoleId(role.roleId);
            }}
            style={{ backgroundColor: "#FFC555" }}
          >
            edit
          </Button>
          <Button
            onClick={() => {
              setRoleId(role.roleId);
              handleDelete(id);
            }}
            style={{ background: "#FF4747" }}
          >
            Delete
          </Button>
        </ButtonWrapper>
      ))}
      <ModalBox
        open={open}
        operation="delete"
        handleClose={handleClose}
        handleOperation={() => {
          actions.delete(id);
          setOpen(false);
        }}
        text={`Do you want to complete the process of deleting all information?`}
      />
    </div>
  );
};

export default HourlyRateActions;
