import { useTheme } from "@emotion/react";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import fields from "./fields";

// use form
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import SMSProvider, { useSms } from "./Provider";
// yup schema
const yupSchema = fields.reduce(createYupSchema, {
  smsProviderId: Yup.number().required("select provider"),
  sender: Yup.string().required("Enter sender name"),
  username: Yup.string().required("Enter user name"),
  password: Yup.string()
    .min(1, "password should be at least one character")
    .required("Enter password"),
  balancePassword: Yup.string(),
  accountID: Yup.string(),
  minSmsAlert: Yup.number(),
  isActive: Yup.bool(),
});
const formSchema = Yup.object().shape(yupSchema);

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 22px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormSMS = ({ handleSuccess, SmsINfo }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: SmsINfo,
  });
  const {
    state: { list: providers },
    fetchList,
    setSmsProvider,
  } = useSms();

  useEffect(() => {
    fetchList(id);
  }, [id, fetchList]);
  const onSubmit = async (values) => {
    try {
      await setSmsProvider(id, values);
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          SMS Provider Details
        </Typography>
        <Grid2>
          {fields.map((f, index) => (
            <>
              {f.switch ? (
                <FormGroup>
                  <FormLabel
                    component="legend"
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                  >
                    {f.label}
                  </FormLabel>
                  <FormControlLabel
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                    control={
                      <Switch
                        color="success"
                        defaultChecked={SmsINfo ? SmsINfo?.isActive : true}
                      />
                    }
                    {...register(f?.name)}
                    label="Active"
                  />
                </FormGroup>
              ) : f.select ? (
                <FormControl
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb,
                  }}
                  required
                  error={!!errors[f.name]}
                  helperText={errors[f.name]?.message}
                >
                  <InputLabel id="demo-simple-select-label">
                    {f.label}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={f.label}
                    defaultValue={SmsINfo?.[f?.name]}
                    inputProps={{
                      ...register(f?.name),
                      required: true,
                    }}
                    size="small"
                    error={!!errors[f.name]}
                    helperText={errors[f.name]?.message}
                  >
                    {providers?.map((s, index) => (
                      <MenuItem key={index} value={s.id}>
                        {s.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  type={f.type}
                  error={!!errors[f.name]}
                  helperText={errors[f.name]?.message}
                  size="small"
                  defaultValue={SmsINfo?.[f?.name]}
                  inputProps={{
                    ...register(f?.name),
                  }}
                  required={f.required}
                  label={f.label}
                />
              )}
            </>
          ))}
        </Grid2>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Save SMS Provider
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default FormSMS;
