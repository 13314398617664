import { Typography } from "@mui/material";
import React from "react";
import e from "../../e.png";

const BrandCarStyle = ({row}) => {
  var source = row?.maker?.fileName;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "150px",
        height: "50px",
      }}
    >
      <div style={{ width: "25px", height: "25px", marginRight: "10px" }}>
        {" "}
        <img src={row?.maker?.fileName} style={{ width: "100%", height: "100%" }} />
      </div>
      <Typography variant="caption" component="h6" ml={2}>
        {row?.maker?.makerName}
      </Typography>
    </div>
  );
};

export default BrandCarStyle;
