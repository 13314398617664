const printingFields = [
  {
    id: 1,
    name: "companyName",
    description: "Company Name",
    value: "companyName",
    registerName: "companyName",
    sitePath: ["company", "companyName"],
    hasValue: true,
    h6: true,
    printedValue: "EMPTY Company Name",
  },
  {
    id: 2,
    name: "siteName",
    description: "Site Name",
    registerName: "site.siteName",
    sitePath: ["siteName"],
    h6: true,
    printedValue: "EMPTY Site Name",
  },
  {
    id: 3,
    name: "printTitle",
    description: "Print Title",
    registerName: "site.printTitle",
    sitePath: ["printTitle"],
    printedValue: "EMPTY Print Title",
  },
  {
    id: 4,
    name: "printText",
    description: "Print Text",
    registerName: "site.printText",
    sitePath: ["printText"],
    printedValue: "EMPTY Print Text",
  },
  {
    id: 5,
    name: "complaintText",
    description: "Complaint Text",
    registerName: "site.complaintText",
    sitePath: ["complaintText"],
    printedValue: "EMPTY Complaint Text",
  },
  {
    id: 6,
    name: "smsMobile",
    description: "Sms Mobile",
    registerName: "site.smsMobile",
    sitePath: ["smsMobile"],
    printedValue: "EMPTY Sms Mobile",
  },
  {
    id: 7,
    name: "address",
    description: "Site Address",
    registerName: "site.address",
    sitePath: ["address"],
    printedValue: "EMPTY Site Address",
  },
  {
    id: 8,
    name: "siteZoneName",
    description: "Zone Name",
    registerName: "zone.title",
    // sitePath: ["siteZoneName"],
    defaultValue: "Zone Name",
  },
  {
    id: 9,
    name: "GateIn",
    description: "Gate-In",
    defaultValue: "Gate-In",
  },
  {
    id: 10,
    name: "GateOut",
    description: "Gate-Out",
    defaultValue: "Gate-Out",
  },
  {
    id: 11,
    name: "TicketNumber",
    description: "Ticket Number",
    defaultValue: "60",
  },
  {
    id: 12,
    name: "TicketCode",
    description: "Ticket Code",
    defaultValue: "23-4432de-60",
  },
  {
    id: 13,
    name: "QRCode",
    description: "QR Code",
  },
  {
    id: 14,
    name: "CheckInDate",
    description: "Check-In Date",
    defaultValue: "Check-In Date",
  },
  {
    id: 15,
    name: "CheckOutDate",
    description: "Check-Out Date",
    defaultValue: "Check-Out Date",
  },
  {
    id: 16,
    name: "openDuration",
    description: "Duration",
    defaultValue: "Duration",
  },
  {
    id: 17,
    name: "Services",
    description: "Services",
  },
  {
    id: 18,
    name: "fixPriceOfficial",
    description: "Official Price",
    // sitePath: ["fixPriceOfficial"],
    defaultValue: "Total official price: 20000",
  },
  {
    id: 19,
    name: "fixPriceTrial",
    description: "Trial Price",
    // sitePath: ["fixPriceTrial"],
    defaultValue: "Total trial price: 20000",
  },
  {
    id: 20,
    name: "Discount",
    description: "Discount",
    defaultValue: "Discount",
  },
  {
    id: 21,
    name: "printFooter",
    description: "Print Footer",
    registerName: "site.printFooter",
    sitePath: ["printFooter"],
  },
  {
    id: 22,
    name: "companyAddress",
    description: "Company Address",
    registerName: "company.address",
    sitePath: ["company", "address"],
  },
  {
    id: 23,
    name: "companyMobile",
    description: "Company Mobile",
    registerName: "company.mobile",
    sitePath: ["company", "mobile"],
  },
  {
    id: 24,
    name: "companyWebSite ",
    description: "Company WebSite",
    registerName: "company.website",
    sitePath: ["company", "website"],
  },
  {
    id: 25,
    name: "vat",
    description: "vat",
    registerName: "vat",
    sitePath: ["vat"],
  },
  {
    id: 26,
    name: "vatNumber",
    description: "Vat Number",
    registerName: "vatNumber",
    sitePath: ["vatNumber"],
  },
  {
    id: 27,
    name: "carInfo",
    description: "Car Info",
    registerName: "carInfo",
    sitePath: ["carInfo"],
  },
  {
    id: 28,
    name: "carPlate",
    description: "Car Plate",
    registerName: "carPlate",
    sitePath: ["carPlate"],
  },
];

export default printingFields;
