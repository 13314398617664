import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../services/auth";
import { useNavigate, useParams } from "react-router-dom";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import fields from "./fields";
import styled from "styled-components";
import { useCompany } from "../Companies/Provider";
import { useWhiteIps } from "./provider";
import { isIPv4 } from "is-ip";

// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
//styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
  grid-template-rows: 80px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormWhiteIps = ({ handleSuccess }) => {
  let { role } = useAuth();
  let navigate = useNavigate();
  const { id } = useParams();
  let theme = useTheme();
  const [error, setError] = useState(null);
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompany();
  const { create } = useWhiteIps();
  useEffect(() => {
    fetchCompany(id);
  }, [id, fetchCompany]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    setValue("companyId", id);
  }, []);
  const onSubmit = async (values) => {
    // console.log(isIPv4("10.10.10.10"), "is ip address");
    if (isIPv4(values.ip)) {
      try {
        create(values);
        handleSuccess();
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("IP address is not correct ipv4 address");
    }
  };
  if (!company) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        {error && <div style={{ color: "red" }}>{error}</div>}
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          White IP Details
        </Typography>
        <GridGroup>
          {fields?.map((f, i) => (
            <Box key={i}>
              {f.disabled && (
                <div>
                  <Typography variant="body2" component="h6" gutterBottom>
                    {f.label}
                  </Typography>

                  <Box
                    sx={{
                      borderBottom: "1px solid gray",
                      width: "290px",
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    {company?.companyName}
                  </Box>
                </div>
              )}
              {f.switch && !f.disabled && (
                <Box>
                  <FormGroup>
                    <FormLabel
                      component="legend"
                      sx={{
                        mt: theme.dimensions.mt,
                      }}
                    >
                      {f.label}
                    </FormLabel>
                    <FormControlLabel
                      sx={{
                        mt: theme.dimensions.mt,
                      }}
                      control={<Switch color="success" defaultChecked />}
                      {...register(f?.name)}
                      label="Active"
                    />
                  </FormGroup>
                </Box>
              )}
              {!f.switch && !f.disabled && (
                <TextField
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  helperText={errors[f.name]?.message}
                  className={f.class}
                  fullWidth
                  required
                  disabled={f.disabled}
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb,
                  }}
                  inputProps={{
                    ...register(f?.name),
                    required: f.required,
                  }}
                  size="small"
                ></TextField>
              )}
            </Box>
          ))}
        </GridGroup>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Add White IP
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default FormWhiteIps;
