import React from "react";

function CompanyCell({ row, i }) {
  return (
    <>
      {row?.company === null ? (
        <span style={{ color: "gray" }}>not specified</span>
      ) : (
        row?.company?.companyName
      )}
    </>
  );
}

export default CompanyCell;
