//react components
import React, { useEffect, useState } from "react";
//react components
import { useParams, useNavigate } from "react-router";
//dashboard components
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
//@material-ui/core components
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
//provider
import { useService } from "../Services/Provider";
//render functions
import ActiveCell from "../Companies/CompanyTable/ActiveCell";
import ServiceStyledType from "../Services/ServiceStyledType";
//functions to filter services to fixed or hourly
import {
  FixedTypeService,
  FixedValidation,
  handleErrorResponse,
  HourlyTypeService,
  HourlyValidation,
} from "../../utilities/functions";
import ModalBox from "../../ui/ModalBox";
import { useZone } from "./Provider";

//icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import DotColorful from "../../icons/DotColorful.svg";
// import DotColorless from "../../icons/DotColorless.svg";
import { useTheme } from "@emotion/react";
import ErrorsList from "../../ui/ErrorsList";
import { FixedServiceType } from "../../utilities/Enum";
import detailsFields from "./detailsFields";
import { useAuth } from "../../services/auth";

const DetailZone = () => {
  const { id, zid } = useParams();
  const pa = useParams();
  const navigate = useNavigate();

  const { role, adminOn } = useAuth();
  const [editCheck, seteditCheck] = useState(false);
  const [vState, setVState] = useState(false);

  let theme = useTheme();
  const TitleStyle = {
    p: theme.dimensions.padding / 4,
    color: theme.color.PaperlabelColor,
    fontSize: "14px",
  };
  const TitleStyle2 = {
    p: theme.dimensions.padding / 4,
    fontSize: "14px",
  };

  const [open, setOpen] = useState(false);
  const [reload, setreload] = useState(false);
  const [error, setError] = useState(null);
  var fixed = [{}];
  var hourly = [{}];

  const {
    state: { list: services },
    fetchList: fetchServices,
    edit: editService,
  } = useService();
  const {
    state: { selected: Zone, Serviceslist },
    fetchOne: fetchZone,
    removeService,
  } = useZone();

  const getCols = (name) => {
    switch (name) {
      case "Site Zone Services":
        return [
          {
            label: "Title",
            name: "title",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Service Type",
            name: "serviceTypeId",
            customRender: ServiceStyledType,
          },
          {
            label: "Service Status",
            name: "isActive",
            customRender: ActiveCell,
          },
        ];
      case "Fixed Type":
        return [
          {
            label: "Title",
            name: "title",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "not empty",
          },
          {
            label: "Service Type",
            name: "serviceTypeId",
            customRender: ServiceStyledType,
            editable: true,
            hourly: false,
          },
          {
            label: "Service Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      case "Hourly Type":
        return [
          {
            label: "Title",
            name: "title",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "not empty",
          },
          {
            label: "Service Type",
            name: "serviceTypeId",
            customRender: ServiceStyledType,
            editable: true,
            hourly: true,
            checkable: true,
          },
          {
            label: "Service Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Site Zone Services":
        return services;
      case "Fixed Type":
        return fixed;
      case "Hourly Type":
        return hourly;
    }
  };
  const getFilters = (name) => {
    switch (name) {
      case "Site Zone Services":
        return {
          add: () => {},
          search: (e) => {
            console.log(e);
          },
          dropDown: true,
          dropDownData: [
            {
              label: "Fixed Services",
              fun: () => {
                // navigate(`/zones/SiteZoneDetails/AddService/${zid}`);
                navigate(
                  `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/AddService/${zid}`
                );
              },
            },
            {
              label: "Hourly Services",
              fun: (ID) => {
                // navigate(`/zones/SiteZoneDetails/AddHourlyService/${zid}`);
                navigate(
                  `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/AddHourlyService/${zid}`
                );
              },
            },
          ],
        };
      case "Fixed Type":
        return {
          add: () => {
            // navigate(`/zones/SiteZoneDetails/AddService/${zid}`);
            navigate(
              `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/AddService/${zid}`
            );
          },
          search: (e) => {
            console.log(e);
          },
        };
      case "Hourly Type":
        return {
          add: () => {
            // navigate(`/zones/SiteZoneDetails/AddHourlyService/${zid}`);
            navigate(
              `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/AddHourlyService/${zid}`
            );
          },
          search: (e) => {
            console.log(e);
          },
        };
      default:
        return;
    }
  };

  //fetch Zones
  useEffect(() => {
    fetchServices(zid);
  }, [fetchServices, zid]);

  //fetch Zones
  useEffect(() => {
    fetchZone(zid);
  }, [fetchZone, zid, reload]);

  fixed = FixedTypeService(services);
  hourly = HourlyTypeService(services);
  const tabs = [
    { title: "Site Zone Services", num: services?.length },
    { title: "Fixed Type", num: fixed?.length },
    { title: "Hourly Type", num: hourly?.length },
  ];
  //tab which selected
  const [selectedCols, setSelectedCols] = useState("Site Zone Services");
  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  //edit function
  //

  const handleDelete = async (ID) => {
    try {
      await removeService(ID);
    } catch (err) {
      setError({ title: err.errors.message });
      fetchZone(zid);
      setreload(true);
    }
  };

  if (!services || !Zone) return;

  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Site Zone Details
      </Typography>
      <Paper
        elevation={0}
        // square
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
          background: theme.color.PaperbgColor,
          width: "90%",
        }}
      >
        <Typography variant="Poster" component="h4" gutterBottom>
          Site Zone Information
        </Typography>
        <Grid container>
          {detailsFields.map((f, index) => {
            if (f.editOnly) return;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                mt={2}
                key={index}
                sx={{ fontSize: "14px" }}
              >
                <span style={{ color: theme.color.PaperlabelColor }}>
                  {f.label} :{" "}
                </span>
                <span>
                  {f?.viewLabel2 && Zone?.[f?.viewLabel]?.[f?.viewLabel2]}
                  {!f?.viewLabel2 && Zone?.[f?.viewLabel]}
                  {f.name === "isActive" &&
                    (Zone?.isActive ? (
                      <span style={{ color: "#4AA785" }}>
                        <FiberManualRecordIcon
                          fontSize="small"
                          sx={{ fontSize: 10, mr: 0.2 }}
                        />
                        Active
                      </span>
                    ) : (
                      <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                        <FiberManualRecordIcon
                          fontSize="small"
                          sx={{ fontSize: 10, mr: 0.2 }}
                        />
                        Inactive
                      </span>
                    ))}
                </span>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
      <Box sx={{ mt: 2 }}>
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
      </Box>
      {error && (
        <Alert severity="error" sx={{ m: 3 }}>
          <ErrorsList error={error} />
        </Alert>
      )}
      <CustomTable
        empty={getData(selectedCols)?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        filters={role !== "SubCompany" && getFilters(selectedCols)}
        actions={
          role !== "SubCompany" &&
          (selectedCols !== "Site Zone Services"
            ? {
                selectedCols: true,
                edit: (id) => {
                  selectedCols === "Fixed Type"
                    ? navigate(
                        `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/UpdateService/${id}`
                      )
                    : navigate(
                        `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/UpdateHourlyService/${id}`
                      );
                  // ? navigate(`/zones/SiteZoneDetails/UpdateService/${id}`)
                  // : navigate(
                  //     `/zones/SiteZoneDetails/UpdateHourlyService/${id}`
                  //   );
                },

                // delete: handleDelete,
                check: (v) => v.length !== 0,
                vState: vState,
                setVState: setVState,
              }
            : false)
        }
        moreOptions={
          selectedCols !== "Site Zone Services" && {
            detail: (ID) => {
              selectedCols === "Fixed Type"
                ? // ? navigate(`/zones/SiteZoneDetails/FixServiceDetail/${ID}`)
                  // : navigate(`/zones/SiteZoneDetails/HourlyServiceDetail/${ID}`);
                  navigate(
                    `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/FixServiceDetail/${ID}`
                  )
                : navigate(
                    `/sites/SiteDetail/${id}/SiteZoneDetails/${zid}/HourlyServiceDetail/${ID}`
                  );
            },
          }
        }
        showButton={true}
        rowsPerPage={10}
        title={"Services"}
        isPagination={false}
      ></CustomTable>
      <ModalBox
        open={open}
        operation="edit"
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
        name="Services"
      />
    </>
  );
};

export default React.memo(DetailZone);
