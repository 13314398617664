// React
import React, { useEffect } from "react";

// Material UI
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  FormControl,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

// MUI Icons
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
// Icons
// import AddIcon from "../icons/Add.svg";
// import FunnelSimple from "../icons/FunnelSimple.svg";
// import Arrows from "../icons/Arrows.svg";
// import Dots from "../icons/Dots.svg";
// import SearchRightIcon from "../icons/SearchRightIcon.svg";

function FilterTable({ name, data, filters }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const open = anchorEl === null ? false : true;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    color: "rgba(0, 0, 0, 0.5)",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    // border: 'solid',
    "& .MuiInputBase-input": {
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      transition: theme.transitions.create("width"),
      width: "100%",
      height: "10px",
      padding: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "8px",
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));
  // const StyledMenu = styled(Menu)(({ theme }) => ({
  //   ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
  //     boxShadow:
  //       "0px 2px 10px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  //     width: "200px",
  //   },
  //   ".css-6hp17o-MuiList-root-MuiMenu-list": {
  //     backgroundColor: "#F9FAFB",
  //   },
  //   ".css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
  //     fontSize: "14px",
  //     lineHeight: "24px",
  //     fontWeight: 500,
  //     color: "#1D2939",
  //     margin: "0px 6px",
  //     borderRadius: "6px",
  //     paddingLeft: "6px",
  //   },
  // }));

  return (
    <div
      style={{
        backgroundColor: "#F7F9FB",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "3px",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginTop: "11px",
        height: "40px",
        maxWidth: "1200px",
      }}
    >
      <div style={{ display: "flex" }}>
        {filters?.dropDown ? (
          <>
            <div>
              <IconButton
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <AddIcon color="action" fontSize="small" />
                {/* <img alt="AddIcon" src={AddIcon} /> */}
              </IconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {filters?.dropDownData?.map((data, index) => {
                  return (
                    <MenuItem key={index} onClick={() => data.fun()}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </>
        ) : (
          <>
            {filters.add && (
              <IconButton onClick={() => filters.add()}>
                <AddIcon color="action" fontSize="small" />
                {/* <img alt="AddIcon" src={AddIcon} /> */}
              </IconButton>
            )}
            {filters.preview && (
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "grey",
                }}
                onClick={() => filters.preview()}
              >
                <PreviewOutlinedIcon color="action" fontSize="small" />
                <span>Preview</span>
              </Button>
            )}
          </>
        )}

        {/* <IconButton>
          <FilterListIcon color="action" fontSize="small" />
        </IconButton> */}
        {name ? (
          <Typography variant="Poster" component="h5" mb={1}>
            {name}
          </Typography>
        ) : (
          <>
            {" "}
            {/* <IconButton>
              <ImportExportIcon color="action" fontSize="small" />
            </IconButton>
            <IconButton>
              <MoreHorizIcon color="action" fontSize="small" />
            </IconButton> */}
          </>
        )}
      </div>
      <div
        style={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          fontSize: "13px",
          marginRight: "60px",
          gap: 5,
          color: "rgba(0, 0, 0, 0.5)",
        }}
      >
        {filters.pagination && (
          <>
            {" "}
            <span>Rows per page:</span>
            <TextField
              id="demo-simple-select"
              value={filters.page}
              select={true}
              // sx={{
              //   ".css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
              //     {
              //       height: "20px",
              //       minHeight: "20px",
              //       fontSize: 12,
              //       paddingTop: "4px",
              //       paddingBottom: "4px",
              //     },
              // }}
              size="small"
              onChange={(e) => filters.changePageSize(e.target.value)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </TextField>
          </>
        )}

        {/* {filters.search && (
          <Search
            onChange={(v) => filters.search(v.target.value)}
            // sx={{ paddingTop: "6px" }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        )} */}
      </div>
    </div>
  );
}

export default FilterTable;
