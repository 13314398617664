import { useEffect, useState } from "react";
// import { useRef } from "react";
// import styled from "styled-components";
// import { useTheme } from "@mui/material/styles";
// import { SafetyCheck } from "@mui/icons-material";
// import Layout from "../layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  TextField,
  Box,
  CssBaseline,
  Button,
  Alert,
  Typography,
  Card,
  CardContent,
  CardActions,
  useTheme,
} from "@mui/material";

import { useAuth } from "../services/auth";
import { Container } from "@mui/system";

const loginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(
      "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])",
      "your password not strong!!"
    )
    .min(8, "Too Short, a password with at least 8 letters is required!"),
});

function Login() {
  const [error, setError] = useState(null);
  const theme = useTheme();

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (auth.user) navigate(from, { replace: true });
  }, [auth, from, navigate]);

  const onSubmit = async (values) => {
    try {
      await auth.signin({
        username: values["username"],
        password: values["password"],
      });
      navigate(from, { replace: true });
    } catch (error) {
      // console.log(error, "error");
      if (error.status === 401)
        setError("Wrong username/password combination!");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Card sx={{ mt: 10 }}>
        <CardContent>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            {error && <Alert severity="error">{error}</Alert>}

            <TextField
              margin="normal"
              label="Username"
              error={!!errors.username}
              helperText={errors.username && errors.username.message}
              inputProps={{
                ...register("username"),
              }}
              fullWidth
              autoComplete="email"
              autoFocus
            />

            <TextField
              margin="normal"
              label="password"
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              type="password"
              inputProps={{
                ...register("password"),
              }}
              fullWidth
              autoComplete="current-password"
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                mt: 3,
                mb: 2,
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              sign in
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Login;
