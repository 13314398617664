import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSubCompany } from "./Provider";
import { useNavigate } from "react-router-dom";
// components
import FormSubCompany from "./FormSubCompany";
import ModalBox from "../../ui/ModalBox";
// icons
// import Edit_Icon from "../../icons/Edit_Icon.svg";

const EditCompany = () => {
  const navigate = useNavigate();
  let params = useParams();
  const id = params.id;
  const {
    state: { selected: subCompany },
    fetchOne: fetchSubCompany,
  } = useSubCompany();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchSubCompany(id);
  }, [id, fetchSubCompany]);
  const handleSuccess = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  
  return (
    <div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Edit Sub Company
      </div>
      <div>
        <FormSubCompany subCompany={subCompany} handleSuccess={handleSuccess} />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.. You modified the subcompany profile "
        name={subCompany?.subCompanyName}
      />
    </div>
  );
};

export default React.memo(EditCompany);
