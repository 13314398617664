import { Route } from "react-router-dom";
import ReportPage from "./ReportPage/ReportPage";
import Reports from "./Reports";
import ReportsList from "./ReportsList";
import ListPayroll from "./ReportsList";
import ShiftReportDetail from "./MoreDetailPages/ShiftReportDetail";
import { RequireAuth } from "../../services/auth";

export default (
  <Route
    path="reports"
    exact
    element={
      <RequireAuth>
        <Reports />
      </RequireAuth>
    }
  >
    <Route index element={<ReportsList />} />
    <Route path=":name" element={<ReportPage />} />
    <Route path=":name/detail/:id" element={<ShiftReportDetail />} />
  </Route>
);
