import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RequireAuth } from "./services/auth";
import Providers from "./services/providers";
import Home from "./view/home/Home";
import Login from "./view/Login";
import CompaniesRoutes from "./view/Companies/routes";
import SubCompaniesRoutes from "./view/SubCompanies/routes";
import EmployeeRoutes from "./view/employee/routes";
import SitesRoutes from "./view/Sites/routes";
import ContractRoutes from "./view/Contracts/routes";
import GateRoutes from "./view/Gates/routes";
import DisCountRoutes from "./view/DisCounts/routes";
import ZoneRoutes from "./view/Zones/routes";
import ServiceRoutes from "./view/Services/routes";
import MemberPlanRoutes from "./view/MemberPlans/routes";
import UserAccountRoutes from "./view/UserAccount/routes";
import MemberShipRoutes from "./view/MemberShip/routes";
import PayrollRoutes from "./view/Payroll/routes";
import AttendanceRoutes from "./view/Attendance/routes";
import ReportsRoute from "./view/Reports/routes";
import TemplateMessRoutes from "./view/TemplateMessage/routes";
import VoidReasonRoutes from "./view/VoidReason/routes";
import SMSRoutes from "./view/SMS/routes";
import WebContentRoutes from "./view/WebContent/routes";
import SecretKeysRoutes from "./view/SecretKeys/routes";
import WhiteIpsRoutes from "./view/WhiteIps/routes";
import PayrollDurationRoute from "./view/Deductions/route";
import StoreRoutes from './view/Store/routes'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Providers>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />

          <Route path="login" element={<Login />} />
          {/* Company Detail */}
          {/* <Route path="CompanyDetails/:id" element={<Company />}>
            <Route index element={<InfoCompany />} />
          </Route> */}
          {/* SubCompany Detail */}
          {/* <Route path="SubCompanyDetails/:id" exact element={<SubCompany />}>
            <Route index element={<InfoSubCompany />} />
          </Route> */}
          {/* Site Detail */}
          {/* <Route path="SiteDetail/:id" element={<Sites />}>
            <Route index element={<DetailSite />} />
          </Route> */}
          {CompaniesRoutes}
          {SubCompaniesRoutes}
          {EmployeeRoutes}
          {SitesRoutes}
          {ContractRoutes}
          {GateRoutes}
          {DisCountRoutes}
          {ZoneRoutes}
          {ServiceRoutes}
          {MemberPlanRoutes}
          {UserAccountRoutes}
          {MemberShipRoutes}
          {PayrollRoutes}
          {AttendanceRoutes}
          {ReportsRoute}
          {TemplateMessRoutes}
          {VoidReasonRoutes}
          {SMSRoutes}
          {WebContentRoutes}
          {SecretKeysRoutes}
          {WhiteIpsRoutes}
          {PayrollDurationRoute}
          {StoreRoutes}
        </Route>
      </Routes>
    </BrowserRouter>
  </Providers>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
