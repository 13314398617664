import React from "react";
import { showLocalDate } from "../../../../utilities/functions";

const PunchInRender = ({ row }) => {
  return (
    <>
      {row.attendances.map((Punchin, index) => (
        <p style={{ borderBottom: "1px solid #F7F9FB" }}>
          {Punchin.punchIn ? showLocalDate(Punchin.punchIn) : ""}
        </p>
      ))}
    </>
  );
};

export default PunchInRender;
