import React from "react";

const Official = ({ row }) => {
  return (
    <>
      {row.costOfficial === "" ? (
        ""
      ) : (
        <div>
          <div>{row.costOfficial?.cashCostOfficial}</div>
          <div>{row.costOfficial?.ccCostOfficial}</div>
          <div>0</div>
          <div>{row.costOfficial?.compCostOfficial}</div>
          <div style={{ borderTop: "1px dashed black" }}>
            <p>{row?.costOfficial?.serviceCostOfficialSum}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Official;
