import React from "react";

const RoleStyled = ({ row }) => {
  return (
    <>
      {row.attendances.map((role, index) => (
        <p style={{borderBottom:"1px solid #F7F9FB"}}>{role.role}</p>
      ))}
    </>
  );
};

export default RoleStyled;
