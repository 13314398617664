import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { CalUtcWithFormat } from "../../utilities/functions";

const Context = createContext();

export function useAttendance() {
  return useContext(Context);
}

const initState = {
  Detailedlist: [],
  Summarylist: [],
  DetailExcelList: [],
  selected: null,
  selectedfile: null,
  companyIdAtt: null,
  displayExportExcel: false,
  siteIdAtt: 0,
  employeeIdAtt: 0,
  count: 1,
  countDetailExcel: 0,
  length: 0,
  page: 1,
  perPage: 10,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_Dtailedlist":
      return { ...state, Detailedlist: [...action.payload] };
    case "set_Summarylist":
      return { ...state, Summarylist: [...action.payload] };
    case "set_DetailExcelList":
      return { ...state, DetailExcelList: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_displayExportExcel":
      return { ...state, displayExportExcel: action.payload };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_countDetailExcel":
      return { ...state, countDetailExcel: action.payload };
    case "set_length":
      return { ...state, length: action.payload };
    case "set_CompanyIdAtt":
      return { ...state, companyIdAtt: action.payload };
    case "set_SiteIdAtt":
      return { ...state, siteIdAtt: action.payload };
    case "set_EmployeeIdAtt":
      return { ...state, employeeIdAtt: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.Detailedlist];
      return { ...state, Detailedlist: newList };
    case "edit":
      const modified = state.Detailedlist.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, Detailedlist: modified };
    case "delete":
      const filtered = state.Detailedlist.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, Detailedlist: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function AttendanceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchDetailedList = useCallback(
    async (data, page, perPage = state.perPage) => {
      let headersData = {
        pageNumber: state.page,
      };
      if (perPage) headersData.pageSize = perPage;
      if (data.from) headersData.from = CalUtcWithFormat(data.from);
      if (data.to) headersData.to = CalUtcWithFormat(data.to);
      if (!data.sid) data.sid = 0;
      if (!data.eid) data.eid = 0;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Attendance/DetailList/${data.cid}, ${data.sid},${data.eid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_Dtailedlist",
        payload: resData.data.attendances || [],
      });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data.totalPages });
      dispatch({ type: "set_length", payload: resData.data.totalSize });
    },
    [req, state.page, state.perPage]
  );
  const fetchSummaryList = useCallback(
    async (data) => {
      let headersData = {};
      if (data.from) headersData.from = CalUtcWithFormat(data.from);
      if (data.to) headersData.to = CalUtcWithFormat(data.to);
      if (!data.sid) data.sid = 0;
      if (!data.eid) data.eid = 0;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Attendance/SummaryList/${data.cid}, ${data.sid},${data.eid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_Summarylist", payload: resData.data || [] });
    },
    [req]
  );

  const edit = useCallback(
    async (data, id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Attendance/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "Attendance/Create",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const removeAttendance = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting ${id}` });
          const resData = await req(
            `Attendance/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  /**to excel file */

  const fetchDetailedListExcel = useCallback(
    async (data, page, perPage) => {
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      if (data.from) headersData.from = CalUtcWithFormat(data.from);
      if (data.to) headersData.to = CalUtcWithFormat(data.to);
      if (!data.sid) data.sid = 0;
      if (!data.eid) data.eid = 0;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Attendance/DetailList/${data.cid}, ${data.sid},${data.eid}`,
        null,
        {},
        true,
        headersData
      );
      if (state.countDetailExcel == 0) {
        // state.ExportExcelList.shift();

        dispatch({
          type: "set_countDetailExcel",
          payload: resData.data.totalPages,
        });
      }
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_page", payload: page });
      state.DetailExcelList.push(resData.data.attendances);
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchSummaryList,
        fetchDetailedList,
        fetchDetailedListExcel,
        fetchOne,
        create,
        removeAttendance,
        edit,
      }}
    >
      {children}
    </Context.Provider>
  );
}
