import React from "react";

const EmployeeHourlyRate = ({ row }) => {
  return (
    <div>
      {row?.roles?.map((role, index) => (
        <div style={{ marginBottom: "10px" }} key={index}>
          {role?.hourlyRate}
        </div>
      ))}
    </div>
  );
};

export default EmployeeHourlyRate;
