import React from "react";
import { TransformtoPercent } from "../../utilities/functions";

const StyledType = ({row, i}) => {
  return (
    <div
      style={{
        backgroundColor: "#EDEDFF",
        color: "#8A8CD9",
        borderRadius: "20px",
        opacity: "0.8",
        display:"inline-block",
        width:"80px",
        padding:"3px 5px",
        textAlign:"center"
      }}
    >
      {row.typeName ? row.typeName : `${TransformtoPercent(row.discount)}%`}
    </div>
  );
};

export default StyledType;
