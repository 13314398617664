import { Route } from "react-router-dom";
import AddVoidReason from "./AddVoidReason";
import ListVoidReason from "./ListVoidReason";
import UpdateVoidReason from "./UpdateVoidReason";
import VoidReason from "./VoidReason";

export default (
  <Route path="void-reason" exact element={<VoidReason />}>
    <Route index element={<ListVoidReason />} />
    <Route path="add" element={<AddVoidReason />} />
    <Route path="update" element={<UpdateVoidReason />} />
  </Route>
);
