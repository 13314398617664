import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";

// use form
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useSite } from "../Sites/Provider";
import { useNavigate, useParams } from "react-router-dom";
import { useTemplateMess } from "./Provider";
import { handleErrorResponse, insertField } from "../../utilities/functions";

// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
  grid-template-rows: 80px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;

const TemplateMessForm = ({ template, handleSuccess }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const [Type, setType] = useState(template ? template?.typeId : 1);
  const [error, setError] = useState({ title: "" });
  const { id } = useParams();
  const {
    state: { list: sites, page, perPage, selected: site },
    fetchOne: fetchSite,
  } = useSite();
  const {
    state: { types: SmsTypes },
    fetchTypes,
    create,
    edit,
  } = useTemplateMess();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  // useEffect
  useEffect(() => {
    if (!template) fetchSite(id);
    else fetchSite(template?.siteId);
  }, [id, fetchSite]);
  useEffect(() => {
    fetchTypes(id);
  }, [fetchTypes]);
  //onSubmit function

  const onSubmit = async (values) => {
    delete values.companyName;
    if (!template) setValue("siteId", id);
    else setValue("siteId", template?.siteId);
    try {
      if (template) {
        await edit(values, id);
      } else {
        await create(values);
      }
      handleSuccess();
    } catch (error) {
      setError({
        title: handleErrorResponse(error.status, "Template Message"),
      });
    }
  };
  const handleSiteClick = () => {
    var el = document.getElementById("text");
    var newText = "{#" + "Site Name" + "#}";
    insertField(el, newText);
  };
  const handleTicketNumClick = () => {
    var el = document.getElementById("text");
    var newText = "{#" + "Ticket Num" + "#}";
    insertField(el, newText);
  };
  const handleCarReqLinkClick = () => {
    var el = document.getElementById("text");
    var newText = "{#" + "Car Request Link" + "#}";
    insertField(el, newText);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          Template Message Details
        </Typography>
        {error.title && (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        )}
        <GridGroup>
          {fields.map((f, index) => {
            if (f.viewOnly) return null;
            const inputProps = { ...f.inputProps };

            if (index === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <Box key={index}>
                {f.switch && (
                  <Box>
                    <FormGroup>
                      <FormLabel
                        component="legend"
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                      >
                        {f.label}
                      </FormLabel>
                      <FormControlLabel
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                        control={
                          <Switch
                            color="success"
                            defaultChecked={template ? template.isActive : true}
                          />
                        }
                        {...register(f?.name)}
                        label="Active"
                      />
                    </FormGroup>
                  </Box>
                )}
                {f.disabled && (
                  <div>
                    <Typography variant="body2" component="h6" gutterBottom>
                      {f.label}
                    </Typography>

                    <Box
                      sx={{
                        borderBottom: "1px solid gray",
                        width: "290px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      {f.label === "Company Name"
                        ? site?.company?.companyName
                        : site?.siteName}
                    </Box>
                  </div>
                )}
                {!f.disabled && !f.switch && (
                  <>
                    {f.name === "message" && Type === 1 && (
                      <Box sx={{ m: "10px" }}>
                        <Button
                          sx={{
                            backgroundColor: theme.color.grayBackground,
                            color: theme.color.dark,
                            "&:hover": { background: `${theme.color.gray50}` },
                            mr: "30px",
                          }}
                          onClick={handleSiteClick}
                        >
                          Site Name
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: theme.color.grayBackground,
                            color: theme.color.dark,
                            mr: "30px",
                            "&:hover": { background: `${theme.color.gray50}` },
                          }}
                          onClick={handleTicketNumClick}
                        >
                          Ticket Num
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: theme.color.grayBackground,
                            color: theme.color.dark,
                            "&:hover": { background: `${theme.color.gray50}` },
                          }}
                          onClick={handleCarReqLinkClick}
                        >
                          Car Request Link
                        </Button>
                      </Box>
                    )}
                    <TextField
                      onChange={(e) =>
                        f.select ? setType(e.target.value) : ""
                      }
                      id={f.name === "message" && "text"}
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      select={f.select}
                      className={f.class}
                      multiline={f.multiline}
                      fullWidth
                      required
                      disabled={f.disabled}
                      sx={{
                        width:
                          f.name === "message"
                            ? "450px"
                            : theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      defaultValue={
                        template
                          ? template?.[f.name]
                          : f.name === "typeId"
                          ? 1
                          : null
                      }
                      inputProps={{
                        ...register(f?.name),
                        required: f.required,
                      }}
                      size="small"
                    >
                      {f.select &&
                        SmsTypes?.map((s, index) => (
                          <MenuItem key={index} value={s.id}>
                            {s[f.data]}
                          </MenuItem>
                        ))}
                    </TextField>
                  </>
                )}
              </Box>
            );
          })}
        </GridGroup>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default TemplateMessForm;
