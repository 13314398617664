import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useCompany } from "../Companies/Provider";
import { useSite } from "../Sites/Provider";
import { useEmployees } from "../employee/Provider";
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { useAttendance } from "./Provider";
import StyledEmployee from "./StyledRender/StyledEmployee";
import StyledSite from "./StyledRender/StyledSite";
import StyledDate from "./StyledRender/StyledDate";
import { FilterList } from "@mui/icons-material";
import {
  convertToHours,
  Diff,
  handleErrorResponse,
  toLocal,
  tolocalDate,
} from "../../utilities/functions";
import StyledDuration from "./StyledRender/StyledDuration";
import StyledDateOut from "./StyledRender/StyledDateOut";
import ModalBox from "../../ui/ModalBox";
import ActiveCell from "../Companies/CompanyTable/ActiveCell";
import { CalTime } from "../../utilities/functions";
import ErrorsList from "../../ui/ErrorsList";
import { useAuth } from "../../services/auth";
import SummaryStyledDuration from "./StyledRender/SummaryStyledDuration";
import { DateTimeField, DateTimePicker } from "@mui/x-date-pickers";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Total from "./StyledRender/Total";
import Rate from "./StyledRender/Rate";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

const locales = ["en-gb"];

// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

const GridGroup1 = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  margin-bottom: 10px;
  grid-template-columns: 325px 325px 325px;
  gap: 10px;
  grid-template-rows: 75px;
  grid-column-gap: 10px;
  background: #f7f9fb;
`;

const ListAttendance = () => {
  /**variable excel file */
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let navigate = useNavigate();
  let theme = useTheme();
  const { role, adminOn } = useAuth();
  const [Error, setError] = useState(null);
  const [Errorlist, setErrorList] = useState(null);
  const [locale, setLocale] = useState("en-gb");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const [vState, setVState] = useState();
  const [open, setOpen] = useState(false);
  const {
    state: { Names: sites, selected: SiteInfo },
    fetchSiteNames: fetchSites,
  } = useSite();
  const {
    state: { Names: companies },
    fetchNames: fetchCompanies,
  } = useCompany();
  const {
    state: { Names: employees, page: employeePage, perPage: employeeperPage },
    fetchNames: fetchEmployees,
  } = useEmployees();
  const {
    state: {
      Detailedlist,
      Summarylist,
      page,
      perPage,
      count,
      length,
      companyIdAtt,
      siteIdAtt,
      employeeIdAtt,
      DetailExcelList,
      countDetailExcel,
      displayExportExcel,
    },
    fetchDetailedList: fetchDetailed,
    fetchSummaryList,
    fetchDetailedListExcel,
    removeAttendance,

    dispatch,
  } = useAttendance();
  const tabs = [
    { title: "Detailed", num: length },
    { title: "Summary", num: Summarylist?.length },
  ];
  useEffect(() => {
    if (role === "Admin") {
      if (companies?.length === 0) fetchCompanies();
    }
  }, [fetchCompanies]);

  useEffect(() => {
    if (role === "Admin") {
      if (companyIdAtt) fetchSites(companyIdAtt);
    } else if (role === "Company") {
      fetchSites(adminOn?.id);
    }
  }, [fetchSites, companyIdAtt]);

  useEffect(() => {
    if (role === "Admin") {
      if (companyIdAtt) fetchEmployees(companyIdAtt, 0);
    } else if (role === "Company") {
      fetchEmployees(adminOn?.id, 0);
    } else if (role === "Site") {
      fetchEmployees(0, 0);
    }
  }, [fetchEmployees, companyIdAtt, SiteInfo]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  const handleDelete = async (ID) => {
    try {
      await removeAttendance(ID);
    } catch (error) {
      setErrorList({ title: handleErrorResponse(error.status, "Attendance") });
    }
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };

  const [selectedCols, setSelectedCols] = useState("Detailed");

  const getCols = (name) => {
    switch (name) {
      case "Detailed":
        return [
          {
            label: "Employee Name",
            name: "employeeName",
            headTextLeft: true,
            textLeft: true,
            customRender: StyledEmployee,
          },
          {
            label: "Site Name",
            name: "siteName",
            customRender: StyledSite,
          },
          {
            label: "Role",
            name: "roleId",
          },
          {
            label: "From(Punch in)",

            editable: true,
            type: "datetime-local",
            customRender: StyledDate,
          },
          {
            label: "To(Punch out)",

            editable: true,
            type: "datetime-local",
            customRender: StyledDateOut,
          },
          {
            label: "Duration (Hours)",
            name: "duration",
            customRender: StyledDuration,
          },
          {
            label: "Rate (Hours)",
            name: "hourlyRate",
            editable: true,
            type: "number",
            number: true,
          },
          {
            label: "Attendance Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      case "Summary":
        return [
          {
            label: "Employee Name",
            name: "employeeName",
            headTextLeft: true,
            textLeft: true,
            customRender: StyledEmployee,
          },
          {
            label: "Site Name",
            name: "siteName",
            customRender: StyledSite,
          },
          {
            label: "Role",
            name: "role",
          },
          {
            label: "From Date",

            customRender: StyledDate,
          },
          {
            label: "To Date",

            customRender: StyledDateOut,
          },
          {
            label: "Duration (Hours)",
            name: "duration",
            customRender: SummaryStyledDuration,
          },
          {
            label: "Rate (Hours)",
            name: "hourlyRate",
            number: true,
            customRender: Rate,
          },
          {
            label: "Total",
            customRender: Total,
          },
        ];
        break;
    }
  };
  // const handleUpdate = async (e, ID) => {
  //   delete e[0].duration;
  //   delete e[0].employee;
  //   delete e[0].employeeId;
  //   delete e[0].id;
  //   delete e[0].inDuty;
  //   delete e[0].role;
  //   //  delete e[0].roleId;
  //   delete e[0].site;
  //   delete e[0].siteId;
  //   e[0].punchIn = CalTime(e[0].punchIn);
  //   e[0].punchOut = CalTime(e[0].punchOut);
  //   if (Diff(e[0].punchIn, e[0].punchOut) <= 15) {
  //     try {
  //       await edit(e[0], ID);
  //       handleSuccess();
  //       fetchDetailed(getValues(), page, perPage);
  //       setError(null);
  //     } catch (error) {
  //       console.log(error, "error");
  //       setErrorList({
  //         title: handleErrorResponse(error.status, "Attendance"),
  //       });
  //       fetchDetailed(getValues(), page, perPage);
  //     }
  //   } else {
  //     setErrorList({
  //       title:
  //         "Hours difference between punch out and punch In must be less than 15 hours and greater than 1 minute",
  //     });
  //     fetchDetailed(getValues(), page, perPage);
  //   }
  // };

  const getData = (name) => {
    switch (name) {
      case "cid":
        return companies;
      case "eid":
        return employees.sort((a, b) => a.name.localeCompare(b.name));
      case "sid":
        return sites;
    }
  };
  const getDataSelected = (name) => {
    switch (name) {
      case "Detailed":
        return Detailedlist;
      case "Summary":
        return Summarylist;
    }
  };
  useEffect(() => {
    if (role === "Site") {
      setValue("cid", SiteInfo?.company?.id);
      setValue("sid", adminOn?.id);
    } else if (role === "Company") {
      setValue("cid", adminOn?.id);
    }
  }, [adminOn]);
  //onSubmit function
  const onSubmit = (values) => {
    dispatch({ type: "set_displayExportExcel", payload: true });
    !values.from ? (values.from = "") : (values.from = CalTime(values.from));
    !values.to ? (values.to = "") : (values.to = CalTime(values.to));
    try {
      fetchDetailed(values, page, perPage);
      if (DetailExcelList.length != 0) {
        DetailExcelList.splice(0, DetailExcelList.length);
      }
      fetchDetailedListExcel(values, 1, 100);
      fetchSummaryList(values);
    } catch (error) {
      handleErrorResponse(error.status);
    }
  };
  useEffect(() => {
    if (getValues("cid") > 0) fetchDetailed(getValues(), page, perPage);
  }, [page, perPage]);
  /** Detailed export excel */
  /**
   *
   *
   * problems
   */

  const handleExportDetailedExcel = async () => {
    for (var i = 2; i <= countDetailExcel; i++) {
      await fetchDetailedListExcel(getValues(), i, 100);
    }
    var newArr = new Array();
    for (var i = 0; i < DetailExcelList.length; i++) {
      newArr = newArr.concat(DetailExcelList[i]);
    }
    var aa = new Array();
    for (var i = 0; i < newArr?.length; i++) {
      aa.push({
        site: newArr[i].site.siteName,
        employee: newArr[i].employee.employeeName,
        role: newArr[i].role,
        punchIn: newArr[i].punchIn ? tolocalDate(newArr[i].punchIn) : "",
        punchOut: newArr[i].punchOut ? tolocalDate(newArr[i].punchOut) : "",
        duration: newArr[i].duration,
        Rate: newArr[i].hourlyRate,
      });
    }

    var fileName = "Detailed Attendance Report File";
    const ws = XLSX.utils.json_to_sheet(aa, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 50 },
      { width: 50 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Detailed Attendance");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  /**Summary export excel */
  const handleExportSummaryExcel = () => {
    var aa = new Array();
    for (var i = 0; i < Summarylist?.length; i++) {
      aa.push({
        site: Summarylist[i].site.siteName,
        employee: Summarylist[i].employee.employeeName,
        role: Summarylist[i].role,
        punchIn: Summarylist[i].punchIn
          ? tolocalDate(Summarylist[i].punchIn)
          : "",
        punchOut: Summarylist[i].punchOut
          ? tolocalDate(Summarylist[i].punchOut)
          : "",
        duration: convertToHours(Summarylist[i].duration),
        Rate: Summarylist[i].hourlyRate,
        Total: Summarylist[i].duration * Summarylist[i].hourlyRate,
      });
    }
    var fileName = "Summary Attendance Report File";
    const ws = XLSX.utils.json_to_sheet(aa, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 50 },
      { width: 50 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Summary Attendance");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  /**function to export excel */
  const handleClick = () => {
    switch (selectedCols) {
      case "Detailed":
        return handleExportDetailedExcel();
        break;
      case "Summary":
        return handleExportSummaryExcel();
        break;
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {role === "Admin" && (
        <>
          <Typography variant="Poster" component="h4" mb={2}>
            Select Company (At least)
          </Typography>
          {Errorlist ? (
            <Alert severity="error" sx={{ m: 3 }}>
              <ErrorsList error={Errorlist} />
            </Alert>
          ) : (
            ""
          )}
        </>
      )}
      <GridGroup1>
        {role !== "Admin" && (
          <Box>
            <span style={{ fontSize: "12px" }}>Company Name</span>
            <Box
              sx={{
                borderBottom: "1px solid gray",
                width: "290px",
                borderRadius: "8px",
                textAlign: "center",
                marginRight: "20px",
                paddingTop: "25px",
              }}
            >
              {role === "Company" && adminOn?.companyName}
              {role === "Site" && SiteInfo?.company?.companyName}
            </Box>
          </Box>
        )}
        {role === "Site" && (
          <Box>
            <span style={{ fontSize: "12px" }}>Site Name</span>
            <Box
              sx={{
                borderBottom: "1px solid gray",
                width: "290px",
                borderRadius: "8px",
                textAlign: "center",
                marginRight: "20px",
                paddingTop: "25px",
              }}
            >
              {adminOn?.siteName}
            </Box>
          </Box>
        )}
        {fields.map((f) => (
          <>
            {f.select && (
              <FormControl
                sx={{
                  width: theme.dimensions.width,
                  mt: theme.dimensions.mt,
                  mb: theme.dimensions.mb,
                  display:
                    (role !== "Admin" && f.name === "cid") ||
                    (role === "Site" && f.name == "sid")
                      ? "none"
                      : null,
                }}
                error={f.name === "cid" && Error}
              >
                <span style={{ fontSize: "12px" }}>{f.label}</span>
                <Select
                  sx={{ mt: theme.dimensions.mt }}
                  onChange={(e) => {
                    f.name === "cid" &&
                      dispatch({
                        type: "set_CompanyIdAtt",
                        payload: e.target.value,
                      });
                    f.name === "sid" &&
                      dispatch({
                        type: "set_SiteIdAtt",
                        payload: e.target.value,
                      });
                    f.name === "eid" &&
                      dispatch({
                        type: "set_EmployeeIdAtt",
                        payload: e.target.value,
                      });
                    setError(null);
                  }}
                  defaultValue={
                    (f.name === "cid" && companyIdAtt) ||
                    (f.name === "sid" && siteIdAtt ? siteIdAtt : 0) ||
                    (f.name === "eid" && employeeIdAtt ? employeeIdAtt : 0)
                  }
                  inputProps={{
                    ...register(f?.name),
                    required: true,
                  }}
                  size="small"
                >
                  {f.name !== "cid" && (
                    <MenuItem value={0}>
                      <em>All</em>
                    </MenuItem>
                  )}
                  {getData(f.name)?.map((s, index) => (
                    <MenuItem key={index} value={s.id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ mb: 2 }}>
                  {Error && f.name === "cid" && Error.title}
                </FormHelperText>
              </FormControl>
            )}

            {f.date && (
              <LocalizationProvider
                key={f.name}
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
              >
                <FormControl
                  components={["DateRangePicker"]}
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb,
                  }}
                >
                  <span style={{ fontSize: "12px" }}>{f.label}</span>
                  <DateTimePicker
                    sx={{ mt: 1 }}
                    //  format="DD-MM-YYYY"
                    error={!!errors[f.name]}
                    onChange={(date) => setValue(f.name, date)}
                    slotProps={{
                      textField: {
                        error: errors[f.name]?.message,
                        helperText: (
                          <p style={{ color: "#d32f2f" }}>
                            {errors[f.name]?.message}
                          </p>
                        ),
                        size: "small",
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          </>
        ))}
        <Box mt={4}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              color: "white",
              ml: 5,
              "&:hover": { background: `${theme.color.blackBackground}` },
              textTransform: "capitalize",
            }}
          >
            <FilterList />
            <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
          </Button>
        </Box>
      </GridGroup1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
        {displayExportExcel ? (
          <Button
            sx={{
              border: `1px solid ${theme.color.Screamingreen}`,
              color: theme.color.Screamingreen,
              mr: "350px",
              textTransform: "capitalize",
              "&:hover": { background: `${theme.color.gray50}` },
            }}
            onClick={handleClick}
          >
            {" "}
            Export to Excel
          </Button>
        ) : (
          ""
        )}
      </div>

      <CustomTable
        empty={getDataSelected(selectedCols)?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getDataSelected(selectedCols)}
        actions={
          selectedCols === "Detailed" && {
            selectedCols: true,
            edit: (id) => {
              if (role === "Admin") {
                companyIdAtt === 0
                  ? setError({ title: "You must select company at first" })
                  : navigate("update/" + companyIdAtt + "/" + id);
              } else if (role === "Company") {
                navigate("update/" + adminOn?.id + "/" + id);
              } else if (role === "Site") {
                navigate("update/" + SiteInfo?.company?.id + "/" + id);
              }
            },
            selectedCols: true,
            //inTableEdit: true,
            delete: handleDelete,
            check: (v) => v.length > 0,
            vState: vState,
            setVState: setVState,
          }
        }
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          add:
            selectedCols === "Detailed"
              ? () => {
                  if (role === "Admin") {
                    !companyIdAtt
                      ? setError({ title: "You must select company at first" })
                      : navigate("add/" + companyIdAtt);
                  } else if (role === "Company") {
                    navigate("add/" + adminOn?.id);
                  } else if (role === "Site") {
                    navigate("add/" + SiteInfo?.company?.id);
                  }
                }
              : null,
        }}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
        page={page}
        isPagination={selectedCols === "Detailed" ? true : false}
      ></CustomTable>
      <ModalBox
        open={open}
        operation="edit"
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
        name={`${selectedCols}`}
      />
    </form>
  );
};

export default React.memo(ListAttendance);
