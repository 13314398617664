import React from 'react'

const PlanName = ({row}) => {
  return (
    <p>
      {row.plan.title}
    </p>
  )
}

export default PlanName;
