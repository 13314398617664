import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEmployees } from "../Provider";
import RatesField from "./RatesField";
import { useZone } from "../../Zones/Provider";
// yup schema
const formSchema = Yup.object().shape({
  siteId: Yup.number(),
  roleId: Yup.string(),
  hourlyRate: Yup.number(),
  zoneIds: Yup.array(),
});
const EmployeeZonesForm = ({ roleId, row, open, handleEditRateClose }) => {
  let theme = useTheme();
  const [Error, setError] = useState("");

  const {
    state: { selected: employee },
    editRoleRate,
  } = useEmployees();
  const [role] = useState(row?.roles.find((role) => role.roleId === roleId));
  const {
    state: { list: Zones },
    fetchList: fetchZones,
  } = useZone();

  const [zonesList, setZonesList] = useState(
    role?.zones?.map((Z) => Z.id) || []
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    fetchZones(row?.siteId);
  }, [fetchZones, row]);

  useEffect(() => {
    setValue("siteId", row?.siteId);
    setValue("roleId", role?.roleId);
  }, [setValue, row]);

  const onSubmit = async (values) => {
    setError(null);
    try {
      await editRoleRate(employee?.id, values);
      handleEditRateClose();
    } catch (error) {
      console.log(error, "error");
      setError(error.errors.message);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setZonesList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {Error ? <Typography sx={{ color: "red" }}>{Error}</Typography> : ""}
      <Typography
        id="modal-modal-description"
        sx={{ textAlign: "center", fontWeight: 600, mb: 2 }}
      >
        Update<em> Hourly Rate </em> or <em>Zones</em> to specific site role
      </Typography>
      {RatesField.map((f) => (
        <>
          {f.name === "siteId" && row && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" component="h6" gutterBottom>
                {f.label}
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid gray",
                  width: "300px",
                  borderRadius: "8px",
                  marginLeft: "30px",
                  marginBottom: "25px",
                }}
              >
                {row.siteName}
              </Box>
            </Box>
          )}
          {f.name === "roleId" && row && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" component="h6" gutterBottom>
                {f.label}
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid gray",
                  width: "320px",
                  borderRadius: "8px",
                  marginLeft: "25px",
                  marginBottom: "25px",
                }}
              >
                {role?.roleTitle}
              </Box>
            </Box>
          )}
          {f.name === "hourlyRate" && (
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <TextField
                label={f.label}
                size="small"
                type="number"
                sx={{ width: 300 }}
                defaultValue={role?.hourlyRate}
                inputProps={{
                  ...register(f.name),
                }}
              />
            </Box>
          )}
          {f.name === "zoneIds" && (
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <FormControl
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{
                  width: theme.dimensions.width,
                  mt: theme.dimensions.mt,
                  mb: theme.dimensions.mb * 3,
                }}
                size="small"
              >
                <InputLabel
                  id="demo-simple-select-label"
                  htmlFor="demo-simple-select"
                >
                  {f.label}
                </InputLabel>
                <Select
                  size="small"
                  multiple
                  label={f.label}
                  value={zonesList}
                  onChange={handleChange}
                  input={<OutlinedInput label="Zone" />}
                  MenuProps={MenuProps}
                  error={!!errors["zoneIds"]?.message}
                  inputProps={{
                    ...register(f?.name),
                  }}
                >
                  {Zones?.map((zone, i) => (
                    <MenuItem key={i} value={zone.id}>
                      {zone.title}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  htmlFor="demo-simple-select"
                  sx={{ color: "#d32f2f" }}
                >
                  {errors["zoneIds"]?.message}
                </FormHelperText>
              </FormControl>
            </Box>
          )}
        </>
      ))}
      <Button
        type="submit"
        sx={{
          background: theme.color.blackBackground,
          color: "white",
          width: "100%",
          mb: 1.3,
          "&:hover": { background: `${theme.color.black}` },
        }}
      >
        save{" "}
      </Button>
    </form>
  );
};

export default EmployeeZonesForm;
