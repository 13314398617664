import { Typography } from "@mui/material";
import React, { useState } from "react";
import FormSMS from "./FormSMS";
import ModalBox from "../../ui/ModalBox";
import { useSms } from "./Provider";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UpdateSms = () => {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  let navigate = useNavigate();
  const handleClose = () => {
    setOpen(!open);
    navigate(-1);
  };
  const {
    state: { selected },
    fetchOne,
  } = useSms();
  const handleSuccess = () => {
    setOpen(true);
    setReload(!reload);
  };
  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id, reload]);
  if (!selected) return;
  if (selected?.siteId != id) return;
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Update SMS Provider
      </Typography>
      <FormSMS handleSuccess={handleSuccess} SmsINfo={selected} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The Update operation has been completed successfully.. You update this SMS Provider"
        name="SMS Provider"
      />
    </>
  );
};

export default UpdateSms;
