import React from "react";

const SitesSales = ({ row }) => {
  return (
    <div>
      <div style={{ paddingBottom: "10px" }}>{row?.site?.siteName}</div>
    </div>
  );
};

export default SitesSales;
