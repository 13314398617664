// React
import { memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useGAEventsTracker from "../../utilities/useGAEventsTracker";

// Components
import Breadcrumb from "./Breadcrumb";
import SearchHeader from "./SearchHeader";

// Layout
import { drawerWidth } from "../Layout";

// Material ui
import { AppBar as MuiAppBar, IconButton, Toolbar, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// MUI Icons
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
import ViewListIcon from "@mui/icons-material/ViewList";
import LightModeTwoToneIcon from "@mui/icons-material/LightModeTwoTone";
import RestoreTwoToneIcon from "@mui/icons-material/RestoreTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { useAuth } from "../../services/auth";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


function Header({ handleDrawerOpen, open, handleRightDrawerOpen }) {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const GAEventsTracker = useGAEventsTracker("External Links");

  // console.log(auth.user.tokens.data.user.username);

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
          color: "black",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
            onClick={() => navigate("/")}
          >
            logo
          </IconButton>
          <Grid container>
            <Grid
              item
              xs={location?.pathname === "/" ? 6 : 8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                style={{ color: "#2A3F54" }}
              >
                <ViewListIcon color="action" />
              </IconButton>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                style={{ color: "#2A3F54" }}
              >
                <StarTwoToneIcon color="action" />
              </IconButton>
              <Breadcrumb />
            </Grid>
            {/* Search and Notification Icons and Theme */}
            <Grid
              item
              xs={location?.pathname === "/" ? 4 : 2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {location?.pathname === "/" && <SearchHeader />}
              {/* Activies Icon */}
              {/* <IconButton
                aria-label="open right drawer"
                onClick={() => handleRightDrawerOpen(false)}
              >
                <RestoreTwoToneIcon color="action" />
              </IconButton> */}
              {/* Notifications Icon */}
              {/* <IconButton
                aria-label="open right drawer"
                onClick={() => handleRightDrawerOpen(false)}
              >
                <NotificationsNoneTwoToneIcon color="action" />
              </IconButton> */}
              <IconButton
                aria-label="open right drawer"
                onClick={(e) => {
                  handleRightDrawerOpen(true);
                  GAEventsTracker("profile icon");
                }}
              >
                <Typography sx={{ pr: 1 }} variant="Poster" component="h6">
                  {auth?.user?.tokens?.data?.user?.username}
                </Typography>
                <AccountCircleTwoToneIcon color="action" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default memo(Header);
