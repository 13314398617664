import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const InfoTableHeaderButton = {
  color: "#A6A6A6",
  textTransform: "capitalize",
  display: "inline",
  padding: "0px",
  margin: "0 10px",
  "&:hover,&:focus": {
    textDecoration: "underline",
    color: "black",
  },
};

function TabsList({ tabs, setSelectedCols, dispatch = false, selectedCols }) {
  const [clicked, setClicked] = useState(selectedCols);
  useEffect(() => {
    setSelectedCols(clicked);
    if (dispatch)
      dispatch({
        type: "set_selected_tab",
        payload: clicked,
      });
  }, [clicked]);
  return (
    <div>
      {tabs?.map((tab, index) => {
        return (
          <Button
            key={index}
            sx={
              clicked === tab.title
                ? {
                    ...InfoTableHeaderButton,
                    textDecoration: "underline",
                    color: "black",
                  }
                : { ...InfoTableHeaderButton }
            }
            onClick={() => {
              setClicked(tab?.title);
            }}
            id="tabs"
            // autoFocus={index === 0}
          >
            {tab?.title} {tab?.num && `(${tab?.num || 0})`}
          </Button>
        );
      })}
    </div>
  );
}

export default TabsList;
