const GateType = [
  { id: 1, name: "In" },
  { id: 2, name: "Out" },
  { id: 3, name: "In/Out" },
];

const ServiceType = [
  { id: 1, name: "Valet" },
  { id: 2, name: "Hourly Valet" }, //hourly
  { id: 3, name: "Self Valet" },
  { id: 4, name: "Self Hourly Valet" }, //hourly
  { id: 5, name: "Re-Valet" },
  { id: 6, name: "Loss-Ticket" },
];
const HourlyServiceType = [
  { id: 2, name: "Hourly Valet" },
  { id: 4, name: "Self Hourly Valet" },
];
const FixedServiceType = [
  { id: 1, name: "Valet" },
  { id: 3, name: "Self Valet" },
  { id: 5, name: "Re-Valet" },
  { id: 6, name: "Loss-Ticket" },
  { id: 7, name: "ValidationTicket" },
];
const PaymentType = [
  { id: 0, name: "None" },
  { id: 1, name: "Void" },
  { id: 2, name: "Comp" },
  { id: 3, name: "Cash" },
  { id: 4, name: "CC" },
];

const PaidStatusType = [
  { id: 1, name: "Unpaid" },
  { id: 2, name: "Partly Paid" },
  { id: 3, name: "Paid" },
  { id: 4, name: "PaidVoid" },
  { id: 5, name: "PaidComp" },
];
const PaidVoidNoteType = [
  { id: 1, name: "Paid Void 1" },
  { id: 2, name: "Paid Void 2" },
  { id: 3, name: "Paid Void 3" },
];
const SmsType = [
  { id: 1, name: "Welcome" },
  { id: 2, name: "Car Ready" },
  { id: 3, name: "Re-Park" },
  { id: 4, name: "Custom message" },
];

const SmsStatusType = [
  { id: 1, name: "Success" },
  { id: 2, name: "Failure" },
];

const CarCategoryType = [
  { id: 1, name: "Indoor" },
  { id: 2, name: "Outdoor" },
];
const CarColorType = [
  { id: 1, name: "Black", HexValue: "#000" },
  { id: 2, name: "white", HexValue: "#FFFFFF" },
  { id: 3, name: "Silver", HexValue: "#C0C0C0" },
  { id: 4, name: "Gray", HexValue: "#808080" },
  { id: 5, name: "Red", HexValue: "#FF0000" },
  { id: 6, name: "Brown", HexValue: "#A52A2A" },
  { id: 7, name: "Orange", Hex: "#FFA500" },
  { id: 8, name: "Yellow", Hex: "#FFFF00" },
  { id: 9, name: "Violet", Hex: "#EE82EE" },
];
const CarMakerType = [
  { id: 1, name: "Alfa Romeo" },
  { id: 2, name: "Aston Martin" },
  { id: 3, name: "Audi" },
  { id: 4, name: "Bentley" },
];
const RolesEnum = [
  { roleId: "Head", name: "Head" },
  { roleId: "CoHead", name: "Co-Head" },
  { roleId: "Cashier", name: "Cashier" },
  { roleId: "KeyRack", name: "Key-Rack" },
  { roleId: "Runner", name: "Runner" },
  { roleId: "Moto", name: "Moto" },
  { roleId: "Supervisor", name: "Supervisor" },
  { roleId: "Concierge", name: "Concierge" },
];
const Units = [
  { id: 1, desription: "Piece" },
  { id: 2, desription: "M" },
  { id: 3, desription: "Kg" },
];
const ShowPrice = [
  { id: 0, desription: "No Price" },
  { id: 1, desription: "Official Price" },
  { id: 2, desription: "Trial Price" },
];
export {
  GateType,
  ServiceType,
  PaymentType,
  PaidStatusType,
  PaidVoidNoteType,
  SmsType,
  SmsStatusType,
  CarCategoryType,
  HourlyServiceType,
  FixedServiceType,
  CarColorType,
  CarMakerType,
  RolesEnum,
  Units,
  ShowPrice,
};
