import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Typography, Breadcrumbs, Link, Box } from "@mui/material";
import { useAuth } from "../../services/auth";
import { useSubCompany } from "../../view/SubCompanies/Provider";
import { useSite } from "../../view/Sites/Provider";

export default function Breadcrumb() {
  const navigate = useNavigate();
  let { role, adminOn } = useAuth();
  const location = useLocation();
  const {
    state: { selected: subCompany },
    fetchOne: fetchSubCompany,
  } = useSubCompany();
  const {
    state: { selected: SiteInfo },

    fetchOne: fetchSiteInfo,
  } = useSite();
  let splitedLocationWithParams = location?.pathname.split("/");
  // remove first element => ''
  splitedLocationWithParams = splitedLocationWithParams.slice(1);

  let splitedLocation = location?.pathname.split("/").filter((item) => {
    if (Number(item) || item === "0") return 0;
    return item;
  });

  function handleClick(event) {
    event.preventDefault();
  }

  const breadcrumbLinkStyle = {
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "14px",
  };
  useEffect(() => {
    if (role === "SubCompany") {
      fetchSubCompany(adminOn?.id);
    } else if (role === "Site") {
      fetchSiteInfo(adminOn?.id);
    }
  }, [adminOn]);
  return (
    <Box role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          sx={breadcrumbLinkStyle}
          onClick={() => navigate("/")}
        >
          {role === "Company" && <>{adminOn?.companyName} </>}
          {role === "SubCompany" && (
            <>
              {subCompany?.company?.companyName} {" / "}{" "}
              {adminOn?.subCompanyName}{" "}
            </>
          )}
          {role === "Site" && (
            <>
              {SiteInfo?.company?.companyName}
              {" /  "} {adminOn?.siteName}{" "}
            </>
          )}
          Dashboard
        </Link>
        {location?.pathname === "/" && (
          <Typography color="text.primary" sx={{ fontSize: "14px" }}>
            Home
          </Typography>
        )}
        {location?.pathname !== "/" &&
          splitedLocationWithParams.map((item, index) => {
            if (Number(item)) return null;
            if (role === "Company" && item === "companies") return 0;
            if (role === "Site" && item === "sites") return 0;
            if (role === "SubCompany" && item === "SubCompanies") return 0;

            // the last element is the current page (primary text)
            // if (index === splitedLocation.length - 1)
            if (item === splitedLocation[splitedLocation.length - 1])
              return (
                <Typography
                  key={index}
                  color="text.primary"
                  sx={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  {item.split(/(?=[A-Z])/).map((it, index) => (
                    <span key={index}>{it + " "}</span>
                  ))}
                </Typography>
              );
            else {
              return (
                <Link
                  key={index}
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    let to = "";
                    let params = "";
                    for (let i = 0; i < splitedLocationWithParams.length; i++) {
                      to = to + "/" + splitedLocationWithParams[i];

                      if (i === index) {
                        if (
                          item.includes("Detail") ||
                          Number(splitedLocationWithParams[i + 1])
                        )
                          params = splitedLocationWithParams[i + 1];
                        break;
                      }
                    }
                    if (item.includes("Detail") || params) {
                      navigate(`${to}/${params}`);
                    } else navigate(to);
                  }}
                  sx={{
                    textTransform: "capitalize",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  {/* Adding spaces */}
                  {item.split(/(?=[A-Z])/).map((it, index) => (
                    <span key={index}>{it + " "}</span>
                  ))}
                </Link>
              );
            }
          })}
      </Breadcrumbs>
    </Box>
  );
}
