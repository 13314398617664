import React from "react";

const ServicePayments = ({ row }) => {
  console.log(row, "row");
  return (
    <div>
      {row?.payments?.map((item, index) => (
        <div>{item?.paymentType}</div>
      ))}
    </div>
  );
};

export default ServicePayments;
