const fieldsAdd = [
  {
    name: "siteId",
    label: "Site",
    disable: true,
  },
  {
    name: "mobile",
    label: "Mobile",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "memberName",
    label: "Full Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "siteMemberPlanId",
    label: "Member Plan",
    select: true,
    validationType: "number",
    data: "title",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "paymentTypeId",
    label: "Payment Type",
    select: true,
    validationType: "number",
    data: "name",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "siteSiftId",
    label: "Site Shift",
    hidden: true,
  },
  {
    name: "amount",
    label: "Amount",
    validationType: "number",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "isActive",
    label: "Member Ship Status",
    switch: true,
  },
];

export default fieldsAdd;
