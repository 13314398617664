import React from "react";

const SummaryStyledDuration = ({ row }) => {
  return (
    <>
      {row.duration === 0 ? (
        <div style={{ background: "#F7E950" }}>00:00</div>
      ) : (
        <>{`${
          Math.floor(row.duration / 60) < 10
            ? `0` + Math.floor(row.duration / 60)
            : Math.floor(row.duration / 60)
        }:${
          Math.floor(row.duration % 60) < 10
            ? `0` + Math.floor(row.duration % 60)
            : Math.floor(row.duration % 60)
        }`}</>
      )}
    </>
  );
};

export default SummaryStyledDuration;
