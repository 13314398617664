import React, { useEffect, useState } from "react";
import { useTemplateMess } from "./Provider";
import { useNavigate, useParams } from "react-router-dom";
import TemplateMessForm from "./TemplateMessForm";
import ModalBox from "../../ui/ModalBox";
import { Typography } from "@mui/material";

const TemplateMessageUpdate = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [reload, setReload] = useState(false);

  const {
    state: { selected: template },
    fetchOne: fetchTemplate,
  } = useTemplateMess();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  useEffect(() => {
    fetchTemplate(id);
  }, [fetchTemplate, reload, id]);
  const handleSuccess = () => {
    setOpen(true);
    setReload(!reload);
  };
  if (!template) return;
  if (template?.id != id) return;
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Update Template Message
      </Typography>
      <TemplateMessForm template={template} handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.. You updated template"
      />
    </>
  );
};

export default TemplateMessageUpdate;
