import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSubCompany } from "./Provider";
import { useCompany } from "../Companies/Provider";
// material ui
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Alert,
  Box,
} from "@mui/material";
// components
import CustomTable from "../../ui/CustomTable";
import SiteNumber from "./SubCompanyTable/SiteNumber";
import ActiveCell from "../Companies/CompanyTable/ActiveCell";
import TabsList from "../../ui/TabsList";
// Style
import "../style.css";
import styled from "styled-components";
import { useAuth } from "../../services/auth";

const GridGroup1 = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  grid-template-columns: 325px 225px 325px;
  gap: 10px;
  grid-template-rows: 75px;
  grid-column-gap: 10px;
  background: #f7f9fb;
`;
const SubCompanyList = () => {
  let navigate = useNavigate();
  const [selectedCols, setSelectedCols] = useState("SubCompanies");
  const [error, setError] = useState(null);
  const { role, adminOn } = useAuth();

  // const [companiesList, setCompaniesList] = useState([]);
  // const [pageN, setPageN] = useState(1);
  // const [HasMore, setHasMore] = useState(true);

  const cols = [
    {
      label: "Sub Company Name",
      name: "subCompanyName",
      headTextLeft: true,
      textLeft: true,
    },
    { label: "Sites Number", name: "sitesCount", customRender: SiteNumber },
    { label: "Sub Company Status", name: "isActive", customRender: ActiveCell },
  ];
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  const {
    state: { list: subCompanies, count, totalSize, filter, perPage, page },
    fetchList: fetchSubCompanies,
    dispatch,
  } = useSubCompany();
  const [selectedCompany, setSelectedCompany] = React.useState(
    filter?.companyId
  );

  //fetch companies list
  useEffect(() => {
    if (role === "Admin") {
      fetchCompany();
    }
  }, []);

  //fetch subcompanies list
  useEffect(() => {
    if (role === "Admin") {
      if (filter.companyId === 0) dispatch({ type: "set_list", payload: [] });
      else fetchSubCompanies(filter.companyId, page, perPage);
    } else {
      fetchSubCompanies(adminOn?.id);
    }
  }, [fetchSubCompanies]);

  //handle detail click
  const handleDetail = (c) => {
    navigate(`SubCompanyDetails/${c}`);
  };

  // handle change selected company
  const handleChange = (event) => {
    // console.log(event.target, "cid");
    if (role === "Admin") {
      dispatch({
        type: "set_filter",
        payload: { companyId: event.target.value },
      });
      setSelectedCompany(event.target.value);
      fetchSubCompanies(event.target.value);
    } else {
      dispatch({
        type: "set_filter",
        payload: { companyId: adminOn?.id },
      });
      setSelectedCompany(adminOn?.id);
      fetchSubCompanies(adminOn?.id);
    }
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };

  // const fetchMoreData = () => {
  //   if (companiesList.length >= totalSizeCompanies) {
  //     console.log("list > total size");
  //     setHasMore(false);
  //     return;
  //   }
  //   if (pageN >= totalPagesCompanies) {
  //     console.log("page > total page");
  //     setHasMore(false);
  //     return;
  //   }
  //   fetchCompany(100, pageN);
  //   setPageN(pageN + 1);
  // };

  const tabs = [{ title: "SubCompanies", num: totalSize }];

  if (!subCompanies) return <div className="loader"></div>;

  return (
    <>
      {role === "Admin" && (
        <>
          <Typography variant="Poster" component="h4" mb={2}>
            Select Company
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: "fit-content" }}>
              {error}
            </Alert>
          )}
          <GridGroup1>
            <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
              <span style={{ fontSize: "12px" }}>Company Name</span>
              <Select
                value={selectedCompany}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  autoFocus: true,
                }}
                sx={{
                  fontSize: "12px",
                  width: "300px",
                  height: "40px",
                  marginTop: "14px",
                }}
                // MenuProps={{
                //   PaperProps: {
                //     onScroll: HasMore && fetchMoreData,
                //   },
                // }}
              >
                <MenuItem value={0}>
                  <em>Please select company</em>
                </MenuItem>
                {companies.map((company) => {
                  return (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridGroup1>
        </>
      )}
      {role === "Company" && (
        <GridGroup1>
          <Box mb={10}>
            <span style={{ fontSize: "12px" }}>Company Name</span>
            {/* Adding sub company => !subcompany */}
            <Box
              sx={{
                borderBottom: "1px solid gray",
                width: "290px",
                borderRadius: "8px",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              {adminOn?.companyName}
            </Box>
          </Box>
        </GridGroup1>
      )}

      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      />
      <CustomTable
        empty={subCompanies?.length === 0 ? true : false}
        cols={cols}
        data={subCompanies}
        moreOptions={{
          detail: handleDetail,
        }}
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          add: () => {
            if (role === "Admin") {
              if (selectedCompany === 0) setError("Please select one company");
              else navigate(`/SubCompanies/add/${selectedCompany}`);
            } else if (role === "Company") {
              navigate(`/SubCompanies/add/${adminOn?.id}`);
            }
          },
        }}
        showButton={true}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
      ></CustomTable>
    </>
  );
};

export default React.memo(SubCompanyList);
