// import styled, { css } from "styled-components";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Button, IconButton, Tooltip } from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { Menu, MenuItem } from "@mui/material";
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import CSS
// import Dots from "../../icons/Dots.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const buildCustomOption = function (id, v) {
  return (
    <Button
      key={v.label}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "inherit",
        padding: "0px",
        height: "30px",
      }}
      data={v.data}
      onClick={() => v.fn(id, v.data)}
    >
      {v.label}
    </Button>
  );
};

const buildOption = function (id, k, v, data) {
  switch (k) {
    case "edit":
      return buildCustomOption(id, {
        label: (
          <Tooltip title="Edit" placement="top">
            <span>
              <BorderColorOutlinedIcon />
            </span>
          </Tooltip>
        ),
        icon: "pen",
        fn: v,
      });
    case "detail":
      return buildCustomOption(id, {
        label: (
          <>
            <span>Details</span>
            <MoreHorizIcon fontSize="small" />
            {/* <img alt="Dots" src={Dots} /> */}
          </>
        ),
        icon: "pen",
        fn: v,
        data: data,
      });
    case "delete":
      return (
        <Tooltip title="Delete" placement="top">
          <IconButton
            key="delete"
            className="delete"
            style={{ color: "grey" }}
            onClick={() => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui">
                      <h1>Are you sure?</h1>
                      <p>You want to delete?</p>
                      <Button
                        style={{
                          backgroundColor: "gray",
                          color: "white",
                          margin: "10px",
                        }}
                        onClick={() => {
                          v(id);
                          onClose();
                        }}
                      >
                        Yes, Delete it!
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "gray",
                          margin: "10px",
                        }}
                        onClick={onClose}
                      >
                        No
                      </Button>
                    </div>
                  );
                },
              });
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      );
    default:
      return buildCustomOption(id, v);
  }
};

function MoreOptions({ id, moreOptions, data }) {
  // console.log(data);
  const renderOptions = (id) => {
    if (!id) return null;
    const list = [];
    Object.keys(moreOptions).forEach((k) => {
      list.push(buildOption(id, k, moreOptions[k], data));
    });
    return (
      <ul style={{ display: "flex", width: "100%", listStyle: "none" }}>
        {list}
      </ul>
    );
  };

  return <>{renderOptions(id)}</>;
}

export default MoreOptions;
