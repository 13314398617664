import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// components
import FormCompany from "./FormCompany";
import ModalBox from "../../ui/ModalBox";
import { Typography } from "@mui/material";

const AddCompany = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('x');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <div>
      <Typography variant="Poster" component="h3" gutterBottom>
        Add Company
      </Typography>
      <div>
        <FormCompany
          handleSuccess={handleSuccess}
          setCompanyName={setCompanyName}
        />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added new company "
        name={companyName}
      />
    </div>
  );
};

export default React.memo(AddCompany);
