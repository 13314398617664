import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalBox from "../../../ui/ModalBox";
import FormHourlyService from "./FormHourlyService";
import { useService } from "../Provider";

const UpdateHourlyService = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [reload, setReload] = useState(false);

  const {
    state: { selected: service },
    fetchOne: fetchService,
  } = useService();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  useEffect(() => {
    fetchService(id);
  }, [fetchService, reload, id]);
  const handleSuccess = () => {
    setOpen(true);
    setReload(!reload);
  };
  if (!service) return;
  if (service?.id != id) return;
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Update Service
      </Typography>
      <FormHourlyService service={service} handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.. You updated service"
      />
    </>
  );
};

export default UpdateHourlyService;
