import React from 'react'

const CarsNumber = ({row}) => {
  return (
    <>
       { row.cars? row?.cars?.length: 0}
    </>
  )
}

export default CarsNumber;
