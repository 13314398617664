// React
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// Material UI
import {
  Alert,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
// Providers
import { useCompany } from "../../Companies/Provider";
// Third Party Components
import Filter from "./Filter";
// Custom UI
import ErrorsList from "../../../ui/ErrorsList";
// Utilities
import { createYupSchema } from "../../../utilities/yupSchemaCreator";
// Styled Component
import styled from "styled-components";
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useVendor } from "./Provider";

// yup schema
const yupSchema = Filter.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

function FilterForm({ setFilterClick, role, adminOn }) {
  const GridGroup1 = styled.div`
    width: fit-content;
    display: grid;
    padding: 20px;
    margin-bottom: 10px;
    grid-template-columns: 325px 325px 150px;
    gap: 10px;
    grid-template-rows: 75px;
    grid-column-gap: 10px;
    background: #f7f9fb;
  `;
  let theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { FilterData },
    fetchList: fetchVendors,
    dispatch,
  } = useVendor();
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  const [Error] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(FilterData?.companyId);

  useEffect(() => {
    if (role === "Admin") {
      fetchCompany(100);
    }
  }, [role, fetchCompany]);

  useEffect(() => {
    // if (role === "Company") {
    //   dispatch({ type: "set_FilterData", payload: { companyId: adminOn?.id } });
    // }
    fetchVendors(FilterData);
  }, [FilterData, fetchVendors]);

  const onSubmit = (values) => {
    dispatch({ type: "set_FilterData", payload: values });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box>
        {Error && (
          <Alert severity="error">
            <ErrorsList error={Error} />
          </Alert>
        )}
      </Box>
      <Box sx={{ background: "#f7f9fb", padding: "20px", mt: 1, width: "85%" }}>
        {role === "Company" ? (
          <GridGroup1>
            <Box mb={10}>
              <span style={{ fontSize: "12px" }}>Company Name</span>
              <Box
                sx={{
                  borderBottom: "1px solid gray",
                  width: "290px",
                  borderRadius: "8px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {adminOn?.companyName}
              </Box>
            </Box>
          </GridGroup1>
        ) : (
          <>
            <GridGroup1>
              {Filter.map((f, index) => (
                <React.Fragment key={index}>
                  {f.select && (
                    <FormControl
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>{f.label}*</span>
                      <Select
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                        error={!!errors[f.name]}
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        inputProps={{
                          ...register(f?.name),
                        }}
                        size="small"
                      >
                        <MenuItem value={0}>
                          <em>Please select company</em>
                        </MenuItem>
                        <MenuItem value={-1}>
                          <em>No Company</em>
                        </MenuItem>
                        {companies.map((company) => {
                          return (
                            <MenuItem key={company.id} value={company.id}>
                              {company[f?.companyName]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </React.Fragment>
              ))}
            </GridGroup1>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignContent: "start",
                mr: "30px",
                mt: "-30px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  color: "white",
                  ml: 5,
                  mt: 2,
                  "&:hover": { background: `${theme.color.blackBackground}` },
                  textTransform: "capitalize",
                }}
                onClick={() => setFilterClick(true)}
              >
                <FilterList />
                <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
}

export default FilterForm;
