const fields = [
  {
    name: "companyId",
    label: "Company Name",
    data: "userAccount",
    value: "companyName",
    adminType: "Company",

    select: true,
    selectExceptionRole: "Company",
    selectExceptionName: "companyName",
  },
  {
    name: "employeeId",
    label: "Employee Name",
    data: "userAccount",
    value: "employeeId",
    UserType: "User",

    select: true,
  },
  {
    name: "subCompanyId",
    label: "Sub Company Name",
    data: "userAccount",
    adminType: "Sub Company",

    select: true,
  },
  {
    name: "siteId",
    label: "Site Name",
    data: "userAccount",
    adminType: "Site",

    select: true,
  },
  {
    forDesign: true,
  },
  {
    name: "displayName",
    label: "Display Name",
    data: "subCompany",
    required: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "username",
    label: "User Name",
    data: "subCompany",
    required: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  // {
  //   name: "userRole",
  //   label: "User Role",
  //   data: "userAccount",
  // required: true,
  //   select: true,
  // },
  {
    name: "email",
    label: "Email",
    data: "userAccount",
    required: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "password",
    label: "Password",
    data: "userAccount",
    required: true,
    type: "password",
    validationType: "password",
  },
  {
    name: "isActive",
    label: "User Status",
    data: "userAccount",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
