//react components
import { Typography } from "@mui/material";
import React, { useState } from "react";
//react router
import { useNavigate } from "react-router";
//dashbord components

import ModalBox from "../../ui/ModalBox";
//form contract component
import FormMemberPlans from "./FormMemberPlans";

const AddMemberPlan = () => {
   //state which check open or close modal
   const [open, setOpen] = useState(false);
   //var to navigate another page
 
   const navigate = useNavigate();
   //close modal function
   const handleClose = () => {
     setOpen(false);
     navigate(-1);
   };
   //open modal function when  clicked button
 
   const handleSuccess = () => {
     setOpen(true);
   };
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Add New Member Plan
      </Typography>
      <FormMemberPlans handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added new Member Plan"

        name="Member Plan"

      />
    </>
  )
}

export default React.memo(AddMemberPlan);
