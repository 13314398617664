import { Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../../ui/CustomTable";
import TabsList from "../../../ui/TabsList";
import FilterForm from "./FilterForm";
import { useReports } from "../Provider";
import { useAuth } from "../../../services/auth";
import SitesCurrency from "../Render/SitesCurrency";
import Creator from "../Render/Creator";
import RatioRender from "../Render/Ratiorender";
import SubSalaryRender from "../Render/SubsalaryRender";
import SitesSales from "../Render/SitesSales";
import TotalAmount from "../Render/TotalAmount";
import TicketCount from "../Render/TicketCount";
import localDate from "../Render/localDate";
import localOutDate from "../Render/localOutDate";
import LocalDateEnd from "../Render/LocalDateEnd";
import LocalDateStart from "../Render/LocalDateStart";
import ShowServicesList from "../Render/ShowServicesList";
import CarCount from "../Render/CarCount";
import TrialSum from "../Render/TrialSum";
import CostOfficial from "../Render/CostOfficial";
import PayrollSite from "../Render/PayrollSite";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Filter from "./Filter";
import { useSite } from "../../Sites/Provider";
import localDateCell from "../Render/localDateCell";
import {
  CalDate,
  CalTime,
  StyleDate,
  tolocalDate,
} from "../../../utilities/functions";
import DailySalesDate from "../Render/DailySalesDate";
import Services from "../Render/Services";
import SpotRender from "../Render/SpotRender";
import CarsAll from "../Render/CarsAll";
import PaidOption from "../Render/PaidOption";
import Official from "../Render/Official";
import CostTrial from "../Render/CostTrial";
import Tips from "../Render/Tips";

function ReportPage() {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let params = useParams();
  let navigate = useNavigate();
  let { role } = useAuth();
  let theme = useTheme();
  const [selected, setSelected] = useState();
  var [SumTotalSales] = useState(0);
  var [SumPayrollRatio] = useState(0);
  var [SumTotalPayrolls] = useState(0);
  const {
    state: { Names: sites, page: sitep, perPage: sppage, selected: SiteInfo },
    fetchSiteNames: fetchSites,
  } = useSite();
  const {
    state: {
      listShift: SiteShiftList,
      listTransaction,
      PayServices,
      totalSize,
      test,
      ExportExcelList,
      FilterData,
      perPage,
      page,
      count,
      countShift,
      countExcel,
      countVoid,
      countVoidExcel,
      ExportVoidExcelList,
      listTransactionServices,
      listLessPayroll,
      listHighestPayroll,
      listSitesSales,
      listSitesTickets,
      listVoidTransactions,
      listPrevPayroll,
      EmployeesRoles,
      listTransactionPaySer,
      siteIdAtt,
      DailySales,
      ExportShiftExcelList,
      countShiftExcel,
      from,
      to,
      companyIdAtt,
      selectedSite,
    },
    fetchListShift: getSiteShiftList,
    fetchTransaction,
    fetchTransactionExcel,
    fetchVoidTransactionExcel,
    fetchShiftTransactionExcel,
    dispatch,
  } = useReports();

  const [Reports, setReports] = useState([]);
  const [selectedCols, setSelectedCols] = useState(params.name + " Reports");
  const [filterClick, setFilterClick] = useState(false);

  var DailySalesFilter = selectedSite
    ? DailySales?.filter((d) => d.siteId === selectedSite)
    : DailySales;

  const tabs = [{ title: params.name + " Reports", num: totalSize }];
  const getCols = (name) => {
    switch (name?.split(" ")[0]) {
      case "Transactions":
        return [
          {
            label: "Ticket Number",
            name: "number",
          },
          {
            label: "In (Date & Time)",

            customRender: localDate,
          },
          {
            label: "Out (Date & Time)",

            customRender: localOutDate,
          },
          {
            label: "Zone",
            name: "zoneTitle",
          },
          {
            label: "service Names",
            customRender: Services,
          },
          {
            label: "Services Count",
            name: "serviceCount",
          },
          {
            label: "Spot",
            name: "spot",
            customRender: SpotRender,
          },
          {
            label: "State",
            name: "status",
          },
          {
            label: "Paid State",
            name: "paidStatus",
          },
          {
            label: "Payment Type",
            name: "paymentType",
          },
          {
            label: "Company Amount",
            name: "compAmount",
          },
          {
            label: "Cash Amount",
            name: "cashAmount",
          },
          {
            label: "CC Amount",
            name: "ccAmount",
          },
          {
            label: "Void Count",
            name: "voidCount",
          },
          {
            label: "Tips",
            name: "tips",
          },
          {
            label: "Discount",
            name: "discount",
          },
          {
            label: "Car Model",
            name: "carMaker",
          },
          // {
          //   label:"Car Color",
          //   name:"carColor"
          // },
          {
            label: "Car Plate",
            name: "carPlate",
          },
          {
            label: "Mobile",
            name: "mobile",
          },
        ];
        break;
      case "Voids":
        return [
          {
            label: "Ticket Number",
            name: "number",
          },
          {
            label: "In (Date & Time)",

            customRender: localDate,
          },
          {
            label: "Out (Date & Time)",

            customRender: localOutDate,
          },
          {
            label: "Zone",
            name: "zoneTitle",
          },
          // {
          //   label: "State",
          //   name: "status",
          // },
          // {
          //   label: "Paid State",
          //   name: "paidStatus",
          // },

          {
            label: "Void Reasons",
            name: "reason",
          },
          { label: "Created By", name: "creator", customRender: Creator },
        ];
        break;
      case "TransactionsByService":
        return [
          { label: "Service Type", name: "serviceType" },
          { label: "Service Title", customRender: ShowServicesList },
          { label: "Cars Count", customRender: CarCount },
          { label: "Cost Trial Sum", customRender: TrialSum },
          { label: "costOfficialSum", customRender: CostOfficial },
        ];
      case "TransactionsServicePayment":
        return [
          { label: "Service Type", name: "type" },
          {
            label: "Title",
            name: "title",
          },
          {
            label: "Paid Option",
            customRender: PaidOption,
          },
          {
            label: "Cars",
            customRender: CarsAll /**after that columns carsCashCount */,
          },
          {
            label: "Cost Trial",
            customRender: CostTrial,
          },
          {
            label: "Cost Official",
            customRender: Official,
          },
          {
            label: "Tips",
            customRender: Tips,
          },
        ];
      case "Shift":
        return [
          {
            label: "Shift Id",
            name: "id",
          },
          {
            label: "In (Date & Time)",
            name: "startDate",
            customRender: LocalDateStart,
          },
          {
            label: "Out (Date & Time)",
            name: "endDate",
            customRender: LocalDateEnd,
          },
        ];
      case "EmployeesRoles":
        return [
          { label: "Date", name: "date", customRender: localDateCell },
          { label: "Role", name: "role" },
          { label: "Employee Count", name: "employeesCount" },
        ];
      case "DailySales":
        return [
          { label: "Date", customRender: DailySalesDate },
          {
            label: "Site Name",
            customRender: PayrollSite,
            searchDropdown: true,
          },
          { label: "Total Sales", name: "totalSales" },
          { label: "Total Payroll", name: "totalPayrolls" },
          { label: "Payroll Ratio", name: "payrollRatio" },
        ];
      case "LessPayroll":
        return [
          {
            label: "Currency",
            name: "currency",
          },
          {
            label: "Sites",
            customRender: SitesCurrency,
          },
          {
            label: "Sub Salary",
            customRender: SubSalaryRender,
          },
          {
            label: "Ratio",
            customRender: RatioRender,
          },
        ];
      case "HighestPayroll":
        return [
          {
            label: "Currency",
            name: "currency",
          },
          {
            label: "Sites",
            customRender: SitesCurrency,
          },
          {
            label: "Sub Salary",
            customRender: SubSalaryRender,
          },
          {
            label: "Ratio",
            customRender: RatioRender,
          },
        ];
      case "SiteBySales":
        return [
          {
            label: "Site Name",
            customRender: SitesSales,
          },
          {
            label: "Total Amount",
            customRender: TotalAmount,
          },
        ];
      case "SitesByTickets":
        return [
          {
            label: "Site Name",
            customRender: SitesSales,
          },
          {
            label: "Ticket Count",
            customRender: TicketCount,
          },
        ];
      case "PreviewPayroll":
        return [
          { label: "col1" },
          {
            label: "col2",
          },
        ];
    }
  };

  const getData = (name) => {
    switch (name?.split(" ")[0]) {
      case "Transactions":
        return listTransaction;
        break;
      case "Voids":
        return listVoidTransactions;
        break;
      case "TransactionsByService":
        return listTransactionServices;
        break;
      case "TransactionsServicePayment":
        return PayServices;
        break;
      case "Shift":
        return SiteShiftList;
        break;
      case "EmployeesRoles":
        return EmployeesRoles;
        break;
      case "DailySales":
        return DailySalesFilter;
        break;
      case "LessPayroll":
        return listLessPayroll;
        break;
      case "HighestPayroll":
        return listHighestPayroll;
        break;
      case "SiteBySales":
        return listSitesSales;
        break;
      case "SitesByTickets":
        return listSitesTickets;
        break;
      case "PreviewPayroll":
        return listPrevPayroll;
        break;
    }
  };

  const getObj = (name) => {
    switch (name) {
      case "Transactions":
        return {
          company: true,
          site: true,
          fromTime: true,
          toTime: true,
          fromDate: true,
          toDate: true,
        };
      case "Shift":
        return {
          company: true,
          site: true,
          fromTime: true,
          toTime: true,
          fromDate: true,
          toDate: true,
        };
    }
  };

  const handleDetail = (id) => {
    navigate(`detail/${id}`);
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };
  // Export Transaction excel function
  const handleExportExcel = async () => {
    for (var i = 2; i <= countExcel; i++) {
      await fetchTransactionExcel(FilterData, i, 100);
    }
    var newArr = new Array();
    var site = sites.filter((s) => s.id == siteIdAtt).map((ss) => ss.name);
    newArr.push({
      Filter: `Site Name:   ${site[0]}, From:  ${
        from ? CalTime(from) : ""
      }, To:  ${to ? CalTime(to) : ""}`,
    });

    for (var i = 0; i < ExportExcelList.length; i++) {
      newArr = newArr.concat(ExportExcelList[i]);
    }

    newArr.forEach((a) => {
      a["checkInDate"] = a["checkInDate"]
        ? tolocalDate(a?.["checkInDate"])
        : "";
      a["checkOutDate"] = a["checkOutDate"]
        ? tolocalDate(a?.["checkOutDate"])
        : "";
      if (a) {
        a["serviceNames"] = a?.["serviceNames"]?.toString();
      } else {
      }
    });

    var fileName = "Transactions Report File";
    const ws = XLSX.utils.json_to_sheet(newArr, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // Export Void Transaction list To Excel File
  const handleVoidExportExcel = async () => {
    for (var i = 2; i <= countVoidExcel; i++) {
      await fetchVoidTransactionExcel({ sid: siteIdAtt }, i, 100);
    }
    var newArr = new Array();
    var site = sites.filter((s) => s.id == siteIdAtt).map((ss) => ss.name);

    newArr.push({
      Filter: `Site Name:   ${site[0]}, From:  ${
        from ? CalTime(from) : ""
      }, To:  ${to ? CalTime(to) : ""}`,
    });

    for (var i = 0; i < ExportVoidExcelList.length; i++) {
      newArr = newArr.concat(ExportVoidExcelList[i]);
    }
    newArr.forEach((a) => {
      a["checkInDate"] = a["checkInDate"] ? tolocalDate(a["checkInDate"]) : "";
      a["checkOutDate"] = a["checkOutDate"]
        ? tolocalDate(a["checkOutDate"])
        : "";
    });

    var fileName = "Void Transactions Report";
    const ws = XLSX.utils.json_to_sheet(newArr, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // Export Shift list To Excel File
  const handleShiftExportExcel = async () => {
    for (var i = 2; i <= countShiftExcel; i++) {
      await fetchShiftTransactionExcel({ sid: siteIdAtt }, i, 100);
    }
    var newArr = new Array();
    var site = sites.filter((s) => s.id == siteIdAtt).map((ss) => ss.name);
    newArr.push({
      Filter: `Site Name:   ${site[0]}, From:  ${
        from ? CalTime(from) : ""
      }, To:  ${to ? CalTime(to) : ""}`,
    });
    for (var i = 0; i < ExportShiftExcelList.length; i++) {
      newArr = newArr.concat(ExportShiftExcelList[i]);
    }
    newArr.forEach((a) => {
      a["startDate"] = a["startDate"] ? tolocalDate(a["startDate"]) : "";
      a["endDate"] = a["endDate"] ? tolocalDate(a["endDate"]) : "";
    });
    var fileName = "Shifts Report";
    const ws = XLSX.utils.json_to_sheet(newArr, {
      origin: -1,
    });
    ws["!cols"] = [{ width: 70 }, { width: 50 }, { width: 50 }, { width: 40 }];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const EmployeeRoleExcel = () => {
    var fileName = "Employee Roles Report";
    var site = sites.filter((s) => s.id == siteIdAtt).map((ss) => ss.name);
    var array = new Array();
    array.push({ Filter: "" });
    array.push({
      Filter: `Site Name: ${site[0]}|From: ${from ? CalTime(from) : ""}|To: ${
        to ? CalTime(to) : ""
      }`,
    });
    for (var i = 0; i <= EmployeesRoles.length; i++) {
      array.push({
        Role: EmployeesRoles[i]?.role,
        Date: EmployeesRoles[i]?.date,
        EmployeeCount: EmployeesRoles[i]?.employeesCount,
      });
    }

    const ws = XLSX.utils.json_to_sheet(array, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 70 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const DailySalesExcel = () => {
    var fileName = "Daily Sales Report";
    var DailyExcelArray = new Array();
    DailyExcelArray.push({
      Filter: `From: ${from ? CalTime(from) : ""}|To:${to ? CalTime(to) : ""}`,
    });

    for (var i = 0; i < DailySalesFilter.length; i++) {
      DailyExcelArray.push({
        Date: DailySalesFilter[i]?.date
          ? StyleDate(DailySalesFilter[i]?.date)
          : "",
        SiteName: DailySalesFilter[i]?.site?.siteName,
        TotalSales: DailySalesFilter[i]?.totalSales,
        TotalPayrolls: DailySalesFilter[i]?.totalPayrolls,
        PayrollRatio: DailySalesFilter[i]?.payrollRatio,
      });
    }

    DailyExcelArray.push({
      TotalSales: `Total Sales Sum:${SumTotalSales}`,
      TotalPayrolls: `Total Payrolls Sum: ${SumTotalPayrolls}`,
      PayrollRatio: `Ratio: ${SumPayrollRatio}`,
    });
    const ws = XLSX.utils.json_to_sheet(DailyExcelArray, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 70 },
      { width: 30 },
      { width: 40 },
      { width: 40 },
      { width: 40 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const TicketExcel = () => {
    var fileName = "Sites By Ticket Report";
    var SitesTicketExcelArray = new Array();
    SitesTicketExcelArray.push({
      Filter: `From:  ${from ? CalTime(from) : ""}, To:  ${
        to ? CalTime(to) : ""
      }`,
    });
    for (var i = 0; i < listSitesTickets.length; i++) {
      SitesTicketExcelArray.push({
        siteName: listSitesTickets[i].site.siteName,
        TicketCount: listSitesTickets[i].ticketCount,
      });
    }
    const ws = XLSX.utils.json_to_sheet(SitesTicketExcelArray, {
      origin: -1,
    });
    ws["!cols"] = [{ width: 50 }, { width: 20 }, { width: 20 }];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const SalesExcel = () => {
    var fileName = "Sites By Sales Report";
    var SitesSalesExcelArray = new Array();
    SitesSalesExcelArray.push({
      Filter: `From: ${from ? CalTime(from) : ""}| To: ${
        to ? CalTime(to) : ""
      }`,
    });
    for (var i = 0; i < listSitesSales.length; i++) {
      SitesSalesExcelArray.push({
        SiteName: listSitesSales[i].site.siteName,
        TotalAmount: listSitesSales[i].totalAmount,
      });
    }

    const ws = XLSX.utils.json_to_sheet(SitesSalesExcelArray, {
      origin: -1,
    });
    ws["!cols"] = [{ width: 70 }, { width: 20 }, { width: 20 }];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Info");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const TransactionServicesExcel = () => {
    var fileName = "Transaction By Service Report";
    var site = sites.filter((s) => s.id == siteIdAtt).map((ss) => ss.name);
    var arr1 = new Array();
    var arr0 = new Array();
    arr0.push({
      Filter: `Site Name:${site[0]}|From: ${from ? CalTime(from) : ""}| To: ${
        to ? CalTime(to) : ""
      }`,
    });
    arr1.push({
      Filter: `Site Name:${site[0]}|From: ${from ? CalTime(from) : ""}| To: ${
        to ? CalTime(to) : ""
      }`,
    });
    for (var i = 0; i < listTransactionServices[0].details.length; i++) {
      arr0.push({
        ServiceTitle: listTransactionServices[0].details[i].serviceTitle,
        CostOfficialSum: listTransactionServices[0].details[i].costOfficialSum,
        CostTrialSum: listTransactionServices[0].details[i].costTrialSum,
        CarCount: listTransactionServices[0].details[i].carsCount,
      });
    }
    for (var i = 0; i < listTransactionServices[1].details.length; i++) {
      arr1.push({
        ServiceTitle: listTransactionServices[1].details[i].serviceTitle,
        CostOfficialSum: listTransactionServices[1].details[i].costOfficialSum,
        CostTrialSum: listTransactionServices[1].details[i].costTrialSum,
        CarCount: listTransactionServices[1].details[i].carsCount,
      });
    }
    arr0.push({
      CostOfficialSum: listTransactionServices[0].costOfficialSum,
      CostTrialSum: listTransactionServices[0].costTrialSum,
      CarCount: listTransactionServices[0].carsCount,
    });
    arr1.push({
      CostOfficialSum: listTransactionServices[1].costOfficialSum,
      CostTrialSum: listTransactionServices[1].costTrialSum,
      CarCount: listTransactionServices[1].carsCount,
    });
    listTransactionServices.shift();

    const wsFixed = XLSX.utils.json_to_sheet(arr1, {
      origin: -1,
    });
    wsFixed["!cols"] = [
      { width: 70 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    const wsHourly = XLSX.utils.json_to_sheet(arr0, {
      origin: -1,
    });
    wsHourly["!cols"] = [
      { width: 70 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsFixed, "Fixed Service");
    XLSX.utils.book_append_sheet(wb, wsHourly, "Hourly Service");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const LessPayrollExcel = () => {
    var fileName = "Less Payroll Report";
    const wb = XLSX.utils.book_new();
    var LessNewArray = new Array();
    LessNewArray.push({
      Filter: `From: ${from ? CalTime(from) : ""}| To:${to ? CalTime(to) : ""}`,
    });

    for (var i = 0; i <= listLessPayroll.length - 1; i++) {
      listLessPayroll[i].sites.map((dailyItem) =>
        LessNewArray.push({
          SiteName: dailyItem.site.siteName,
          SubSalary: dailyItem.subSalary,
          Ratio: dailyItem.ratio,
          Currency: dailyItem.currency,
        })
      );
    }
    const ws = XLSX.utils.json_to_sheet(LessNewArray, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 70 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, "Less Payroll Data");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const HighPayrollExcel = () => {
    var fileName = "High Payroll Report";
    const wb = XLSX.utils.book_new();
    var HighNewArray = new Array();
    HighNewArray.push({
      Filter: `From: ${from ? CalTime(from) : ""}| To: ${
        to ? CalTime(to) : ""
      }`,
    });
    for (var i = 0; i <= listHighestPayroll.length - 1; i++) {
      listHighestPayroll[i].sites.map((dailyItem) =>
        HighNewArray.push({
          SiteName: dailyItem.site.siteName,
          SubSalary: dailyItem.subSalary,
          Ratio: dailyItem.ratio,
          Currency: dailyItem.currency,
        })
      );
    }
    const ws = XLSX.utils.json_to_sheet(HighNewArray, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 70 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, "Highest Payroll Data");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handlePaySerExportExcel = () => {
    var fileName = "Transaction By Service and Payment Report";
    var site = sites.filter((s) => s.id == siteIdAtt).map((ss) => ss.name);
    var arr = new Array();

    for (
      let index = 0;
      index < listTransactionPaySer[0].details.length;
      index++
    ) {
      arr.push({
        ServiceType: listTransactionPaySer[0].serviceType,
        ServiceTitle: listTransactionPaySer[0].details[index].serviceTitle,
        PaidOption: ["Cash", "C.C", "Void", "Company"],
        cars: [
          listTransactionPaySer[0]?.details[index]?.carsCashCount,
          listTransactionPaySer[0]?.details[index]?.carsCCCount,
          listTransactionPaySer[0]?.details[index]?.carsVoidCount,
          listTransactionPaySer[0]?.details[index]?.carsCompCount,
        ],
        costTrial: [
          listTransactionPaySer[0]?.details[index]?.cashCostTrial,
          listTransactionPaySer[0]?.details[index]?.ccCostTrial,
          0,
          listTransactionPaySer[0]?.details[index]?.compCostTrial,
        ],
        costOfficial: [
          listTransactionPaySer[0]?.details[index]?.cashCostOfficial,
          listTransactionPaySer[0]?.details[index]?.ccCostOfficial,
          0,
          listTransactionPaySer[0]?.details[index]?.compCostOfficial,
        ],
        costTips: [
          listTransactionPaySer[0]?.details[index]?.cashTips,
          listTransactionPaySer[0]?.details[index]?.ccTips,
          0,
          listTransactionPaySer[0]?.details[index]?.compTips,
        ],
      });
      arr.push({
        ServiceType: "",
        ServiceTitle: "",
        PaidOption: ["Total"],
        cars: [listTransactionPaySer[0]?.details[index]?.serviceCarsCount],
        costTrial: [
          listTransactionPaySer[0]?.details[index]?.serviceCostTrialSum,
        ],
        costOfficial: [
          listTransactionPaySer[0]?.details[index]?.serviceCostOfficialSum,
        ],
        costTips: [listTransactionPaySer[0]?.details[index]?.serviceTipsSum],
      });
      arr.push({
        ServiceType: "",
        ServiceTitle: "",
        PaidOption: [""],
        cars: [],
        costTrial: [],
        costOfficial: [],
        costTips: [],
      });
    }
    var arr2 = new Array();
    arr2.push({
      Filter: `Site Name:${site[0]}|From: ${from ? CalTime(from) : ""}| To: ${
        to ? CalTime(to) : ""
      }`,
    });
    for (let index = 0; index < arr.length; index++) {
      for (let j = 0; j < arr[index]?.PaidOption?.length; j++) {
        arr2.push({
          serviceType: arr[index].ServiceType,
          serviceTitle: arr[index].ServiceTitle,
          PaidOption: arr[index].PaidOption[j],
          Cars: arr[index]?.cars[j],
          CostTrial: arr[index]?.costTrial[j],
          CostOfficial: arr[index]?.costOfficial[j],
          CostTips: arr[index]?.costTips[j],
        });
      }
    }

    /** for fixed */
    var arrFixed = new Array();
    for (
      let index = 0;
      index < listTransactionPaySer[1].details.length;
      index++
    ) {
      arrFixed.push({
        ServiceType: listTransactionPaySer[1].serviceType,
        ServiceTitle: listTransactionPaySer[1].details[index].serviceTitle,
        PaidOption: ["Cash", "C.C", "Void", "Company"],
        cars: [
          listTransactionPaySer[1]?.details[index]?.carsCashCount,
          listTransactionPaySer[1]?.details[index]?.carsCCCount,
          listTransactionPaySer[1]?.details[index]?.carsVoidCount,
          listTransactionPaySer[1]?.details[index]?.carsCompCount,
        ],
        costTrial: [
          listTransactionPaySer[1]?.details[index]?.cashCostTrial,
          listTransactionPaySer[1]?.details[index]?.ccCostTrial,
          0,
          listTransactionPaySer[1]?.details[index]?.compCostTrial,
        ],
        costOfficial: [
          listTransactionPaySer[1]?.details[index]?.cashCostOfficial,
          listTransactionPaySer[1]?.details[index]?.ccCostOfficial,
          0,
          listTransactionPaySer[1]?.details[index]?.compCostOfficial,
        ],
        costTips: [
          listTransactionPaySer[1]?.details[index]?.cashTips,
          listTransactionPaySer[1]?.details[index]?.ccTips,
          0,
          listTransactionPaySer[1]?.details[index]?.compTips,
        ],
      });
      arrFixed.push({
        ServiceType: "",
        ServiceTitle: "",
        PaidOption: ["Total"],
        cars: [listTransactionPaySer[1]?.details[index]?.serviceCarsCount],
        costTrial: [
          listTransactionPaySer[1]?.details[index]?.serviceCostTrialSum,
        ],
        costOfficial: [
          listTransactionPaySer[1]?.details[index]?.serviceCostOfficialSum,
        ],
        costTips: [listTransactionPaySer[1]?.details[index]?.serviceTipsSum],
      });
      arrFixed.push({
        ServiceType: "",
        ServiceTitle: "",
        PaidOption: [""],
        cars: [],
        costTrial: [],
        costOfficial: [],
        costTips: [],
      });
    }

    var arr3 = new Array();
    arr3.push({
      Filter: `Site Name:${site[0]}|From: ${from ? CalTime(from) : ""}| To: ${
        to ? CalTime(to) : ""
      }`,
    });
    for (let index = 0; index < arrFixed.length; index++) {
      for (let j = 0; j < arrFixed[index]?.PaidOption?.length; j++) {
        arr3.push({
          serviceType: arrFixed[index].ServiceType,
          serviceTitle: arrFixed[index].ServiceTitle,
          PaidOption: arrFixed[index].PaidOption[j],
          Cars: arrFixed[index]?.cars[j],
          CostTrial: arrFixed[index]?.costTrial[j],
          CostOfficial: arrFixed[index]?.costOfficial[j],
          CostTips: arrFixed[index]?.costTips[j],
        });
      }
    }
    const wsH = XLSX.utils.json_to_sheet(arr2, {
      origin: -1,
    });
    wsH["!cols"] = [
      { width: 70 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    const wsF = XLSX.utils.json_to_sheet(arr3, {
      origin: -1,
    });
    wsF["!cols"] = [
      { width: 70 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsH, "Hourly Services");
    XLSX.utils.book_append_sheet(wb, wsF, "Fixed Services");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleClick = () => {
    switch (params.name) {
      case "Transactions":
        return handleExportExcel();
        break;
      case "Voids":
        return handleVoidExportExcel();

        break;
      case "TransactionsServicePayment":
        return handlePaySerExportExcel();

        break;
      /**add export excel */
      case "Shift":
        return handleShiftExportExcel();
        break;
      case "EmployeesRoles":
        return EmployeeRoleExcel();
        break;
      case "DailySales":
        return DailySalesExcel();
        break;
      case "SitesByTickets":
        return TicketExcel();
        break;
      case "SiteBySales":
        return SalesExcel();
        break;

      case "TransactionsByService":
        return TransactionServicesExcel();
        break;

      case "LessPayroll":
        return LessPayrollExcel();
        break;
      case "HighestPayroll":
        return HighPayrollExcel();
        break;
    }
  };

  if (DailySalesFilter?.length !== 0) {
    DailySalesFilter.map((daySale, index) => {
      return (SumTotalSales += daySale?.totalSales);
    });
    DailySalesFilter.map((daySale, index) => {
      return (SumTotalPayrolls += daySale?.totalPayrolls);
    });
    DailySalesFilter.map((daySale, index) => {
      return (SumPayrollRatio += daySale?.payrollRatio);
    });
    SumPayrollRatio = SumPayrollRatio / totalSize;
  }

  return (
    <>
      {role !== "Site" && (
        <Typography variant="Poster" component="h4">
          {params.name === "Transactions" ||
          params.name === "Shift" ||
          params.name === "Voids" ||
          params.name === "TransactionsByService" ? (
            <>Select Company & Site (At least)</>
          ) : (
            <>Select Company & From Date & To Date</>
          )}
        </Typography>
      )}

      <FilterForm
        obj={getObj(params.name)}
        PageName={selectedCols}
        filterClick={filterClick}
        setFilterClick={setFilterClick}
      ></FilterForm>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
        {filterClick && (
          <Button
            sx={{
              border: `1px solid ${theme.color.Screamingreen}`,
              color: theme.color.Screamingreen,
              mt: "5px",
              textTransform: "capitalize",
              "&:hover": { background: `${theme.color.gray50}` },
            }}
            onClick={handleClick}
          >
            {" "}
            Export to Excel
          </Button>
        )}
      </div>

      <CustomTable
        empty={getData(selectedCols)?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        showButton={true}
        moreOptions={
          selectedCols === "Shift Reports" && {
            detail: handleDetail,
          }
        }
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          searchDropdown: true,
        }}
        page={page}
        rowsPerPage={10}
        dispatch={dispatch}
        count={
          (params.name === "Transactions" && count) ||
          (params.name === "Shift" && countShift) ||
          (params.name === "Voids" && countVoid)
        }
        isPagination={
          params.name === "Transactions" ||
          params.name === "Shift" ||
          params.name === "Voids"
            ? true
            : false
        }
        ExpandRow={params?.name === "DailySales" ? true : false}
        expandValues={[
          "",
          "",
          SumTotalSales,
          SumTotalPayrolls,
          SumPayrollRatio,
        ]}
      ></CustomTable>
    </>
  );
}

export default ReportPage;
