import React, { useState } from "react";
import { useEmployees } from "../../Provider";
import { IconButton } from "@mui/material";
import ModalBox from "../../../../ui/ModalBox";
import AddIcon from "@mui/icons-material/Add";

const EmployeeRole = ({ row }) => {
  const [openAddRole, setOpenAddRole] = useState(false);
  const {
    state: { selected: employee },
  } = useEmployees();
  /**handle update rate close */

  const handleAddRateClose = () => {
    setOpenAddRole(false);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        {row.roles.map((role, index) => (
          <div style={{ marginBottom: "10px", height: "30px" }}>
            {role.roleTitle}
          </div>
        ))}
      </div>
      <IconButton onClick={() => setOpenAddRole(true)}>
        <AddIcon color="action" fontSize="small" />
      </IconButton>
      <ModalBox
        open={openAddRole}
        addRate={true}
        handleClose={handleAddRateClose}
        text="The update operation has been completed successfully.."
        existSite={row}
        employee={employee}
      />
    </div>
  );
};

export default EmployeeRole;
