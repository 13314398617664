//react component
import React, { useEffect, useState } from "react";
//react router
import { useNavigate, useParams } from "react-router-dom";

// use form
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import fields from "./fields";

//@mui material components

import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
//provider
import { useSite } from "../Sites/Provider";
import { useGate } from "./Provider";

//Gate Type from Enum File
import { GateType } from "../../utilities/Enum";
import ErrorsList from "../../ui/ErrorsList";
import { handleErrorResponse } from "../../utilities/functions";
import { useAuth } from "../../services/auth";

// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
  grid-template-rows: 80px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormGate = ({ handleSuccess }) => {
  //var to navigate to another page

  let { role } = useAuth();
  let navigate = useNavigate();
  const { id } = useParams();
  let theme = useTheme();
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const {
    state: { list: sites, page, perPage, selected: site },
    fetchList: fetchSites,
    fetchOne: fetchSite,
  } = useSite();
  const { create } = useGate();

  //useEffect to fetch sites list
  useEffect(() => {
    if (role === "Admin" || role === "Company" || role === "SubCompany") {
      fetchSites(0, page, perPage, 0);
    }
  }, [fetchSites, role]);
  useEffect(() => {
    fetchSite(id);
  }, [id, fetchSite]);
  // fetch data function
  const getData = (name) => {
    switch (name) {
      case "sites":
        return sites;
      case "gatesType":
        return GateType;
      default:
        return;
    }
  };
  useEffect(() => {
    setValue("siteId", id);
  }, [id]);
  //onSubmit function

  const onSubmit = async (values) => {
    delete values.companyName;
    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      setError({ title: handleErrorResponse(error.status, "Gate") });
    }
  };
  if (!sites) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          Gate Details
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        ) : (
          ""
        )}
        <GridGroup>
          {fields?.map((f, i) => {
            if (f.viewOnly) return null;
            const inputProps = { ...f.inputProps };

            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <Box key={i}>
                {f.switch ? (
                  <Box>
                    <FormGroup>
                      <FormLabel
                        component="legend"
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                      >
                        {f.label}
                      </FormLabel>
                      <FormControlLabel
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                        control={<Switch color="success" defaultChecked />}
                        {...register(f?.name)}
                        label="Active"
                      />
                    </FormGroup>
                  </Box>
                ) : f.select ? (
                  !f.disabled ? (
                    <>
                      <FormControl
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        required
                        error={!!errors[f.name]}
                      >
                        <InputLabel id="demo-simple-select-label">
                          {f.label}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={id ? id : f.name}
                          label={f.label}
                          inputProps={{
                            ...register(f?.name),
                            required: true,
                          }}
                          disabled={id && f.disabled}
                          size="small"
                        >
                          {getData(f.getData)?.map((s, index) => (
                            <MenuItem key={index} value={s.id}>
                              {s[f.data]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <div>
                      <Typography variant="body2" component="h6" gutterBottom>
                        {f.label}
                      </Typography>

                      <Box
                        sx={{
                          borderBottom: "1px solid gray",
                          width: "290px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {f.label === "Company Name"
                          ? site?.company?.companyName
                          : site?.siteName}
                      </Box>
                    </div>
                  )
                ) : (
                  <>
                    <TextField
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      select={f.select}
                      className={f.class}
                      fullWidth
                      required
                      disabled={f.disabled}
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      inputProps={{
                        ...register(f?.name),
                        required: f.required,
                      }}
                      size="small"
                    ></TextField>
                  </>
                )}
              </Box>
            );
          })}
        </GridGroup>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Add Gate
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default React.memo(FormGate);
