import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorsList from "../../../ui/ErrorsList";
import { HourlyServiceType } from "../../../utilities/Enum";
import hourlyFields from "./HourField";
import { useNavigate, useParams } from "react-router-dom";
import { useZone } from "../../Zones/Provider";
import { useService } from "../Provider";
import { handleResponse } from "../../../utilities/functions";

const formSchema = Yup.object().shape({
  siteZoneId: Yup.number(),
  serviceTypeId: Yup.number().required("this field is required"),
  title: Yup.string()
    .required("this field is required")
    .max(100, "must be less than or equal to 100 character"),
  freePeriod: Yup.number("free period is number must be 0 at least")
    .min(0)
    .max(1440),
  fixPriceTrial: Yup.number().min(0).max(2147483647),
  fixPriceOfficial: Yup.number().min(0).max(2147483647),
  period1: Yup.number().required("this field is required").min(0).max(24),
  hours1: Yup.number().required("this field is required").min(0).max(24),
  price1Trial: Yup.number()
    .required("this field is required")
    .min(0)
    .max(2147483647),
  price1Official: Yup.number()
    .required("this field is required")
    .min(0)
    .max(2147483647),
  period2: Yup.number().min(0).max(24),
  hours2: Yup.number().min(0).max(24),
  price2Trial: Yup.number().min(0).max(2147483647),
  price2Official: Yup.number().min(0).max(2147483647),
  hours3: Yup.number().min(0).max(24),
  price3Trial: Yup.number().min(0).max(2147483647),
  price3Official: Yup.number().min(0).max(2147483647),
  printDivision1: Yup.number(),
  printDivision2: Yup.number(),
  printDivision3: Yup.number(),
  printDivision4: Yup.number(),
  isActive: Yup.boolean(),
});

const GridGroup2 = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50px;
  gap: 42px;
  margin-bottom: 10px;
`;

const GridGroup3 = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 50px;
  gap: 42px;
  margin-bottom: 10px;
  margin-top: 30px;
`;
const GridGroup4 = styled.div`
  width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 50px;
  gap: 42px;
  margin-bottom: 10px;
  margin-top: 40px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormHourlyService = ({ service, handleSuccess }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();

  const [error, setError] = useState(false);
  const [serviceName, setServiceName] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: Zone },
    fetchOne: fetchZone,
  } = useZone();
  const { edit, createService } = useService();

  useEffect(() => {
    service
      ? setValue("siteZoneId", service?.siteZoneId)
      : setValue("siteZoneId", id);
  }, [setValue]);

  // on submit
  const onSubmit = async (values) => {
    if (values.period1 % values.hours1 !== 0) {
      setError({ title: "The period 1 must be a multiple of hours 1" });
    } else if (
      values.period2 % values.hours2 !== 0 &&
      values.period2 !== 0 &&
      values.hours2 !== 0
    ) {
      setError({ title: "The period 2 must be a multiple of hours 2" });
    } else if (
      values.hours3 !== 0 &&
      values.hours2 === 0 &&
      values.period2 === 0
    ) {
      setError({
        title: "You should specify period 2 and hours 2 before hours 3",
      });
    } else {
      try {
        if (!service) {
          await createService(values);
        } else {
          await edit(values, id);
        }
        setServiceName(values.title);
        handleSuccess();
      } catch (e) {
        window.scrollTo(0, 0);
        if (e.status === 400) setError(e.errors);
        if (e.status === 409) setError({ title: "Service Already exists" });
        if (e.status === 500) setError({ title: "Internal Server Error" });
        handleResponse(e.status);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" component="h4" gutterBottom>
          Service Details
        </Typography>
        {error && (
          <Alert severity="error">
            <ErrorsList error={error} />
          </Alert>
        )}

        <GridGroup2>
          {hourlyFields?.map((f, index) => {
            return (
              <>
                {f.disable && !f.section2 && !f.section && (
                  <Box>
                    <Typography variant="body2" component="h6" gutterBottom>
                      {f.label}
                    </Typography>

                    <Box
                      sx={{
                        borderBottom: "1px solid gray",
                        width: "290px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      {f.name1 ? Zone?.[f.name1]?.[f.name] : Zone?.[f.name]}
                    </Box>
                  </Box>
                )}
                {f.select && !f.section2 && !f.disable && !f.section && (
                  <>
                    <FormControl
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      required
                      error={!!errors[f.name]}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {f.label}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={f.label}
                        inputProps={{
                          ...register(f?.name),
                          required: true,
                        }}
                        defaultValue={service?.[f.name]}
                        size="small"
                      >
                        {HourlyServiceType?.map((s, index) => (
                          <MenuItem key={index} value={s.id}>
                            {s[f.contentName]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {!f.select &&
                  !f.section2 &&
                  !f.disable &&
                  !f.switch &&
                  !f.section && (
                    <TextField
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      select={f.select}
                      className={f.class}
                      disabled={f.disabled}
                      size="small"
                      type={f.type === "number" ? "number" : "text"}
                      sx={{ width: theme.dimensions.width }}
                      defaultValue={service?.[f.name] ? service?.[f.name] : 0}
                      inputProps={{
                        ...register(f?.name),
                        required: f.required,
                        min: "1",
                        max: "100",
                        step: "1",
                      }}
                      required
                    ></TextField>
                  )}
                {f.switch && !f.section2 && (
                  <FormGroup>
                    <FormLabel
                      component="legend"
                      sx={{
                        mt: theme.dimensions.mt,
                      }}
                    >
                      {f.label}
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={service?.[f.name]}
                          color="success"
                        />
                      }
                      {...register(f?.name)}
                      label="Active"
                    />
                  </FormGroup>
                )}
              </>
            );
          })}
        </GridGroup2>
        <GridGroup3>
          {hourlyFields?.map((f, index) => {
            return (
              <>
                {!f.select && f.section2 && !f.disable && !f.switch && (
                  <TextField
                    label={f.label}
                    name={f.name}
                    error={!!errors[f.name]}
                    helperText={errors[f.name]?.message}
                    select={f.select}
                    className={f.class}
                    disabled={f.disable}
                    size="small"
                    defaultValue={service ? service?.[f.name] : 0}
                    type={f.type === "number" ? "number" : "text"}
                    sx={{
                      width: f.type === "number" ? "100px" : "170px",
                    }}
                    inputProps={{
                      ...register(f?.name),
                      required: f.required,
                      min: "1",
                      max: "100",
                      step: "1",
                    }}
                    required
                  ></TextField>
                )}
              </>
            );
          })}
        </GridGroup3>
        <GridGroup4>
          {hourlyFields?.map((f, index) => {
            return (
              <>
                {f.section && (
                  <TextField
                    label={f.label}
                    name={f.name}
                    error={!!errors[f.name]}
                    helperText={errors[f.name]?.message}
                    select={f.select}
                    className={f.class}
                    disabled={f.disabled}
                    size="small"
                    type="number"
                    defaultValue={service ? service?.[f.name] : 0}
                    sx={{
                      width: "145px",
                    }}
                    inputProps={{
                      ...register(f?.name),
                      required: f.required,
                    }}
                  ></TextField>
                )}
              </>
            );
          })}
        </GridGroup4>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default FormHourlyService;
