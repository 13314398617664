// React
import { memo, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// MUI
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  List,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
// Analytics
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
// MUI Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import Groups2TwoToneIcon from "@mui/icons-material/Groups2TwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import RememberMeTwoToneIcon from "@mui/icons-material/RememberMeTwoTone";
import WysiwygTwoToneIcon from "@mui/icons-material/WysiwygTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import BugReportIcon from "@mui/icons-material/BugReport";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import DiscountTwoToneIcon from "@mui/icons-material/DiscountTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import LockResetTwoToneIcon from "@mui/icons-material/LockResetTwoTone";
import MoneyOffCsredTwoToneIcon from "@mui/icons-material/MoneyOffCsredTwoTone";
import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";
// Icons
// import CompaniesIcon from "../icons/CompaniesIcon.svg";
// import FolderNotch from "../icons/FolderNotch.svg";
// import ShoppingBagOpen from "../icons/ShoppingBagOpen.svg";
// import UsersThree from "../icons/UsersThree.svg";
// import IdentificationCard from "../icons/IdentificationCard.svg";
// import IdentificationBadge from "../icons/IdentificationBadge.svg";
// import Notebook from "../icons/Notebook.svg";
// import FeaturedIcon from "../icons/FeaturedIcon.svg";
// import ProfileNotificationIcon from "../icons/ProfileNotificationIcon.svg";

// import { useTheme } from "@mui/material/styles";
// Components
import Header from "./header/Header";
import { useAuth } from "../services/auth";
import PassModal from "../ui/PassModal";
import ModalBox from "../ui/ModalBox";
import { useSite } from "../view/Sites/Provider";
import { useSubCompany } from "../view/SubCompanies/Provider";

export const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, id }) => ({
  width: drawerWidth,
  flexShrink: 0,

  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    zIndex: 1204,
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  ...(!open &&
    id === "right" && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: "0px",
      },
    }),
}));
ReactGA.initialize("UA-264542386-1");

function Layout({ children }) {
  let { user, role, adminOn, logout } = useAuth();
  // console.log(user?.tokens?.data?.user, "user");
  // console.log(role, "role");
  // console.log(adminOn, "adminOn");
  const navigate = useNavigate();
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const [profileClicked, setProfileClicked] = useState(false);
  const [openChangePass, setOpenChangePass] = useState(false);
  const location = useLocation();
  const { fetchOne: fetchSiteInfo } = useSite();
  const { fetchOne: fetchSubCompany } = useSubCompany();
  // const theme = useTheme();
  useEffect(() => {
    if (role === "Site") {
      try {
        fetchSiteInfo(adminOn?.id);
      } catch (error) {
        console.log(error, "error");
      }
    } else if (role == "SubCompany") {
      try {
        fetchSubCompany(adminOn?.id);
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, [user, role, adminOn]);
  useEffect(() => {
    setOpen(window.innerWidth < 1200 ? false : true);
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRightDrawerOpen = (profileFlag = false) => {
    setOpenRightDrawer(true);
    if (profileFlag) setProfileClicked(true);
    else setProfileClicked(false);
  };

  const handleRightDrawerClose = () => {
    setOpenRightDrawer(false);
    setProfileClicked(false);
  };

  // handle detail in drawer
  const handleDetail = () => {
    if (role === "Company") navigate(`/companies/CompanyDetails/${adminOn.id}`);
    if (role === "Site") navigate(`/sites/SiteDetail/${adminOn.id}`);
    if (role === "SubCompany")
      navigate(`/SubCompanies/SubCompanyDetails/${adminOn.id}`);
  };

  // Modal Change Password
  const handleCloseChangePass = () => {
    setOpenChangePass(false);
  };
  const handleOpenChangePass = (user) => {
    setOpenChangePass(true);
  };

  const listItemStyles = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    alignItems: "center",
    px: 2.5,
    marginLeft: 0.5,
    marginRight: 0.5,
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "8px",
    },
  };
  // Notification drawer
  const rightListItemStyles = {
    minHeight: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: openRightDrawer ? "initial" : "center",
    alignItems: "center",
    padding: "4px",
    height: "46px",
    width: "220px",
    borderRadius: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1C1C1C",
    "&.Mui-selected": {
      borderRight: "2px solid",
      borderRightColor: "#88C4D1",
    },
  };

  const listItemIconStyles = {
    minWidth: 0,
    mr: open ? 2 : "auto",
    justifyContent: "center",
    color: "black",
  };
  const rightListItemIconStyles = {
    minWidth: 0,
    mr: openRightDrawer ? 1 : "auto",
    marginTop: profileClicked ? "0" : "-10px",
    color: "black",
  };

  const listItemTextStyles = {
    opacity: open ? 1 : 0,
    span: { fontSize: "14px" },
  };
  const rightListItemTextStyles = {
    opacity: openRightDrawer ? 1 : 0,
    div: { fontSize: "14px", lineHeight: "20px", color: "#1C1C1C" },
    span: { fontSize: "12px", lineHeight: "18px", color: "rgba(0, 0, 0, 0.4)" },
  };

  const RightDrawerHeaders = styled("div")(() => ({
    fontWeight: "bold",
    color: "#1C1C1C",
    fontSize: "14px",
    paddingLeft: "10px",
    opacity: openRightDrawer ? 1 : 0,
  }));
  const Notifications = [
    {
      text: "You have a bug that needs to be fixed.",
      time: "5m ago",
      icon: BugReportIcon,
    },
    {
      text: "New employee registered.",
      time: "1:23 AM",
      icon: PersonOutlineTwoToneIcon,
    },
    {
      text: "You have a bug that needs to be fixed.",
      time: "5m ago",
      icon: BugReportIcon,
    },
    {
      text: "New employee registered.",
      time: "1:23 AM",
      icon: PersonOutlineTwoToneIcon,
    },
  ];
  const Activities = [
    {
      text: "create new company .",
      time: "1:23 AM",
      icon: PersonOutlineTwoToneIcon,
    },
    {
      text: "X deleted xx role for xxx.",
      time: "Yesterday 12:39 AM",
      icon: PersonOutlineTwoToneIcon,
    },
    {
      text: "create new company .",
      time: "1:23 AM",
      icon: PersonOutlineTwoToneIcon,
    },
    {
      text: "X deleted xx role for xxx.",
      time: "Yesterday 12:39 AM",
      icon: PersonOutlineTwoToneIcon,
    },
  ];
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Header
        handleDrawerOpen={handleDrawerOpen}
        handleRightDrawerOpen={handleRightDrawerOpen}
        open={open}
      />

      <Drawer variant="permanent" open={open} data-testid="drawer">
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>

        {/* <Divider /> */}
        <List role="menu" sx={{ mt: 2, height: "100%" }}>
          {role === "Admin" && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/companies"}
              to="/companies"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <AccountBalanceTwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={CompaniesIcon}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="Companies" sx={listItemTextStyles} />
            </ListItemButton>
          )}
          {(role === "Admin" || role === "Company") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/SubCompanies"}
              to="/SubCompanies"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <ApartmentTwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={FolderNotch}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="Sub Companies" sx={listItemTextStyles} />
            </ListItemButton>
          )}
          {role !== "Site" && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/sites"}
              to="/sites"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <BusinessTwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={ShoppingBagOpen}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="Sites" sx={listItemTextStyles} />
            </ListItemButton>
          )}
          {(role === "Admin" || role === "Company" || role === "Site") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/employees"}
              to="/employees"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <Groups2TwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={UsersThree}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="Employees" sx={listItemTextStyles} />
            </ListItemButton>
          )}
          <ListItemButton
            role="menuitem"
            component={Link}
            selected={location.pathname === "/membership"}
            to="/membership"
            sx={listItemStyles}
          >
            <ListItemIcon sx={listItemIconStyles}>
              <BadgeTwoToneIcon color="action" />
              {/* <img
                alt="listicon"
                src={IdentificationCard}
                style={{ width: "24px", height: "24px" }}
              /> */}
            </ListItemIcon>
            <ListItemText primary="Membership" sx={listItemTextStyles} />
          </ListItemButton>
          {(role === "Admin" || role === "Company") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/UserAccounts"}
              to="/UserAccounts"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <RememberMeTwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={IdentificationBadge}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="User Accounts" sx={listItemTextStyles} />
            </ListItemButton>
          )}

          {(role === "Admin" || role === "Company" || role === "Site") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/attendance"}
              to="/attendance"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <DiscountTwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={Notebook}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="Attendance" sx={listItemTextStyles} />
            </ListItemButton>
          )}

          {(role === "Admin" || role === "Company") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/payroll"}
              to="/payroll"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <ReceiptLongTwoToneIcon color="action" />
                {/* <img
                alt="listicon"
                src={Notebook}
                style={{ width: "24px", height: "24px" }}
              /> */}
              </ListItemIcon>
              <ListItemText primary="Payroll" sx={listItemTextStyles} />
            </ListItemButton>
          )}
          {(role === "Admin" || role === "Company") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/payrollDeductions"}
              to="/payrollDeductions"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <MoneyOffCsredTwoToneIcon color="action" />
              </ListItemIcon>
              <ListItemText
                primary="Payroll Deductions"
                sx={listItemTextStyles}
              />
            </ListItemButton>
          )}
          {(role === "Admin" || role === "Company") && (
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/Store"}
              to="/store"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <LocalMallTwoToneIcon color="action" />
              </ListItemIcon>
              <ListItemText primary="Store" sx={listItemTextStyles} />
            </ListItemButton>
          )}
          <ListItemButton
            role="menuitem"
            component={Link}
            selected={location.pathname === "/reports"}
            to="/reports"
            sx={listItemStyles}
          >
            <ListItemIcon sx={listItemIconStyles}>
              <WysiwygTwoToneIcon color="action" />
              {/* <img
                alt="listicon"
                src={Notebook}
                style={{ width: "24px", height: "24px" }}
              /> */}
            </ListItemIcon>
            <ListItemText primary="Reports" sx={listItemTextStyles} />
          </ListItemButton>
        </List>
        {/* <List sx={{ mt: 3 }}>
          <ListItemButton
            role="menuitem"
            component={Link}
            selected={location.pathname === ""}
            to=""
            sx={{
              position: "absolute",
              left: 0,
              bottom: 0,
              marginTop: "10px",
              paddingLeft: "25px",
              backgroundColor: "white",
              listItemStyles,
            }}
            onClick={() => logout()}
          >
            <ListItemIcon sx={listItemIconStyles}>
              <LogoutTwoToneIcon color="action" />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={listItemTextStyles} />
          </ListItemButton>
        </List> */}
      </Drawer>
      {/* Notifications and Activities Drawer */}
      <Drawer
        anchor={"right"}
        variant="permanent"
        open={openRightDrawer}
        sx={{ position: "absolute" }}
        data-testid="drawer"
        id="right"
      >
        {profileClicked && (
          <>
            <DrawerHeader
              sx={{
                direction: "rtl",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <IconButton onClick={handleRightDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
              <RightDrawerHeaders>
                <Typography sx={{ pr: 1 }} variant="Poster" component="h4">
                  {role} Admin
                  {/* {auth?.user?.tokens?.data?.user?.username} */}
                </Typography>
              </RightDrawerHeaders>
            </DrawerHeader>
            <List
              role="menu"
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-8px",
                gap: "8px",
              }}
            >
              {role !== "Admin" && (
                <ListItemButton
                  role="menuitem"
                  sx={rightListItemStyles}
                  onClick={() => handleDetail()}
                >
                  {role === "Company" && (
                    <>
                      <ListItemIcon sx={rightListItemIconStyles}>
                        <AccountBalanceTwoToneIcon color="action" />
                      </ListItemIcon>
                      <ListItemText sx={rightListItemTextStyles}>
                        <div>
                          {adminOn?.companyName} {role}
                        </div>
                      </ListItemText>
                    </>
                  )}
                  {role === "SubCompany" && (
                    <>
                      <ListItemIcon sx={rightListItemIconStyles}>
                        <ApartmentTwoToneIcon color="action" />
                      </ListItemIcon>
                      <ListItemText sx={rightListItemTextStyles}>
                        <div>
                          {adminOn?.subCompanyName} {role}
                        </div>
                      </ListItemText>
                    </>
                  )}
                  {role === "Site" && (
                    <>
                      <ListItemIcon sx={rightListItemIconStyles}>
                        <BusinessTwoToneIcon color="action" />
                      </ListItemIcon>
                      <ListItemText sx={rightListItemTextStyles}>
                        <div>
                          {adminOn?.siteName} {role}
                        </div>
                      </ListItemText>
                    </>
                  )}
                </ListItemButton>
              )}

              <ListItemButton
                role="menuitem"
                sx={rightListItemStyles}
                onClick={() => handleOpenChangePass()}
              >
                <ListItemIcon sx={rightListItemIconStyles}>
                  <LockResetTwoToneIcon color="action" />
                </ListItemIcon>
                <ListItemText sx={rightListItemTextStyles}>
                  <div>Change Password</div>
                </ListItemText>
              </ListItemButton>
              <ListItemButton
                role="menuitem"
                sx={rightListItemStyles}
                onClick={() => {
                  logout();
                  // if(logout())navigate('/login');
                }}
              >
                <ListItemIcon sx={rightListItemIconStyles}>
                  <LogoutTwoToneIcon color="action" />
                </ListItemIcon>
                <ListItemText sx={rightListItemTextStyles}>
                  <div>Logout</div>
                </ListItemText>
              </ListItemButton>
            </List>
          </>
        )}
        {!profileClicked && (
          <>
            <DrawerHeader
              sx={{
                direction: "rtl",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <IconButton onClick={handleRightDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
              <RightDrawerHeaders>Notifications</RightDrawerHeaders>
            </DrawerHeader>
            <List
              role="menu"
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-8px",
                gap: "8px",
              }}
            >
              {Notifications.map((item, index) => {
                const ItemIcon = item.icon;
                return (
                  <ListItemButton
                    key={index}
                    role="menuitem"
                    sx={rightListItemStyles}
                  >
                    <ListItemIcon sx={rightListItemIconStyles}>
                      <ItemIcon
                        color="action"
                        sx={{
                          backgroundColor: "#E5ECF6",
                          borderRadius: 1,
                          p: "1px",
                        }}
                      />
                      {/* <img
                    alt="rightlist"
                    src={item.icon}
                    style={{ width: "24px", height: "24px" }}
                  /> */}
                    </ListItemIcon>
                    <ListItemText sx={rightListItemTextStyles}>
                      <div>{item.text.slice(0, 25)}...</div>
                      <span>{item.time}</span>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>

            <DrawerHeader
              sx={{
                direction: "rtl",
                marginTop: "-8px",
                paddingTop: "10px",
              }}
            >
              <RightDrawerHeaders>Activities</RightDrawerHeaders>
            </DrawerHeader>
            <List
              role="menu"
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-8px",
                gap: "8px",
              }}
            >
              {Activities.map((item, index) => {
                const ItemIcon = item.icon;
                return (
                  <ListItemButton
                    key={index}
                    role="menuitem"
                    sx={rightListItemStyles}
                  >
                    <ListItemIcon sx={rightListItemIconStyles}>
                      <ItemIcon
                        color="action"
                        sx={{
                          backgroundColor: "#E5ECF6",
                          borderRadius: 1,
                          p: "1px",
                        }}
                      />
                      {/* <img
                    alt="rightList"
                    src={item.icon}
                    style={{ width: "24px", height: "24px" }}
                  /> */}
                    </ListItemIcon>
                    <ListItemText sx={rightListItemTextStyles}>
                      <div>{item.text.slice(0, 25)}...</div>
                      <span>{item.time}</span>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </>
        )}
      </Drawer>
      <PassModal
        open={openChangePass}
        type="change"
        handleClose={handleCloseChangePass}
        handleSuccess={() => setOpenSuccess(true)}
      />
      <ModalBox
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        operation="success"
        text="Password changed successfully.. you can now use the new password"
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor: "#f6f7f9",
          minHeight: "100vh",
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default memo(Layout);
