import { Typography } from "@mui/material";
import React from "react";

const ColorCarStyle = ({row}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "25px",
          height: "25px",
          borderRadius: "8px",
          border:"solid 1px gray",
          background: `#${row?.color?.hexValue}`,
        }}
      ></div>
      <Typography variant="caption" component="h6" ml={2}>
        {row?.color?.colorName}
      </Typography>
    </div>
  );
};

export default ColorCarStyle;
