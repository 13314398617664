const fields = [
  {
    name: "companyCode",
    label: "Company Code",
    data: "company",
    required: true,
    validationType: "string",
    type: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
    viewLabel: "company",
    viewLabel2: "companyCode",
  },
  {
    name: "companyName",
    label: "Company Name",
    data: "company",
    required: true,
    validationType: "string",
    type: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
    viewLabel: "company",
    viewLabel2: "companyName",
  },
  {
    name: "webSite",
    label: "Company Website",
    data: "company",
    validationType: "string",
    type: "string",
  },
  {
    name: "email",
    label: "Company Email",
    data: "company",
  },
  {
    name: "tvaPercent",
    label: "TVA Percent",
    data: "company",
    validationType: "number",
    type: "number",
  },
  {
    name: "address",
    label: "Company Address",
    data: "company",
    validationType: "string",
    type: "string",
  },
  {
    name: "contactPerson",
    label: "Contact Person Name",
    data: "company",
    required: true,
    validationType: "string",
    type: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "mobile",
    label: "Company Mobile Number",
    data: "company",
    validationType: "string",
  },
  {
    name: "tvaNumber",
    label: "TVA Number",
    data: "company",
    validationType: "string",
    type: "string",
  },
  {
    name: "isActive",
    label: "Company Status",
    data: "company",
    switch: true,
    required: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
