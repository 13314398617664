const fields = [
  {
    name: "name",
    label: "Material Name",
    value: "name",
  },
  {
    name: "number",
    label: "Material Number",
    value: "number",
  },
  {
    name: "materialCategoryId",
    label: "Stock Material Category",
    value: "materialCategoryId",
    select: true,
    selectData: "materialCategories",
    selectSelector: "name",
    selectValueSelector: "id",
  },
  {
    name: "startQuantity",
    label: "Start Quantity",
    value: "startQuantity",
  },
  {
    name: "currentQuantity",
    label: "Current Quantity",
    value: "currentQuantity",
  },
  {
    name: "unit",
    label: "Unit",
    value: "unit",
    select: true,
    selectData: "unit",
    selectSelector: "desription",
    selectValueSelector: "desription",
  },
  {
    name: "description",
    label: "Description",
    value: "description",
  },
];

export default fields;
