import {
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import SiteName from "./PayrollTable/SiteName";
import { usePayroll } from "./Provider";
import { groupBy } from "core-js/actual/array/group-by";
import { groupByToMap } from "core-js/actual/array/group-by-to-map";

function DetailsPayroll() {
  let { peid } = useParams();
  let theme = useTheme();
  const [selectedCols, setSelectedCols] = useState("");
  const [GroupByItem, setGroupByItem] = useState(0);
  const [GroupedPayrolls, setGroupedPayrolls] = useState([]);
  const {
    state: { selected: payroll },
    fetchOne: fetchPayroll,
  } = usePayroll();

  useEffect(() => {
    fetchPayroll(peid);
  }, [peid, fetchPayroll]);
  useEffect(() => {
    if (GroupByItem === 0) setGroupedPayrolls(payroll?.rows);
  }, [payroll, setGroupedPayrolls]);

  // Style
  const CardComponent = styled(Card)(() => ({
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    width: "fit-content",
    padding: "20px 60px",
    boxShadow: "none",
    borderRadius: "8px",
  }));

  // Functions
  const getTabsGroupedBy = (GroupByItem, item) => {
    if (item && item[1] && item[1][0]) {
      switch (GroupByItem) {
        case 1:
          return [{ title: item[1][0]?.site?.siteName }];
        case 2:
          return [{ title: item[1][0]?.role }];
      }
    }
  };
  const getDataGroupedBy = (item) => {
    if (item[1]) return item[1];
    return [];
  };
  const getCols = (GroupByItem) => {
    switch (GroupByItem) {
      case 0:
        return [
          { label: "Site", name: "site", customRender: SiteName },
          { label: "Role", name: "role" },
          // { label: "Rate", name: "rate" },
          { label: "Duration", name: "duration" },
          { label: "Salary", name: "salary" },
          { label: "Currency", name: "currency" },
        ];
      case 1:
        return [
          { label: "Role", name: "role" },
          // { label: "Rate", name: "rate" },
          { label: "Duration", name: "duration" },
          { label: "Salary", name: "salary" },
          { label: "Currency", name: "currency" },
        ];
      case 2:
        return [
          { label: "Site", name: "site", customRender: SiteName },
          // { label: "Rate", name: "rate" },
          { label: "Duration", name: "duration" },
          { label: "Salary", name: "salary" },
          { label: "Currency", name: "currency" },
        ];
    }
  };
  // Handling
  const changeGroupByItem = (e) => {
    setGroupByItem(e.target.value);
    switch (e.target.value) {
      case 0:
        return setGroupedPayrolls(payroll?.rows);
      case 1:
        return setGroupedPayrolls(
          Object.entries(payroll?.rows?.groupBy((item) => item?.siteId) || [])
        );

      case 2:
        return setGroupedPayrolls(
          Object.entries(payroll?.rows?.groupBy((item) => item?.roleId) || [])
        );
    }
  };
  if (!payroll) return;
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Payroll Details
      </Typography>
      <Box sx={{ ml: 4 }}>
        <CardComponent style={{ backgroundColor: theme.color.PaperbgColor }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h6">
                {payroll?.rows[0]?.employee?.employeeName}
              </Typography>
              {/* <Typography
                variant="span"
                // color="text.secondary"
                component="div"
              >
                200:00 Hours
              </Typography>
              <Typography variant="span" component="div">
                {payroll?.totalSalary} LBP
              </Typography> */}
            </CardContent>
          </Box>
          <CardMedia
            component="img"
            sx={{
              width: 90,
              height: 90,
              mb: 2,
              border: "solid 1px",
              borderRadius: "50%",
            }}
            image="/static/images/cards/live-from-space.jpg"
            alt="Employee Photo"
          />
        </CardComponent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <span style={{ fontSize: "12px", marginBottom: "10px" }}>
              Group by
            </span>
            <Select
              value={GroupByItem}
              onChange={changeGroupByItem}
              size="small"
              inputProps={{
                "aria-label": "Without label",
                autoFocus: true,
              }}
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={1}>Site</MenuItem>
              <MenuItem value={2}>Role</MenuItem>
            </Select>
          </FormControl>
          {/* <TextField
            label={"Group by"}
            name={"groupBy"}
            select={true}
            size="small"
            sx={{ width: "10%" }}
            value={GroupByItem}
            onChange={changeGroupByItem}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={1}>Site</MenuItem>
            <MenuItem value={2}>Role</MenuItem>
          </TextField> */}
        </Box>
        <Box sx={{ mt: 2 }}>
          {GroupByItem === 0 && (
            <Box
              sx={{
                backgroundColor: theme.color.PaperbgColor,
                pt: 1,
                pb: 1,
                width: "95%",
                borderRadius: "5px",
              }}
            >
              <CustomTable
                empty={GroupedPayrolls?.length === 0 ? true : false}
                cols={getCols(GroupByItem)}
                data={GroupedPayrolls && GroupedPayrolls}
                filters={null}
                isPagination={false}
              />
            </Box>
          )}
          {GroupByItem !== 0 &&
            GroupedPayrolls?.map((item, index) => {
              return (
                <Box key={index}>
                  <TabsList
                    tabs={getTabsGroupedBy(GroupByItem, item)}
                    setSelectedCols={setSelectedCols}
                  ></TabsList>
                  <Box
                    mb={4}
                    sx={{
                      backgroundColor: theme.color.PaperbgColor,
                      pt: 1,
                      pb: 2,
                      width: "95%",
                      borderRadius: "5px",
                    }}
                  >
                    <CustomTable
                      empty={
                        getDataGroupedBy(item)?.length === 0 ? true : false
                      }
                      cols={getCols(GroupByItem)}
                      data={getDataGroupedBy(item)}
                      filters={null}
                      isPagination={false}
                    />
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </>
  );
}

export default DetailsPayroll;
