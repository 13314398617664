import React, { useEffect } from "react";
import RoleRateFields from "./RoleRateFields";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../../utilities/yupSchemaCreator";
import { RolesEnum } from "../../../utilities/Enum";
import { useSite } from "../../Sites/Provider";
import { useEmployees } from "../Provider";

// yup schema
const yupSchema = RoleRateFields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
const RoleRateForm = ({
  employee,
  companyId,
  existSite,
  handleAddRateClose,
}) => {
  let theme = useTheme();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { createRoleRate, fetchOne: fetchEmployee } = useEmployees();
  const {
    state: { Names: sites },
    fetchSiteNames: fetchSites,
  } = useSite();
  useEffect(() => {
    fetchSites(employee?.company?.id);
  }, [fetchSites]);

  const getData = (name) => {
    switch (name) {
      case "sites":
        return sites?.filter(
          (s) => !employee?.sites.flatMap((site) => site.siteId).includes(s.id)
        );

      case "roles":
        return !existSite
          ? RolesEnum
          : RolesEnum?.filter(
              (s) =>
                !existSite?.roles
                  .flatMap((role) => role.roleId)
                  .includes(s.roleId)
            );
    }
  };
  useEffect(() => {
    if (existSite) {
      setValue("siteId", existSite.siteId);
    }
  }, [existSite, setValue]);
  //const handleSuccess

  //onSubmit function

  const onSubmit = async (values) => {
    try {
      await createRoleRate(employee?.id, values);
      handleAddRateClose();
      fetchEmployee(employee?.id);
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography
        id="modal-modal-description"
        sx={{ textAlign: "center", fontWeight: 600, mb: 2 }}
      >
        Add New Role with Hourly Rate to specific Site
      </Typography>
      {RoleRateFields.map((f, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            {f.name === "siteId" && existSite.siteId && (
              <div>
                <Typography variant="body2" component="h6" gutterBottom>
                  {f.label}
                </Typography>
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    width: "290px",
                    borderRadius: "8px",
                    marginLeft: "35px",
                    marginBottom: "20px",
                  }}
                >
                  {existSite.siteName}
                </Box>
              </div>
            )}
            {f.select && (
              <>
                <FormControl
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb * 2,
                    display: f.name === "siteId" && existSite.siteId && "none",
                  }}
                  required
                  error={!!errors[f.name]}
                  key={index}
                >
                  <InputLabel id="demo-simple-select-label">
                    {f.label}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={f.label}
                    inputProps={{
                      ...register(f?.name),
                      required: true,
                    }}
                    size="small"
                  >
                    {getData(f.getData)?.map((s, index) => (
                      <MenuItem
                        key={index}
                        value={f.name === "roleId" ? s.roleId : s.id}
                      >
                        {f.name === "roleId" ? s.name : s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {f.number && (
              <TextField
                label={f.label}
                name={f.name}
                error={!!errors[f.name]}
                helperText={errors[f.name]?.message}
                required
                type="number"
                disabled={f.disabled}
                sx={{
                  width: theme.dimensions.width,
                  mt: theme.dimensions.mt,
                  mb: theme.dimensions.mb * 6,
                }}
                inputProps={{
                  ...register(f?.name),
                  required: f.required,
                }}
                size="small"
              ></TextField>
            )}
          </div>
        );
      })}
      <Button
        type="submit"
        sx={{
          background: theme.color.blackBackground,
          color: "white",
          width: "100%",
          mb: 1.3,
          "&:hover": { background: `${theme.color.black}` },
        }}
      >
        save{" "}
      </Button>
    </form>
  );
};

export default RoleRateForm;
