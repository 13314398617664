import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const CategoryStyle = ({row}) => {
  return (
    <div
      style={{
        backgroundColor: "#EDEDFF",
        color: "#8A8CD9",
        borderRadius: "20px",
        opacity: "0.8",
        display: "inline-block",
        width: "80px",
        padding: "3px 5px",
        textAlign: "center",
      }}
    >
      {row?.categoryId === 1 && <>Indoor</>}
      {row?.categoryId === 2 && <>Outdoor</>}
    </div>
  );
};

export default CategoryStyle;
