import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useDeduction() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  count: 1,
  page: 1,
  perPage: 10,
  status: "idle",
  error: null,
  companyIDAtt: null,
  employeeIDAtt: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_companyIDAtt":
      return { ...state, companyIDAtt: action.payload };
    case "set_employeeIDAtt":
      return { ...state, employeeIDAtt: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function DeductionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const fetchList = useCallback(
    async (eid) => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Payroll/EmployeeDeductionList/${eid}`,
        null,
        {},
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data || [] });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data.totalPages });
    },
    [req]
  );
  /** create deduction */
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "Payroll/EmployeeDeductionAdd",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const edit = useCallback(
    async (data, id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Payroll/EmployeeDeductionUpdate/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting ${id}` });
          const resData = await req(
            `Payroll/EmployeeDeductionDelete/${id}`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        edit,
        remove,
      }}
    >
      {children}
    </Context.Provider>
  );
}
