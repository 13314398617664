import { useEffect } from "react";
import styled from "styled-components";
// import List from "./List";

const Wrapper = styled.div`
  p {
    margin: 0;
  }
  dl {
    padding-top: 0;
  }
`;

function parseObjErrors(errors) {
  const fieldErrorsList = (errors) => {
    return errors?.map((e, i) => <dd key={i}>{e}</dd>);
  };

  const fields = Object.keys(errors);
  return fields.map((f, i) => (
    <ul key={i}>
      <li>
        <strong>{f}</strong>
      </li>
      {fieldErrorsList(errors[f])}
    </ul>
  ));
}

function parseYupErrors(errors) {
  const fields = Object.keys(errors);
  console.log(errors, "errors");
  console.log(fields, "fields");
  // [service,site,zone]
  return fields.map((f, i) => {
    if (f === "serviceType") {
      return (
        <ul key={i}>
          <li>
            <strong>{f}</strong>
          </li>
          <dd>{errors[f].message}</dd>
        </ul>
      );
    }
    const subFields = Object.keys(errors[f]);
    //[country,currency,..]
    return subFields.map((subf, subi) => {
      console.log(subf, "subf");
      if (subf === "counter") {
        const counterFields = Object.keys(errors[f][subf]);
        return counterFields.map((cnt, i) => (
          <ul key={i}>
            <li>
              <strong>{cnt}</strong>
            </li>
            <dd>{errors[f][subf][cnt].message}</dd>
          </ul>
        ));
      }
      return (
        <ul key={subi}>
          <li>
            <strong>{subf}</strong>
          </li>
          <dd>{errors[f][subf].message}</dd>
        </ul>
      );
    });
  });
}

function ErrorsList({ error, addSiteYup = false }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper className="error" style={{ fontSize: "12px", color: "red" }}>
      {addSiteYup && error ? (
        parseYupErrors(error)
      ) : (
        <>
          <p>{error?.title}</p>
          {error?.errors && parseObjErrors(error?.errors)}
        </>
      )}
    </Wrapper>
  );
}

export default ErrorsList;
