import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useEmployees() {
  return useContext(Context);
}

const initState = {
  list: [],
  Names: [],
  listSubCompanies: [],
  selected: null,
  selectedfile: null,
  companyIdAtt: 0,
  count: 1,
  length: 0,
  page: 1,
  perPage: 10,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_Names":
      return { ...state, Names: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_selectedfile":
      return { ...state, selectedfile: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_CompanyIdAtt":
      return { ...state, companyIdAtt: action.payload };
    case "set_length":
      return { ...state, length: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_listSubCompanies":
      return { ...state, listSubCompanies: [...action.payload] };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function EmployeesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (
      cid = state.companyIdAtt,
      sid,
      page = state.page,
      perPage = state.perPage,
      search
    ) => {
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      if (search) headersData.search = search;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Employee/List/${cid}, ${sid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.employees || [] });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data.totalPages });
      dispatch({ type: "set_length", payload: resData.data.totalSize });
    },
    [req]
  );
  /** fetch employees names */
  const fetchNames = useCallback(
    async (cid = state.companyIdAtt, sid) => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Employee/Names/${cid}, ${sid}`,
        null,
        {},
        true,
        {}
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_Names", payload: resData.data || [] });
    },
    [req]
  );
  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Employee/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "Employee/Create",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Employee/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          // console.log(resData)
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  /**create role rate */
  const createRoleRate = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Employee/UpdateRole/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          // console.log(resData)
          resolve(resData);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  /**update role rate */

  const editRoleRate = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Employee/UpdateRole/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  /**delete role rate */
  const removeRoleRate = useCallback(
    async (id, sid, rid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `deleting` });
        try {
          const resData = await req(
            `Employee/DeleteRole/${id}, ${sid}, ${rid}`,
            data,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const uploadEmployeeContract = useCallback(
    async (data, id) => {
      let formData = new FormData();
      formData.append("File", data);
      formData.append("id", id);
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/EmployeeContract",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );
  const uploadPhoto = useCallback(
    async (data, id) => {
      let formData = new FormData();
      // console.log(data)
      formData.append("file", data);
      formData.append("id", id);
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/Photo",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `deleting` });
        try {
          const resData = await req(
            `Employee/Delete/${id}`,
            data,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: id });

          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        fetchNames,
        fetchOne,
        create,
        edit,
        dispatch,
        remove,
        uploadPhoto,
        uploadEmployeeContract,
        editRoleRate,
        removeRoleRate,
        createRoleRate,
      }}
    >
      {children}
    </Context.Provider>
  );
}
