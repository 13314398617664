const hourlyFields = [
  {
    name1: "company",
    name: "companyName",
    label: "Company Name",
    disable: true,
    value: "companyName",
  },
  {
    name1: "site",
    name: "siteName",
    label: "Site Name",
    required: true,
    disable: true,
    validationType: "number",
  },
  {
    name: "title",
    label: "Zone Name",
    disable: true,
  },
  {
    name: "title",
    label: "Service Name",
  },
  {
    name: "serviceTypeId",
    label: "Service Type",
    select: true,
    contentName: "name",
    validationType: "number",
    data: "name",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "isActive",
    label: "Service Status",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "freePeriod",
    label: "Free Period",
    type: "number",
  },
  {
    name: "period1",
    label: "Period 1",
    required: true,
    section2: true,
    type: "number",
  },
  {
    name: "hours1",
    label: "Hour 1",
    section2: true,
    type: "number",
  },
  {
    name: "price1Trial",
    label: "Price 1 Trial",
    section2: true,
  },
  {
    name: "price1Official",
    label: "Price 1 Official",
    section2: true,
  },
  {
    name: "period2",
    label: "Period 2",
    section2: true,
    type: "number",
  },
  {
    name: "hours2",
    label: "Hour 2",
    section2: true,
    type: "number",
  },
  {
    name: "price2Trial",
    label: "Price 2 Trial",
    section2: true,
  },
  {
    name: "price2Official",
    label: "Price 2 Official",
    section2: true,
  },
  {
    name: "hours3",
    label: "Hour 3",
    section2: true,
    type: "number",
  },
  {
    name: "price3Trial",
    label: "Price 3 Trial",
    section2: true,
  },
  {
    name: "price3Official",
    label: "Price 3 Official",
    section2: true,
  },
  {
    name: "printDivision1",
    label: "Print Division 1",
    type: "number",
    section: true,
    printDivision: true,
  },
  {
    name: "printDivision2",
    label: "Print Division 2",
    type: "number",
    section: true,
    printDivision: true,
  },
  {
    name: "printDivision3",
    label: "Print Division 3",
    type: "number",
    section: true,
    printDivision: true,
  },
  {
    name: "printDivision4",
    label: "Print Division 4",
    type: "number",
    section: true,
    printDivision: true,
  },
];

export default hourlyFields;
