import React from "react";
import FormDeduction from "./FormDeduction";

const UpdateDeduction = () => {
  return (
    <div>
      update deduction
      <FormDeduction />
    </div>
  );
};

export default UpdateDeduction;
