// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// YUP
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// Fields
import fields from "../fields";
// Material UI
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  useTheme,
  Card,
  Alert,
} from "@mui/material";
// Third Party Components
import ErrorsList from "../../../../ui/ErrorsList";
// Provider
import { useMaterial } from "../Provider";
import { useMaterialCategory } from "../../Categories/Provider";
// Styled Component
import styled from "styled-components";
// Enum &&  Utilities
import { Units } from "../../../../utilities/Enum";
import { handleResponse } from "../../../../utilities/functions";

const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
// yup schema
const formSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .max(200, "must be less than or equal to 100 character"),
  number: Yup.string()
    .required("this field is required")
    .max(20, "must be less than or equal to 100 character"),
  materialCategoryId: Yup.number().required("this field is required"),
  startQuantity: Yup.number().required("this field is required"),
  currentQuantity: Yup.number().required("this field is required"),
  unit: Yup.string()
    .required("this field is required")
    .max(10, "must be less than or equal to 100 character"),
  description: Yup.string().max(
    400,
    "must be less than or equal to 100 character"
  ),
});

const Form = ({ item, handleSuccess, setItem }) => {
  // providers
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { create, edit } = useMaterial();
  const {
    state: { list: materialCategories },
    fetchList: fetchMaterialCategories,
  } = useMaterialCategory();
  const navigate = useNavigate();
  let theme = useTheme();
  // vars
  let params = useParams();
  const id = params.id;
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchMaterialCategories();
  }, [fetchMaterialCategories]);

  // on submit
  const onSubmit = async (values) => {
    console.log(values, "done submit");
    try {
      if (item) {
        // edit
        await edit(id, values);
      } else {
        // add
        await create(values);
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 404) setError({ title: e.errors.message });
      else setError({ title: e.errors.message });
      handleResponse(e.errors.status);
    }
  }; // Fields functions
  const getSelectData = (name) => {
    switch (name) {
      case "unit":
        return Units;
      case "materialCategories":
        return materialCategories;
      default:
        return;
    }
  };

  // if (!item) return;
  return (
    <>
      <Box>
        {/* Body */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <Typography variant="Poster" component="h4" gutterBottom>
              Material Details
            </Typography>
            {error && (
              <Alert severity="error">
                <ErrorsList error={error} />
              </Alert>
            )}
            <GridGroup>
              {fields?.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;
                inputProps.type = f.type;
                return (
                  <Box key={i}>
                    {
                      <TextField
                        label={!f.hasValue && f.label}
                        name={f.name}
                        select={f.select}
                        size="small"
                        className={f.class}
                        error={!!errors[f.name]}
                        helperText={errors[f.name]?.message}
                        defaultValue={item && item[f?.name]}
                        fullWidth
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        disabled={f.disabled}
                        required={f.required}
                        inputProps={{
                          ...register(f?.name),
                        }}
                      >
                        {f.select &&
                          getSelectData(f.selectData)?.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={item[f?.selectValueSelector]}
                              >
                                {item[f?.selectSelector]}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    }
                  </Box>
                );
              })}
            </GridGroup>
            {/* End Body */}
            {/* Buttons */}
            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                {item ? "Save" : "Add Material"}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(Form);
