//react component
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//context we need
import { useContract } from "./Provider";
//form and yup
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
//ui component
import CustomTable from "../../ui/CustomTable";

const ListContracts = ({ id }) => {
  const {
    state: { list: contracts },
    fetchList: fetchContracts,
    dispatch,
  } = useContract();

  const cols = [
    { label: "File Name", name: "fileName" },
    { label: "Start Date", name: "startDate" },
    { label: "End Date", name: "endDate" },
    { label: "First Reminder", name: "firstReminder" },
    { label: "Second Reminder", name: "secondReminder" },
    { label: "Contracts Status", name: "isActive" },
  ];
  //useEffect
  useEffect(() => {
    fetchContracts(id);
  }, [fetchContracts]);
  if (!contracts) return;
  return (
    <div>
      <CustomTable
        empty={contracts?.length === 0 ? true : false}
        cols={cols}
        data={contracts}
        showButton
      ></CustomTable>
    </div>
  );
};

export default React.memo(ListContracts);
