import React, { useState } from "react";
// Hooks
import { useForm } from "react-hook-form";
// YUP
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// material ui
import {
  Box,
  Typography,
  Modal,
  Button,
  useTheme,
  TextField,
  Alert,
} from "@mui/material";
import styled from "styled-components";
import ErrorsList from "./ErrorsList";
import { handleResponse } from "../utilities/functions";
import { useSite } from "../view/Sites/Provider";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 22px;
  padding: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 2,
};

const changeSchema = Yup.object().shape({});
const HourlyModal = ({ open = false, handleClose = () => {}, data = [] }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  let theme = useTheme();
  const { updateRole } = useSite();
  const { register, reset, getValues } = useForm({
    resolver: yupResolver(changeSchema),
  });
  const handleRoleAdd = async (id, role, hourlyRate) => {
    console.log({ roleId: role, hourlyRate: hourlyRate }, "values");
    if (hourlyRate === "") {
      setError({
        title: (
          <span>
            Please fill the <strong>hourly role rate</strong>
          </span>
        ),
      });
      setSuccess();
      return;
    }
    if (Number(hourlyRate) < 0) {
      setError({ title: "Hourly rate must be greater than zero" });
      setSuccess();
      return;
    }
    try {
      await updateRole({ roleId: role, hourlyRate: hourlyRate }, id);
      reset();
      setSuccess("Added Successfully");
      setError();
    } catch (e) {
      setError({ title: e.errors.message });
      setSuccess();
      handleResponse(e.errors.status);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ p: 20 }}
    >
      <Box sx={style}>
        {error && (
          <Alert severity="error" sx={{ mb: 1, width: "100%" }}>
            <ErrorsList error={error} />
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 1, width: "100%" }}>
            {success}
          </Alert>
        )}
        <Box>
          {data &&
            data?.map((site) => {
              return (
                <>
                  <Typography variant="Poster" component="h3" gutterBottom>
                    {site.site.siteName}
                  </Typography>
                  <GridGroup>
                    {site?.roles?.map((role, index) => {
                      return (
                        <>
                          <TextField
                            label="Role"
                            size="small"
                            name={`${site.site.siteName}${role}`}
                            value={role}
                            fullWidth
                            sx={{ width: 200 }}
                          ></TextField>
                          <TextField
                            label="Hourly Rate"
                            size="small"
                            type="number"
                            name={`${site.site.siteName}${index}`}
                            fullWidth
                            sx={{ width: 200 }}
                            required={true}
                            inputProps={{
                              ...register(`hourlyRate${role}${site.siteId}`),
                            }}
                          ></TextField>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleRoleAdd(
                                site.siteId,
                                role,
                                getValues(`hourlyRate${role}${site.siteId}`)
                              )
                            }
                            sx={{
                              backgroundColor: theme.color.blackBackground,
                              "&:hover": { background: `${theme.color.black}` },
                              height: "fit-content",
                            }}
                          >
                            Add Role
                          </Button>
                        </>
                      );
                    })}
                  </GridGroup>
                </>
              );
            })}
        </Box>

        <Button
          onClick={handleClose}
          sx={{
            background: theme.color.gray200,
            color: "black",
            width: "60%",
            "&:hover": { background: `${theme.color.gray300}` },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default React.memo(HourlyModal);
