const siteFields = [
  {
    name: "companyName",
    label: "Company Name",
    data: "sites",
    registerName: "companyName",
    xs: 4,
    disabled: true,
    value: "companyName",
    hasValue: true,
    viewLabel: "company",
    viewLabel2: "companyName",
  },
  {
    name: "country",
    viewLabel: "country",
    label: "Country",
    data: "sites",
    errorFields: ["site", "country"],
    required: true,
    error: "site",
    registerName: "site.country",
    xs: 4,
    select: true,
  },

  {
    name: "currency",
    label: "Currency",
    data: "sites",
    errorFields: ["site", "currency"],
    required: true,
    error: "site",
    registerName: "site.currency",
    xs: 4,
    select: true,
  },
  {
    label: "Site ID",
    name: "id",
    viewOnly: true,
  },
  {
    name: "siteName",
    label: "Site Name",
    data: "sites",
    errorFields: ["site", "siteName"],
    required: true,
    error: "site",
    registerName: "site.siteName",
    xs: 4,
  },
  {
    name: "address",
    label: "Site Address",
    data: "sites",
    registerName: "site.address",
    xs: 4,
  },
  {
    name: "mobile",
    label: "Mobile Number",
    data: "sites",
    errorFields: ["site", "mobile"],
    registerName: "site.mobile",
    xs: 4,
  },
  {
    name: "latitude",
    label: "Latitude",
    placeholder: "xx.xxx",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "site.latitude",
    xs: 2,
  },
  {
    name: "longitude",
    label: "Longitude",
    placeholder: "xx.xxx",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "site.longitude",
    xs: 2,
  },
  {
    name: "loginRange",
    label: "Login Range",
    data: "sites",
    errorFields: ["site", "loginRange"],
    type: "number",
    defaultValue: 0,
    registerName: "site.loginRange",
    xs: 2,
    min: 0,
    viewLabel: "loginRange",
    viewLabel2: "loginRange",
  },
  {
    name: "capacity",
    label: "Capacity",
    data: "sites",
    errorFields: ["site", "capacity"],
    type: "number",
    defaultValue: 0,
    registerName: "site.capacity",
    xs: 2,
    min: 0,
    max: 1000,
    viewLabel: "capacity",
    viewLabel2: "capacity",
  },
  {
    name: "vat",
    label: "Vat",
    placeholder: "xx.xxx",
    type: "number",
    defaultValue: 0,
    registerName: "site.vat",
  },
  {
    name: "includedVat",
    label: "Vat Rate",
    registerName: "site.includedVat",
    switch: true,
    divider: true,
  },
  {
    name: "counterNo",
    label: "Counter No",
    viewOnly: true,
    data: "sites",
    errorFields: ["site", "counter", "counterNo"],
    type: "number",
    defaultValue: 0,
    registerName: "site.counter.counterNo",
    xs: 2,
    min: 0,
    viewLabel: "counter",
    viewLabel2: "counterNo",
    icon: true,
  },
  {
    name: "counterMaxvalue",
    label: "Counter Max Value",
    data: "sites",
    errorFields: ["site", "counter", "counterMaxvalue"],
    type: "number",
    defaultValue: 0,
    registerName: "site.counter.counterMaxvalue",
    xs: 2,
    min: 0,
    viewLabel: "counter",
    viewLabel2: "counterMaxvalue",
  },
  {
    name: "counterRenwalTime",
    label: "Counter Renewal Time",
    placeholder: "HH:MM",
    data: "sites",
    viewLabel: "counter",
    viewLabel2: "counterRenwalTime",
    errorFields: ["site", "counter", "counterRenwalTime"],
    registerName: "site.counter.counterRenwalTime",
    xs: 2,
  },
  {
    name: "openTime",
    label: "Open Time",
    placeholder: "HH:MM",
    data: "sites",
    errorFields: ["site", "openTime"],
    required: true,
    error: "site",
    registerName: "site.openTime",
    xs: 2,
  },
  {
    name: "openDuration",
    label: "Open Duration",
    placeholder: "HH:MM",
    data: "sites",
    required: true,
    error: "site",
    // defaultValue: 60,
    // type: "number",
    errorFields: ["site", "openDuration"],
    registerName: "site.openDuration",
    xs: 2,
    min: 60,
    max: 1440,
  },
  {
    name: "printTitle",
    label: "Print Title",
    data: "sites",
    registerName: "site.printTitle",
    xs: 4,
    longText: true,
  },
  {
    name: "printText",
    label: "Print Text",
    data: "sites",
    registerName: "site.printText",
    xs: 4,
    longText: true,
  },
  {
    name: "printFooter",
    label: "Print Footer",
    data: "sites",
    registerName: "site.printFooter",
    xs: 4,
    longText: true,
  },
  {
    name: "complaintText",
    label: "Complaint Text",
    data: "sites",
    registerName: "site.complaintText",
    errorFields: ["site", "complaintText"],
    xs: 4,
    longText: true,
  },
  {
    name: "smsMobile",
    label: "SMS Mobile",
    data: "sites",
    errorFields: ["site", "smsMobile"],
    registerName: "site.smsMobile",
    xs: 4,
  },
  {
    name: "inHouse",
    label: "In House Service",
    data: "sites",
    registerName: "site.inHouse",
    xs: 4,
    switch: true,
    divider: true,
  },
  {
    name: "siteIsActive",
    label: "Site Status",
    data: "sites",
    registerName: "site.isActive",
    xs: 4,
    switch: true,
    divider: true,
  },
  {
    name: "countryCode",
    viewLabel: "country Code",
    label: "Country Code",
    data: "sites",
    errorFields: ["site", "countryCode"],
    required: true,
    error: "site",
    registerName: "site.countryCode",
  },
];
const zoneFields = [
  {
    name: "siteZoneName",
    label: "Site Zone Name",
    data: "sites",
    errorFields: ["zone", "title"],
    required: true,
    notEditable: true,
    error: "zone",
    registerName: "zone.title",
    xs: 4,
    editOnly: true,
  },
  {
    name: "servicesType",
    label: "Services Type",
    data: "sites",
    errorFields: ["serviceType"],
    registerName: "serviceType",
    xs: 4,
    select: true,
    required: true,
    notEditable: true,
    editOnly: true,
    menuItems: [
      { key: 1, value: "Fixed Services" },
      { key: 2, value: "Hourly Services" },
    ],
  },
  // COMMON FIELDS
  {
    name: "freePeriod",
    label: "Free Period",
    data: "sites",
    errorFields: ["service", "freePeriod"],
    type: "number",
    defaultValue: 0,
    registerName: "service.freePeriod",
    xs: 4,
    notEditable: true,
    fixed: true,
    hourly: true,
    editOnly: true,
  },
  {
    name: "title",
    label: "Service Name",
    data: "sites",
    errorFields: ["service", "title"],
    registerName: "service.title",
    xs: 4,
    notEditable: true,
    required: true,
    fixed: true,
    hourly: true,
    editOnly: true,
  },
  {
    name: "serviceTypeId",
    label: "Service Type",
    data: "sites",
    errorFields: ["service", "serviceTypeId"],
    registerName: "service.serviceTypeId",
    xs: 4,
    notEditable: true,
    required: true,
    fixed: true,
    hourly: true,
    select: true,
    editOnly: true,
  },
  {
    name: "serviceIsActive",
    label: "Services Status",
    data: "sites",
    registerName: "service.isActive",
    xs: 4,
    switch: true,
    notEditable: true,
    fixed: true,
    hourly: true,
    editOnly: true,
  },
];
const zoneFields2 = [
  {
    name: "printDivision1",
    label: "Print Division 1",
    data: "sites",
    errorFields: ["service", "printDivision1"],
    type: "number",
    defaultValue: 0,
    registerName: "service.printDivision1",
    xs: 2,
    notEditable: true,
    width: "126px",
    fixed: true,
    hourly: true,
    editOnly: true,
  },
  {
    name: "printDivision2",
    label: "Print Division 2",
    data: "sites",
    errorFields: ["service", "printDivision2"],
    type: "number",
    defaultValue: 0,
    registerName: "service.printDivision2",
    xs: 2,
    notEditable: true,
    width: "126px",
    fixed: true,
    hourly: true,
    editOnly: true,
  },
  {
    name: "printDivision3",
    label: "Print Division 3",
    data: "sites",
    errorFields: ["service", "printDivision3"],
    type: "number",
    defaultValue: 0,
    registerName: "service.printDivision3",
    xs: 2,
    notEditable: true,
    width: "126px",
    fixed: true,
    hourly: true,
    editOnly: true,
  },
  {
    name: "printDivision4",
    label: "Print Division 4",
    data: "sites",
    errorFields: ["service", "printDivision4"],
    type: "number",
    defaultValue: 0,
    registerName: "service.printDivision4",
    xs: 2,
    notEditable: true,
    width: "126px",
    fixed: true,
    hourly: true,
    editOnly: true,
  },
];
// Fixed and Hourly Services fields
const fixedFields = [
  {
    name: "fixPriceTrial",
    label: "Fix Price Trial",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.fixPriceTrial",
    xs: 4,
    fixed: true,
    min: 0,
    notEditable: true,
    required: true,
    editOnly: true,
  },
  {
    name: "fixPriceOfficial",
    label: "Fix Price Official",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.fixPriceOfficial",
    xs: 4,
    fixed: true,
    min: 0,
    notEditable: true,
    required: true,
    editOnly: true,
  },
];
const hourlyFields = [
  {
    name: "period1",
    label: "Period 1",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.period1",
    xs: 2,
    width: "126px",
    hourly: true,
    min: 0,
    max: 24,
    notEditable: true,
    required: true,
    editOnly: true,
  },
  {
    name: "hours1",
    label: "Hour 1",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.hours1",
    xs: 2,
    width: "126px",
    hourly: true,
    notEditable: true,
    required: true,
    min: 0,
    max: 24,
    editOnly: true,
  },
  {
    name: "price1Trial",
    label: "Price 1 Trial",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.price1Trial",
    xs: 4,
    width: "250px",
    hourly: true,
    notEditable: true,
    required: true,
    min: 0,
    editOnly: true,
  },
  {
    name: "price1Official",
    label: "Price 1 Official",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.price1Official",
    xs: 4,
    width: "250px",
    hourly: true,
    notEditable: true,
    required: true,
    min: 0,
    editOnly: true,
  },
  {
    name: "period2",
    label: "Period 2",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.period2",
    xs: 2,
    width: "126px",
    hourly: true,
    notEditable: true,
    dependentdisable2: true,
    min: 0,
    max: 24,
    editOnly: true,
  },
  {
    name: "hours2",
    label: "Hour 2",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.hours2",
    xs: 2,
    width: "126px",
    hourly: true,
    notEditable: true,
    dependentdisable2: true,
    min: 0,
    max: 24,
    editOnly: true,
  },
  {
    name: "price2Trial",
    label: "Price 2 Trial",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.price2Trial",
    xs: 4,
    width: "250px",
    hourly: true,
    notEditable: true,
    dependentdisable2: true,
    min: 0,
    editOnly: true,
  },
  {
    name: "price2Official",
    label: "Price 2 Official",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.price2Official",
    xs: 4,
    width: "250px",
    notEditable: true,
    dependentdisable2: true,
    hourly: true,
    min: 0,
    editOnly: true,
  },
  {
    alignment: true,
  },
  {
    name: "hours3",
    label: "Hour 3",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.hours3",
    xs: 2,
    width: "126px",
    hourly: true,
    notEditable: true,
    dependentdisable3: true,
    min: 0,
    max: 24,
    editOnly: true,
  },
  {
    name: "price3Trial",
    label: "Price 3 Trial",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.price3Trial",
    xs: 4,
    width: "250px",
    hourly: true,
    notEditable: true,
    dependentdisable3: true,
    min: 0,
    editOnly: true,
  },
  {
    name: "price3Official",
    label: "Price 3 Official",
    data: "sites",
    type: "number",
    defaultValue: 0,
    registerName: "service.price3Official",
    xs: 4,
    width: "250px",
    hourly: true,
    notEditable: true,
    dependentdisable3: true,
    min: 0,
    editOnly: true,
  },
];
export { siteFields, zoneFields, zoneFields2, fixedFields, hourlyFields };
