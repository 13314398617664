import React from 'react'

const StyledSite = ({row}) => {
  return (
    <>
    {row?.site?.siteName}  
    </>
  )
}

export default StyledSite
