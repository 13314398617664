import React from "react";

const TotalAmount = ({ row }) => {
  return (
    <div>
      {row.payments.map((item, index) => (
        <div>{item.totalAmount}</div>
      ))}
    </div>
  );
};

export default TotalAmount;
