// Providers
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSite } from "../../Sites/Provider";
import { useZone } from "../Provider";
// Yup
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box } from "@mui/system";
import {
  Switch,
  TextField,
  Typography,
  Card,
  Button,
  FormGroup,
  FormControlLabel,
  useTheme,
  FormLabel,
} from "@mui/material";
import styled from "styled-components";
// UI
import ErrorsList from "../../../ui/ErrorsList";
// Utilities
import { handleResponse } from "../../../utilities/functions";
// Data
import fields from "./addZoneFields";
// Style
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormZone = ({ handleSuccess, setZoneName, formSchema }) => {
  // providers
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: site },
    fetchOne: fetchSite,
  } = useSite();
  const { create } = useZone();
  const navigate = useNavigate();
  let theme = useTheme();
  let params = useParams();
  // vars
  const id = params.id;
  const [error, setError] = useState(null);

  // use effects
  useEffect(() => {
    fetchSite(id);
  }, [id, fetchSite]);

  // on submit
  const onSubmit = async (values) => {
    delete values.companyName;
    values.siteId = id;
    try {
      await create(values);
      setZoneName(values.title);
      reset();
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      if (e.status === 409) setError({ title: "Zone Already exists" });
      if (e.status === 500) setError({ title: "Internal Server Error" });
      handleResponse(e.status);
    }
  };
  const getName = (f) => {
    if (f.label === "Company Name") return site?.company?.companyName;
    else if (f?.label === "Site Name") return site?.siteName;
    return {};
  };
  return (
    <>
      <Box>
        {/* Body */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <Typography variant="Poster" component="h4" gutterBottom>
              Zone Details
            </Typography>
            <ErrorsList error={error} />
            <GridGroup>
              {fields?.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;
                inputProps.type = f.type;
                return (
                  <Box key={i}>
                    {f.hasValue ? (
                      <Box
                        inputProps={{
                          ...register(f?.registerName),
                        }}
                      >
                        <Typography variant="body2" component="h6" gutterBottom>
                          {f.label}
                        </Typography>
                        <Box
                          sx={{
                            borderBottom: "1px solid gray",
                            width: "290px",
                            borderRadius: "8px",
                            textAlign: "center",
                          }}
                        >
                          {getName(f)}
                        </Box>
                        {/* <hr style={{ marginRight: "80px" }} /> */}
                      </Box>
                    ) : f.switch ? (
                      <FormGroup>
                        <FormLabel
                          component="legend"
                          sx={{
                            mt: theme.dimensions.mt,
                          }}
                        >
                          {f.label}
                        </FormLabel>
                        <FormControlLabel
                          sx={{
                            mt: theme.dimensions.mt * 2,
                          }}
                          control={
                            <Switch color="success" defaultChecked={true} />
                          }
                          {...register(f?.registerName)}
                          required={f.required}
                          label="Active"
                        />
                      </FormGroup>
                    ) : (
                      <TextField
                        label={f.label}
                        select={f.select}
                        size="small"
                        type={f.type}
                        name={f.name}
                        helperText={errors[f.name]?.message}
                        error={!!errors[f.name]}
                        fullWidth
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        defaultValue={f.defaultValue}
                        placeholder={f.placeholder}
                        required={f.required}
                        inputProps={{
                          ...register(f?.registerName),
                        }}
                      ></TextField>
                    )}
                  </Box>
                );
              })}
            </GridGroup>
            {/* End Body */}
            {/* Buttons */}
            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                Add Zone
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(FormZone);
