import {
  Alert,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorsList from "../../../ui/ErrorsList";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../../utilities/yupSchemaCreator";
import { useForm } from "react-hook-form";
import fields from "./fields";
import styled from "styled-components";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FilterList } from "@mui/icons-material";
import { useCompany } from "../../Companies/Provider";
import { useMemory } from "./Provider";
import { useSite } from "../../Sites/Provider";
import { useEmployees } from "../../employee/Provider";
import { useVendor } from "../Vendors/Provider";
import { useAuth } from "../../../services/auth";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

const locales = ["en-gb"];
// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

const GridGroup = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-template-rows: 70px;
  grid-column-gap: 10px;
  background: #f7f9fb;
`;
const GridGroup2 = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 10px;
  grid-column-gap: 1px;
  background: #f7f9fb;
`;
function FilterForm() {
  let theme = useTheme();

  const { role, adminOn } = useAuth();

  const [locale, setLocale] = useState("en-gb");

  const [Error, setError] = useState("");
  const [companyID, setcompanyID] = useState(0);
  const [siteId, setSiteId] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      cid: 0,
      typeId: 0,
      vendorId: 0,
      employeeId: 0,
    },
  });

  /**get companies */
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  /**get resources from memory provider */
  const {
    state: { typeList, CompanyId, page, perPage },
    fetchTypeList,
    fetchList: fetchMemoList,
    dispatch,
  } = useMemory();
  useEffect(() => {
    fetchMemoList(getValues(), page, perPage);
  }, [page, perPage]);
  /**get resources from site provider */
  const {
    state: { Names: Sites },
    fetchSiteNames: fetchSites,
  } = useSite();
  /**get resources from employee provider */
  const {
    state: { Names: Employees },
    fetchNames: fetchEmployees,
  } = useEmployees();
  /**get vendors */
  const {
    state: { list: listVendors },
    fetchList: fetchVendors,
  } = useVendor();
  /**invoke companies  */
  useEffect(() => {
    if (role === "Admin") {
      fetchCompany();
    }
  }, [role]);

  /**invoke types */
  useEffect(() => {
    fetchTypeList();
  }, []);
  /**invoke Sites */
  useEffect(() => {
    if (role === "Admin") {
      if (companyID && companyID !== -1) fetchSites(companyID, 0);
    } else if (role === "Company") {
      fetchSites(adminOn?.id, 0);
    }
  }, [companyID]);
  /**invoke Employees */
  useEffect(() => {
    if (role === "Admin") {
      if (siteId) fetchEmployees(companyID, siteId);
    } else if (role === "Company") {
      if (siteId) fetchEmployees(adminOn?.id, siteId);
    }
  }, [siteId]);
  /**invoke vendors */
  useEffect(() => {
    if (role === "Admin") {
      fetchVendors({ companyId: -1 });
    } else if (role === "Company") {
      if (adminOn?.id) fetchVendors({ companyId: adminOn?.id });
    }
  }, [companyID]);
  const getData = (name) => {
    switch (name) {
      case "cid":
        return [
          { id: -1, name: "No Company" },
          { id: 0, name: "All Companies" },
          ...companies,
        ];
        break;
      case "typeId":
        return typeList;
      case "siteId":
        return Sites;
        break;
      case "employeeId":
        return Employees;
        break;
      case "vendorId":
        return listVendors;
      default:
        break;
    }
  };
  /**value of item */
  const getValue = (name) => {
    switch (name) {
      case "cid":
        return "name";
        break;
      case "typeId":
        return "description";
      case "siteId":
        return "name";
      case "employeeId":
        return "name";
      case "vendorId":
        return "name";
      default:
        break;
    }
  };
  useEffect(() => {
    if (role === "Company") setValue("cid", adminOn?.id);
  }, [role]);

  const onSubmit = async (values) => {
    values.vendorId
      ? (values.vendorId = values.vendorId)
      : (values.vendorId = 0);
    values.siteId ? (values.siteId = values.siteId) : (values.siteId = 0);
    values.employeeId
      ? (values.employeeId = values.employeeId)
      : (values.employeeId = 0);
    try {
      await fetchMemoList(values, page, perPage);
    } catch (error) {
      setError({ title: error.errors.message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box>
        {Error && (
          <Alert severity="error">
            <ErrorsList error={Error} />
          </Alert>
        )}
      </Box>
      <Box sx={{ background: "#f7f9fb", padding: "20px", mt: 1, width: "85%" }}>
        <GridGroup>
          {fields?.map((f, index) => (
            <React.Fragment key={index}>
              {role === "Company" && f.name === "cid" && (
                <Box>
                  <span style={{ fontSize: "12px" }}>Company</span>

                  <Box
                    sx={{
                      borderBottom: "1px solid gray",
                      width: "290px",
                      borderRadius: "8px",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {adminOn?.companyName}
                  </Box>
                </Box>
              )}
              {f.select && (
                <FormControl
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb,
                    display:
                      role === "Admin"
                        ? watch("typeId") !== 1 && f.name === "vendorId"
                          ? "none"
                          : null ||
                            (watch("typeId") === 1 && f.name === "siteId")
                          ? "none"
                          : null ||
                            (watch("typeId") === 1 && f.name === "employeeId")
                          ? "none"
                          : null
                        : role === "Company"
                        ? f.name === "cid"
                          ? "none"
                          : null ||
                            (watch("typeId") !== 1 && f.name === "vendorId")
                          ? "none"
                          : null ||
                            (watch("typeId") === 1 && f.name === "siteId")
                          ? "none"
                          : null ||
                            (watch("typeId") === 1 && f.name === "employeeId")
                          ? "none"
                          : null
                        : null,
                  }}
                >
                  <span style={{ fontSize: "12px" }}>{f.label}</span>
                  <Select
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                    error={!!errors[f.name]}
                    onChange={
                      role === "Admin"
                        ? f.name === "cid"
                          ? (e) => setcompanyID(e.target.value)
                          : f.name === "siteId"
                          ? (e) => setSiteId(e.target.value)
                          : null || f.name === "typeId"
                          ? (e) => {
                              setValue("siteId", 0);
                              setValue("employeeId", 0);
                              setValue("vendorId", 0);
                            }
                          : null
                        : f.name === "cid"
                        ? null
                        : f.name === "siteId"
                        ? (e) => setSiteId(e.target.value)
                        : null
                    }
                    inputProps={{
                      ...register(f?.name),
                    }}
                    defaultValue={0}
                    size="small"
                  >
                    {getData(f.name).map((item, index) => (
                      <MenuItem value={item.id}>
                        {item?.[getValue(f.name)]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </React.Fragment>
          ))}
        </GridGroup>
        <GridGroup2>
          {fields.map((f, index) => (
            <React.Fragment key={index}>
              {f.date && (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}
                >
                  <FormControl
                    sx={{
                      width: f.time
                        ? theme.dimensions.width / 3
                        : theme.dimensions.width,
                      mb: theme.dimensions.mb,
                    }}
                    components={["DatePicker"]}
                  >
                    <span style={{ fontSize: "12px" }}>{f.label}</span>

                    <DateTimePicker
                      onChange={(date) => setValue(f.name, date)}
                      error={!!errors[f.name]}
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      slotProps={{
                        textField: {
                          required: f.required,
                          error: errors[f.name]?.message,
                          helperText: (
                            <p style={{ color: "#d32f2f" }}>
                              {errors[f.name]?.message}
                            </p>
                          ),
                          size: "small",
                        },
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
            </React.Fragment>
          ))}
          <Box>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                color: "white",
                ml: 5,
                mt: 2,
                "&:hover": { background: `${theme.color.blackBackground}` },
                textTransform: "capitalize",
              }}
            >
              <FilterList />
              <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
            </Button>
          </Box>
        </GridGroup2>
      </Box>
    </form>
  );
}

export default FilterForm;
