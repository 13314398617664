import React from 'react'

const AmountRender = ({row}) => {
  return (
<>
    {row.payments.map((pay, index) => (
      <p style={{borderBottom:"1px solid #F7F9FB"}}>{pay.amount}</p>
    ))}
  </>
  )
}

export default AmountRender
