import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMemory } from "../Provider";
import FormMemo from "./FormMemo";
import { Typography } from "@mui/material";
import ModalBox from "../../../../ui/ModalBox";

const UpdateMemo = () => {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  const {
    state: { selected },
    fetchOne,
  } = useMemory();

  //var to navigate another page

  const navigate = useNavigate();
  //close modal function
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  //open modal function when  clicked button

  const handleSuccess = () => {
    setOpen(true);
    setReload(!reload);
  };
  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id, reload]);

  if (!selected) return;
  if (selected?.id != id) return;

  return (
    <>
      <Typography variant="Poster" component="h3" gutterBottom>
        Update Memo
      </Typography>
      <FormMemo handleSuccess={handleSuccess} memo={selected} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The update operation has been completed successfully.. You update memo "
      />
    </>
  );
};

export default UpdateMemo;
