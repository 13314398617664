import React, { useState } from "react";
// Hooks
import { useForm } from "react-hook-form";
// YUP
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// material ui
import {
  Box,
  Typography,
  Modal,
  Button,
  useTheme,
  TextField,
  Alert,
} from "@mui/material";
// MUI Icons
// Password
import PasswordTwoToneIcon from "@mui/icons-material/PasswordTwoTone";
import ErrorsList from "./ErrorsList";
import { useUserAccount } from "../view/UserAccount/Provider";
import { handleResponse } from "../utilities/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 520,
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 2,
};

const resetSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("this field is required")
    // .matches(
    //   "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])",
    //   "your password not strong!!"
    // )
    // .min(8, "Too Short, a password with at least 8 letters is required!"),
});
const changeSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("this field is required"),
    // .matches(
    //   "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])",
    //   "your password not strong!!"
    // )
    // .min(8, "Too Short, a password with at least 8 letters is required!"),
  newPassword: Yup.string()
    .required("this field is required"),
    // .matches(
    //   "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])",
    //   "your password not strong!!"
    // )
    // .min(8, "Too Short, a password with at least 8 letters is required!"),
});
const PassModal = ({
  open = false,
  type = "reset",
  handleClose = () => {},
  handleSuccess = () => {},
  employeeId = 1,
}) => {
  const [error, setError] = useState(null);
  let theme = useTheme();

  const { ResetPassword, changePassword } = useUserAccount();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(type === "reset" ? resetSchema : changeSchema),
  });
  const onSubmit = async (values) => {
    try {
      if (type === "reset") {
        await ResetPassword(values, employeeId);
      } else await changePassword(values);
      reset();
      handleClose();
      handleSuccess();
    } catch (e) {
      if (e.status === 404) setError({ title: e.errors.message });
      if (e.status === 400) {
        type === "reset"
          ? setError({ title: e.errors.message })
          : setError({ title: e.errors.message });
      }
      if (e.status === 409) setError({ title: e.errors.message });
      handleResponse(e.errors.status);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <PasswordTwoToneIcon sx={{ fontSize: "150px", mb: 5 }} />
        <Typography
          id="modal-modal-description"
          sx={{
            textAlign: "center",
            mt: "-60px",
            mb: 1,
            fontWeight: "bold",
          }}
          component="h5"
          variant="h5"
        >
          {type === "reset" ? "Reset Password" : "Change Password"}
        </Typography>
        {type === "reset" && (
          <Typography id="modal-modal-description" sx={{ textAlign: "center" }}>
            Please, Enter a new password
          </Typography>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {error && (
            <Alert severity="error" sx={{ mb: 1 }}>
              <ErrorsList error={error} />
            </Alert>
          )}
          {type === "change" && (
            <TextField
              name="oldPassword"
              error={!!errors["oldPassword"]}
              helperText={errors["oldPassword"]?.message}
              size="small"
              label="Old Password"
              placeholder="***************"
              type="password"
              required={true}
              sx={{
                width: theme.dimensions.width,
                mb: theme.dimensions.mb,
                ml: 7,
              }}
              inputProps={{
                ...register("oldPassword"),
              }}
            />
          )}
          <TextField
            name="newPassword"
            error={!!errors["newPassword"]}
            helperText={errors["newPassword"]?.message}
            size="small"
            label="New Password"
            placeholder="***************"
            type="password"
            required={true}
            sx={{
              width: theme.dimensions.width,
              mb: theme.dimensions.mb,
              ml: 7,
            }}
            inputProps={{
              ...register("newPassword"),
            }}
          />
          <>
            <Button
              type="submit"
              sx={{
                background: theme.color.blackBackground,
                color: "white",
                mt: 2,
                mb: 1.3,
                width: "100%",
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              Done, Close it
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                background: theme.color.gray200,
                color: "black",
                width: "100%",
                "&:hover": { background: `${theme.color.gray300}` },
              }}
            >
              Cancel
            </Button>
          </>
        </form>
      </Box>
    </Modal>
  );
};

export default React.memo(PassModal);
