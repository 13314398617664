//react component
import React, { useEffect, useState } from "react";
//router dom
import { useNavigate } from "react-router-dom";

//site provider
import { useSite } from "../Sites/Provider";
import CarsNumber from "./CarsNumber";
import PlanName from "./PlanName";
import { useMemberShip } from "./Provider";
import { useCompany } from "../Companies/Provider";

//MUI elements
import {
  Alert,
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

//dashboard component
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import { useAuth } from "../../services/auth";
import { useSubCompany } from "../SubCompanies/Provider";
import ErrorsList from "../../ui/ErrorsList";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";

const ListMemberShip = () => {
  let navigate = useNavigate();
  const { role, adminOn } = useAuth();
  const [error, setError] = useState();

  const {
    state: { Names: sites },
    fetchSiteNames: fetchSites,
  } = useSite();
  const {
    state: { Names: companies },
    fetchNames: fetchCompanies,
  } = useCompany();
  const {
    state: { selected: subCompany },
  } = useSubCompany();
  const {
    state: {
      list: memberships,
      page: membershipPage,
      perPage: membershipperPage,
      count,
      length,
      filter,
    },
    dispatch,
    fetchList: fetchmemberships,
    remove,
  } = useMemberShip();

  const [siteId, setSiteId] = useState(filter.siteId);
  const [companyId, setCompanyId] = useState(filter.companyId);
  const [reload, setreload] = useState(false);
  const [selectedCols, setSelectedCols] = useState("Memberships");
  const tabs = [{ title: "Memberships", num: length }];

  const getCols = (name) => {
    switch (name) {
      case "Memberships":
        return [
          {
            label: "Member Name",
            name: "memberName",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Mobile Number",
            name: "mobile",
          },
          {
            label: "Balance",
            name: "balance",
          },
          {
            label: "Expire Date",
            name: "expireDate",
          },
          {
            label: "Car Number",
            customRender: CarsNumber,
          },
          {
            label: "Plan Name",
            customRender: PlanName,
          },
        ];
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Memberships":
        return memberships;
    }
  };

  //fetch companies
  useEffect(() => {
    if (role === "Admin") {
      if (companies?.length === 0) fetchCompanies();
    }
  }, [fetchCompanies]);
  useEffect(() => {
    if (role === "Admin") {
      if (companyId) {
        fetchSites(companyId, 0);
      }
    } else if (role === "Company") {
      fetchSites(adminOn?.id, 0);
    } else if (role === "SubCompany") {
      fetchSites(subCompany?.company?.id, adminOn?.id);
    }
  }, [fetchSites, companyId]);

  //fetch sites
  useEffect(() => {
    if (siteId) fetchmemberships(siteId, membershipPage, membershipperPage);
    if (role === "Site") fetchmemberships(0, membershipPage, membershipperPage);
  }, [siteId, fetchmemberships, membershipPage, membershipperPage, reload]);

  const handleDetail = (id) => {
    navigate("/membership/MembershipDetails/" + id);
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };
  const handleDeleteMemberShip = async (ID) => {
    try {
      await remove(ID);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {role === "Admin" && (
        <>
          <Typography variant="Poster" component="h4" mb={2}>
            Select Company and Site
          </Typography>
          <Box>
            {error && (
              <Alert severity="error">
                <ErrorsList error={error} />
              </Alert>
            )}
            {sites.length === 0 && companyId ? (
              <Alert severity="error">
                <Typography variant="Poster" component="span">
                  {" "}
                  You should add a new Site first,{" "}
                  <Typography
                    variant="Poster"
                    component="span"
                    onClick={() => navigate(`/sites/add/${companyId}`)}
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <strong>Add Site</strong>
                  </Typography>
                </Typography>
              </Alert>
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{ background: "#f7f9fb", padding: "20px", mt: 1, width: "70%" }}
          >
            <Box>
              <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
                <span style={{ fontSize: "12px" }}>Company Name</span>
                <Select
                  value={companyId}
                  onChange={(e) => {
                    dispatch({
                      type: "set_filter",
                      payload: { companyId: e.target.value, siteId: 0 },
                    });
                    setCompanyId(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    autoFocus: true,
                  }}
                  sx={{
                    fontSize: "12px",
                    width: "300px",
                    height: "40px",
                    marginTop: "14px",
                  }}
                >
                  {companies.map((company) => {
                    return (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
                <span style={{ fontSize: "12px" }}>Site Name</span>
                <Select
                  value={siteId}
                  onChange={(e) => {
                    dispatch({
                      type: "set_filter",
                      payload: { companyId: companyId, siteId: e.target.value },
                    });
                    setSiteId(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    autoFocus: true,
                  }}
                  sx={{
                    fontSize: "12px",
                    width: "300px",
                    height: "40px",
                    marginTop: "14px",
                  }}
                >
                  {sites.map((site) => {
                    return (
                      <MenuItem key={site.id} value={site.id}>
                        {site.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </>
      )}
      {role === "Company" && (
        <>
          <Box
            sx={{
              background: "#f7f9fb",
              padding: "20px",
              width: "70%",
              mb: 1,
              p: 5,
            }}
          >
            {/* Adding sub company => !subcompany */}
            <div style={{ display: "flex" }}>
              <Box>
                <span style={{ fontSize: "12px" }}>Company Name</span>
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    width: "290px",
                    borderRadius: "8px",
                    textAlign: "center",
                    marginRight: "20px",
                    marginTop: "15px",
                  }}
                >
                  {adminOn?.companyName}
                </Box>
              </Box>
              <FormControl sx={{ minWidth: 120 }}>
                <span style={{ fontSize: "12px" }}>Site Name</span>
                <Select
                  value={siteId}
                  onChange={(e) => {
                    dispatch({
                      type: "set_filter",
                      payload: {
                        companyId: adminOn?.id,
                        siteId: e.target.value,
                      },
                    });
                    setSiteId(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{
                    autoFocus: true,
                  }}
                  sx={{
                    fontSize: "12px",
                    width: "300px",
                    height: "40px",
                    mt: 1,
                  }}
                >
                  {sites.map((site) => {
                    return (
                      <MenuItem key={site.id} value={site.id}>
                        {site.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Box>
        </>
      )}
      {role === "SubCompany" && (
        <>
          <Box
            sx={{
              background: "#f7f9fb",
              padding: "20px",
              width: "70%",
              mb: 1,
              p: 5,
            }}
          >
            {/* Adding sub company => !subcompany */}
            <div style={{ display: "flex" }}>
              <Box>
                <span style={{ fontSize: "12px" }}>Company Name</span>
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    width: "290px",
                    borderRadius: "8px",
                    textAlign: "center",
                    marginRight: "20px",
                    marginTop: "15px",
                  }}
                >
                  {subCompany?.company?.companyName}
                </Box>
              </Box>
              <FormControl sx={{ minWidth: 120 }}>
                <span style={{ fontSize: "12px" }}>Site Name</span>
                <Select
                  value={siteId}
                  onChange={(e) => {
                    dispatch({
                      type: "set_filter",
                      payload: {
                        companyId: subCompany?.company?.id,
                        siteId: e.target.value,
                      },
                    });
                    setSiteId(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{
                    autoFocus: true,
                  }}
                  sx={{
                    fontSize: "12px",
                    width: "300px",
                    height: "40px",
                    mt: 1,
                  }}
                >
                  {sites.map((site) => {
                    return (
                      <MenuItem key={site.id} value={site.id}>
                        {site.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Box>
        </>
      )}
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      ></TabsList>

      <CustomTable
        empty={
          role === "Site"
            ? memberships?.length === 0
              ? true
              : false
            : memberships?.length === 0 || sites.length === 0
            ? true
            : false
        }
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        moreOptions={
          role !== "SubCompany" && {
            detail: handleDetail,
          }
        }
        // actions={{
        //   selectedCols: true,
        //   edit: (ID) => navigate(`update/${ID}`),
        //   delete: (ID) => handleDeleteMemberShip(ID),
        // }}
        filters={{
          pagination: true,
          page: membershipperPage,
          changePageSize: changePageSize,
          add:
            role !== "SubCompany"
              ? () => {
                  if (siteId !== 0) navigate("add");
                  else setError({ title: "you should select site at first" });
                }
              : "",
        }}
        page={membershipPage}
        showButton={true}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
      ></CustomTable>
    </>
  );
};

export default React.memo(ListMemberShip);
