import React from "react";

const PaidOption = ({ row }) => {
  return (
    <>
      {row.paidOption === "Types" ? (
        <div>
          <div>Cash</div>
          <div>C.C</div>
          <div>Void</div>
          <div>Company</div>
          <div style={{ borderTop: "1px dashed black" }}>
            <p style={{ fontWeight: "bold" }}>Total</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PaidOption;
