import React, { useState } from "react";
import {
  // Button,
  TextField,
  Box,
  Grid,
  Typography,
  Paper,
  useTheme,
  Button,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import DotColorful from "../../../icons/DotColorful.svg";
// import DotColorless from "../../../icons/DotColorless.svg";
// import CompanyLogo from "../../../icons/CompanyLogo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useCompany } from "../Provider";
import { useAuth } from "../../../services/auth";
import fields from "../fields";
import { Image } from "@mui/icons-material";

function BoxInfo({ company = [] }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { uploadPhoto } = useCompany();
  const [URLImage, setURLImage] = useState(company?.logoUrl);

  let params = useParams();
  let { user, role, adminOn } = useAuth();
  const id = params.id;
  // const labelsCol1 = [
  //   { label: "Company Status", name: "isActive" },
  //   { label: "Company Name", name: "companyName" },
  //   { label: "Company Website", name: "webSite" },
  //   { label: "Company Email", name: "email" },
  //   { label: "Company Address", name: "address" },
  //   { label: "Contact Person Name", name: "contactPerson" },
  //   { label: "Company Mobile Number", name: "mobile" },
  //   { label: "TVA Percent", name: "tvaPercent" },
  //   { label: "TVA Number", name: "tvaNumber" },
  // ];
  // const labelsCol2 = [{ label: "Company Logo", name: "logoUrl" }];
  //   Styles
  // const TitleStyle = {
  //   p: theme.dimensions.padding / 4,
  //   color: theme.color.PaperlabelColor,
  // };
  // const TitleStyle2 = {
  //   p: theme.dimensions.padding / 4,
  // };
  const uploadImage = async (image, id) => {
    try {
      await uploadPhoto(image, id);
      setURLImage(URL.createObjectURL(image));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper
      elevation={0}
      // square
      sx={{
        p: theme.dimensions.padding,
        mt: theme.dimensions.mt,
        mb: theme.dimensions.mb,
        background: theme.color.PaperbgColor,
        width: "90%",
      }}
    >
      <Typography variant="Poster" component="h4" gutterBottom>
        Company Information
      </Typography>
      <Grid container>
        {fields.map((f, index) => {
          if (f.editOnly) return;
          return (
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              mt={2}
              key={index}
              sx={{ fontSize: "14px" }}
            >
              <span style={{ color: theme.color.PaperlabelColor }}>
                {f.label} :{" "}
              </span>
              <span>
                {company?.[f?.name] && company?.[f?.name]}
                {f.name === "isActive" &&
                  (company?.isActive ? (
                    <span style={{ color: "#4AA785" }}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: 10, mr: 0.2 }}
                      />
                      Active
                    </span>
                  ) : (
                    <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: 10, mr: 0.2 }}
                      />
                      Inactive
                    </span>
                  ))}
              </span>
            </Grid>
          );
        })}
        <Grid item xs={4} md={4} mt={5} ml={1}></Grid>
        <Grid item xs={4} md={4} mt={5} ml={1} sx={{ fontSize: "14px", mt: 3 }}>
          <span style={{ color: theme.color.PaperlabelColor }}>
            Company Logo
          </span>
          {/* LOGO */}
          {/* company.logoUrl */}
          <Box
            sx={{
              width: 250,
              height: 150,
              border: "1px dashed gray",
              borderRadius: 1,
              mt: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!company.logoUrl && <Image fontSize="large" color="action" />}
            {company.logoUrl && (
              <div style={{ height: "150px", width: "150px" }}>
                {" "}
                <img
                  src={URLImage}
                  style={{ height: "100%", width: "100%" }}
                />{" "}
              </div>
            )}
          </Box>
          <TextField
            type="file"
            fullWidth
            readOnly={true}
            helperText="Company Logo : Accepts .svg, .gif, .jpg, and .png"
            sx={{
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
            onChange={(event) => uploadImage(event.target.files[0], id)}
          ></TextField>
          {/* <Box id="uploadCard">
            <Button
              variant="outlined"
              color="success"
              type="submit"
              size="small"
              sx={{ mt: theme.dimensions.mt }}
              onClick={() => document.getElementById("inputLogo").click()}
            >
              update photo
            </Button>
            <Typography sx={{ mt: theme.dimensions.mt }}>
              Accepts .svg, .gif, .jpg, and .png
            </Typography>
          </Box>
          <input
            type="file"
            accept=".svg, .gif, .jpg, .png"
            id="inputLogo"
            onChange={(event) => uploadPhoto(event.target.files[0], id)}
            style={{ display: "none" }}
          /> */}
        </Grid>
      </Grid>
      {role === "Admin" && (
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      )}
    </Paper>
  );
}

export default BoxInfo;
