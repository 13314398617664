// React
import React from "react";
// MUI
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
// Icon
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useSite } from "../Provider";

const Bill = ({ getCardContent }) => {
  let theme = useTheme();
  const {
    state: { printingDiv1, printingDiv2, printingDiv3, printingDiv4 },
  } = useSite();
  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ width: "100%", p: 1 }}>
        <CardContent sx={{ width: "100%", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              gap: 0.5,
            }}
          >
            {[printingDiv1, printingDiv2, printingDiv3, printingDiv4]?.map(
              (div, i) => {
                return (
                  <>
                    {getCardContent(div)?.length === 0 && `DIVISION ${i + 1}`}
                    {getCardContent(div)?.map((item, index) => {
                      if (item.name === "QRCode")
                        return (
                          <QrCode2Icon key={index} sx={{ fontSize: "50px" }} />
                        );
                      else if (item.defaultValue)
                        return (
                          <Typography
                            key={index}
                            variant="body2"
                            component="span"
                            sx={{
                              fontSize: item.name === "TicketNumber" && "50px",
                              backgroundColor: theme.color.PaperbgColor,
                              paddingLeft: 1,
                              paddingRight: 1,
                            }}
                          >
                            {item.defaultValue}
                          </Typography>
                        );
                      else if (item.name === "Services")
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: theme.color.PaperbgColor,
                              width: "100%",
                            }}
                          >
                            <Typography variant="h6" component="h6">
                              Services
                            </Typography>
                            <Typography variant="body2" component="span">
                              {"Service1: 5000  1000"}
                            </Typography>
                            <Typography variant="body2" component="span">
                              {"Service2: 5000  1000"}
                            </Typography>
                          </Box>
                        );
                      else
                        return (
                          <Typography
                            key={index}
                            variant={item.h6 ? "h6" : "body2"}
                            component={item.h6 ? "h6" : "span"}
                            sx={{
                              backgroundColor: theme.color.PaperbgColor,
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            {item.printedValue}
                          </Typography>
                        );
                    })}
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontStyle: "oblique", pt: 3 }}
                    >
                      Thank you for parking with us
                    </Typography>
                    <div
                      style={{
                        width: "100%",
                        border: "dashed 0.5px gray",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    ></div>
                  </>
                );
              }
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default React.memo(Bill);
