import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import EmployeeActions from "./EmployeeActions";
import ModalBox from "../ModalBox";
import HourlyRateActions from "./HourlyRateActions";

const ButtonWrapper = styled.div`
  width: 200px;
  margin: 10px auto;

  button {
    margin: 0 10px;
    color: white;
    font-size: 11px;
  }
`;
const SingleButtonWrapper = styled.div`
  margin: 10px auto;

  button {
    margin: 0 10px;
    color: white;
    font-size: 11px;
  }
`;

function Actions({
  editing,
  setEditing,
  id,
  actions,
  data,
  row,
  name,
  col,
  roleId,
  setRoleId,
  Discarding,
  setDiscarding,
  cid,
}) {
  const [open, setOpen] = useState(false);
  const [openPayrollModal, setOpenPayrollModal] = useState(false);

  let theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  const handleEdit = (row) => {
    editing &&
      actions.edit(
        data.filter((e) => e.id === id || e.roleId === id || e.siteId === id),
        id
      );
    editing && setEditing(null);
  };
  const handleDelete = (id) => {
    editing && setEditing(false);
    !editing && setOpen(true);
  };

  if (actions.emoloyee) {
    return (
      <EmployeeActions
        editing={editing}
        setEditing={setEditing}
        id={id}
        actions={actions}
        data={data}
        row={row}
      ></EmployeeActions>
    );
  }
  if (actions.hourlyRate) {
    return (
      <HourlyRateActions
        data={data}
        row={row}
        id={id}
        companyId={cid}
        actions={actions}
        editing={editing}
        setEditing={setEditing}
        roleId={roleId}
        setRoleId={setRoleId}
        col={col}
        Discarding={Discarding}
        setDiscarding={setDiscarding}
      />
    );
  }
  if (editing === id) {
    return (
      <ButtonWrapper>
        {actions.edit && (
          <Button
            onClick={() => {
              handleEdit(id);
            }}
            style={{
              backgroundColor: "black",
              "&:hover": { background: `${theme.color.black}` },
            }}
            disabled={actions.vState}
          >
            Save
          </Button>
        )}
        {actions.delete && (
          <Button
            onClick={() => {
              setEditing(null);
            }}
            style={{
              background: theme.color.gray200,
              color: "black",
              "&:hover": { background: `${theme.color.gray200}` },
            }}
          >
            Discard
          </Button>
        )}
      </ButtonWrapper>
    );
  }
  if (actions.close || actions.paid)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SingleButtonWrapper>
          {actions.close && (
            <Box>
              <Button
                onClick={() => {
                  setOpenPayrollModal(true);
                }}
                disabled={
                  row.status === "Closed" || row.status === "Archive"
                    ? true
                    : false
                }
                sx={{
                  background: "#FF4747",
                  "&:hover": {
                    background: `${theme.color.redHoverBackground}`,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: `${theme.color.grayBackground}`,
                    color: `${theme.color.dark}`,
                  },
                }}
              >
                {row.status === "Closed"
                  ? "Saved"
                  : row.status === "Archive"
                  ? "Archived"
                  : "Saved"}
              </Button>
            </Box>
          )}
          {actions.paid &&
            (row.status === "Open" ? (
              <Typography variant="Poster" component="h5">
                Close payroll to pay salary
              </Typography>
            ) : (
              <Button
                onClick={() => {
                  setOpenPayrollModal(true);
                }}
                disabled={!!row.paidDate}
                sx={{
                  background: "#FF4747",
                  "&:hover": {
                    background: `${theme.color.redHoverBackground}`,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: `${theme.color.grayBackground}`,
                    color: `${theme.color.dark}`,
                  },
                }}
              >
                {row.paidDate === null ? "Pay Salary" : "Paid"}
              </Button>
            ))}
        </SingleButtonWrapper>
        <ModalBox
          open={openPayrollModal}
          operation={actions.paid ? "pay" : "delete"}
          handleClose={() => {
            actions.setInTableRender(!actions.InTableRender);
            setOpenPayrollModal(false);
          }}
          handleOperation={() => {
            if (actions.paid) actions.paid(id);
            if (actions.close) {
              actions.close(id, cid);
            }
          }}
          text={
            actions.paid
              ? `Are you sure you want to pay the salary of this employee?`
              : `Are you sure you want to complete the process of closing this payroll?`
          }
          btnLabel={actions.paid && "Pay Salary"}
        />
      </Box>
    );

  return (
    <>
      <ButtonWrapper>
        {actions.edit && (
          <Button
            onClick={() => {
              actions.inTableEdit ? setEditing(id || 0) : actions.edit(id, row);
            }}
            style={{
              backgroundColor: "#FFC555",
              "&:hover": { background: `${theme.color.black}` },
              width: !actions.delete && "150px",
            }}
          >
            Edit
          </Button>
        )}
        {actions.delete && (
          <Button
            onClick={() => {
              handleDelete(id);
            }}
            style={{
              background: "#FF4747",
              "&:hover": { background: `${theme.color.redHoverBackground}` },
            }}
          >
            Delete
          </Button>
        )}
      </ButtonWrapper>
      <ModalBox
        open={open}
        operation="delete"
        handleClose={handleClose}
        handleOperation={() => {
          actions.delete(id);
          setOpen(false);
        }}
        text={`Do you want to complete the process of deleting all information?`}
      />
    </>
  );
}

export default Actions;
