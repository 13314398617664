import React from "react";
import { Outlet } from "react-router";
import Layout from "../../layout/Layout";

const Services = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default React.memo(Services);
