import React from 'react'
import { Outlet } from 'react-router-dom';
import Layout from "../../layout/Layout";

const Attendance = () => {
  return (
    <Layout>
       <Outlet /> 
    </Layout>
  )
}

export default React.memo(Attendance);
