import { Route } from "react-router-dom";

import ListContracts from "./ListContracts";
import Contracts from "./Contracts";
import AddContract from "./AddContract"

export default (
  <Route path="contracts" exact element={<Contracts />}>
    <Route index element={<ListContracts />} />
    <Route path="AddContract/:id" element={<AddContract />} />
  </Route>
);
