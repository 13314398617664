import React from "react";
import styled from "styled-components";

const Cell = styled.div`
  display: flex;
  div{
    display: flex;
    flex: 1;
  }
  img {
    width: 30px;
    height:30px;
    margin-top: 8px;
    display: flex;
    border-radius: 100px;
  }
  p {
    font-size: 14px;
    color: black;
  }
`;

function EmployeeCell({row, id}) {
  return (
    <Cell>
      <div>
      <img
        src={
          row?.photoUrl ||
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJQ-NCMFIn2rSYyVUZDyaXP6yau90cTKhBk9BnARSrGQ&s"
        }
        alt="Employee Image"
      />

      <p>{row.fullName}</p>
      </div>
    </Cell>
  );
}

export default EmployeeCell;
