const countries = [
  {
    country: "Afghanistan",
    currency_code: "AFN",
    dial_code: "+93",
  },
  {
    country: "Åland Islands",
    flag: "🇦🇽",
    currency_code: "AX",
    dial_code: "+358",
  },
  {
    country: "Albania",
    currency_code: "ALL",
    dial_code: "+355",
  },
  {
    country: "Algeria",
    currency_code: "DZD",
    dial_code: "+213",
  },
  {
    country: "American Samoa",
    currency_code: "USD",
    dial_code: "+1684",
  },
  {
    country: "Andorra",
    currency_code: "EUR",
    dial_code: "+376",
  },
  {
    country: "Angola",
    currency_code: "AOA",
    dial_code: "+244",
  },
  {
    country: "Anguilla",
    currency_code: "XCD",
    dial_code: "+1264",
  },
  {
    country: "Antarctica",
    currency_code: "XCD",
    dial_code: "+672",
  },
  {
    country: "Antigua and Barbuda",
    currency_code: "XCD",
    dial_code: "+1268",
  },
  {
    country: "Argentina",
    currency_code: "ARS",
    dial_code: "+54",
  },
  {
    country: "Armenia",
    currency_code: "AMD",
    dial_code: "+374",
  },
  {
    country: "Aruba",
    currency_code: "AWG",
    dial_code: "+297",
  },
  {
    country: "Australia",
    currency_code: "AUD",
    dial_code: "+61",
  },
  {
    country: "Austria",
    currency_code: "EUR",
    dial_code: "+43",
  },
  {
    country: "Azerbaijan",
    currency_code: "AZN",
    dial_code: "+994",
  },
  {
    country: "Bahamas",
    currency_code: "BSD",
    dial_code: "+1242",
  },
  {
    country: "Bahrain",
    currency_code: "BHD",
    dial_code: "+973",
  },
  {
    country: "Bangladesh",
    currency_code: "BDT",
    dial_code: "+880",
  },
  {
    country: "Barbados",
    currency_code: "BBD",
    dial_code: "+1246",
  },
  {
    country: "Belarus",
    currency_code: "BYR",
    dial_code: "+375",
  },
  {
    country: "Belgium",
    currency_code: "EUR",
    dial_code: "+32",
  },
  {
    country: "Belize",
    currency_code: "BZD",
    dial_code: "+501",
  },
  {
    country: "Benin",
    currency_code: "XOF",
    dial_code: "+229",
  },
  {
    country: "Bermuda",
    currency_code: "BMD",
    dial_code: "+1441",
  },
  {
    country: "Bhutan",
    currency_code: "BTN",
    dial_code: "+975",
  },
  {
    country: "Bolivia",
    currency_code: "BOB",
    dial_code: "+591",
  },
  {
    country: "Bosnia and Herzegovina",
    currency_code: "BAM",
    dial_code: "+387",
  },
  {
    country: "Botswana",
    currency_code: "BWP",
    dial_code: "+267",
  },
  {
    country: "Bouvet Island",
    currency_code: "NOK",
    dial_code: "+47",
  },
  {
    country: "Brazil",
    currency_code: "BRL",
    dial_code: "+55",
  },
  {
    country: "British Indian Ocean Territory",
    currency_code: "USD",
    dial_code: "+246",
  },
  {
    country: "Brunei",
    currency_code: "BND",
    dial_code: "+673",
  },
  {
    country: "Bulgaria",
    currency_code: "BGN",
    dial_code: "+359",
  },
  {
    country: "Burkina Faso",
    currency_code: "XOF",
    dial_code: "+226",
  },
  {
    country: "Burundi",
    currency_code: "BIF",
    dial_code: "+257",
  },
  {
    country: "Cambodia",
    currency_code: "KHR",
    dial_code: "+855",
  },
  {
    country: "Cameroon",
    currency_code: "XAF",
    dial_code: "+237",
  },
  {
    country: "Canada",
    currency_code: "CAD",
    dial_code: "+1",
  },
  {
    country: "Cape Verde",
    currency_code: "CVE",
    dial_code: "+238",
  },
  {
    country: "Cayman Islands",
    currency_code: "KYD",
    dial_code: "+345",
  },
  {
    country: "Central African Republic",
    currency_code: "XAF",
    dial_code: "+236",
  },
  {
    country: "Chad",
    currency_code: "XAF",
    dial_code: "+235",
  },
  {
    country: "Chile",
    currency_code: "CLP",
    dial_code: "+56",
  },
  {
    country: "China",
    currency_code: "CNY",
    dial_code: "+86",
  },
  {
    country: "Christmas Island",
    currency_code: "AUD",
    dial_code: "+61",
  },
  {
    country: "Cocos (Keeling) Islands",
    currency_code: "AUD",
    dial_code: "+61",
  },
  {
    country: "Colombia",
    currency_code: "COP",
    dial_code: "+57",
  },
  {
    country: "Comoros",
    currency_code: "KMF",
    dial_code: "+269",
  },
  {
    country: "Congo",
    currency_code: "XAF",
    dial_code: "+242",
  },
  {
    country: "Cook Islands",
    currency_code: "NZD",
    dial_code: "+682",
  },
  {
    country: "Costa Rica",
    currency_code: "CRC",
    dial_code: "+506",
  },
  {
    country: "Croatia",
    currency_code: "HRK",
    dial_code: "+385",
  },
  {
    country: "Cuba",
    currency_code: "CUP",
    dial_code: "+53",
  },
  {
    country: "Cyprus",
    currency_code: "EUR",
    dial_code: "+357",
  },
  {
    country: "Czech Republic",
    currency_code: "CZK",
    dial_code: "+420",
  },
  {
    country: "Denmark",
    currency_code: "DKK",
    dial_code: "+45",
  },
  {
    country: "Djibouti",
    currency_code: "DJF",
    dial_code: "+253",
  },
  {
    country: "Dominica",
    currency_code: "XCD",
    dial_code: "+1767",
  },
  {
    country: "Dominican Republic",
    currency_code: "DOP",
    dial_code: "+1849",
  },
  {
    country: "Ecuador",
    currency_code: "ECS",
    dial_code: "+593",
  },
  {
    country: "Egypt",
    currency_code: "EGP",
    dial_code: "+20",
  },
  {
    country: "El Salvador",
    currency_code: "SVC",
    dial_code: "+503",
  },
  {
    country: "Equatorial Guinea",
    currency_code: "XAF",
    dial_code: "+240",
  },
  {
    country: "Eritrea",
    currency_code: "ERN",
    dial_code: "+291",
  },
  {
    country: "Estonia",
    currency_code: "EUR",
    dial_code: "+372",
  },
  {
    country: "Ethiopia",
    currency_code: "ETB",
    dial_code: "+251",
  },
  {
    country: "Falkland Islands",
    currency_code: "FKP",
    dial_code: "+500",
  },
  {
    country: "Faroe Islands",
    currency_code: "DKK",
    dial_code: "+298",
  },
  {
    country: "Fiji Islands",
    currency_code: "FJD",
    dial_code: "+679",
  },
  {
    country: "Finland",
    currency_code: "EUR",
    dial_code: "+358",
  },
  {
    country: "France",
    currency_code: "EUR",
    dial_code: "+33",
  },
  {
    country: "French Guiana",
    currency_code: "EUR",
    dial_code: "+594",
  },
  {
    country: "French Polynesia",
    currency_code: "XPF",
    dial_code: "+689",
  },
  {
    country: "French Southern territories",
    currency_code: "EUR",
    dial_code: "+262",
  },
  {
    country: "Gabon",
    currency_code: "XAF",
    dial_code: "+241",
  },
  {
    country: "Gambia",
    currency_code: "GMD",
    dial_code: "+220",
  },
  {
    country: "Georgia",
    currency_code: "GEL",
    dial_code: "+995",
  },
  {
    country: "Germany",
    currency_code: "EUR",
    dial_code: "+49",
  },
  {
    country: "Ghana",
    currency_code: "GHS",
    dial_code: "+233",
  },
  {
    country: "Gibraltar",
    currency_code: "GIP",
    dial_code: "+350",
  },
  {
    country: "Greece",
    currency_code: "EUR",
    dial_code: "+30",
  },
  {
    country: "Greenland",
    currency_code: "DKK",
    dial_code: "+299",
  },
  {
    country: "Grenada",
    currency_code: "XCD",
    dial_code: "+1473",
  },
  {
    country: "Guadeloupe",
    currency_code: "EUR",
    dial_code: "+590",
  },
  {
    country: "Guam",
    currency_code: "USD",
    dial_code: "+1671",
  },
  {
    country: "Guatemala",
    currency_code: "QTQ",
    dial_code: "+502",
  },
  {
    country: "Guinea",
    currency_code: "GNF",
    dial_code: "+224",
  },
  {
    country: "Guinea-Bissau",
    currency_code: "CFA",
    dial_code: "+245",
  },
  {
    country: "Guyana",
    currency_code: "GYD",
    dial_code: "+592",
  },
  {
    country: "Haiti",
    currency_code: "HTG",
    dial_code: "+509",
  },
  {
    country: "Heard Island and McDonald Islands",
    currency_code: "AUD",
    dial_code: "+672",
  },
  {
    country: "Holy See (Vatican City State)",
    currency_code: "EUR",
    dial_code: "+379",
  },
  {
    country: "Honduras",
    currency_code: "HNL",
    dial_code: "+504",
  },
  {
    country: "Hong Kong",
    currency_code: "HKD",
    dial_code: "+852",
  },
  {
    country: "Hungary",
    currency_code: "HUF",
    dial_code: "+36",
  },
  {
    country: "Iceland",
    currency_code: "ISK",
    dial_code: "+354",
  },
  {
    country: "India",
    currency_code: "INR",
    dial_code: "+91",
  },
  {
    country: "Indonesia",
    currency_code: "IDR",
    dial_code: "+62",
  },
  {
    country: "Iran",
    currency_code: "IRR",
    dial_code: "+98",
  },
  {
    country: "Iraq",
    currency_code: "IQD",
    dial_code: "+964",
  },
  {
    country: "Ireland",
    currency_code: "EUR",
    dial_code: "+353",
  },
  {
    country: "Italy",
    currency_code: "EUR",
    dial_code: "+39",
  },
  {
    country: "Jamaica",
    currency_code: "JMD",
    dial_code: "+1876",
  },
  {
    country: "Japan",
    currency_code: "JPY",
    dial_code: "+81",
  },
  {
    country: "Jordan",
    currency_code: "JOD",
    dial_code: "+962",
  },
  {
    country: "Kazakhstan",
    currency_code: "KZT",
    dial_code: "+7",
  },
  {
    country: "Kenya",
    currency_code: "KES",
    dial_code: "+254",
  },
  {
    country: "Kiribati",
    currency_code: "AUD",
    dial_code: "+686",
  },
  {
    country: "Kuwait",
    currency_code: "KWD",
    dial_code: "+965",
  },
  {
    country: "Kyrgyzstan",
    currency_code: "KGS",
    dial_code: "+996",
  },
  {
    country: "Laos",
    currency_code: "LAK",
    dial_code: "+856",
  },
  {
    country: "Latvia",
    currency_code: "LVL",
    dial_code: "+371",
  },
  {
    country: "Lebanon",
    currency_code: "LBP",
    dial_code: "+961",
  },
  {
    country: "Lesotho",
    currency_code: "LSL",
    dial_code: "+266",
  },
  {
    country: "Liberia",
    currency_code: "LRD",
    dial_code: "+231",
  },
  {
    country: "Libyan Arab Jamahiriya",
    currency_code: "LYD",
    dial_code: "+218",
  },
  {
    country: "Liechtenstein",
    currency_code: "CHF",
    dial_code: "+423",
  },
  {
    country: "Lithuania",
    currency_code: "LTL",
    dial_code: "+370",
  },
  {
    country: "Luxembourg",
    currency_code: "EUR",
    dial_code: "+352",
  },
  {
    country: "Macau",
    currency_code: "MOP",
    dial_code: "+853",
  },
  {
    country: "North Macedonia",
    currency_code: "MKD",
    dial_code: "+389",
  },
  {
    country: "Madagascar",
    currency_code: "MGF",
    dial_code: "+261",
  },
  {
    country: "Malawi",
    currency_code: "MWK",
    dial_code: "+265",
  },
  {
    country: "Malaysia",
    currency_code: "MYR",
    dial_code: "+60",
  },
  {
    country: "Maldives",
    currency_code: "MVR",
    dial_code: "+960",
  },
  {
    country: "Mali",
    currency_code: "XOF",
    dial_code: "+223",
  },
  {
    country: "Malta",
    currency_code: "EUR",
    dial_code: "+356",
  },
  {
    country: "Marshall Islands",
    currency_code: "USD",
    dial_code: "+692",
  },
  {
    country: "Martinique",
    currency_code: "EUR",
    dial_code: "+596",
  },
  {
    country: "Mauritania",
    currency_code: "MRO",
    dial_code: "+222",
  },
  {
    country: "Mauritius",
    currency_code: "MUR",
    dial_code: "+230",
  },
  {
    country: "Mayotte",
    currency_code: "EUR",
    dial_code: "+262",
  },
  {
    country: "Mexico",
    currency_code: "MXN",
    dial_code: "+52",
  },
  {
    country: "Micronesia, Federated States of",
    currency_code: "USD",
    dial_code: "+691",
  },
  {
    country: "Moldova",
    currency_code: "MDL",
    dial_code: "+373",
  },
  {
    country: "Monaco",
    currency_code: "EUR",
    dial_code: "+377",
  },
  {
    country: "Mongolia",
    currency_code: "MNT",
    dial_code: "+976",
  },
  {
    country: "Montserrat",
    currency_code: "XCD",
    dial_code: "+1664",
  },
  {
    country: "Morocco",
    currency_code: "MAD",
    dial_code: "+212",
  },
  {
    country: "Mozambique",
    currency_code: "MZN",
    dial_code: "+258",
  },
  {
    country: "Myanmar",
    currency_code: "MMR",
    dial_code: "+95",
  },
  {
    country: "Namibia",
    currency_code: "NAD",
    dial_code: "+264",
  },
  {
    country: "Nauru",
    currency_code: "AUD",
    dial_code: "+674",
  },
  {
    country: "Nepal",
    currency_code: "NPR",
    dial_code: "+977",
  },
  {
    country: "Netherlands",
    currency_code: "EUR",
    dial_code: "+31",
  },
  {
    country: "Netherlands Antilles",
    currency_code: "ANG",
    dial_code: "+599",
  },
  {
    country: "New Caledonia",
    currency_code: "XPF",
    dial_code: "+687",
  },
  {
    country: "New Zealand",
    currency_code: "NZD",
    dial_code: "+64",
  },
  {
    country: "Nicaragua",
    currency_code: "NIO",
    dial_code: "+505",
  },
  {
    country: "Niger",
    currency_code: "XOF",
    dial_code: "+227",
  },
  {
    country: "Nigeria",
    currency_code: "NGN",
    dial_code: "+234",
  },
  {
    country: "Niue",
    currency_code: "NZD",
    dial_code: "+683",
  },
  {
    country: "Norfolk Island",
    currency_code: "AUD",
    dial_code: "+672",
  },
  {
    country: "North Korea",
    currency_code: "KPW",
    dial_code: "+1670",
  },
  {
    country: "Norway",
    currency_code: "NOK",
    dial_code: "+47",
  },
  {
    country: "Oman",
    currency_code: "OMR",
    dial_code: "+968",
  },
  {
    country: "Pakistan",
    currency_code: "PKR",
    dial_code: "+92",
  },
  {
    country: "Palau",
    currency_code: "USD",
    dial_code: "+680",
  },
  {
    country: "Palestine",
    currency_code: null,
    dial_code: "+970",
  },
  {
    country: "Panama",
    currency_code: "PAB",
    dial_code: "+507",
  },
  {
    country: "Papua New Guinea",
    currency_code: "PGK",
    dial_code: "+675",
  },
  {
    country: "Paraguay",
    currency_code: "PYG",
    dial_code: "+595",
  },
  {
    country: "Peru",
    currency_code: "PEN",
    dial_code: "+51",
  },
  {
    country: "Philippines",
    currency_code: "PHP",
    dial_code: "+63",
  },
  {
    country: "Pitcairn Islands",
    currency_code: "NZD",
    dial_code: "+64",
  },
  {
    country: "Poland",
    currency_code: "PLN",
    dial_code: "+48",
  },
  {
    country: "Portugal",
    currency_code: "EUR",
    dial_code: "+351",
  },
  {
    country: "Puerto Rico",
    currency_code: "USD",
    dial_code: "+1939",
  },
  {
    country: "Qatar",
    currency_code: "QAR",
    dial_code: "+974",
  },
  {
    country: "Reunion",
    currency_code: "EUR",
  },
  {
    country: "Romania",
    currency_code: "RON",
    dial_code: "+40",
  },
  {
    country: "Russian Federation",
    currency_code: "RUB",
    dial_code: "+7",
  },
  {
    country: "Rwanda",
    currency_code: "RWF",
    dial_code: "+250",
  },
  {
    country: "Saint Helena",
    currency_code: "SHP",
    dial_code: "+290",
  },
  {
    country: "Saint Kitts and Nevis",
    currency_code: "XCD",
    dial_code: "+1869",
  },
  {
    country: "Saint Lucia",
    currency_code: "XCD",
    dial_code: "+1758",
  },
  {
    country: "Saint Pierre and Miquelon",
    currency_code: "EUR",
    dial_code: "+508",
  },
  {
    country: "Saint Vincent and the Grenadines",
    currency_code: "XCD",
    dial_code: "+1784",
  },
  {
    country: "Samoa",
    currency_code: "WST",
    dial_code: "+685",
  },
  {
    country: "San Marino",
    currency_code: "EUR",
    dial_code: "+378",
  },
  {
    country: "Sao Tome and Principe",
    currency_code: "STD",
    dial_code: "+239",
  },
  {
    country: "Saudi Arabia",
    currency_code: "SAR",
    dial_code: "+966",
  },
  {
    country: "Senegal",
    currency_code: "XOF",
    dial_code: "+221",
  },
  {
    country: "Serbia",
    currency_code: "RSD",
    dial_code: "+381",
  },
  {
    country: "Seychelles",
    currency_code: "SCR",
    dial_code: "+248",
  },
  {
    country: "Sierra Leone",
    currency_code: "SLL",
    dial_code: "+232",
  },
  {
    country: "Singapore",
    currency_code: "SGD",
    dial_code: "+65",
  },
  {
    country: "Slovakia",
    currency_code: "EUR",
    dial_code: "+421",
  },
  {
    country: "Slovenia",
    currency_code: "EUR",
    dial_code: "+386",
  },
  {
    country: "Solomon Islands",
    currency_code: "SBD",
    dial_code: "+677",
  },
  {
    country: "Somalia",
    currency_code: "SOS",
    dial_code: "+252",
  },
  {
    country: "South Africa",
    currency_code: "ZAR",
    dial_code: "+27",
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    currency_code: "GBP",
    dial_code: "+500",
  },
  {
    country: "Spain",
    currency_code: "EUR",
    dial_code: "+34",
  },
  {
    country: "Sri Lanka",
    currency_code: "LKR",
    dial_code: "+94",
  },
  {
    country: "Sudan",
    currency_code: "SDG",
    dial_code: "+249",
  },
  {
    country: "Suriname",
    currency_code: "SRD",
    dial_code: "+597",
  },
  {
    country: "Svalbard and Jan Mayen",
    currency_code: "NOK",
    dial_code: "+47",
  },

  {
    country: "Sweden",
    currency_code: "SEK",
    dial_code: "+46",
  },
  {
    country: "Switzerland",
    currency_code: "CHF",
    dial_code: "+41",
  },
  {
    country: "Syria",
    currency_code: "SYP",
    dial_code: "+963",
  },
  {
    country: "Tajikistan",
    currency_code: "TJS",
    dial_code: "+992",
  },
  {
    country: "Tanzania",
    currency_code: "TZS",
    dial_code: "+255",
  },
  {
    country: "Thailand",
    currency_code: "THB",
    dial_code: "+66",
  },
  {
    country: "Togo",
    currency_code: "XOF",
    dial_code: "+228",
  },
  {
    country: "Tokelau",
    currency_code: "NZD",
    dial_code: "+690",
  },
  {
    country: "Tonga",
    currency_code: "TOP",
    dial_code: "+676",
  },
  {
    country: "Trinidad and Tobago",
    currency_code: "TTD",
    dial_code: "+1868",
  },
  {
    country: "Tunisia",
    currency_code: "TND",
    dial_code: "+216",
  },
  {
    country: "Turkey",
    currency_code: "TRY",
    dial_code: "+90",
  },
  {
    country: "Turkmenistan",
    currency_code: "TMT",
    dial_code: "+993",
  },
  {
    country: "Turks and Caicos Islands",
    currency_code: "USD",
    dial_code: "+1649",
  },
  {
    country: "Tuvalu",
    currency_code: "AUD",
    dial_code: "+688",
  },
  {
    country: "Uganda",
    currency_code: "UGX",
    dial_code: "+256",
  },
  {
    country: "Ukraine",
    currency_code: "UAH",
    dial_code: "+380",
  },
  {
    country: "United Arab Emirates",
    currency_code: "AED",
    dial_code: "+971",
  },
  {
    country: "United Kingdom",
    currency_code: "GBP",
    dial_code: "+44",
  },
  {
    country: "United States",
    currency_code: "USD",
    dial_code: "+1",
  },
  {
    country: "Uruguay",
    currency_code: "UYU",
    dial_code: "+598",
  },
  {
    country: "Uzbekistan",
    currency_code: "UZS",
    dial_code: "+998",
  },
  {
    country: "Vanuatu",
    currency_code: "VUV",
    dial_code: "+678",
  },
  {
    country: "Venezuela",
    currency_code: "VEF",
    dial_code: "+58",
  },
  {
    country: "Vietnam",
    currency_code: "VND",
    dial_code: "+84",
  },
  {
    country: "Virgin Islands, British",
    currency_code: "USD",
    dial_code: "+1284",
  },
  {
    country: "Virgin Islands, U.S.",
    currency_code: "USD",
    dial_code: "+1340",
  },
  {
    country: "Wallis and Futuna",
    currency_code: "XPF",
    dial_code: "+681",
  },
  {
    country: "Yemen",
    currency_code: "YER",
    dial_code: "+967",
  },
  {
    country: "Zambia",
    currency_code: "ZMW",
    dial_code: "+260",
  },
  {
    country: "Zimbabwe",
    currency_code: "ZWD",
    dial_code: "+263",
  },
];
export default countries;
