const fields = [
  {
    name: "companyName",
    label: "Company Name",
    disabled: true,
    value: "companyName",
    select: true,
  },
  {
    name: "siteId",
    label: "Site Name",
    required: true,
    disabled: true,
    select: true,
    validationType: "number",
  },
  {
    name: "description",
    label: "Discount Description",
    required: true,
    type: "text",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "discount",
    label: "Discount Value",
    required: true,
    type: "number",
    validationType: "number",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },

  {
    name: "isActive",
    label: "Discount Status",
    switch: true,
    validationType: "boolean",
    label: "Discount Status",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
