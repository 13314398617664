import React from "react";
import { showLocalDate, tolocalDate } from "../../../utilities/functions";

const LocalDateEnd = ({ row }) => {
  return (
    <div>
      {row.endDate ? <>{showLocalDate(row.endDate)}</> : <>not specific</>}
    </div>
  );
};

export default LocalDateEnd;
