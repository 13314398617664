import React from "react";

const CostOfficial = ({ row }) => {
  return (
    <div>
      {row.details.length !== 0 ? (
        <div
          style={{
            borderBottom: "dashed 1px gray",
            marginBottom: "5px",
          }}
        >
          {row.details.map((service, index) => (
            <div style={{ paddingBottom: "5px" }}>
              {service?.costOfficialSum}
            </div>
          ))}
        </div>
      ) : null}
      <div>{row.costOfficialSum}</div>
    </div>
  );
};

export default CostOfficial;
