import { Route } from "react-router-dom";
import DeductionsList from "./DeductionsList";
import Deductions from "./Deductions";
import AddDeduction from "./AddDeduction";
import UpdateDeduction from "./UpdateDeduction";

export default (
  <Route path="payrollDeductions" exact element={<Deductions />}>
    <Route index element={<DeductionsList />} />
    <Route path="add/:id" element={<AddDeduction />} />
    <Route path="edit/:id" element={<UpdateDeduction />} />
  </Route>
);
