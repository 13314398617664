import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSubCompany } from "../Provider";
import BoxInfo from "./BoxInfo";
import TableInfo from "./TableInfo";

function InfoSubCompany() {
  let params = useParams();
  const id = params.id;
  const {
    state: { selected: subCompany },
    fetchOne: fetchSubCompany,
  } = useSubCompany();

  useEffect(() => {
    fetchSubCompany(id);
  }, [id, fetchSubCompany]);
  if (!subCompany) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Sub Company Details
      </Typography>
      <BoxInfo subCompany={subCompany} />
      <TableInfo subCompany={subCompany} />
    </>
  );
}

export default InfoSubCompany;
