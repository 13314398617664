const fields = [
  {
    label: "Caption",
    name: "caption",
    type: "text",

    validationType: "string",
  },
  {
    label: "Left Text",
    name: "leftText",
    type: "text",
    validationType: "string",
  },
  {
    label: "Right Text",
    name: "rightText",
    type: "text",

    validationType: "string",
  },
  // {
  //   label: "Show Official Price",
  //   name: "showOfficialPrice",
  //   type: "boolean",
  //   switch: true,
  //   validationType: "boolean",
  // },
  {
    label: "Show  Price",
    name: "showPrice",
    type: "boolean",
    select: true,
    validationType: "number",
  },
];

export default fields;
