import React from "react";
import { Typography } from "@mui/material";
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";

function PasswordCell({ row, i, renderParams }) {
  return (
    <>
      <Typography variant="Poster" component="span" gutterBottom>
        {row?.user?.displayName}
      </Typography>
      <Typography
        variant="Poster"
        component="h4"
        gutterBottom
        sx={{
          cursor: "pointer",
          color: "#FFC555",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => renderParams.openModal(row)}
      >
        <RestartAltTwoToneIcon
          sx={{ mr: "1px", mb: "-3px", fontSize: "15px" }}
        />
        Reset
      </Typography>
    </>
  );
}

export default PasswordCell;
