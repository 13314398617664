const fields = [
  {
    name: "companyName",
    label: "Company Name",
    disabled: true,
    select: true,
    value: "companyName",
  },
  {
    name: "description",
    label: "Description",
    required: true,
    disabled: false,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "isActive",
    label: "Secret Key Status",
    switch: true,
    validationType: "boolean",
    disabled: false,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
