import React from "react";

const ShowServicesList = ({ row }) => {
  return (
    <div>
      {row?.details.length !== 0 ? (
        <div style={{ marginBottom: "15px", borderBottom: "1px dashed gray" }}>
          {row.details.map((service, index) => (
            <div key={index} style={{ paddingBottom: "5px" }}>
              {service.serviceTitle}
            </div>
          ))}
        </div>
      ) : null}

      <div style={{ fontWeight: "bold" }}>Total</div>
    </div>
  );
};

export default ShowServicesList;
