import { useTheme } from "@emotion/react";
import { Button, Paper } from "@mui/material";
import React from "react";
import styled from "styled-components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../services/auth";

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 22px;
`;

const SMSInfo = ({ SMSInfo }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  const { role } = useAuth();

  return (
    <Paper
      elevation={0}
      // square
      sx={{
        p: 2,
        mt: 1,

        background: theme.color.PaperbgColor,
        width: "60%",
      }}
    >
      <div style={{ textAlign: "right" }}>
        {role === "Admin" || role === "Company" ? (
          <Button
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
            onClick={() =>
              navigate(`/sites/SiteDetail/${id}/UpdateSmsProvider`)
            }
          >
            Edit
          </Button>
        ) : (
          ""
        )}
      </div>
      <Grid2>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Title
        </span>
        <span>{SMSInfo?.title}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          User Name
        </span>
        <span>{SMSInfo?.username}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Password
        </span>
        <span>{SMSInfo?.password}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Sender
        </span>
        <span>{SMSInfo?.sender}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Balance Password
        </span>
        <span>{SMSInfo?.balancePassword}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Account ID
        </span>
        <span>{SMSInfo?.accountID}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Min SMS Alert
        </span>
        <span>{SMSInfo?.minSmsAlert}</span>

        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Status
        </span>
        {SMSInfo?.isActive ? (
          <span style={{ color: "#4AA785" }}>
            <FiberManualRecordIcon
              fontSize="small"
              sx={{ fontSize: 10, mr: 0.2 }}
            />
            Active
          </span>
        ) : (
          <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
            <FiberManualRecordIcon
              fontSize="small"
              sx={{ fontSize: 10, mr: 0.2 }}
            />
            No Active
          </span>
        )}
      </Grid2>
    </Paper>
  );
};

export default SMSInfo;
