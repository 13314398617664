import React from 'react'

const StyledEmployee = ({row}) => {
  return (
    <>
     { row?.employee?.employeeName}
    </>
  )
}

export default StyledEmployee
