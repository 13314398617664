import { Route } from "react-router-dom";

import ListZones from "./ListZones";
import Zones from "./Zones";
import DetailZone from "./DetailZone";
//import DetailService from "../Services/DetailService";
import AddService from "../Services/FixedService/AddService";
import UpdateService from "../Services/FixedService/UpdateService";
import AddHourlyService from "../Services/HourlyService/AddHourlyService";
import UpdateHourlyService from "../Services/HourlyService/UpdateHourlyService";
import DetailFixedService from "../Services/FixedService/DetailFixedService";
import DetailHourlyService from "../Services/HourlyService/DetailHourlyService";

export default (
  <Route path="zones" exact element={<Zones />}>
    <Route index element={<ListZones />} />
    {/* <Route path="SiteZoneDetails/:id/AddHourly" element={<AddHourly />} />
    <Route path="SiteZoneDetails/:id/AddFixed" element={<AddFixed />} /> */}
    {/* <Route path="SiteZoneDetails/:id/UpdateFixed" element={<UpdateFixed />} /> */}
    <Route path="SiteZoneDetails/:id" element={<DetailZone />} />
    {/* <Route
      path="SiteZoneDetails/ServiceDetail/:id"
      element={<DetailService />}
    /> */}
    <Route
      path="SiteZoneDetails/FixServiceDetail/:id"
      element={<DetailFixedService />}
    />
    <Route
      path="SiteZoneDetails/HourlyServiceDetail/:id"
      element={<DetailHourlyService />}
    />
    <Route path="SiteZoneDetails/AddService/:id" element={<AddService />} />
    <Route
      path="SiteZoneDetails/UpdateService/:id"
      element={<UpdateService />}
    />
    <Route
      path="SiteZoneDetails/AddHourlyService/:id"
      element={<AddHourlyService />}
    />
    <Route
      path="SiteZoneDetails/UpdateHourlyService/:id"
      element={<UpdateHourlyService />}
    />
  </Route>
);
