import { Route } from "react-router-dom";

import ListDiscounts from "./ListDiscounts";
import DisCounts from "./DisCounts";
import AddDisCount from "./AddDisCount";

export default (
  <Route path="discounts" exact element={<DisCounts />}>
    <Route index element={<ListDiscounts />} />
    <Route path="SiteDetail/AddDiscount/:id" element={<AddDisCount />} />
  </Route>
);
