import React from "react";
import { showLocalDate, tolocalDate } from "../../../utilities/functions";

const LocalDateStart = ({ row }) => {
  return (
    <div>
      <div>
        {row.startDate ? (
          <>{showLocalDate(row.startDate)}</>
        ) : (
          <>not specific</>
        )}
      </div>
    </div>
  );
};

export default LocalDateStart;
