import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";
import EmployeeForm from "./EmployeeForm";
// import Add_Done from "../../icons/Add_Done.svg";
import { useCompany } from "../Companies/Provider";
import { useAuth } from "../../services/auth";

function AddEmployee() {
  const navigate = useNavigate();
  const params = useParams();
  const { role, adminOn } = useAuth();

  const [companyName, setCompanyName] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };

  const {
    state: { list: companies },
    fetchList: fetchCompany,
  } = useCompany();

  useEffect(() => {
    if (role === "Admin") {
      fetchCompany();
    }
  }, [fetchCompany]);

  useEffect(() => {
    if (role == "Admin") {
      setCompanyName(
        companies.filter((c) => c.id == params.id).map((c) => c.companyName)
      );
    } else if (role === "Company") {
      setCompanyName(adminOn?.companyName);
    }
  }, [params]);

  if (!companyName) return;
  return (
    <div>
      <h3
        style={{
          fontWeight: "600",
        }}
      >
        Add Employee
      </h3>
      <div>
        <EmployeeForm handleSuccess={handleSuccess} companyName={companyName} />
      </div>
      {/* <ToastContainer /> */}
      <ModalBox
        open={open}
        handleClose={handleClose}
        icon="add"
        text="The add operation has been completed successfully.. You added new employee"
      />
    </div>
  );
}

export default AddEmployee;
