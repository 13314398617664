import { Typography } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ModalBox from '../../ui/ModalBox';
import FormCar from './FormCar';

const AddCar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
    <Typography variant="Poster" component="h4" gutterBottom
    >
      Add New Car
    </Typography>
    <FormCar handleSuccess={handleSuccess} />
    <ModalBox
      open={open}
      handleClose={handleClose}
      text="The add operation has been completed successfully.. You added new car"
      name="Car"
    />
  </>
  )
}

export default React.memo(AddCar);
