import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { CalDate, CalUtcWithFormat } from "../../utilities/functions";

const Context = createContext();

export function useReports() {
  return useContext(Context);
}

const initState = {
  list: [],
  listShift: [],
  listLessPayroll: [],
  listHighestPayroll: [],
  listSitesSales: [],
  listSitesSalesDaily: [],
  listSitesSalesMonthly: [],
  listSitesSalesWeekly: [],
  listSitesTickets: [],
  listTransaction: [],
  listTransactionServices: [],
  listVoidTransactions: [],
  listTransactionPaySer: [],
  PayServices: [],
  listPrevPayroll: [],
  test: [],
  FilterData: null,
  ExportExcelList: [],
  countExcel: 0,
  ExportShiftExcelList: [],
  countShiftExcel: 0,
  ExportVoidExcelList: [],
  countVoidExcel: 0,
  selected: null,
  selectedSiteShiftLive: null,
  companyIdAtt: null,
  EmployeesRoles: [],
  DailySales: [],
  siteIdAtt: null,
  PaidMethod: 0,
  form: null,
  to: null,
  selectedSite: null,
  count: 1,
  page: 1,
  perPage: 10,
  totalSize: 0,
  countShift: 1,
  totalSizeShift: 0,
  countVoid: 1,
  pageVoid: 1,
  perPageVoid: 10,
  totalSizeVoid: 0,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_test":
      return { ...state, test: [...action.payload] };
    case "set_listShift":
      return { ...state, listShift: [...action.payload] };
    case "set_PayServices":
      return { ...state, PayServices: [...action.payload] };
    case "set_listVoidTransactions":
      return { ...state, listVoidTransactions: [...action.payload] };
    case "set_listTransactionPaySer":
      return { ...state, listTransactionPaySer: [...action.payload] };
    case "set_EmployeesRoles":
      return { ...state, EmployeesRoles: [...action.payload] };
    case "set_DailySales":
      return { ...state, DailySales: [...action.payload] };
    case "set_listTransactionService":
      return { ...state, listTransactionServices: [...action.payload] };
    case "set_listPrevPayroll":
      return { ...state, listPrevPayroll: [...action.payload] };
    case "set_listlessPayroll":
      return {
        ...state,
        listLessPayroll: [...action.payload],
      };

    case "set_listSitesSales":
      return {
        ...state,
        listSitesSales: [...action.payload],
      };
    case "set_listHighPayroll":
      return {
        ...state,
        listHighestPayroll: [...action.payload],
      };
    case "set_listSitesTickets":
      return {
        ...state,
        listSitesTickets: [...action.payload],
      };
    case "set_listTransaction":
      return { ...state, listTransaction: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_FilterData":
      return { ...state, FilterData: { ...action.payload } };
    case "set_selectedSiteShiftLive":
      return { ...state, selectedSiteShiftLive: { ...action.payload } };
    case "set_ExportExcelList":
      return { ...state, ExportExcelList: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_countShift":
      return { ...state, countShift: action.payload };
    case "set_countExcel":
      return { ...state, countExcel: action.payload };
    case "set_countExcelVoid":
      return { ...state, countVoidExcel: action.payload };
    case "set_countVoid":
      return { ...state, countVoid: action.payload };
    case "set_selectedSite":
      return { ...state, selectedSite: action.payload };
    case "set_countShiftExcel":
      return { ...state, countShiftExcel: action.payload };
    case "set_CompanyIdAtt":
      return { ...state, companyIdAtt: action.payload };
    case "set_SiteIdAtt":
      return { ...state, siteIdAtt: action.payload };
    case "set_PaidMethod":
      return { ...state, PaidMethod: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_totalSize":
      return { ...state, totalSize: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_totalSizeShift":
      return { ...state, totalSizeShift: action.payload };
    case "set_totalSizeVoid":
      return { ...state, totalSizeVoid: action.payload };
    case "set_pageVoid":
      return { ...state, pageVoid: action.payload };
    case "set_per_pageVoid":
      return { ...state, perPageVoid: action.payload };
    case "set_from":
      return { ...state, from: action.payload };
    case "set_to":
      return { ...state, to: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ReportsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchListShift = useCallback(
    async (data, page, perPage = state.perPage) => {
      let headersShift = {
        pageNumber: state.page,
      };
      if (perPage) headersShift.pageSize = perPage;
      if (data?.fromDate)
        headersShift.fromDate = CalUtcWithFormat(data.fromDate);
      if (data?.toDate) headersShift.toDate = CalUtcWithFormat(data.toDate);
      const resData = await req(
        `SiteShift/List/${data?.sid}`,
        null,
        {},
        true,
        headersShift
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listShift", payload: resData.data.shifts || [] });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_countShift",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSize",
        payload: resData.data.totalSize || 0,
      });
    },
    [req, state.page, state.perPage]
  );
  const fetchTransactionService = useCallback(
    async (data, page, perPage = state.perPage) => {
      let headersShift = {
        from: "",
        to: "",
      };
      if (data?.fromDate) {
        headersShift.from = CalUtcWithFormat(data.fromDate);
        dispatch({ type: "set_from", payload: data.fromDate });
      }
      if (data?.toDate) {
        headersShift.to = CalUtcWithFormat(data.toDate);
        dispatch({ type: "set_to", payload: data.toDate });
      }
      const resData = await req(
        `Site/TransactionsByService/${data?.sid}`,
        null,
        {},
        true,
        headersShift
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_listTransactionService",
        payload: resData.data || [],
      });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_countShift",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSize",
        payload: resData.data.length,
      });
    },
    [req, state.page, state.perPage]
  );
  /**Transaction Payment Services */
  const fetchTransactionPaymentService = useCallback(
    async (data) => {
      let headersShift = {
        from: "",
        to: "",
      };
      if (data?.fromDate) {
        headersShift.from = CalUtcWithFormat(data.fromDate);
        dispatch({ type: "set_from", payload: data.fromDate });
      }
      if (data?.toDate) {
        headersShift.to = CalUtcWithFormat(data.toDate);
        dispatch({ type: "set_to", payload: data.toDate });
      }
      const resData = await req(
        `Site/TransactionsByServiceAndPaymentType/${data?.sid}`,
        null,
        {},
        true,
        headersShift
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_listTransactionPaySer",
        payload: resData.data || [],
      });
      for (let i = 0; i < resData.data.length; i++) {
        if (resData.data[i].serviceType === "Hourly") {
          for (let j = 0; j < resData.data[i].details.length; j++) {
            state.PayServices.push({
              type: resData.data[i].serviceType,
              title: resData.data[i].details[j].serviceTitle,
              paidOption: "Types",
              cars: resData.data[i].details[j],
              costTrial: resData.data[i].details[j],
              costOfficial: resData.data[i].details[j],
              tips: resData.data[i].details[j],
            });
          }
        }
      }
      state.PayServices.push({
        type: "",
        title: "",
        paidOption: "",
        cars: "",
        costTrial: "",
        costOfficial: "",
        tips: "",
      });
      for (let i = 0; i < resData.data.length; i++) {
        if (resData.data[i].serviceType === "Fixed") {
          for (let j = 0; j < resData.data[i].details.length; j++) {
            state.PayServices.push({
              type: resData.data[i].serviceType,
              title: resData.data[i].details[j].serviceTitle,
              paidOption: "Types",
              cars: resData.data[i].details[j],
              costTrial: resData.data[i].details[j],
              costOfficial: resData.data[i].details[j],
              tips: resData.data[i].details[j],
            });
          }
        }
      }
    },
    [req]
  );

  const fetchTransaction = useCallback(
    async (data, page = state.page, perPage = state.perPage) => {
      let headersTransaction = {
        pageNumber: page,
        from: "",
        to: "",
      };
      if (perPage) headersTransaction.pageSize = perPage;
      if (data?.fromDate)
        headersTransaction.from = CalUtcWithFormat(data.fromDate);
      if (data?.toDate) headersTransaction.to = CalUtcWithFormat(data.toDate);
      if (data?.printTimes) headersTransaction.printTimes = data.printTimes;
      if (data?.paymentType) headersTransaction.paymentType = data.paymentType;
      if (data?.mobile) headersTransaction.mobile = data.mobile;
      if (data?.serviceTitle)
        headersTransaction.serviceTitle = data.serviceTitle;
      if (data?.ticketNumber)
        headersTransaction.ticketNumber = data.ticketNumber;
      if (data?.carPlate) headersTransaction.carPlate = data.carPlate;

      const resData = await req(
        `Site/Transactions/${data?.sid}`,
        null,
        {},
        true,
        headersTransaction
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_listTransaction",
        payload: resData.data.transactions || [],
      });

      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data.totalPages || 1 });
      dispatch({ type: "set_totalSize", payload: resData.data.totalSize || 0 });
    },
    [req]
  );
  const fetchTransactionExcel = useCallback(
    async (data, page, perPage) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersTransaction = {
            pageNumber: page,
            from: "",
            to: "",
          };
          if (perPage) headersTransaction.pageSize = perPage;
          if (data?.fromDate) {
            headersTransaction.from = CalUtcWithFormat(data.fromDate);
            dispatch({ type: "set_from", payload: data.fromDate });
          }
          if (data?.toDate) {
            headersTransaction.to = CalUtcWithFormat(data.toDate);
            dispatch({ type: "set_to", payload: data.toDate });
          }
          if (data?.printTimes) headersTransaction.printTimes = data.printTimes;
          if (data?.paymentType)
            headersTransaction.paymentType = data.paymentType;
          if (data?.mobile) headersTransaction.mobile = data.mobile;
          if (data?.serviceTitle)
            headersTransaction.serviceTitle = data.serviceTitle;
          if (data?.ticketNumber)
            headersTransaction.ticketNumber = data.ticketNumber;
          if (data?.carPlate) headersTransaction.carPlate = data.carPlate;

          dispatch({ type: "status", payload: "fetching" });
          const resData = await req(
            `Site/Transactions/${data?.sid}`,
            null,
            {},
            true,
            headersTransaction
          );
          if (state.countExcel == 0) {
            dispatch({
              type: "set_countExcel",
              payload: resData.data.totalPages,
            });
          }
          dispatch({ type: "set_page", payload: page });
          dispatch({ type: "status", payload: `idle` });
          state.ExportExcelList.push(resData.data.transactions);
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  /**fetch void to excel */
  const fetchVoidTransactionExcel = useCallback(
    async (data, page, perPage) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersTransaction = {
            pageNumber: page,
            from: "",
            to: "",
          };
          if (perPage) headersTransaction.pageSize = perPage;
          if (data?.fromDate) {
            headersTransaction.from = CalUtcWithFormat(data.fromDate);
            dispatch({ type: "set_from", payload: data.fromDate });
          }
          if (data?.toDate) {
            headersTransaction.to = CalUtcWithFormat(data.toDate);
            dispatch({ type: "set_to", payload: data.toDate });
          }
          if (data?.printTimes) headersTransaction.printTimes = data.printTimes;
          dispatch({ type: "status", payload: "fetching" });

          const resData = await req(
            `Site/VoidTransactions/${data?.sid}`,
            null,
            {},
            true,
            headersTransaction
          );
          if (state.countVoidExcel == 0) {
            //state.ExportVoidExcelList.shift();
            dispatch({
              type: "set_countExcelVoid",
              payload: resData.data.totalPages,
            });
          }
          dispatch({ type: "set_page", payload: page });
          dispatch({ type: "status", payload: `idle` });
          state.ExportVoidExcelList.push(resData.data.transactions);

          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  /**fetch Shift to excel */
  const fetchShiftTransactionExcel = useCallback(
    async (data, page, perPage) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersTransaction = {
            pageNumber: page,
            from: "",
            to: "",
          };
          if (perPage) headersTransaction.pageSize = perPage;
          if (data?.fromDate) {
            headersTransaction.from = CalUtcWithFormat(data.fromDate);
            dispatch({ type: "set_from", payload: data.fromDate });
          }
          if (data?.toDate) {
            headersTransaction.to = CalUtcWithFormat(data.toDate);
            dispatch({ type: "set_to", payload: data.toDate });
          }
          if (data?.printTimes) headersTransaction.printTimes = data.printTimes;
          dispatch({ type: "status", payload: "fetching" });

          const resData = await req(
            `SiteShift/List/${data?.sid}`,
            null,
            {},
            true,
            headersTransaction
          );
          if (state.countShiftExcel == 0) {
            //state.ExportShiftExcelList.shift();

            dispatch({
              type: "set_countShiftExcel",
              payload: resData.data.totalPages,
            });
          }
          dispatch({ type: "set_page", payload: page });
          dispatch({ type: "status", payload: `idle` });
          state.ExportShiftExcelList.push(resData.data.shifts);

          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const fetchSiteShiftLive = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`SiteShift/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selectedSiteShiftLive", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  /** void transactions */
  const fetchVoidTransaction = useCallback(
    async (data, page, perPage = state.perPage) => {
      let headersVoidTransaction = {
        pageNumber: state.page,
        from: "",
        to: "",
      };
      if (perPage) headersVoidTransaction.pageSize = perPage;
      if (data?.fromDate)
        headersVoidTransaction.from = CalUtcWithFormat(data.fromDate);
      if (data?.toDate)
        headersVoidTransaction.to = CalUtcWithFormat(data.toDate);
      if (data?.printTimes) headersVoidTransaction.printTimes = data.printTimes;

      const resData = await req(
        `Site/VoidTransactions/${data?.sid}`,
        null,
        {},
        true,
        headersVoidTransaction
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_listVoidTransactions",
        payload: resData.data.transactions || [],
      });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_countVoid",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSize",
        payload: resData.data.totalSize || 0,
      });
    },
    [req, state.page, state.perPage]
  );
  /**less payroll list */
  const fetchLessPayroll = useCallback(
    async (id, data) => {
      const headersLessPayroll = {
        from: data.fromDate
          ? CalUtcWithFormat(data.fromDate)
          : CalUtcWithFormat(state.from),
        to: data?.toDate
          ? CalUtcWithFormat(data.toDate)
          : CalUtcWithFormat(state.to),
      };
      dispatch({ type: "set_from", payload: data.fromDate });
      dispatch({ type: "set_to", payload: data.toDate });
      const resData = await req(
        `Report/LessPayroll/${id}`,
        null,
        {},
        true,
        headersLessPayroll
      );

      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listlessPayroll", payload: resData.data || [] });
      dispatch({ type: "set_totalSize", payload: resData.data.length || 0 });
    },
    [req]
  );
  /**preview payroll */
  const fetchPrevPayroll = useCallback(
    async (id, data) => {
      const headersLessPayroll = {
        from: data.fromDate
          ? CalUtcWithFormat(data.fromDate)
          : CalUtcWithFormat(state.from),
        to: data?.toDate
          ? CalUtcWithFormat(data.toDate)
          : CalUtcWithFormat(state.to),
      };
      dispatch({ type: "set_from", payload: data.fromDate });
      dispatch({ type: "set_to", payload: data.toDate });
      const resData = await req(
        `Report/LessPayroll/${id}`,
        null,
        {},
        true,
        headersLessPayroll
      );

      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listPrevPayroll", payload: resData.data || [] });
      dispatch({ type: "set_totalSize", payload: resData.data.length || 0 });
    },
    [req]
  );
  /**Highest payroll list */
  const fetchHighestPayroll = useCallback(
    async (id, data) => {
      const headersHighestPayroll = {
        from: data.fromDate
          ? CalUtcWithFormat(data.fromDate)
          : CalUtcWithFormat(state.from),
        to: data.toDate
          ? CalUtcWithFormat(data.toDate)
          : CalUtcWithFormat(state.to),
      };
      dispatch({ type: "set_from", payload: data.fromDate });
      dispatch({ type: "set_to", payload: data.toDate });
      const resData = await req(
        `Report/HighestPayroll/${id}`,
        null,
        {},
        true,
        headersHighestPayroll
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listHighPayroll", payload: resData.data || [] });
      dispatch({ type: "set_totalSize", payload: resData.data.length || 0 });
    },
    [req]
  );
  /**sites by sales list */
  const fetchSitesBySales = useCallback(
    async (id, data) => {
      const headersSitesSales = {
        from: data.fromDate
          ? CalUtcWithFormat(data?.fromDate)
          : CalUtcWithFormat(state.from),
        to: data.toDate
          ? CalUtcWithFormat(data?.toDate)
          : CalUtcWithFormat(state.to),
      };
      dispatch({ type: "set_from", payload: data.fromDate });
      dispatch({ type: "set_to", payload: data.toDate });
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Report/SitesBySales/${id}`,
        null,
        {},
        true,
        headersSitesSales
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listSitesSales", payload: resData.data || [] });
      dispatch({ type: "set_totalSize", payload: resData.data.length || 0 });
    },
    [req]
  );
  /**sites by Ticket list */
  const fetchSitesByTickets = useCallback(
    async (id, data) => {
      const headersTickets = {
        from: data.fromDate
          ? CalUtcWithFormat(data?.fromDate)
          : CalUtcWithFormat(state.from),
        to: data.toDate ? CalUtcWithFormat(data?.toDate) : state.to,
      };
      dispatch({ type: "set_from", payload: data.fromDate });
      dispatch({ type: "set_to", payload: data.toDate });

      const resData = await req(
        `Report/SitesByTickets/${id}`,
        null,
        {},
        true,
        headersTickets
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listSitesTickets", payload: resData.data || [] });
      dispatch({ type: "set_totalSize", payload: resData.data.length || 0 });
    },
    [req]
  );
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "Site/Create",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
          // console.log(resData, "resData");
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Site/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Site/Delete/${id}`,
            null,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: resData.data });
          // console.log(resData, "delete");
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const fetchEmployeesRoles = useCallback(
    async (data) => {
      let headersShift = {
        from: "",
        to: "",
      };
      if (data?.fromDate) {
        dispatch({ type: "set_from", payload: data.fromDate });
        headersShift.from = CalUtcWithFormat(data.fromDate);
      }
      if (data?.toDate) {
        dispatch({ type: "set_to", payload: data.toDate });
        headersShift.to = CalUtcWithFormat(data.toDate);
      }
      const resData = await req(
        `Report/SiteRolesEmployees/${data?.sid}`,
        null,
        {},
        true,
        headersShift
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_EmployeesRoles",
        payload: resData.data || [],
      });

      dispatch({
        type: "set_totalSize",
        payload: resData.data.length,
      });
    },
    [req]
  );
  /**Daily sales payroll */
  const DailySalesPayroll = useCallback(
    async (id, sid, data) => {
      let headersShift = {
        from: "",
        to: "",
      };
      if (data?.fromDate) headersShift.from = CalUtcWithFormat(data.fromDate);
      if (data?.toDate) headersShift.to = CalUtcWithFormat(data.toDate);
      const resData = await req(
        `Report/DailySalesPayrolls/${id}, ${sid}`,
        null,
        {},
        true,
        headersShift
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_DailySales",
        payload: resData.data || [],
      });

      dispatch({
        type: "set_totalSize",
        payload: resData.data.length,
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchListShift,
        fetchSiteShiftLive,
        fetchTransaction,
        fetchTransactionExcel,
        fetchLessPayroll,
        fetchHighestPayroll,
        fetchSitesBySales,
        fetchSitesByTickets,
        fetchVoidTransaction,
        fetchTransactionService,
        fetchEmployeesRoles,
        fetchVoidTransactionExcel,
        fetchShiftTransactionExcel,
        fetchTransactionPaymentService,
        DailySalesPayroll,
        fetchPrevPayroll,
        edit,
        create,
        remove,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
