// React
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Components
import Form from "./Form";
// Material UI
import ModalBox from "../../../../ui/ModalBox";
// Providers
import { useMaterial } from "../Provider";

const Edit = () => {
  const navigate = useNavigate();
  let params = useParams();
  const id = params.id;
  const {
    state: { selected },
    fetchOne: fetchMaterial,
  } = useMaterial();
  const [open, setOpen] = useState(false);
  const [material, setMaterial] = useState(selected);

  useEffect(() => {
    fetchMaterial(id);
  }, [id, fetchMaterial]);

  useEffect(() => {
    setMaterial(selected);
  }, [selected]);

  const handleSuccess = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  
  if (!material) return;
  if (material?.id != id) return;
  return (
    <div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Edit Material
      </div>
      <div>
        <Form item={material} handleSuccess={handleSuccess} />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.. You modified the material "
        name={material?.name}
      />
    </div>
  );
};

export default React.memo(Edit);
