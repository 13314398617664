import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAccount } from "./Provider";
import { useCompany } from "../Companies/Provider";
// material ui
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Alert,
  Box,
  TextField,
  Button,
  useTheme,
} from "@mui/material";
// components
import CustomTable from "../../ui/CustomTable";
import ActiveCell from "../Companies/CompanyTable/ActiveCell";
import PasswordCell from "./UserAccountTable/PasswordCell";
import AdminRole from "./UserAccountTable/AdminRole";
import UserRole from "./UserAccountTable/UserRole";
import TabsList from "../../ui/TabsList";
import ModalBox from "../../ui/ModalBox";
// Style
import "../style.css";
import styled from "styled-components";
import PassModal from "../../ui/PassModal";
import { useAuth } from "../../services/auth";
import { FilterList } from "@mui/icons-material";
/** form */
import { useForm } from "react-hook-form";

// Yup
import { yupResolver } from "@hookform/resolvers/yup";
// Yup
import * as Yup from "yup";
const GridGroup1 = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  grid-template-columns: 325px 225px 325px;
  gap: 10px;
  grid-template-rows: 75px;
  grid-column-gap: 10px;
`;
const formSchema = Yup.object().shape({
  companyId: Yup.number(),
  search: Yup.string(),
});
const ListUserAccounts = () => {
  // Providers
  let theme = useTheme();
  let navigate = useNavigate();
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  const {
    state: {
      listUser: usersList,
      listAdmin: adminsList,
      countUser,
      countAdmin,
      totalSizeUsers,
      totalSizeAdmins,
      filter,
      page,
      perPage,
    },
    fetchUserList,
    fetchAdminList,
    updateAccount,
    deleteAccount,
    dispatch,
  } = useUserAccount();
  let { role, adminOn } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  // Use state
  const [selectedCols, setSelectedCols] = useState("Admins");
  const [open, setOpen] = useState(false);
  const [reload, setreload] = useState(false);
  const [vState, setVState] = useState(false);
  const [error, setError] = useState(null);
  const [userforDelete, setUserforDelete] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(filter.companyId);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [employeeIdResetPassword, setEmployeeIdResetPassword] = useState(1);
  const tabs = [
    { title: "Admins", num: totalSizeAdmins },
    { title: "Users", num: totalSizeUsers },
  ];
  useEffect(() => {
    if (role === "Company")
      dispatch({
        type: "set_filter",
        payload: { companyId: adminOn?.id },
      });
  }, [role]);
  //fetch companies list
  useEffect(() => {
    if (companies?.length === 0 && role === "Admin") fetchCompany(100);
  }, [fetchCompany]);

  //fetch default data
  useEffect(() => {
    if (role === "Admin") {
      fetchUserList(0, page, perPage, "");
      fetchAdminList(0, page, perPage, "");
    } else if (role === "Company") {
      fetchUserList(adminOn?.id, page, perPage, "");
      fetchAdminList(adminOn?.id, page, perPage, "");
    }
  }, [page, perPage]);
  //handle pagination
  useEffect(() => {
    if (role === "Company") {
      fetchUserList(adminOn?.id, page, perPage, "");
      fetchAdminList(adminOn?.id, page, perPage, "");
    } else if (role === "Admin") {
      fetchUserList(getValues("companyId"), page, perPage, getValues("search"));
      fetchAdminList(
        getValues("companyId"),
        page,
        perPage,
        getValues("search")
      );
    }
  }, [page, perPage]);
  // useEffect(() => {
  //
  //   if (role !== "Admin") {
  //     if (filter?.companyId !== 0) {
  //       fetchUserList(filter?.companyId);
  //       fetchAdminList(filter?.companyId);
  //     }
  //   }
  //   if (role === "Admin") {
  //     fetchUserList(filter?.companyId);
  //     fetchAdminList(filter?.companyId);
  //   }
  // }, [fetchUserList, fetchCompany, fetchAdminList, filter]);
  // useEffect(() => {
  //   console.log(adminsList, "adminsList");
  // }, [adminsList]);

  // Functions
  // Modal Reset Password
  const handleCloseResetPass = () => {
    setOpenResetPass(false);
  };
  const handleOpenResetPass = (user) => {
    setOpenResetPass(true);
    setEmployeeIdResetPassword(user.id);
  };
  // handle close box
  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  // handle change selected company
  const handleChange = (event) => {
    dispatch({
      type: "set_filter",
      payload: { companyId: event.target.value },
    });
    setSelectedCompany(event.target.value);
    // fetchUserList(event.target.value);
    // fetchAdminList(event.target.value);
  };
  // handle delete
  const handleDelete = async (id, listName) => {
    try {
      await deleteAccount(id, listName);
      if (listName === "adminList")
        fetchAdminList(filter?.companyId, page, perPage);
      else fetchUserList(filter?.companyId, page, perPage);
    } catch (e) {
      console.log("error", e);
    }
  };
  const handleEdite = async (e, ID) => {
    const tempId = e[0].id;
    delete e[0].id;
    delete e[0].avatar;
    delete e[0].company;
    delete e[0].employee;
    delete e[0].roles;
    delete e[0].site;
    delete e[0].subCompany;
    delete e[0].userName;

    switch (selectedCols) {
      case "Admins":
        {
          try {
            await updateAccount(tempId, e[0], "adminList");
            handleSuccess();
            fetchAdminList(filter?.companyId, page, perPage);
          } catch (error) {
            console.log(error, "error");
            setError({ title: error.errors.message });
            fetchAdminList(filter?.companyId, page, perPage);
          }
        }
        break;
      case "Users":
        {
          try {
            await updateAccount(tempId, e[0], "userList");
            handleSuccess();
            fetchUserList(filter?.companyId, page, perPage);
          } catch (error) {
            console.log(error, "error");
            setError({ title: error.errors.message });
            fetchUserList(filter?.companyId, page, perPage);
          }
        }
        break;
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Admins":
        return adminsList;
      case "Users":
        return usersList;
      default:
        return;
    }
  };
  const getCols = (name) => {
    switch (name) {
      case "Admins":
        return [
          {
            label: "Display Name",
            name: "displayName",
            editable: true,
            headTextLeft: true,
          },
          { label: "Username", name: "userName" },
          { label: "Email", name: "email", editable: true },
          {
            label: "Admin Status",
            name: "isActive",
            editable: true,
            customRender: ActiveCell,
          },
          {
            label: "Password",
            name: "password",
            customRender: PasswordCell,
          },
          { label: "Role Information", name: "roles", customRender: AdminRole },
        ];
      case "Users":
        return [
          {
            label: "Display Name",
            name: "displayName",
            editable: true,
            headTextLeft: true,
          },
          { label: "Username", name: "userName" },
          { label: "Email", name: "email", editable: true },
          {
            label: "User Status",
            name: "isActive",
            editable: true,
            customRender: ActiveCell,
          },
          {
            label: "Password",
            name: "password",
            customRender: PasswordCell,
          },
          { label: "Role Information", name: "roles", customRender: UserRole },
        ];
      default:
        return;
    }
  };
  const getFilters = (name) => {
    switch (name) {
      case "Admins":
        return {
          pagination: true,
          page: perPage,
          changePageSize: (size) =>
            dispatch({ type: "set_per_page", payload: size }),
          add: () => navigate(`/UserAccounts/addAdmin`),
        };
      case "Users":
        return {
          pagination: true,
          page: perPage,
          changePageSize: (size) =>
            dispatch({ type: "set_per_page", payload: size }),
          add: () => navigate(`/UserAccounts/addUser`),
          search: (e) => {
            console.log(e);
          },
        };
      default:
        return;
    }
  };

  const getActions = (name) => {
    switch (name) {
      case "Admins":
        return {
          selectedCols: true,
          inTableEdit: true,
          edit: handleEdite,
          delete: (e) => {
            handleDelete(e, "adminList");
          },
          check: (v) => v.length > 0,
          vState: vState,
          setVState: setVState,
        };
      case "Users":
        return {
          selectedCols: true,
          inTableEdit: true,
          edit: handleEdite,
          delete: (e) => {
            console.log(e, "error");
            handleDelete(e, "userList");
          },
          check: (v) => v.length > 0,
          vState: vState,
          setVState: setVState,
        };
      default:
        return;
    }
  };
  const onSubmit = async (values) => {
    try {
      if (role === "Company") {
        if (filter?.companyId !== 0) {
          await fetchUserList(filter?.companyId, page, perPage, values.search);
          await fetchAdminList(filter?.companyId, page, perPage, values.search);
        }
      }
      if (role === "Admin") {
        await fetchUserList(values?.companyId, page, perPage, values.search);
        await fetchAdminList(values?.companyId, page, perPage, values.search);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  if (!usersList) return <div className="loader"></div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {role === "Admin" && (
        <>
          <Typography variant="Poster" component="h4" mb={2}>
            Select Company
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: "fit-content" }}>
              {error}
            </Alert>
          )}
          <Box sx={{ width: "1000px", background: "#f7f9fb" }}>
            <GridGroup1>
              <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
                <span style={{ fontSize: "12px" }}>Company Name</span>
                <Select
                  value={filter?.companyId}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    autoFocus: true,
                    ...register("companyId"),
                  }}
                  sx={{
                    fontSize: "12px",
                    width: "300px",
                    height: "40px",
                    marginTop: "14px",
                  }}
                >
                  <MenuItem value={0}>
                    <em>ALL</em>
                  </MenuItem>
                  {companies.map((company) => {
                    return (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box>
                <FormControl sx={{ minWidth: 120, marginBottom: "30px" }}>
                  <span style={{ fontSize: "12px" }}>Search</span>
                  <TextField
                    inputProps={{
                      ...register("search"),
                    }}
                    sx={{ marginTop: "20px" }}
                    size="small"
                  />
                </FormControl>
              </Box>
            </GridGroup1>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  color: "white",
                  mr: 5,
                  mb: 2,
                  "&:hover": { background: `${theme.color.blackBackground}` },
                  textTransform: "capitalize",
                }}
              >
                <FilterList />
                <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
              </Button>
            </Box>
          </Box>
        </>
      )}
      {role === "Company" && (
        <Box sx={{ width: "1000px", background: "#f7f9fb" }}>
          <GridGroup1>
            <Box mb={10}>
              <Typography variant="body2" component="h6" gutterBottom>
                Company Name
              </Typography>
              {/* Adding sub company => !subcompany */}
              <Box
                sx={{
                  borderBottom: "1px solid gray",
                  width: "290px",
                  borderRadius: "8px",
                  textAlign: "center",
                  mt: 5,
                }}
              >
                {adminOn?.companyName}
              </Box>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 120, marginBottom: "30px" }}>
                <span style={{ fontSize: "12px" }}>Search</span>
                <TextField
                  inputProps={{
                    ...register("search"),
                  }}
                  sx={{ marginTop: "20px" }}
                  size="small"
                />
              </FormControl>
            </Box>
          </GridGroup1>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                color: "white",
                mr: 5,
                mb: 2,
                "&:hover": { background: `${theme.color.blackBackground}` },
                textTransform: "capitalize",
              }}
            >
              <FilterList />
              <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
            </Button>
          </Box>
        </Box>
      )}

      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      />
      <CustomTable
        empty={getData(selectedCols)?.length === 0}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        filters={getFilters(selectedCols)}
        renderParams={{
          openModal: handleOpenResetPass,
        }}
        actions={getActions(selectedCols)}
        showButton={true}
        page={page}
        rowsPerPage={perPage}
        dispatch={dispatch}
        count={selectedCols === "Admins" ? countAdmin : countUser}
      ></CustomTable>
      <ModalBox
        open={open}
        operation="edit"
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
      />
      <PassModal
        open={openResetPass}
        type="reset"
        handleClose={handleCloseResetPass}
        handleSuccess={() => setOpenSuccess(true)}
        employeeId={employeeIdResetPassword}
      />
      <ModalBox
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        operation="success"
        text="Password changed successfully.. you can now use the new password"
      />
    </form>
  );
};

export default React.memo(ListUserAccounts);
