const RatesField = [
  {
    name: "siteId",
    label: "Site Name",
    disabled: true,
  },
  {
    name: "roleId",
    label: "Role",
    disabled: true,
  },
  {
    name: "hourlyRate",
    label: "Hourly Rate",
    type: "number",
  },
  {
    name: "zoneIds",
    label: "Zones",
    multi_Select: true,
  },
];

export default RatesField;
