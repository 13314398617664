import {
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  FormHelperText,
  Typography,
  useTheme,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import fields from "./fields";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { Box } from "@mui/system";
import ErrorsList from "../../ui/ErrorsList";
import { BiTrash } from "react-icons/bi";
import { useSite } from "../Sites/Provider";
import { useEmployees } from "./Provider";
import { CalDate, handleErrorResponse } from "../../utilities/functions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

const locales = ["en-gb"];
//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 0 20px;
`;
const Grid3 = styled.div`
  max-width: 770px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const ButtonWrapper = styled.div`
  button {
    float: right;
    margin-left: 20px;
  }
`;
const ShiftsWrap = styled.div`
  max-width: 93%;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 24px;
    gap: 30px;
    position: relative;
    row-gap: 10;
  }
`;
const Grid2 = styled.div`
  width: 98%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 32px;
  row-gap: 10;
  margin-left: 20px;
  h4 {
    margin: 0;
  }
  p {
    color: red;
  }
  .trash {
    margin-top: 25px;
  }
  .addSite {
    display: block;
    justify-self: center;
    cursor: pointer;
    color: #95a4fc;
    text-decoration: underline;
  }

  .css-1q9ag5b-MuiGrid-root > .MuiGrid-item {
    padding: 0px;
  }
  .MuiSelect-multiple {
    padding-top: 16 px;
  }
`;

const yupSchema = fields.reduce(createYupSchema, {
  mobile: Yup.string()
    .max(20, "mobile number must be less than 10 num")
    .required("Mobile Number is Required"),
  fullName: Yup.string().required("Full Name is Required"),
  nssfNumber: Yup.string().required("nssfNumber is Required"),
  companyId: Yup.number(),
  employeeCode: Yup.string(),
  startDate: Yup.date().required(),
  employeeID: Yup.string().required("Employee ID is Required"),
  sites: Yup.array().of(
    Yup.object().shape({
      siteId: Yup.string().required("require field"),
      roles: Yup.array().min(1, "require field"),
    })
  ),
});
const schema = Yup.object().shape(yupSchema);
function EmployeeForm({
  handleSuccess,
  employee,
  companyName,
  setafterUpdate,
}) {
  let navigate = useNavigate();
  const params = useParams();
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(params.id ? params.id : 0);
  const [selectedSiteId, setSelectedSiteId] = useState([]);
  const [siteListFiltered, setSiteListFiltered] = useState();
  const [locale, setLocale] = useState("en-gb");
  let theme = useTheme();

  const [sites, setSites] = useState(
    Array(1)
      .fill(0)
      .map(() => ({ siteId: "", roles: [] }))
  );
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setRoles([value, ...roles]);
  // };
  const {
    register,
    handleSubmit,
    reset,

    setValue,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (employee) {
      let sh = employee.sites.map((sites, i) => {
        delete sites.siteName;
        sites.siteId = sites.siteId;
        sites.roles = sites.roles.map((s) => s?.roleId);
        return sites;
      });
      setSites(sh);
    }
  }, [employee]);
  useEffect(() => {
    setValue("sites", sites);
  }, [sites]);
  useEffect(() => {
    if (companyName) {
      setValue("companyId", params.id);
    } else {
      setValue("companyId", employee.companyId);
    }
  }, [companyName, employee]);
  // useEffect(() => {
  //   fetchCompany(companyId);
  // }, [fetchCompany, companyId]);
  useEffect(() => {
    setCompanyId(employee?.company?.companyId);
  }, [employee]);

  function displayShiftsFields() {
    if (employee && siteList?.length === 0) return;
    return sites.map((site, i) => (
      <div key={i}>
        <div>
          <TextField
            label="Site"
            select
            size="small"
            data={siteList}
            sx={{
              width: theme.dimensions.width,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
            helperText={errors["sites"] && errors["sites"][i]?.siteId?.message}
            inputProps={{
              onChange: (e) => {
                setSelectedSiteId((prev) => [...prev, e.target.value]);
                setSiteListFiltered(e.target.value);
                setSites((sites) => {
                  sites[i].siteId = e.target.value;
                  setValue("sites", [...sites]);
                  return [...sites];
                });
              },
              required: true,
              type: "text",
            }}
            required
          >
            {siteList &&
              siteList.map((c, i) => (
                <MenuItem
                  key={i}
                  value={c.id}
                  disabled={selectedSiteId.includes(c.id)}
                >
                  {c.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
        <div>
          {" "}
          <FormControl sx={{ width: 275 }} required>
            <Select
              id="demo-multiple-chip"
              label="Role"
              multiple
              value={sites[i].roles || []}
              size="small"
              onChange={(e) => {
                setSites((sites) => {
                  sites[i].roles = e.target.value;
                  delete sites[0][0];
                  setValue("sites", sites);
                  return [...sites];
                });
              }}
              sx={{
                width: theme.dimensions.width,
                mt: theme.dimensions.mt,
                mb: theme.dimensions.mb - 1,
              }}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.2,
                  }}
                >
                  {sites[i].roles &&
                    sites[i].roles.map((value, j) => {
                      return <Chip key={j} label={value} />;
                    })}
                </Box>
              )}
            >
              {siteInfo?.roleRates.map((role, index) => (
                <MenuItem key={index} value={role.roleId}>
                  {role.role}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {errors && errors["sites"] && errors["sites"][i]?.roles?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <BiTrash onClick={() => remoeSite(i)} className="trash" />
      </div>
    ));
  }

  // const onChange = useCallback(
  //   (v, name) => {
  //     if (name === "companyId") setCompanyId(v.target.value);
  //     else setValue(name, v.target.value);
  //   },
  //   [setValue]
  // );
  const { create, edit, uploadPhoto, uploadEmployeeContract } = useEmployees();

  const {
    state: { Names: siteList, selected: siteInfo },
    fetchSiteNames: fetchSites,
    fetchOne: fetchSite,
  } = useSite();

  useEffect(() => {
    if (employee) {
      fetchSites(employee?.companyId, 0);
    } else {
      fetchSites(params.id, 0);
    }
  }, [employee, params.id]);
  useEffect(() => {
    if (siteListFiltered) fetchSite(siteListFiltered);
  }, [siteListFiltered]);

  const addSite = () => {
    setSites((sites) => {
      sites.push({ siteId: "", roles: [] });
      return [...sites];
    });
  };

  const remoeSite = (i) => {
    setSites(sites.filter((s, i2) => i !== i2));
  };

  const onSubmit = async (values) => {
    values.startDate
      ? (values.startDate = CalDate(values.startDate))
      : (values.startDate = CalDate(employee?.startDate));
    try {
      if (employee) {
        await edit(params.id, values);
        setafterUpdate(true);
      } else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 409)
        setError({
          title: `There is another employee with the same name or same shift number`,
        });
      else setError({ title: handleErrorResponse(e.status) });
    }
  };
  const uploadContract = (file, id) => {
    uploadEmployeeContract(file, id);
  };
  const getData = (name) => {
    switch (name) {
      case "sites":
        return sites;
    }
  };

  if (employee && !sites) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        variant="outlined"
        square
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          Employee Details
        </Typography>
        <Box mb={2}>
          {siteList.length === 0 && !employee && (
            <Alert severity="error" sx={{ m: 3 }}>
              <Typography variant="Poster" component="span">
                {" "}
                You should add a new Site first,{" "}
                <Typography
                  variant="Poster"
                  component="span"
                  onClick={() => navigate(`/sites/add/${params.id}`)}
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                >
                  <strong>Add Site</strong>
                </Typography>
              </Typography>
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ m: 3 }}>
              <ErrorsList error={error} />
            </Alert>
          )}
        </Box>
        <GridGroup>
          {fields?.map((f, i) => {
            if (f.viewOnly) return null;
            const inputProps = { ...f.inputProps };

            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <Box key={i}>
                {f.switch && (
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          mt: theme.dimensions.mt * 2,
                        }}
                        required={f.required}
                        control={
                          <Switch
                            color="success"
                            defaultChecked={
                              employee ? employee?.isActive : true
                            }
                          />
                        }
                        {...register(f?.name)}
                        label="Active"
                      />
                    </FormGroup>
                  </Box>
                )}
                {f.select && (
                  <>
                    <FormControl
                      sx={{
                        width: theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                      }}
                      required={f.required}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {f.label}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={f.label}
                        size="small"
                        inputProps={{
                          defaultValue: employee && employee[f.name],
                          ...register(f?.name),
                          required: true,
                        }}
                        required={true}
                      >
                        {getData(f.getData)?.map((s, index) => (
                          <MenuItem key={index} value={s.id}>
                            {s[f.data]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {f.disabled && (
                  <>
                    <Typography variant="body2" component="h6" gutterBottom>
                      {f.label}
                    </Typography>

                    <Box
                      sx={{
                        borderBottom: "1px solid gray",
                        width: "290px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      {companyName
                        ? companyName
                        : employee?.company?.companyName}
                    </Box>
                  </>
                )}
                {f.date && (
                  <LocalizationProvider
                    key={f.name}
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <div components={["DateRangePicker"]}>
                      <DatePicker
                        label={f.label}
                        onChange={(date) => setValue(f.name, date)}
                        defaultValue={employee && dayjs(employee?.[f.name])}
                        error={!!errors[f.name]}
                        //   format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            required: f.required,
                            error: errors[f.name]?.message,
                            helperText: (
                              <p style={{ color: "#d32f2f" }}>
                                {errors[f.name]?.message}
                              </p>
                            ),
                            size: "small",
                          },
                        }}
                        sx={{ mt: 2, width: "300px" }}
                      />
                    </div>
                  </LocalizationProvider>
                )}
                {!f.disabled && !f.switch && !f.date && (
                  <TextField
                    label={f.label}
                    name={f.name}
                    error={!!errors[f.name]}
                    helperText={errors[f.name]?.message}
                    select={f.select}
                    className={f.class}
                    fullWidth
                    disabled={f.disabled}
                    size="small"
                    sx={{
                      width: theme.dimensions.width,
                      mt: theme.dimensions.mt,
                      mb: theme.dimensions.mb,
                    }}
                    defaultValue={employee?.[f.name]}
                    inputProps={{
                      ...register(f?.name),
                      required: f.required,
                    }}
                    required={f.required}
                  ></TextField>
                )}
              </Box>
            );
          })}
        </GridGroup>

        <div>
          <Grid2>
            {!employee && (
              <>
                <ShiftsWrap>{displayShiftsFields()}</ShiftsWrap>

                <div>
                  <p className="addSite" onClick={addSite}>
                    + Add more site
                  </p>
                </div>
              </>
            )}
            {employee && (
              <Grid3>
                <Box>
                  <Typography variant="body2" component="h6" gutterBottom>
                    Employee Photo
                  </Typography>
                  <TextField
                    type="file"
                    fullWidth
                    readOnly={true}
                    helperText="User Photo :  Accepts .svg, .gif, .jpg, and .png"
                    onChange={(v) => uploadPhoto(v.target.files[0], params.id)}
                    sx={{
                      width: theme.dimensions.width,
                      mt: theme.dimensions.mt,
                      mb: theme.dimensions.mb,
                    }}
                  ></TextField>
                </Box>
                <Box>
                  <Typography variant="body2" component="h6" gutterBottom>
                    Employee Contract
                  </Typography>
                  <TextField
                    type="file"
                    fullWidth
                    readOnly={true}
                    onChange={(v) =>
                      uploadContract(v.target.files[0], params.id)
                    }
                    sx={{
                      width: theme.dimensions.width,
                      mt: theme.dimensions.mt,
                      mb: theme.dimensions.mb,
                    }}
                  ></TextField>
                </Box>
              </Grid3>
            )}
          </Grid2>
        </div>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            {employee ? "Update" : "Add Employee"}
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
}

export default EmployeeForm;
