import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEmployees } from "../Provider";
import BoxInfo from "./BoxInfo";

function InfoEmployee() {
  let params = useParams();
  const id = params.id;
  const {
    state: { selected: employee },
    fetchOne: fetchEmployee,
  } = useEmployees();

  useEffect(() => {
    fetchEmployee(id);
  }, [id, fetchEmployee]);
  if (!employee) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Employee Details
      </Typography>
      <BoxInfo employee={employee} />
    </>
  );
}

export default InfoEmployee;
