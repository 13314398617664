import { Route } from "react-router-dom";

import SmsProviderList from "./SmsProviderList";
import Sms from "./Sms";

export default (
  <Route path="SMS" exact element={<Sms />}>
    <Route index element={<SmsProviderList />} />
  </Route>
);
