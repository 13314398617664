import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useGate() {
  return useContext(Context);
}

const initState = {
  list: [],
  listSub: [],
  selected: null,
  selectedfile: null,
  count: 1,
  page: 1,
  perPage: 10,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_listSubCompany":
      return { ...state, listSub: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function GateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const fetchList = useCallback(
    async (cid) => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `SiteGate/List/${cid}`,
        null,
        {},
        true,
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data || [] });
    },
    [req ]
  );
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "SiteGate/Add",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData)
        } catch (e) {
          reject(e);
        
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
      
    },[req])
  const edit = useCallback(
    async (data, id) => {

      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `SiteGate/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try{
          dispatch({ type: "status", payload: `deleting ${id}` });
          const resData= await req(`SiteGate/Delete/${id}/`, {}, { method: "DELETE" }, true);
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        }
        catch(error){
          reject(error);
        }
      });
      
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        edit,
        create,
        remove
      }}
    >
      {children}
    </Context.Provider>
  );
}
