import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./field";
// use form
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

import { useDeduction } from "./provider";
import { CalDate } from "../../utilities/functions";
const locales = ["en-gb"];

// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

//css styled
const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
  grid-template-rows: 80px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormDeduction = ({ handleSuccess }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  const [locale, setLocale] = useState("en-gb");

  const {
    state: { filter },
    create,
    dispatch,
  } = useDeduction();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (values) => {
    values.dueDate = CalDate(values.dueDate);
    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setValue("employeeId", id);
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" mb={3} component="h4" gutterBottom>
          Payroll Deduction Details
        </Typography>
        <GridGroup>
          {fields.map((f, index) => (
            <>
              {f.Number && (
                <TextField
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  helperText={errors[f.name]?.message}
                  select={f.select}
                  className={f.class}
                  fullWidth
                  required
                  disabled={f.disabled}
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb,
                  }}
                  inputProps={{
                    ...register(f?.name),
                    required: f.required,
                  }}
                  size="small"
                ></TextField>
              )}
              {f.date && (
                <LocalizationProvider
                  key={f.name}
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}
                >
                  <div components={["DateTimePicker"]}>
                    <span style={{ fontSize: "12px" }}>{f.label}*</span>
                    <br />
                    <DatePicker
                      onChange={(date) => setValue(f.name, date)}
                      error={!!errors[f.name]}
                      sx={{ width: theme.dimensions.width }}
                      inputProps={{
                        ...register(f?.name),
                        required: true,
                      }}
                      type="datetime-local"
                      slotProps={{
                        textField: {
                          required: f.required,
                          error: errors[f.name]?.message,
                          helperText: (
                            <p style={{ color: "#d32f2f" }}>
                              {errors[f.name]?.message}
                            </p>
                          ),
                          size: "small",
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
              )}
            </>
          ))}
        </GridGroup>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <ButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                ml: theme.dimensions.ml,
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: theme.color.grayBackground,
                color: theme.color.dark,
                "&:hover": { background: `${theme.color.gray50}` },
              }}
            >
              Discard
            </Button>
          </ButtonWrapper>
        </Box>
      </Card>
    </form>
  );
};

export default FormDeduction;
