import FormMemberShip from "./FormMemberShip";
import { useMemberShip } from "./Provider";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
//styled
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import fieldsAdd from "./fieldsAdd";
import * as Yup from "yup";
import ErrorsList from "../../ui/ErrorsList";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useSite } from "../Sites/Provider";
import { useMemberPlan } from "../MemberPlans/Provider";
import { PaymentType } from "../../utilities/Enum";
import ModalBox from "../../ui/ModalBox";

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const GridGroup = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 42px;
`;

const yupSchema = fieldsAdd.reduce(createYupSchema, {});

const AddMemberShip = () => {
  const formSchema = Yup.object().shape({
    siteId: Yup.number(),
    mobile: Yup.string()
      .max(21, "mobile number must be less than 10num")
      .required("require field"),
    memberName: Yup.string().required("require field"),
    siteMemberPlanId: Yup.number(),
    siteSiftId: Yup.number().nullable(),
    amount: Yup.number(),
    paymentTypeId: Yup.number(),
    isActive: Yup.boolean(),
  });
  let theme = useTheme();
  let navigate = useNavigate();
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: site },
    fetchOne: getSiteInfo,
  } = useSite();
  const {
    state: { filter },
    create,
  } = useMemberShip();

  const {
    state: { list: plan },
    fetchList: getMemberPlans,
  } = useMemberPlan();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };

  useEffect(() => {
    getMemberPlans(filter?.siteId);
  }, [getMemberPlans]);
  useEffect(() => {
    getSiteInfo(filter?.siteId);
  }, [getSiteInfo]);
  useEffect(() => {
    setValue("siteSiftId", null);
    setValue("siteId", filter?.siteId);
  }, [setValue]);
  const onSubmit = async (values) => {
    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
      setError({ title: error.errors.message });
    }
  };

  const getData = (title) => {
    switch (title) {
      case "paymentTypeId":
        return PaymentType;
        break;
      case "siteMemberPlanId":
        return plan;
        break;
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Card
          sx={{
            p: theme.dimensions.padding,
            mt: theme.dimensions.mt,
            mb: theme.dimensions.mb,
          }}
        >
          <Typography variant="Poster" component="h4" mb={3} gutterBottom>
            Membership Details
          </Typography>
          {error && (
            <Alert severity="error" sx={{ m: 3 }}>
              <ErrorsList error={error} />
            </Alert>
          )}
          <GridGroup>
            {fieldsAdd.map((field, index) => {
              if (field.viewOnly) return null;
              const inputProps = { ...field.inputProps };

              if (index === 0) inputProps.autoFocus = true;
              inputProps.type = field.type;
              return (
                <>
                  {field.disable && (
                    <div>
                      <Typography variant="body2" component="h6" gutterBottom>
                        {field.label}
                      </Typography>

                      <Box
                        sx={{
                          borderBottom: "1px solid gray",
                          width: "290px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {field.label === "Company Name"
                          ? site?.company?.companyName
                          : site?.siteName}
                      </Box>
                    </div>
                  )}
                  {field.select && (
                    <>
                      <FormControl
                        sx={{
                          width: theme.dimensions.width,
                          mt: theme.dimensions.mt,
                          mb: theme.dimensions.mb,
                        }}
                        required
                        error={!!errors[field.name]}
                      >
                        <InputLabel id="demo-simple-select-label">
                          {field.label}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={field.label}
                          inputProps={{
                            ...register(field?.name),
                            required: true,
                          }}
                          size="small"
                        >
                          {getData(field.name)?.map((s, index) => (
                            <MenuItem key={index} value={s.id}>
                              {s[field.data]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  {!field.switch &&
                    !field.disable &&
                    !field.select &&
                    !field.hidden && (
                      <Box key={index}>
                        <TextField
                          error={!!errors[field.name]}
                          sx={{ width: theme.dimensions.width }}
                          size="small"
                          label={field.label}
                          inputProps={{
                            ...register(field?.name),
                          }}
                          fullWidth
                          type={field.validationType}
                          helperText={errors[field.name]?.message}
                        />
                      </Box>
                    )}
                  {field.switch && (
                    <Box key={index}>
                      <FormGroup>
                        <FormLabel
                          component="legend"
                          sx={{
                            mt: theme.dimensions.mt,
                          }}
                        >
                          {field.label}
                        </FormLabel>
                        <FormControlLabel
                          sx={{
                            mt: theme.dimensions.mt,
                          }}
                          control={<Switch color="success" defaultChecked />}
                          {...register(field.name)}
                          label="Active"
                        />
                      </FormGroup>
                    </Box>
                  )}
                </>
              );
            })}
          </GridGroup>
          <ButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                ml: theme.dimensions.ml,
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: theme.color.grayBackground,
                color: theme.color.dark,
                "&:hover": { background: `${theme.color.gray50}` },
              }}
            >
              Discard
            </Button>
          </ButtonWrapper>
        </Card>
        <ModalBox
          open={open}
          handleClose={handleClose}
          text="The Add operation has been completed successfully.. You Added"
          name="Membership"
        />
      </form>
    </>
  );
};

export default React.memo(AddMemberShip);
