import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import DotColorful from "../../../icons/DotColorful.svg";
// import DotColorless from "../../../icons/DotColorless.svg";
import { switchStyle } from "../FormCompany";
function ActiveCell({row, id}) {
  return (
    <>
      {row.isActive ? (
        <span
          style={{
            color: "#4AA785",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiberManualRecordIcon
            fontSize="small"
            sx={{ fontSize: 10, mr: 0.2 }}
          />
          {/* <img alt="DotColorful" src={DotColorful} /> */}
          <span>Active</span>
        </span>
      ) : (
        <span
          style={{
            color: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiberManualRecordIcon
            fontSize="small"
            sx={{ fontSize: 10, mt: 0.2, mr: 0.2 }}
          />
          {/* <img alt="DotColorless" src={DotColorless} /> */}
          <span>Inactive</span>
        </span>
      )}
    </>
  );
}

export default ActiveCell;
