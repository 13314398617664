import { addDays } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
const fields = [
    {
        name: "plate",
        label: "Car Plate",
        required: true,
    
        validationType: "string",
        validations: [
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      },
      {
        name: "categoryId",
        label: "Category",
        required: true,
        select:true,
        data: "name",
        getData:"Category",
        validationType: "number",
        validations: [
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      },
      {
        data:"makerName",
        name: "carMakerId",
        label: "Car Brand",
        required: true,
        select:true,
        URL: "name",
        getData:"Brand",
        validationType: "number",
        validations: [
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      },
      {
        data:"colorName",
        name: "carColorId",
        label: "Car Color",
        required: true,
        select:true,
        colors: "hexValue",
        getData:"Color",
        validationType: "number",
        validations: [
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      },

      {
        name: "siteMemberId",
        label: "Member ship",
        validationType: "number",
        disable:true,
      },
];

export default fields;
