import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
// Store
import Store from "./Store";
import StoreList from "./StoreList";
// Materials
import MaterialsList from "./Materials/List";
import MaterialDetails from "./Materials/Details";
import MaterialEdit from "./Materials/Form/Edit";
import MaterialAdd from "./Materials/Form/Add";
// Vendors
import VendorsList from "./Vendors/List";
import VendorsDetails from "./Vendors/Details";
import VendorsEdit from "./Vendors/Form/Edit";
import VendorsAdd from "./Vendors/Form/Add";
// Categories
import CategoriesList from "./Categories/List";
import CategoriesAdd from "./Categories/Form/Add";
import MemoList from "./Memo/MemoList";
import AddMemo from "./Memo/Form/AddMemo";
import UpdateMemo from "./Memo/Form/UpdateMemo";
import DetailMemo from "./Memo/DetailMemo";

export default (
  <Route
    path="store"
    exact
    element={
      <RequireAuth>
        <Store />
      </RequireAuth>
    }
  >
    <Route index element={<StoreList />} />
    {/* Materials */}
    <Route path="materials" element={<MaterialsList />} />
    <Route path="materials/detail/:id" element={<MaterialDetails />} />
    {/* <Route path="materials/detail/:id/edit" element={<MaterialEdit />} /> */}
    <Route path="materials/edit/:id" element={<MaterialEdit />} />
    <Route path="materials/add" element={<MaterialAdd />} />
    {/* Vendors */}
    <Route path="vendors" element={<VendorsList />} />
    <Route path="vendors/detail/:id" element={<VendorsDetails />} />
    {/* <Route path="vendors/detail/:id/edit" element={<VendorsEdit />} /> */}
    <Route path="vendors/edit/:id" element={<VendorsEdit />} />

    <Route path="vendors/add" element={<VendorsAdd />} />
    {/* Categories */}
    <Route path="categories" element={<CategoriesList />} />
    <Route path="categories/add" element={<CategoriesAdd />} />
    {/**memo */}
    <Route path="memo" element={<MemoList />} />
    <Route path="memo/:name" element={<AddMemo />} />
    <Route path="memo/:name/:id" element={<UpdateMemo />} />
    <Route path="memo/detail/:id" element={<DetailMemo />} />
  </Route>
);
