import { useTheme } from "@emotion/react";
import { Button, Paper } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
`;

const WebContentInfo = ({ webContentInfo }) => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { id } = useParams();
  return (
    <Paper
      elevation={0}
      // square
      sx={{
        p: 2,
        mt: 1,

        background: theme.color.PaperbgColor,
        width: "60%",
      }}
    >
      <div style={{ textAlign: "right" }}>
        <Button
          sx={{
            background: theme.color.blackBackground,
            color: "white",
            "&:hover": { background: `${theme.color.black}` },
          }}
          onClick={() => navigate(`/sites/SiteDetail/${id}/UpdateWebClient`)}
        >
          Edit
        </Button>
      </div>
      <Grid2>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Caption
        </span>
        <span>{webContentInfo?.caption}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Left Text
        </span>
        <span>{webContentInfo?.leftText}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Right Text
        </span>
        <span>{webContentInfo?.rightText}</span>
        <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Show Price
        </span>
        <span>
          {webContentInfo?.showPrice === 0 && <span>No Price</span>}
          {webContentInfo?.showPrice === 1 && <span>Official Price</span>}
          {webContentInfo?.showPrice === 2 && <span>Trial Price</span>}
        </span>
        {/* <span style={{ color: theme.color.PaperlabelColor, fontSize: "14px" }}>
          Show Official Price
        </span> */}
        {/* {webContentInfo?.showOfficialPrice ? (
          <span style={{ color: "#4AA785" }}>
            <FiberManualRecordIcon
              fontSize="small"
              sx={{ fontSize: 10, mr: 0.2 }}
            />
            Active
          </span>
        ) : (
          <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
            <FiberManualRecordIcon
              fontSize="small"
              sx={{ fontSize: 10, mr: 0.2 }}
            />
            Inactive
          </span>
        )} */}
      </Grid2>
    </Paper>
  );
};

export default WebContentInfo;
