import { Typography } from "@mui/material";
import React, { useState } from "react";
import FormSMS from "./FormSMS";
import ModalBox from "../../ui/ModalBox";
import { useNavigate } from "react-router-dom";

const AddSms = () => {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const handleClose = () => {
    setOpen(!open);
    navigate(-1);
  };

  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <Typography variant="Poster" component="h4" gutterBottom>
        Add SMS Provider
      </Typography>
      <FormSMS handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added SMS Provider to this site"
        name="SMS Provider"
      />
    </>
  );
};

export default AddSms;
