import React from "react";

function CategoryCell({ row }) {
  return (
    <>
      {row?.materialCategory === null ? (
        <span style={{ color: "gray" }}>not specified</span>
      ) : (
        row?.materialCategory?.name
      )}
    </>
  );
}

export default CategoryCell;
