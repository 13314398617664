const addZoneFields = [
  {
    name: "companyName",
    label: "Company Name",
    data: "sites",
    registerName: "companyName",
    value: "companyName",
    width: "300px",
    xs: 4.5,
    disabled: true,
    hasValue: true,
  },
  {
    name: "siteId",
    label: "Site Name",
    data: "sites",
    error: "site",
    registerName: "siteId",
    width: "300px",
    xs: 4.5,
    disabled: true,
    hasValue: true,
  },
  {
    name: "title",
    label: "Site Zone Name",
    data: "sites",
    error: "zone",
    registerName: "title",
    width: "300px",
    xs: 4.5,
    required: true,
  },
  {
    name: "isActive",
    label: "Site Zone Status",
    data: "sites",
    registerName: "isActive",
    width: "300px",
    xs: 4.5,
    switch: true,
  },
];
export default addZoneFields;
