import React from "react";

const Services = ({ row }) => {
  return (
    <div>
      <div style={{ paddingBottom: "10px", width: "120px" }}>
        {row?.serviceNames.length != 0
          ? row?.serviceNames?.map((s) => <div>{s}</div>)
          : ""}
      </div>
    </div>
  );
};

export default Services;
