import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as Yup from "yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import field from "./field";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useNavigate, useParams } from "react-router-dom";
import { useSite } from "../Sites/Provider";

import { DateTimePicker } from "@mui/x-date-pickers";
import { useCompany } from "../Companies/Provider";
import { useEmployees } from "../employee/Provider";
import { useAttendance } from "./Provider";
import {
  CalUtcWithFormat,
  Diff,
  newValueConvert,
} from "../../utilities/functions";
import ErrorsList from "../../ui/ErrorsList";
import { useAuth } from "../../services/auth";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

const yupSchema = field.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

const GridGroup2 = styled.div`
  width: 64%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  gap: 42px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const locales = ["en-gb"];

const FormAttendance = ({ handleSuccess, attendance }) => {
  let theme = useTheme();
  let navigate = useNavigate();

  const [Error, setError] = useState({ title: "" });
  const [EmployeeID, setEmployeeID] = useState();
  const [siteID, setSiteID] = useState(attendance?.siteId);
  const [locale, setLocale] = useState("en-gb");

  const { role } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  var { companyId, id } = useParams();
  const { create, edit } = useAttendance();

  const {
    state: {
      Names: employees,

      selected: employee,
    },
    fetchNames: fetchEmployees,
    fetchOne: InfoEmployee,
  } = useEmployees();
  const {
    state: { selected: comapny },
    fetchOne: getCompany,
  } = useCompany();
  const {
    state: { selected: SiteInfo },
  } = useSite();

  useEffect(() => {
    fetchEmployees(companyId, 0);
  }, [id]);
  useEffect(() => {
    if (role === "Admin" || role === "SubCompany") getCompany(companyId);
  }, [getCompany, companyId]);

  useEffect(() => {
    if (EmployeeID) InfoEmployee(EmployeeID);
    if (attendance) {
      InfoEmployee(attendance?.employeeId);
    }
  }, [EmployeeID]);

  const getData = (name) => {
    if (!attendance) {
      switch (name) {
        case "siteId":
          return employee?.sites;
          break;
        case "roleId":
          return employee?.sites
            .filter((s) => s?.siteId === siteID)
            .map((site) => site.roles)[0];
        case "employeeId":
          return employees;
        default:
          break;
      }
    }
    if (attendance) {
      return employee?.sites
        .filter((s) => s?.siteId === attendance?.siteId)
        .map((site) => site.roles)[0];
    }
  };
  useEffect(() => {
    if (attendance) {
      setValue("employeeId", attendance?.employee?.id);
      setValue("siteId", attendance?.site?.id);
    }
  }, [attendance]);
  const onSubmit = async (values) => {
    delete values.companyName;
    values.siteId = siteID;
    values.punchIn
      ? (values.punchIn = CalUtcWithFormat(values.punchIn))
      : (values.punchIn = attendance.punchIn);

    values.punchOut
      ? (values.punchOut = CalUtcWithFormat(values.punchOut))
      : (values.punchOut = attendance.punchOut);

    if (Diff(values.punchIn, values.punchOut) <= 15) {
      try {
        if (attendance) {
          await edit(values, id);
        } else {
          await create(values);
        }
        handleSuccess();
      } catch (error) {
        setError({ title: error.errors.message });
      }
    } else {
      setError({
        title:
          "The time difference between punch in and punch out should be less than 15 hours and later than 1 minutes",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {employee?.sites.length === 0 ||
        (employee?.sites === undefined && EmployeeID && (
          <Alert severity="error">
            <Typography variant="Poster" component="span">
              {role === "Site" ? (
                "No sites available, try select an employee to view sites related to the employee"
              ) : (
                <>
                  {" "}
                  You should add a new Site first,{" "}
                  <Typography
                    variant="Poster"
                    component="span"
                    onClick={() => navigate(`/sites/add/${companyId}`)}
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <strong>Add Site</strong>
                  </Typography>
                </>
              )}
            </Typography>
          </Alert>
        ))}
      {Error.title && (
        <Alert severity="error" sx={{ m: 3 }}>
          <ErrorsList error={Error} />
        </Alert>
      )}
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" component="h4" mb={3} gutterBottom>
          Attendance Details
        </Typography>
        <GridGroup2>
          {field.map((f, index) => {
            if (f.viewOnly) return null;
            const inputProps = { ...f.inputProps };

            if (index === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <>
                {f.disabled && (
                  <Box>
                    <Typography variant="body2" component="h6" gutterBottom>
                      {f.label}
                    </Typography>

                    <Box
                      sx={{
                        borderBottom: "1px solid gray",
                        width: "290px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      {role === "Site"
                        ? SiteInfo?.company?.companyName
                        : comapny?.companyName}
                    </Box>
                  </Box>
                )}
                {f.select && (
                  <FormControl
                    sx={{
                      width: theme.dimensions.width,
                      mt: theme.dimensions.mt,
                      mb: theme.dimensions.mb,
                    }}
                    error={!!errors[f.name]}
                  >
                    <span style={{ fontSize: "12px" }}>{f.label}</span>
                    {attendance && f.name !== "roleId" && (
                      <Box
                        sx={{
                          borderBottom: "1px solid gray",
                          width: "290px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {f.name === "employeeId" ? (
                          <>{attendance.employee.employeeName}</>
                        ) : (
                          <>{attendance.site.siteName}</>
                        )}
                      </Box>
                    )}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      inputProps={{
                        ...register(f?.name),
                        required: true,
                      }}
                      size="small"
                      style={{
                        visibility:
                          (attendance && f.name === "employeeId") ||
                          (attendance && f.name === "siteId")
                            ? "hidden"
                            : "",
                      }}
                      defaultValue={
                        attendance && f.name === "roleId" && attendance.roleId
                      }
                      onChange={
                        (f.name === "employeeId" &&
                          ((e) => setEmployeeID(e.target.value))) ||
                        (f.name === "siteId" &&
                          ((e) => setSiteID(e.target.value)))
                      }
                    >
                      {getData(f.name)?.map((s, index) => (
                        <MenuItem
                          key={index}
                          value={
                            (f.name === "roleId" && s.roleId) ||
                            (f.name === "siteId" && s.siteId) ||
                            (f.name === "employeeId" && s.id)
                          }
                        >
                          {s[f.value]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {f.date && (
                  <LocalizationProvider
                    key={f.name}
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <div components={["DateTimePicker"]}>
                      <span style={{ fontSize: "12px" }}>{f.label}</span>
                      <DateTimePicker
                        onChange={(date) => setValue(f.name, date)}
                        error={!!errors[f.name]}
                        sx={{ width: theme.dimensions.width }}
                        inputProps={{
                          ...register(f?.name),
                          required: true,
                        }}
                        defaultValue={dayjs(
                          newValueConvert(attendance?.[f.name])
                        )}
                        //      defaultValue={dayjs(newValueConvert(f))}
                        type="datetime-local"
                        slotProps={{
                          textField: {
                            required: f.required,
                            error: errors[f.name]?.message,
                            helperText: (
                              <p style={{ color: "#d32f2f" }}>
                                {errors[f.name]?.message}
                              </p>
                            ),
                            size: "small",
                          },
                        }}
                      />
                    </div>
                  </LocalizationProvider>
                )}
                {f.number && (
                  <div>
                    <span style={{ fontSize: "12px" }}>{f.label}</span>

                    <TextField
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      sx={{ width: theme.dimensions.width }}
                      required
                      defaultValue={attendance?.hourlyRate}
                      inputProps={{
                        ...register(f?.name),
                        required: true,
                      }}
                      size="small"
                    />
                  </div>
                )}
                {f.switch && (
                  <Box key={index}>
                    <FormGroup>
                      <FormLabel
                        component="legend"
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                      >
                        {f.label}
                      </FormLabel>
                      <FormControlLabel
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                        control={
                          <Switch
                            color="success"
                            defaultChecked={
                              attendance ? attendance?.[f.name] : true
                            }
                          />
                        }
                        {...register(f.name)}
                        label="Active"
                      />
                    </FormGroup>
                  </Box>
                )}
              </>
            );
          })}
        </GridGroup2>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            {attendance ? <>Update</> : <>Add</>} Attendance
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default React.memo(FormAttendance);
