const fields = [
  {
    name: "cid",
    label: "Company",
    select: true,
  },
  {
    name: "typeId",
    label: "Type",
    select: true,
  },
  {
    name: "siteId",
    label: "Site",
    select: true,
  },
  {
    name: "employeeId",
    label: "Employee",
    select: true,
  },
  {
    name: "vendorId",
    label: "Vendor",
    select: true,
  },
  {
    name: "from",
    label: "Form Date",
    date: true,
  },
  {
    name: "to",
    label: "To Date",
    date: true,
  },
];

export default fields;
