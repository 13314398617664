import { StyleDate } from "../../utilities/functions";

const fields = [
  {
    name: "companyName",
    label: "Company Name",
    disabled: true,
    value: "name",
  },
  {
    name: "employeeId",
    label: "Employee Name",
    select: true,
    disabled: false,
    select: true,
    validationType: "number",
    value: "name",
    displayData1: "employee",
    displayData: "employeeName",
  },
  {
    name: "siteId",
    label: "Site Name",
    disabled: false,
    select: true,
    validationType: "number",
    value: "siteName",
  },
  {
    name: "roleId",
    label: "Role Name",
    select: true,

    validationType: "string",
    value: "roleTitle",
  },
  {
    name: "punchIn",
    label: "From (Punch In)",
    required: true,
    validationType: "string",
    date: true,
    edit: true,
    validation: [
      {
        type: "transform",
        params: [StyleDate],
      },
    ],
  },
  {
    name: "punchOut",
    label: "To (Punch Out)",
    required: true,
    validationType: "string",
    date: true,
    edit: true,
    validation: [
      {
        type: "transform",
        params: [StyleDate],
      },
    ],
  },
  {
    name: "hourlyRate",
    label: "Rate (Hours)",
    required: true,
    validationType: "number",

    edit: true,
    number: true,
  },

  {
    name: "isActive",
    label: "Attendance Status",
    switch: true,
    validationType: "boolean",

    edit: true,
  },
];

export default fields;
