import { Route } from "react-router-dom";

import ListMemberPlans from "./ListMemberPlans";
import MemberPlans from "./MemberPlans";
import AddMemberPlan from "./AddMemberPlan";

export default (
  <Route path="memberplans" exact element={<MemberPlans />}>
    <Route index element={<ListMemberPlans />} />
    <Route path="SiteDetail/AddMemberPlan/:id" element={<AddMemberPlan />} />
  </Route>
);
