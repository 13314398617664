const fields = [
  {
    name: "fullName",
    label: "Full Name",
  },
  {
    name: "companyName",
    label: "Company Name",
    viewLabel2: "companyName",
  },
  {
    name: "employeeID",
    label: "Employee Id",
  },
  {
    name: "employeeCode",
    label: "Employee Code",
  },

  {
    name: "nssfNumber",
    label: "NSSF Number",
  },
  {
    name: "mobile",
    label: "Mobile Number",
  },
  {
    name: "isActive",
    label: "Employee Status",
  },
  {
    name: "fingerPrintDate",
    label: "Fingerprint",
    fingerprintView: true,
  },
];

export default fields;
