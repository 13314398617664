import { Route } from "react-router-dom";

import UserAccounts from "./UserAccounts";
import ListUserAccounts from "./ListUserAccounts";
import AddUserAccount from "./AddUserAccount";

export default (
  <Route path="UserAccounts" exact element={<UserAccounts />}>
    <Route index element={<ListUserAccounts />} />
    <Route path="addAdmin" element={<AddUserAccount accountType="Admin" />} />
    <Route path="addUser" element={<AddUserAccount accountType="User" />} />
    <Route path="addUser/:id" element={<AddUserAccount accountType="User" />} />
  </Route>
);
