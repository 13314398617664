import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormMemo from "./FormMemo";
import ModalBox from "../../../../ui/ModalBox";

const AddMemo = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <Typography variant="Poster" component="h3" gutterBottom>
        {`Add Memo (${name})`}
      </Typography>
      <div>
        <FormMemo handleSuccess={handleSuccess} />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added new memo "
      />
    </>
  );
};

export default AddMemo;
