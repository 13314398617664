import React from "react";

const CostTrial = ({ row }) => {
  return (
    <>
      {row.costTrial === "" ? (
        ""
      ) : (
        <div>
          <div>{row.costTrial?.cashCostTrial}</div>
          <div>{row.costTrial?.ccCostTrial}</div>
          <div>0</div>
          <div>{row.costTrial?.compCostTrial}</div>
          <div style={{ borderTop: "1px dashed black" }}>
            <p>{row?.costTrial.serviceCostTrialSum}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CostTrial;
