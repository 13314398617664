import { Route } from "react-router-dom";

// import { RequireAuth } from "../../services/auth";
import SubCompany from "./SubCompany";
import SubCompanyList from "./SubCompanyList";
import InfoSubCompany from "./InfoSubCompany/InfoSubCompany";
import AddSubCompany from "./AddSubCompany";
import EditSubCompany from "./EditSubCompany";
import { RequireAuth } from "../../services/auth";

export default (
  <Route path="SubCompanies" exact element={<SubCompany />}>
    <Route
      index
      element={
        <RequireAuth>
          <SubCompanyList />
        </RequireAuth>
      }
    />
    <Route path="SubCompanyDetails/:id" element={<InfoSubCompany />}>
      {/* <Route path="edit" element={<EditCompany />} /> */}
    </Route>
    <Route path="SubCompanyDetails/:id/edit" element={<EditSubCompany />} />
    <Route path="add/:id" element={<AddSubCompany />} />
  </Route>
);
