import React, { useState } from "react";
import { Grid, Typography, useTheme, Paper, Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import fields from "./fields";
import { useAuth } from "../../../services/auth";
import ModalBox from "../../../ui/ModalBox";
import { useEmployees } from "../Provider";
import EmployeeRole from "./Render/EmployeeRole";
import HourlyRate from "./Render/HourlyRate";
import CustomTable from "../../../ui/CustomTable";
import TabsList from "../../../ui/TabsList";
import SiteCell from "./Render/SiteCell";
import UpdateDate from "./Render/UpdateDate";
import ZonesCell from "./Render/ZonesCell";

function BoxInfo({ employee = [] }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const [openRateEdit, setOpenRateEdit] = useState(false);
  /** */
  const tabs = [{ title: "Sites", num: employee.sites.length }];
  const { role } = useAuth();
  const { remove, removeRoleRate, fetchOne: fetchEmployee } = useEmployees();

  const [open, setOpen] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [openAddRate, setOpenAddRate] = useState(false);
  const [selectedCols, setSelectedCols] = useState();
  const [index, setIndex] = useState(0);
  const [roleId, setRoleId] = useState("");
  const [row, setRow] = useState();

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    try {
      remove(employee?.id);
      navigate(-1);
    } catch (e) {
      console.log("error", e);
    }
  };

  const getData = (data) => {
    switch (data) {
      case "companyName":
        return employee?.company?.companyName
          ? employee?.company?.companyName
          : "";
      default:
        break;
    }
  };
  const col = [
    {
      label: "Site Name",
      headTextLeft: true,
      textLeft: true,
      customRender: SiteCell,
    },

    {
      label: "Role",
      customRender: EmployeeRole,
    },
    {
      label: "Hourly Rate",
      name: "hourlyRate",
      customRender: HourlyRate,

      // editing: true,
      // editable: true,
      helperText: "not empty",
      checkable: true,
      multiRow: true,
      type: "number",
      number: true,
    },
    {
      label: "Zones",
      customRender: ZonesCell,
      multiRow: true,
    },
    {
      label: "Update Date",
      customRender: UpdateDate,
    },
  ];
  /**handle update rate close */

  const handleRateClose = () => {
    setOpenRate(false);
  };
  const handleEditRoleZone = () => {
    setOpenRateEdit(false);
  };
  /**handle Add Rate close */

  const handleAddRateClose = () => {
    setOpenAddRate(false);
  };
  const handleEditRateClose = () => {
    setOpenRateEdit(false);
    fetchEmployee(employee?.id);
  };
  /**handle update rate success */
  // const handleRateSuccess = () => {
  //   setOpenRate(true);
  // };
  /**update role  */
  // const handleEditRole = async (e, ID) => {
  //   roleData["siteId"] = ID;
  //   roleData["roleId"] = e[0].roles[index].roleId;
  //   roleData["hourlyRate"] = e[0].roles[index].hourlyRate;

  //   try {
  //     await editRoleRate(employee?.id, roleData);
  //     handleRateSuccess();
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };
  /**delete role */
  const handleDeleteRole = async (ID) => {
    try {
      await removeRoleRate(employee?.id, ID, roleId);
      fetchEmployee(employee?.id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        // square
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
          background: theme.color.PaperbgColor,
          width: "90%",
        }}
      >
        <Grid container>
          <Grid item xs={11} sm={11} md={11}>
            <Typography variant="Poster" component="h4" gutterBottom>
              Employee Information
            </Typography>
            {employee.photoUrl && (
              <div style={{ width: "150px", height: "150px" }}>
                <img src={employee?.photoUrl} alt="employeePhoto" />
              </div>
            )}
            <Grid container>
              {fields.map((f, index) => {
                if (f.editOnly) return <></>;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    mt={2}
                    key={index}
                    sx={{ fontSize: "14px" }}
                  >
                    <span style={{ color: theme.color.PaperlabelColor }}>
                      {f.label}{" "}
                    </span>
                    <span>
                      {f.fingerprintView ? (
                        <span>
                          {employee?.[f.name]
                            ? "updated at: " + employee?.[f.name]
                            : "not specified"}
                        </span>
                      ) : (
                        employee?.[f?.name] && employee?.[f?.name]
                      )}
                      {getData(f.viewLabel2)}

                      {f.name === "isActive" &&
                        (employee?.isActive ? (
                          <span style={{ color: "#4AA785" }}>
                            <FiberManualRecordIcon
                              fontSize="small"
                              sx={{ fontSize: 10, mr: 0.2 }}
                            />
                            Active
                          </span>
                        ) : (
                          <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                            <FiberManualRecordIcon
                              fontSize="small"
                              sx={{ fontSize: 10, mr: 0.2 }}
                            />
                            Inactive
                          </span>
                        ))}
                    </span>
                  </Grid>
                );
              })}
            </Grid>

            {employee?.contractUrl && (
              <Button
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                <a
                  style={{ textDecoration: "none", color: theme.color.dark }}
                  href={employee.contractUrl}
                  download
                >
                  Download Contract
                </a>
              </Button>
            )}
            {/* 
      {role === "Admin" && (
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      )}
      {role === "Company" && (
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      )} */}
          </Grid>
          {employee?.user === null && role !== "Site" && (
            <Grid item>
              <Button
                variant="contained"
                style={{ background: "#FF4747" }}
                onClick={() => setOpen(true)}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
        <ModalBox
          open={open}
          operation="delete"
          handleClose={handleClose}
          handleOperation={() => {
            handleDelete();
            setOpen(false);
          }}
          text={`Do you want to complete the process of deleting all information?`}
        />
      </Paper>
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      ></TabsList>

      <CustomTable
        empty={employee?.sites.length === 0}
        cols={col}
        data={employee?.sites}
        actions={
          (role === "Admin" || role === "Company") && {
            hourlyRate: true,
            //   selectedCols: true,
            // inTableEdit: true,
            edit: (e, row) => {
              setOpenRateEdit(true);
              setRow(row);
              // handleEditRole(e, e[0].siteId);
            },
            delete: (e) => handleDeleteRole(e),
          }
        }
        filters={{
          add: () => setOpenAddRate(true),
        }}
        showButton={true}
        setIndex={setIndex}
        Index={index}
        isPagination={false}
        roleId={roleId}
        setRoleId={setRoleId}
      ></CustomTable>
      <ModalBox
        open={openRate}
        operation="edit"
        handleClose={handleRateClose}
        text="The update operation has been completed successfully.."
        name={`${selectedCols}`}
      />
      <ModalBox
        open={openAddRate}
        addRate={employee.companyId}
        handleClose={handleAddRateClose}
        text="The update operation has been completed successfully.."
        name={`${selectedCols}`}
        employee={employee}
      />
      <ModalBox
        open={openRateEdit}
        row={row}
        roleId={roleId}
        setRoleId={setRoleId}
        operation="edit"
        handleClose={handleEditRateClose}
        text="The update operation has been completed successfully.."
        name={`${selectedCols}`}
        editRoleZoneEmp={true}
      />
    </>
  );
}

export default BoxInfo;
