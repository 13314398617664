import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalBox from "../../../ui/ModalBox";
import FormTicket from "./FormTicket";

const UpdateTicket = ({ ticket }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <>
      <FormTicket
        ticket={ticket}
        handleSuccess={handleSuccess}
      />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.. You updated ticket printing divisions "
      />
    </>
  );
};

export default React.memo(UpdateTicket);
