const RoleRateFields = [
  {
    name: "siteId",
    label: "Site Name",
    select: true,
    getData: "sites",
  },
  {
    name: "roleId",
    label: "Role Name",
    select: true,
    getData: "roles",
  },
  {
    name: "hourlyRate",
    label: "Hourly Rate",
    number: true,
  },
];

export default RoleRateFields;
