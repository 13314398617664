import React from "react";
import FormWebContent from "./FormWebContent";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWebContent } from "./provider";
import ModalBox from "../../ui/ModalBox";
import { useEffect } from "react";

const UpdateWebContent = () => {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  let navigate = useNavigate();
  const handleClose = () => {
    setOpen(!open);
    navigate(-1);
  };
  const {
    state: { selected },
    fetchOne,
  } = useWebContent();
  const handleSuccess = () => {
    setOpen(true);
    setReload(true);
  };
  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id, reload]);

  if (!selected) return;
  if (selected?.siteId != id) return;
  return (
    <div>
      update web content
      <FormWebContent handleSuccess={handleSuccess} WebContentInfo={selected} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The Update operation has been completed successfully.. You update this web content"
        name="Web Content"
      />
    </div>
  );
};

export default UpdateWebContent;
