import { Route } from "react-router-dom";
import AddAttendance from "./AddAttendance";

import Attendance from "./Attendance";
import ListAttendance from "./ListAttendance";
import { RequireAuth } from "../../services/auth";
import UpdateAttendance from "./UpdateAttendance";

export default (
  <Route
    path="attendance"
    exact
    element={
      <RequireAuth>
        <Attendance />
      </RequireAuth>
    }
  >
    <Route index element={<ListAttendance />} />
    <Route path="add/:companyId" element={<AddAttendance />} />
    <Route path="update/:companyId/:id" element={<UpdateAttendance />} />
  </Route>
);
