// React
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// YUP
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// Fields
import fields from "../fields";
// Material UI
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  OutlinedInput,
  Select,
  Chip,
  Button,
  useTheme,
  Card,
  InputLabel,
  FormControl,
  Alert,
  FormHelperText,
} from "@mui/material";
// Third Party Components
import ErrorsList from "../../../../ui/ErrorsList";
// Provider
import { useVendor } from "../Provider";
// Styled Component
import styled from "styled-components";
// Utilities
import { handleResponse } from "../../../../utilities/functions";

const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
// yup schema
const formSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .max(100, "must be less than or equal to 100 character"),
  weblink: Yup.string().max(100, "must be less than or equal to 100 character"),
  description: Yup.string().max(
    100,
    "must be less than or equal to 100 character"
  ),
});

const Form = ({ item, handleSuccess }) => {
  // providers
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { create, edit } = useVendor();
  const navigate = useNavigate();
  let theme = useTheme();
  // vars
  let params = useParams();
  const id = params.id;
  const [error, setError] = useState(null);
  const [Units] = React.useState([]);

  // on submit
  const onSubmit = async (values) => {
    console.log(values, "done submit");
    try {
      if (item) {
        // edit
        await edit(id, values);
      } else {
        // add
        await create(values);
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 404) setError({ title: e.errors.message });
      else setError({ title: e.errors.message });
      handleResponse(e.errors.status);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Fields functions
  const getData = (name) => {
    return [];
  };

  // if (!item) return;
  return (
    <>
      <Box>
        {/* Body */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <Typography variant="Poster" component="h4" gutterBottom>
              Vendor Details
            </Typography>
            {error && (
              <Alert severity="error">
                <ErrorsList error={error} />
              </Alert>
            )}
            <GridGroup>
              {fields?.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;
                inputProps.type = f.type;
                return (
                  <Box key={i}>
                    {f.select ? (
                      <>
                        <FormControl
                          sx={{
                            width: theme.dimensions.width,
                            mt: theme.dimensions.mt,
                            mb: theme.dimensions.mb,
                          }}
                          size="small"
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            htmlFor="demo-simple-select"
                          >
                            {f.label}
                            {" *"}
                          </InputLabel>
                          <Select
                            label={f.label}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            multiple
                            value={Units}
                            onChange={() => {}}
                            required={true}
                            error={!!errors[f.name]?.message}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Chip"
                              />
                            }
                            inputProps={{
                              ...register(f?.name),
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 1,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    key={value?.id}
                                    label={value?.siteName}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getData(f.data)?.map((c, i) => (
                              <MenuItem key={i} value={c}>
                                {c.siteName}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            htmlFor="demo-simple-select"
                            sx={{ color: "#d32f2f" }}
                          >
                            {errors[f.name]?.message}
                          </FormHelperText>
                        </FormControl>
                      </>
                    ) : (
                      <>
                        <TextField
                          label={!f.hasValue && f.label}
                          name={f.name}
                          select={f.select}
                          size="small"
                          className={f.class}
                          error={!!errors[f.name]}
                          helperText={errors[f.name]?.message}
                          defaultValue={item && item[f?.name]}
                          fullWidth
                          sx={{
                            width: theme.dimensions.width,
                            mt: theme.dimensions.mt,
                            mb: theme.dimensions.mb,
                          }}
                          disabled={f.disabled}
                          required={f.required}
                          inputProps={{
                            ...register(f?.name),
                          }}
                        ></TextField>
                      </>
                    )}
                  </Box>
                );
              })}
            </GridGroup>
            {/* End Body */}
            {/* Buttons */}
            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                {item ? "Save" : "Add Vendor"}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(Form);
