const fields = [
  {
    name: "companyId",
    label: "Company",
    select: true,
    viewName: "companyName",
    viewNameObject: "company",
  },
  {
    name: "siteId",
    label: "Site",
    select: true,
    viewName: "siteName",
    viewNameObject: "site",
  },
  {
    name: "employeeId",
    label: "Employee",
    select: true,
    viewName: "employeeName",
    viewNameObject: "employee",
  },
  {
    name: "vendorId",
    label: "Vendor",
    select: true,
    viewName: "name",
    viewNameObject: "vendor",
  },
  {
    name: "typeId",
    label: "Type",
    select: true,
    viewName: "typeName",
  },

  {
    name: "memoDate",
    label: "Memo Date",
    date: true,
    viewName: "memoDate",
  },

  {
    name: "leadTime",
    label: "Lead Time",
    type: "number",
    viewName: "leadTime",
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
    viewName: "notes",
  },
];

export default fields;
