// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// Providers
import { useCompany } from "../Companies/Provider";
import { usePayroll } from "./Provider";
// MUI material
import {
  Select,
  MenuItem,
  FormControl,
  Typography,
  FormHelperText,
  Alert,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
//UI component
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
// styled
import styled from "styled-components";
import "../style.css";
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HourlyModal from "../../ui/HourlyModal";
import { useAuth } from "../../services/auth";
import PayrollDuration from "./Render/PayrollDuration";
import LocallyDate from "./PayrollTable/LocallyDate";
import EndDateLocally from "./PayrollTable/EndDateLocally";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { tolocalDate } from "../../utilities/functions";
// yup schema
const formSchema = Yup.object().shape({});

const GridGroup1 = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  grid-template-columns: 325px 225px 325px;
  gap: 10px;
  grid-template-rows: 75px;
  grid-column-gap: 10px;
  background: #f7f9fb;
`;
const ListPayroll = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let navigate = useNavigate();
  const { role, adminOn } = useAuth();

  const theme = useTheme();
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  const {
    state: { list: payrolls, totalSize, filter },
    create: createPayroll,
    close: closePayroll,
    fetchPayrollCompanies: fetchPayrollCompanies,
    dispatch,
  } = usePayroll();
  const {
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const [selectedCompany, setSelectedCompany] = React.useState(
    filter.companyId
  );
  const [selectedYear, setSelectedYear] = React.useState(filter.year);
  const [selectedMonth, setSelectedMonth] = useState(filter.month);
  const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
  const [SelectedDateMonth, setSelectedDateMonth] = React.useState(
    dayjs(new Date())
  );
  const [error, setError] = useState(null);
  const [addRoles, setAddRoles] = useState(false);
  const [InTableRender, setInTableRender] = useState(0);
  const [openHourlyModal, setOpenHourlyModal] = useState(false);
  const [rolesData, setRolesData] = useState(false);

  const getCols = () => {
    return [
      {
        label: "Start Date",
        headTextLeft: true,
        customRender: LocallyDate,
      },
      {
        label: "End Date",
        customRender: EndDateLocally,
      },
      {
        label: "Paid Amount",
        name: "paidAmount",
      },
      { label: "Employees Count", name: "payrollEmployeesCount" },
    ];
  };

  //useEffect to fetch companies list
  useEffect(() => {
    if (role === "Admin") {
      if (companies?.length === 0) fetchCompany();
    }
  }, [role]);

  //useEffect to fetch payrolls list
  useEffect(() => {
    if (role === "Admin") {
      fetchPayrollCompanies(selectedCompany, selectedYear, selectedMonth);
    } else if (role === "Company") {
      fetchPayrollCompanies(adminOn?.id, selectedYear, selectedMonth);
    }
  }, [selectedCompany, selectedYear, selectedMonth, InTableRender]);
  const tabs = [{ title: "Payroll", num: totalSize }];

  const [selectedCols, setSelectedCols] = useState("Payroll");

  //onSubmit function
  // const onSubmit = (values) => {
  //   console.log(values, "values");
  //   try {
  //     fetchPayrollCompanies(values.cid, values.year);
  //   } catch (error) {
  //     handleErrorResponse(error.status);
  //   }
  // };
  //handle detail click
  const handleDetail = (ID, data) => {
    navigate(`EmployeesPayrollList/${ID}`);
  };
  // handle add payroll
  const handleAdd = async () => {
    if (role === "Admin") {
      if (selectedCompany === 0) setError("Please select one company");
      else {
        try {
          setError("");
          await createPayroll(selectedCompany);
          fetchPayrollCompanies(selectedCompany, selectedYear);
        } catch (e) {
          if (e.status === 404) setError("No Attendances in this company");
          if (e.status === 412) {
            setError(e.errors.message);
            setAddRoles(true);
            setRolesData(e.errors.data);
          }
          if (e.status === 500) {
            if (e.errors.response === 10) setError(e.errors.message);
            else setError("Error 500: Internal Exception");
          }
        }
      }
    } else if (role === "Company") {
      try {
        setError("");
        await createPayroll(adminOn?.id);
        fetchPayrollCompanies(adminOn?.id, selectedYear);
      } catch (e) {
        if (e.status === 404) setError("No Attendances in this company");
        if (e.status === 412) {
          setError(e.errors.message);
          setAddRoles(true);
          setRolesData(e.errors.data);
        }
        if (e.status === 500) {
          if (e.errors.response === 10) setError(e.errors.message);
          else setError("Error 500: Internal Exception");
        }
      }
    }
  };
  // handle change selected company
  const handleChangeCompanyId = (event) => {
    // console.log(event.target.value, "cid");
    dispatch({
      type: "set_filter",
      payload: { companyId: event.target.value, year: selectedYear },
    });
    setError(null);
    setSelectedCompany(event.target.value);
    fetchPayrollCompanies(event.target.value, selectedYear);
  };
  // handle change selected year
  const handleChangeYear = (event) => {
    // console.log(event["$y"], "year");
    dispatch({
      type: "set_filter",
      payload: { companyId: selectedCompany, year: event["$y"] },
    });
    setSelectedYear(event["$y"]);
    setSelectedDate(event);
    fetchPayrollCompanies(selectedCompany, event["$y"]);
  };
  // handle change selected month
  const handleChangeMonth = (event) => {
    dispatch({
      type: "set_filter",
      payload: {
        companyId: selectedCompany,
        year: event["$y"],
        month: event["$M"] + 1,
      },
    });
    setSelectedMonth(event["$M"] + 1);
    setSelectedDateMonth(event);
    setSelectedYear(event["$y"]);
    setSelectedDate(event);
    fetchPayrollCompanies(selectedCompany, event["$y"], event["$M"] + 1);
  };
  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };

  // handle Close
  const handleClose = async (pcid) => {
    try {
      await closePayroll(pcid);
      reset();
      fetchPayrollCompanies(selectedCompany, selectedYear);
    } catch (e) {
      if (e.status === 404) {
        setError("You can't Close Payroll ,Please Preview...");
      } else {
        window.scrollTo(0, 0);
        setError("Error Closing The Payroll");
      }
    }
  };

  // handle Modal hourly
  const handleOpenHourlyModal = () => {
    setOpenHourlyModal(true);
  };
  const handleCloseHourlyModal = () => {
    setOpenHourlyModal(false);
  };
  const handleClick = async () => {
    var aa = new Array();
    for (var i = 0; i < payrolls?.length; i++) {
      aa.push({
        startDate: payrolls[i].startDate
          ? tolocalDate(payrolls[i].startDate)
          : "",
        endDate: payrolls[i].endDate ? tolocalDate(payrolls[i].endDate) : "",
        paidAmount: payrolls[i].paidAmount,
        paidMonth: payrolls[i].paidMonth,
        paidYear: payrolls[i].paidYear,
        status: payrolls[i].status,
      });
    }
    var fileName = "Payroll Report File";
    const ws = XLSX.utils.json_to_sheet(aa, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Summary Attendance");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  if (!companies || !payrolls) return <div className="loader"></div>;

  return (
    <>
      <Typography variant="Poster" component="h4" mb={2}>
        Select Company (At least)
      </Typography>
      {error && (
        <Alert
          severity="error"
          sx={{ minWidth: "330px", maxWidth: "fit-content" }}
        >
          {error}
          {addRoles && (
            <Typography
              variant="Poster"
              component="span"
              onClick={() => setOpenHourlyModal(true)}
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
            >
              <strong> Add Roles</strong>
            </Typography>
          )}
        </Alert>
      )}
      <GridGroup1>
        {role === "Admin" && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <span style={{ fontSize: "12px" }}>Company Name *</span>
            <Select
              value={selectedCompany}
              onChange={handleChangeCompanyId}
              displayEmpty
              error={!!errors["cid"]}
              inputProps={{
                "aria-label": "Without label",
                autoFocus: true,
              }}
              sx={{
                fontSize: "12px",
                width: "300px",
                height: "40px",
                marginTop: "14px",
              }}
            >
              <MenuItem value={0}>
                <em>Please select company</em>
              </MenuItem>
              {companies.map((company) => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText
              htmlFor="demo-simple-select"
              sx={{ color: "#d32f2f" }}
            >
              {errors["cid"]?.message}
            </FormHelperText>
          </FormControl>
        )}
        {role === "Company" && (
          <Box mt={1}>
            <span style={{ fontSize: "12px" }}>Company Name</span>
            <Box
              sx={{
                borderBottom: "1px solid gray",
                width: "290px",
                borderRadius: "8px",
                textAlign: "center",
                marginRight: "20px",
                paddingTop: "20px",
              }}
            >
              {adminOn?.companyName}
            </Box>
          </Box>
        )}
        {/* <FormControl
          components={["DateRangePicker"]}
          sx={{
            width: "100px",
            ml: 5,
            mt: 1.5,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <span style={{ fontSize: "12px" }}>Year</span>
            <DatePicker
              sx={{ mt: 1.3 }}
              error={!!errors["year"]}
              // label={'"year"'}
              views={["year"]}
              openTo="year"
              value={selectedDate}
              maxDate={dayjs(new Date())}
              onChange={handleChangeYear}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              // onChange={(event) => {
              //   setValue("year", event["$y"]);
              // }}
            />
          </LocalizationProvider>
        </FormControl> */}
        <FormControl
          components={["DateRangePicker"]}
          sx={{
            width: "155 px",
            ml: 5,
            mt: 1.5,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <span style={{ fontSize: "12px" }}> Year*</span>
            <DatePicker
              sx={{ mt: 1.3 }}
              error={!!errors["month"]}
              // label={'"year"'}
              views={["year"]}
              openTo="month"
              value={SelectedDateMonth}
              maxDate={dayjs(new Date())}
              onChange={handleChangeMonth}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              // onChange={(event) => {
              //   setValue("year", event["$y"]);
              // }}
            />
          </LocalizationProvider>
        </FormControl>
        {/* <Box mt={5}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              color: "white",
              ml: 5,
              "&:hover": { background: `${theme.color.blackBackground}` },
              textTransform: "capitalize",
            }}
          >
            <FilterList />
            <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
          </Button>
        </Box> */}
      </GridGroup1>
      {/* {employees.length > 0 && selectedCompany !== 0 && (
          <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
            <span style={{ fontSize: "12px" }}>Employee Name</span>
            <Select
              value={SelectedEmployee}
              onChange={handleChangeEmployeeId}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                autoFocus: true,
              }}
              sx={{
                fontSize: "12px",
                width: "300px",
                height: "40px",
                marginTop: "14px",
              }}
            >
              <MenuItem value={0}>
                <em>ALL</em>
              </MenuItem>
              {employees.map((employee) => {
                return (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )} */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <TabsList
            tabs={tabs}
            setSelectedCols={setSelectedCols}
            selectedCols={selectedCols}
          ></TabsList>
        </div>
        <Button
          sx={{
            border: `1px solid ${theme.color.Screamingreen}`,
            color: theme.color.Screamingreen,
            mr: "350px",
            textTransform: "capitalize",
            "&:hover": { background: `${theme.color.gray50}` },
          }}
          onClick={handleClick}
        >
          {" "}
          Export to Excel
        </Button>
      </Box>

      <CustomTable
        empty={payrolls?.length === 0 ? true : false}
        cols={getCols()}
        data={payrolls}
        filters={{
          preview: handleAdd,
          // search: (e) => {
          //   console.log(e);
          // },
        }}
        actions={{
          close: (pcid) => {
            handleClose(pcid, selectedCompany);
          },
          setInTableRender: setInTableRender,
          InTableRender: InTableRender,
        }}
        moreOptions={{
          detail: handleDetail,
        }}
        showButton={true}
        isPagination={false}
        rowsPerPage={10}
        dispatch={dispatch}
      ></CustomTable>
      {/* Create Modal for solve site role rates */}
      <HourlyModal
        open={openHourlyModal}
        handleClose={handleCloseHourlyModal}
        data={rolesData}
        cid={selectedCompany}
      />
    </>
  );
};

export default React.memo(ListPayroll);
