// React
import React, { useEffect, useState } from "react";
// MUI
import { Box } from "@mui/material";
// Providers
import { useNavigate, useParams } from "react-router-dom";
import { useSubCompany } from "../../SubCompanies/Provider";
import { useSite } from "../../Sites/Provider";
import { useEmployees } from "../../employee/Provider";
import { useUserAccount } from "../../UserAccount/Provider";
// Table Custom Render
import AccountCell from "../CompanyTable/AccountCell";
import ActiveCell from "../CompanyTable/ActiveCell";
import EmployeeNumber from "../CompanyTable/EmployeeNumber";
import SiteNumber from "../CompanyTable/SiteNumber";
import SiteCell from "../../employee/EmployeeTable/SiteCell";
// UI
import CustomTable from "../../../ui/CustomTable";
import TabsList from "../../../ui/TabsList";
import ModalBox from "../../../ui/ModalBox";
import PassModal from "../../../ui/PassModal";
import { useCompany } from "../Provider";
import { useAuth } from "../../../services/auth";
import { useSecretKeys } from "../../SecretKeys/provider";
import { useWhiteIps } from "../../WhiteIps/provider";

function TableInfo({ company = [] }) {
  const navigate = useNavigate();
  let params = useParams();
  const { role, adminOn } = useAuth();

  const id = params.id;
  const [open, setOpen] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [employeeForDelete, setEmployeeForDelete] = useState(false);
  const [employeeIdResetPassword, setEmployeeIdResetPassword] = useState(1);

  const {
    state: { selectedTab },
    dispatch,
  } = useCompany();
  const [selectedCols, setSelectedCols] = useState(selectedTab);
  useEffect(() => {
    setSelectedCols(selectedTab);
  }, [selectedTab]);

  const {
    state: { list: subCompanies, page: pageSubCompany },
    fetchList: fetchSubCompanies,
  } = useSubCompany();
  const {
    state: { list: sites, page, perPage },
    fetchList: fetchSites,
  } = useSite();

  const {
    state: { list: employees, page: pageEmployee },
    fetchList: fetchEmployees,
    remove,
  } = useEmployees();
  const {
    state: { list: secretKeys },
    fetchList: fetchSecretKeys,
    edit: editSecretKey,
    deleting: deleteSecretKey,
  } = useSecretKeys();

  const {
    state: { list: WhiteListIps },
    fetchList: fetchWhiteList,
    edit: editWhiteList,
    deleting: deleteWhiteList,
  } = useWhiteIps();
  const tabs = [
    { title: "subCompanies", num: company?.subCompaniesCount },
    { title: "Sites", num: company?.sitesCount },
    { title: "Employees", num: company?.employeesCount },
    { title: "Secret Keys", num: secretKeys?.length },
    { title: "White List Ips", num: WhiteListIps?.length },
  ];
  useEffect(() => {
    // if (id) fetchSubCompanies(id);
    // if (company?.id) {
    //   fetchSites(company?.id, page, perPage);
    //   fetchEmployees(company?.id);
    // }
    if (selectedCols === "subCompanies")
      fetchSubCompanies(id, pageSubCompany, 100);
    else if (selectedCols === "Sites") fetchSites(company?.id, page, 100);
    else if (selectedCols === "Employees")
      fetchEmployees(company?.id, 0, pageEmployee, 100);
    // console.log(employees, "employees");
    else if (selectedCols === "Secret Keys") {
      fetchSecretKeys(id);
    } else if (selectedCols === "White List Ips") {
      fetchWhiteList(id);
    }
  }, [
    id,
    page,
    perPage,
    fetchSubCompanies,
    fetchSites,
    fetchEmployees,
    selectedCols,
    company,
  ]);
  const handleDetail = (c) => {
    if (selectedCols === "subCompanies")
      navigate(`/subcompanies/SubCompanyDetails/${c}`);
    else if (selectedCols === "Sites") navigate(`/sites/SiteDetail/${c}`);
    else if (selectedCols === "Employees")
      navigate(`/employees/EmployeeDetails/${c}`);
  };

  // Modal Delete Employee
  const handleDelete = (id) => {
    try {
      remove(employeeForDelete);
    } catch (e) {
      console.log("error", e);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Modal Reset Password
  const handleCloseResetPass = () => {
    setOpenResetPass(false);
  };
  const handleOpenResetPass = (user) => {
    setOpenResetPass(true);
    setEmployeeIdResetPassword(user.id);
  };

  const getCols = (name) => {
    switch (name) {
      case "subCompanies":
        return [
          {
            label: "Sub Company Name",
            name: "subCompanyName",
            textLeft: true,
            headTextLeft: true,
          },
          { label: "Sites Number", name: "sites", customRender: SiteNumber },
          // { label: "Employees Number", name: "sites" },
          {
            label: "Sub Company Status",
            name: "isActive",
            customRender: ActiveCell,
          },
        ];
      case "Employees":
        return [
          {
            label: "Employee Code",
            name: "employeeCode",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Employee Full Name",
            name: "fullName",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Current Site Name",
            name: "currentSite",
            customRender: SiteCell,
          },
          {
            label: "User account",
            name: "userId",
            customRender: AccountCell,
          },
        ];
      case "Sites":
        return [
          {
            label: "Site Name",
            name: "siteName",
            textLeft: true,
            headTextLeft: true,
          },
          { label: "Site Country", name: "country" },
          {
            label: "Employees Number",
            name: "employees",
            customRender: EmployeeNumber,
          },
          // { label: "Date Added", name: "date" },
          { label: "Site Status", name: "isActive", customRender: ActiveCell },
        ];
      case "Secret Keys":
        return [
          {
            label: "Secret Key",
            name: "secretKey",
          },
          {
            label: "Description",
            name: "description",
            editable: true,
          },
          {
            label: "Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      case "White List Ips":
        return [
          {
            label: "IP",
            name: "ip",
            editable: true,
          },
          {
            label: "Description",
            name: "description",
            editable: true,
          },
          {
            label: "Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      default:
        return;
    }
  };

  const getData = (name) => {
    switch (name) {
      case "subCompanies":
        return subCompanies;
      case "Sites":
        return sites;
      case "Employees":
        return employees;
      case "Secret Keys":
        return secretKeys;
      case "White List Ips":
        return WhiteListIps;
      default:
        return;
    }
  };
  const getFilters = (name) => {
    switch (name) {
      case "subCompanies":
        return {
          add: () => navigate(`/SubCompanies/add/${id}`),
        };
      case "Sites":
        return { add: () => navigate(`/sites/add/${id}`) };
      case "Employees":
        return {
          add: () => {
            navigate(`/employees/add/${id}`);
          },
          search: (e) => {
            console.log(e);
          },
        };
      case "Secret Keys":
        return {
          add: () => {
            navigate(`AddSecretKey`);
          },
          search: (e) => {
            console.log(e);
          },
        };
      case "White List Ips":
        return {
          add: () => {
            navigate(`AddWhiteIp`);
          },
          search: (e) => {
            console.log(e);
          },
        };
      default:
        return;
    }
  };
  const handleEditSecretKey = async (row, sid) => {
    delete row[0].id;
    delete row[0].company;
    delete row[0].companyId;
    delete row[0].secretKey;
    try {
      await editSecretKey(sid, row[0]);
      fetchSecretKeys(id);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleDeleteSecretKey = async (rowID) => {
    try {
      await deleteSecretKey(rowID);
      fetchSecretKeys(id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditWhiteIp = async (row, wid) => {
    delete row[0].id;
    delete row[0].company;
    delete row[0].secretKey;
    try {
      await editWhiteList(wid, row[0]);
      fetchWhiteList(id);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleDeleteWhiteIp = async (rowID) => {
    try {
      await deleteWhiteList(rowID);
      fetchWhiteList(id);
    } catch (error) {
      console.log(error);
    }
  };
  const getActions = (name) => {
    switch (name) {
      case "Employees":
        return {
          emoloyee: true,
          edit: (id) => navigate("/employees/edit/" + id),
          // delete: (e) => {
          //   setOpen(true);
          //   setEmployeeForDelete(e);
          // },
        };
      case "Secret Keys":
        return {
          inTableEdit: true,
          edit: handleEditSecretKey,
          delete: handleDeleteSecretKey,
        };
      case "White List Ips":
        return {
          inTableEdit: true,
          edit: handleEditWhiteIp,
          delete: handleDeleteWhiteIp,
        };
      default:
        return;
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* Info Table */}
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        dispatch={dispatch}
        selectedCols={selectedCols}
      ></TabsList>
      {/* Table */}
      <CustomTable
        empty={getData(selectedCols)?.length === 0}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        filters={
          (role === "Admin" || role === "Company") && getFilters(selectedCols)
        }
        actions={getActions(selectedCols)}
        moreOptions={
          // selectedCols !== "Employees" && {
          //   detail: handleDetail,
          // }
          selectedCols !== "Secret Keys" &&
          selectedCols !== "White List Ips" && {
            detail: handleDetail,
          }
        }
        renderParams={{
          openModal: handleOpenResetPass,
        }}
        showButton={true}
        isPagination={false}
      ></CustomTable>
      {selectedCols === "Employees" && (
        <>
          <ModalBox
            open={open}
            operation="delete"
            handleClose={handleClose}
            handleOperation={handleDelete}
            text="The delete operation has been completed successfully.."
            name="employee"
          />
          <PassModal
            open={openResetPass}
            handleClose={handleCloseResetPass}
            employeeId={employeeIdResetPassword}
          />
        </>
      )}
    </Box>
  );
}

export default TableInfo;
