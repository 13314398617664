// React
import React from "react";
// Material ui
import { InputBase } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
// MUI Icons
import SearchIcon from "@mui/icons-material/Search";
// Icons
// import SearchHomeRightIcon from "../../icons/SearchHomeRightIcon.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // height: "40px",
    transition: theme.transitions.create("width"),
    width: "100%",
    padding: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchHeader() {
  return (
    <Search sx={{ color: "rgba(0, 0, 0, 0.2);" }}>
      <SearchIconWrapper>
        <SearchIcon />
        {/* <img
          alt="Search right icon"
          src={SearchHomeRightIcon}
          style={{ width: "17px", height: "18px" }}
        /> */}
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
}
