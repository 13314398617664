const fields = [
  {
    label: "User Name",
    name: "username",
    type: "text",

    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    label: "Password",
    name: "password",
    type: "password",
  },
  {
    label: "Sender",
    name: "sender",
    required: true,
    type: "text",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    label: "Balance Password",
    name: "balancePassword",
    type: "password",
  },
  {
    label: "Account ID",
    name: "accountID",
    text: true,
    type: "text",
  },
  {
    label: "Min SMS Alert",
    type: "number",
    name: "minSmsAlert",
    type: "number",
  },
  {
    label: "SMS Provider",
    name: "smsProviderId",
    select: true,
    validationType: "number",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    label: "Status",
    name: "isActive",
    switch: true,
  },
];

export default fields;
