import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request.js";
import {
  CalUtcWithFormat,
  startOfMonth,
  startOfWeek,
} from "../../utilities/functions.js";
import { useAuth } from "../../services/auth.js";

const Context = createContext();

export function useDashboardStatistics() {
  return useContext(Context);
}

const initState = {
  StatisticInfo: null,
  listSitesSalesDaily: [],
  listSitesSalesMonthly: [],
  listSitesSalesWeekly: [],
  listSitesTicketsDaily: [],
  listSitesTicketsMonthly: [],
  listSitesTicketsWeekly: [],
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_StatisticInfo":
      return { ...state, StatisticInfo: { ...action.payload } };

    case "set_listSitesSalesDaily":
      return { ...state, listSitesSalesDaily: [...action.payload] };
    case "set_listSitesSalesMonthly":
      return { ...state, listSitesSalesMonthly: [...action.payload] };
    case "set_listSitesSalesWeekly":
      return { ...state, listSitesSalesWeekly: [...action.payload] };

    case "set_listSitesTicketsDaily":
      return { ...state, listSitesTicketsDaily: [...action.payload] };
    case "set_listSitesTicketsMonthly":
      return { ...state, listSitesTicketsMonthly: [...action.payload] };
    case "set_listSitesTicketsWeekly":
      return { ...state, listSitesTicketsWeekly: [...action.payload] };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function DashboardStatisticsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const { role, adminOn } = useAuth();
  const req = useRequest();

  const fetchOne = useCallback(
    (id) => {
      dispatch({ type: "status", payload: `fetching` });
      req(`Statistic/Info/${id}`, null, {}, true).then((result) => {
        dispatch({ type: "set_StatisticInfo", payload: result.data });
        if (role === "Admin" || role === "Company") {
          fetchSitesBySales(id, {
            fromDate: startOfMonth(new Date()),
            toDate: new Date(),
            current: "Monthly",
          }).then(() => {
            fetchSitesBySales(id, {
              fromDate: startOfWeek(new Date()),
              toDate: new Date(),
              current: "Weekly",
            }).then(() => {
              fetchSitesBySales(id, {
                fromDate: new Date(),
                toDate: new Date(),
                current: "Daily",
              }).then(() => {
                fetchSitesByTickets(id, {
                  fromDate: startOfMonth(new Date()),
                  toDate: new Date(),
                  current: "Monthly",
                }).then(() => {
                  fetchSitesByTickets(id, {
                    fromDate: startOfWeek(new Date()),
                    toDate: new Date(),
                    current: "Weekly",
                  }).then(() => {
                    fetchSitesByTickets(id, {
                      fromDate: new Date(),
                      toDate: new Date(),
                      current: "Daily",
                    });
                  });
                });
              });
            });
          });
        }
      });
      dispatch({ type: "status", payload: `idle` });
    },
    [req]
  );

  /** Sites By Sales */
  const fetchSitesBySales = useCallback(
    async (id, data) => {
      const headersSitesSales = {
        from: data.fromDate
          ? CalUtcWithFormat(data?.fromDate)
          : CalUtcWithFormat(state.from),
        to: data.toDate
          ? CalUtcWithFormat(data?.toDate)
          : CalUtcWithFormat(state.to),
      };

      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Report/SitesBySales/${id}`,
        null,
        {},
        true,
        headersSitesSales
      );
      dispatch({ type: "status", payload: `idle` });
      switch (data?.current) {
        case "Daily":
          dispatch({
            type: "set_listSitesSalesDaily",
            payload: resData.data || [],
          });
          break;
        case "Weekly":
          dispatch({
            type: "set_listSitesSalesWeekly",
            payload: resData.data || [],
          });
          break;
        case "Monthly":
          dispatch({
            type: "set_listSitesSalesMonthly",
            payload: resData.data || [],
          });
          break;
        default:
          break;
      }
    },
    [req]
  );

  /**sites by Ticket list */
  const fetchSitesByTickets = useCallback(
    async (id, data) => {
      const headersTickets = {
        from: data.fromDate
          ? CalUtcWithFormat(data?.fromDate)
          : CalUtcWithFormat(state.from),
        to: data.toDate ? CalUtcWithFormat(data?.toDate) : state.to,
      };

      const resData = await req(
        `Report/SitesByTickets/${id}`,
        null,
        {},
        true,
        headersTickets
      );
      dispatch({ type: "status", payload: "fetching" });

      dispatch({ type: "status", payload: `idle` });
      switch (data?.current) {
        case "Daily":
          dispatch({
            type: "set_listSitesTicketsDaily",
            payload: resData.data || [],
          });
          break;
        case "Weekly":
          dispatch({
            type: "set_listSitesTicketsWeekly",
            payload: resData.data || [],
          });
          break;
        case "Monthly":
          dispatch({
            type: "set_listSitesTicketsMonthly",
            payload: resData.data || [],
          });
          break;
        default:
          break;
      }
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchOne,
        fetchSitesBySales,
        fetchSitesByTickets,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
