import { Route } from "react-router-dom";

import TemplateMessage from "./TemplateMessage";
import TemplateMessageList from "./TemplateMessageList";
import TemplateMessAdd from "./TemplateMessAdd";

export default (
  <Route path="messages" exact element={<TemplateMessage />}>
    <Route index element={<TemplateMessageList />} />
    {/* <Route
      path="SiteDetail/AddTemplateMess/:id"
      element={<TemplateMessAdd />}
    /> */}
  </Route>
);
