import { Route } from "react-router-dom";

import ListServices from "./ListServices";
import Services from "./Services";

export default (
  <Route path="service" exact element={<Services />}>
    <Route index element={<ListServices />} />
  </Route>
);
