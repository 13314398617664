import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useUserAccount() {
  return useContext(Context);
}

const initState = {
  listUser: [],
  listAdmin: [],
  selected: null,
  selectedUser: null,
  selectedfile: null,
  filter: { companyId: 0 },
  countUser: 1,
  countAdmin: 1,
  page: 1,
  perPage: 10,
  totalSizeUsers: 0,
  totalSizeAdmins: 0,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_listUser":
      return { ...state, listUser: [...action.payload] };
    case "set_listAdmin":
      return { ...state, listAdmin: [...action.payload] };
    case "set_selected":
      return { ...state, selectedUser: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_filter":
      return { ...state, filter: action.payload };
    case "addUser":
      const newUserList = [{ ...action.payload }, ...state.listUser];
      return { ...state, listUser: newUserList };
    case "addAdmin":
      const newAdminList = [{ ...action.payload }, ...state.listAdmin];
      return { ...state, listAdmin: newAdminList };
    case "editUser":
      const modifiedUser = state.listUser.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, listUser: modifiedUser };
    case "editAdmin":
      const modifiedAdmin = state.listAdmin.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, listAdmin: modifiedAdmin };
    case "deleteAdmin":
      const filteredAdmin = state.listAdmin.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, listAdmin: filteredAdmin };
    case "deleteUser":
      const filteredUser = state.listUser.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, listUser: filteredUser };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_countUser":
      return { ...state, countUser: action.payload };
    case "set_countAdmin":
      return { ...state, countAdmin: action.payload };
    case "set_totalSizeUsers":
      return { ...state, totalSizeUsers: action.payload };
    case "set_totalSizeAdmins":
      return { ...state, totalSizeAdmins: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function UserAccountProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const fetchUserList = useCallback(
    async (cid, page, perPage, search) => {
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      if (search) headersData.search = search;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `UserAccount/UserList/${cid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listUser", payload: resData.data.users || [] });
      // dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_countUser",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSizeUsers",
        payload: resData.data.totalSize || 0,
      });
    },
    [req]
  );
  //admin users list
  const fetchAdminList = useCallback(
    async (cid, page, perPage, search) => {
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      if (search) headersData.search = search;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `UserAccount/AdminList/${cid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listAdmin", payload: resData.data.users || [] });
      // dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({
        type: "set_countAdmin",
        payload: resData.data.totalPages || 1,
      });
      dispatch({
        type: "set_totalSizeAdmins",
        payload: resData.data.totalSize || 0,
      });
    },
    [req]
  );
  //fetch all information about User Account
  const fetchuserAccountInfo = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`UserAccount/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  //create a new user account
  const createUser = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "UserAccount/CreateUser",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "addUser", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  //create a new admin user account
  const createAdmin = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "UserAccount/CreateAdmin",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "addAdmin", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const ResetPassword = useCallback(
    async (data, id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `UserAccount/ResetPassword/${id}`,
            data,
            { method: "PUT" },
            true
          );
          // dispatch({ type: "edit", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  //create a new admin user account
  const changePassword = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "UserAccount/ChangePassword",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const updateAccount = useCallback(
    async (id, data, listName) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `UserAccount/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          if (listName === "adminList")
            dispatch({ type: "editAdmin", payload: resData });
          else if (listName === "userList")
            dispatch({ type: "editUser", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteAccount = useCallback(
    async (id, listName) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `UserAccount/Delete/${id}`,
            null,
            { method: "DELETE" },
            true
          );
          if (listName === "adminList")
            dispatch({ type: "deleteAdmin", payload: resData.data });
          else if (listName === "userList")
            dispatch({ type: "deleteUser", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchUserList,
        fetchAdminList,
        fetchuserAccountInfo,
        createUser,
        createAdmin,
        updateAccount,
        deleteAccount,
        ResetPassword,
        changePassword,
      }}
    >
      {children}
    </Context.Provider>
  );
}
