var apiUrl;
if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://api.smartvalet.libancallplus.com/api/v1";
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://staging.api.smartvalet.libancallplus.com/api/v1";
else {
  apiUrl = "https://staging.api.smartvalet.libancallplus.com/api/v1";
}

export { apiUrl };
