//react components

import React, { useEffect, useState } from "react";
//react router
import { useNavigate, useParams } from "react-router-dom";
//use form and yup
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import fields from "./fields";

//@material-ui/core components

import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
//site provider
import { useSite } from "../Sites/Provider";
//discount provider
import { useMemberPlan } from "./Provider";
import {
  handleErrorResponse,
  TransformFromPercent,
} from "../../utilities/functions";
import { useTheme } from "@emotion/react";
import ErrorsList from "../../ui/ErrorsList";
import { useAuth } from "../../services/auth";
// yup schema
const yupSchema = {
  siteId: Yup.number(),
  title: Yup.string().required("required"),
  price: Yup.number()
    .required("required")
    .min(0, "price must be greater than zero"),
  duration: Yup.number()
    .required("required")
    .min(0, "duration must be greater than zero"),
  isActive: Yup.boolean(),
};
const formSchema = Yup.object().shape(yupSchema);

//css styled
const GridGroup1 = styled.div`
  width: 65%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px;
  gap: 42px;
`;
const GridGroup2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 80px;
  gap: 42px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;

  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormMemberPlans = ({ handleSuccess }) => {
  let navigate = useNavigate();
  const { id } = useParams();
  let theme = useTheme();
  let { role } = useAuth();

  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const {
    state: { list: sites, page, perPage, selected: site },
    fetchList: fetchSites,
    fetchOne: fetchSite,
  } = useSite();
  const { create } = useMemberPlan();

  //useEffect to fetch sites list
  useEffect(() => {
    if (role === "Admin" || role === "Company" || role === "SubCompany")
      fetchSites(0, page, perPage, 0);
  }, [fetchSites]);

  useEffect(() => {
    fetchSite(id);
  }, [id, fetchSite]);
  useEffect(() => {
    setValue("siteId", id);
  }, [id]);
  //onSubmit function
  const onSubmit = async (values) => {
    delete values.companyName;
    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      setError({ title: handleErrorResponse(error.status, "Member Plan") });
    }
  };
  if (!sites) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" component="h4" mb={3} gutterBottom>
          Member Plan Details
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {" "}
          <ErrorsList error={error} />
        </Typography>
        <GridGroup2>
          {fields.map((field, index) => {
            if (field.viewOnly) return null;
            const inputProps = { ...field.inputProps };

            if (index === 0) inputProps.autoFocus = true;
            inputProps.type = field.type;
            if (field.switch) {
              return (
                <Box>
                  <FormGroup>
                    <FormLabel
                      component="legend"
                      sx={{
                        mt: theme.dimensions.mt,
                      }}
                    >
                      {field.label}
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          color="success"
                          defaultChecked
                          // defaultChecked={company ? company[field?.name] : true}
                        />
                      }
                      {...register(field.name)}
                      label="Active"
                    />
                  </FormGroup>
                </Box>
              );
            }
            if (field.select) {
              return !field.disabled ? (
                <TextField
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  sx={{ width: theme.dimensions.width }}
                  label={field.label}
                  select={field.select}
                  inputProps={{
                    ...register(field?.name),
                    required: true,
                  }}
                  size="small"
                >
                  {sites.map((site, index) => (
                    <MenuItem key={site?.id} value={site?.id}>
                      {site?.siteName}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <div>
                  <Typography variant="body2" component="h6" gutterBottom>
                    {field.label}
                  </Typography>

                  <Box
                    sx={{
                      borderBottom: "1px solid gray",
                      width: "290px",
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    {field.label === "Company Name"
                      ? site?.company?.companyName
                      : site?.siteName}
                  </Box>
                </div>
              );
            }
          })}
        </GridGroup2>
        <GridGroup2>
          {fields.map(
            (field, index) =>
              !field.select &&
              !field.switch && (
                <>
                  <TextField
                    type={field.name === "title" ? "text" : "number"}
                    key={index}
                    label={field.label}
                    name={field.name}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    select={field.select}
                    className={field.class}
                    disabled={field.disabled}
                    sx={{
                      width:
                        field.name === "duration"
                          ? "150px"
                          : theme.dimensions.width,
                    }}
                    inputProps={{
                      ...register(field?.name),
                      required: field.required,
                    }}
                    size="small"
                    required
                  ></TextField>
                </>
              )
          )}
        </GridGroup2>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Add Plan
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default React.memo(FormMemberPlans);
