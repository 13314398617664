import React from "react";

const RenderMess = ({ row }) => {
  return (
    <div
      style={{
        width: "200px",
      }}
    >
      {row.message}
    </div>
  );
};

export default RenderMess;
