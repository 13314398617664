import React from "react";
import { convertToHours } from "../../../../utilities/functions";

const DurationRender = ({ row }) => {
  return (
    <>
      {row.attendances.map((duration, index) => (
        <p style={{ borderBottom: "1px solid #F7F9FB" }}>
          {duration.duration ? convertToHours(duration.duration) : 0}
        </p>
      ))}
    </>
  );
};

export default DurationRender;
