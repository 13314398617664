import React from "react";
import FormWebContent from "./FormWebContent";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalBox from "../../ui/ModalBox";

function AddWebContent() {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const handleClose = () => {
    setOpen(!open);
    navigate(-1);
  };

  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <div>
      <Typography variant="Poster" component="h4" gutterBottom>
        Add Web Content
      </Typography>
      <FormWebContent handleSuccess={handleSuccess} />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The add operation has been completed successfully.. You added web content to this site"
        name="Web Content"
      />
    </div>
  );
}

export default AddWebContent;
