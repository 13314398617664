// React
import React from "react";
import { useNavigate } from "react-router-dom";
// Material UI
import { Box, Typography, Grid, Paper } from "@mui/material";
// Styled Component
import styled from "styled-components";
// Icons
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";
import TextSnippetTwoToneIcon from "@mui/icons-material/TextSnippetTwoTone";
// Services
import { useAuth } from "../../services/auth";

const StoreList = () => {
  let navigate = useNavigate();
  const { role } = useAuth();

  let Store = [
    {
      name: "Materials",
      icon: (
        <Inventory2TwoToneIcon
          color="action"
          fontSize="large"
        ></Inventory2TwoToneIcon>
      ),
      route: "materials",
      roles: ["Admin", "Company"],
    },
    {
      name: "Categories",
      icon: (
        <CategoryTwoToneIcon
          color="action"
          fontSize="large"
        ></CategoryTwoToneIcon>
      ),
      route: "categories",
      roles: ["Admin", "Company"],
    },
    {
      name: "Vendors",
      icon: (
        <PointOfSaleTwoToneIcon
          color="action"
          fontSize="large"
        ></PointOfSaleTwoToneIcon>
      ),
      route: "vendors",
      roles: ["Admin", "Company"],
    },
    {
      name: "Memo",
      icon: (
        <TextSnippetTwoToneIcon
          color="action"
          fontSize="large"
        ></TextSnippetTwoToneIcon>
      ),
      route: "memo",
      roles: ["Admin", "Company"],
    },
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#F7F9FB",
    padding: 5,
    textAlign: "center",
    color: "#1C1C1C",
  }));

  return (
    <>
      <Typography variant="Poster" component="h4">
        Store
      </Typography>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 10 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Store.map((report, index) => {
            if (report.roles.includes(role))
              return (
                <Grid item xs={2} sm={6} md={6} key={index}>
                  <Item>
                    <Box sx={{ flexGrow: 1, display: "flex" }}>
                      <Box
                        sx={{
                          flex: "1",
                          mt: 1,
                          mr: 2,
                          fontSize: "24px",
                          textAlign: "right",
                        }}
                      >
                        {report.icon}
                      </Box>
                      <Box
                        sx={{ flex: "3", textAlign: "left", cursor: "pointer" }}
                        onClick={() => navigate(report.route)}
                      >
                        <Box sx={{ fontWeight: "bold" }}>{report.name}</Box>
                        <Box>Details ...</Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default React.memo(StoreList);
