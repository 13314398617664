// Providers
import React, { useEffect, useState } from "react";
import TicketTable from "./TicketTable.js";
import styled from "styled-components";
import printingFields from "./printingFields.js";
import Bill from "./Bill.js";
import { Alert, Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSite } from "../Provider.js";
import { useForm } from "react-hook-form";
import { handleResponse } from "../../../utilities/functions";

// Yup
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorsList from "../../../ui/ErrorsList.js";

const GridGroup = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 5fr 2fr;
  gap: 100px;
  padding: 10px;
`;
const ButtonWrapper = styled.div`
  button {
    float: right;
    margin-left: 20px;
  }
`;

const FormTicket = ({ ticket = false, handleSuccess }) => {
  let theme = useTheme();
  const navigate = useNavigate();
  const {
    state: { printingDiv1, printingDiv2, printingDiv3, printingDiv4 },
    updateTicket,
    fetchTicketPrintingFields,
  } = useSite();
  const [error, setError] = useState(null);
  let { id } = useParams();
  const { reset } = useForm({
    resolver: yupResolver(),
  });
  // useEffect(() => {
  //   fetchTicketPrintingFields(id);
  // }, [fetchTicketPrintingFields, id]);
  const getCardContent = (selectedDiv) => {
    let arr = [];
    for (let i = 0; i < selectedDiv?.length; i++) {
      let printedValue = "";
      if (selectedDiv[i]) {
        let field = printingFields[selectedDiv[i] - 1];
        printedValue = `${field?.description}`;
        arr.push({
          ...field,
          printedValue,
        });
      }
    }
    return arr;
  };

  const submitTickets = async () => {
    try {
      await updateTicket(id, {
        printDivisions: [
          {
            divisionId: 1,
            fieldIds: printingDiv1,
          },
          {
            divisionId: 2,
            fieldIds: printingDiv2,
          },
          {
            divisionId: 3,
            fieldIds: printingDiv3,
          },
          {
            divisionId: 4,
            fieldIds: printingDiv4,
          },
        ],
      });
      reset();
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      if (e.status === 404)
        setError({ title: "Pick at least One printing field." });
      if (e.status === 500) setError({ title: "Internal Server Error" });
      handleResponse(e.errors.status);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        {error && (
          <Alert severity="error">
            <ErrorsList error={error} />
          </Alert>
        )}
        <Typography variant="Poster" component="h4" gutterBottom>
          Ticket Printing Divisions
        </Typography>
        {/* view Buttons only when updating */}
        {ticket && (
          <ButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              onClick={submitTickets}
              sx={{
                backgroundColor: theme.color.blackBackground,
                ml: theme.dimensions.ml,
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: theme.color.grayBackground,
                color: theme.color.dark,
                "&:hover": { background: `${theme.color.gray50}` },
              }}
            >
              Discard
            </Button>
          </ButtonWrapper>
        )}
      </Box>
      <GridGroup>
        {/* Table */}
        <TicketTable ticket={ticket} />
        {/* Preview */}
        <Bill getCardContent={getCardContent} />
      </GridGroup>
    </Box>
  );
};

export default React.memo(FormTicket);
