import React, { useState, useEffect } from "react";
import { useSubCompany } from "./Provider";
import { useSite } from "../Sites/Provider";
import { useCompany } from "../Companies/Provider";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
// import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import fields from "./fields";
// material ui
import {
  Box,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  MenuItem,
  OutlinedInput,
  Select,
  Chip,
  Button,
  useTheme,
  Card,
  InputLabel,
  FormControl,
  FormLabel,
  Alert,
  FormHelperText,
} from "@mui/material";
import styled from "styled-components";
// utilities
import { handleResponse } from "../../utilities/functions";
// components
import ErrorsList from "../../ui/ErrorsList";
// style
//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
// yup schema
const formSchema = Yup.object().shape({
  siteIds: Yup.array()
    .required("this field is required")
    .min(1, "must select at least 1 site"),
  subCompanyName: Yup.string()
    .required("this field is required")
    .max(100, "must be less than or equal to 100 character"),
  isActive: Yup.boolean(),
});

const FormSubCompany = ({ subCompany, handleSuccess, setSubCompanyName }) => {
  // providers
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { Names: sites, page, perPage },
    fetchSiteNames: fetchSites,
  } = useSite();
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
    dispatch,
  } = useCompany();
  const { create, edit } = useSubCompany();
  const navigate = useNavigate();
  let theme = useTheme();
  // vars
  let params = useParams();
  const id = params.id;
  const [companyName, setCompanyName] = useState(null);
  const [error, setError] = useState(null);
  const [siteList, setSiteList] = React.useState([]);

  // use effects
  useEffect(() => {
    if (!subCompany) fetchSites(id);
    else fetchSites(subCompany?.company?.id);
  }, [fetchSites, id, subCompany]);

  useEffect(() => {
    if (company) setCompanyName(company?.companyName);
  }, [company]);

  useEffect(() => {
    // if we are adding subcompany => id refers to selected company id
    if (!subCompany) {
      fetchCompany(id);
    } else {
      dispatch({ type: "set_selected", payload: null });
      // store selected sites as it is in sites (same format)
      let defaultSites = sites.map((site) => {
        var flag = null;
        subCompany.sites.map((selectedSite) => {
          if (selectedSite.id === site.id) {
            flag = site;
            return;
          }
          return;
        });
        if (flag) return flag;
        flag = null;
        return null;
      });
      defaultSites = defaultSites.filter((item) => item !== null);
      setSiteList(defaultSites);
      setValue("siteIds", defaultSites);
    }
  }, [id, fetchCompany, subCompany, dispatch, sites, setValue, setSiteList]);

  // check if array contains only numbers
  function onlyNumbers(array) {
    return array.every((element) => {
      return typeof element === "number";
    });
  }
  // on submit
  const onSubmit = async (values) => {
    delete values.companyName;
    delete values.id;
    if (!subCompany) values.companyId = id;
    else values.companyId = subCompany.companyId;
    // store ids only
    if (!onlyNumbers(values.siteIds)) {
      const siteIds = values.siteIds.map((site) => {
        return site?.id;
      });
      values.siteIds = siteIds;
    }

    try {
      if (subCompany) await edit(id, values);
      else {
        await create(values);
        setSubCompanyName(values.subCompanyName);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) {
        if (e.errors.message) setError({ title: "Site is already used" });
        else setError(e.errors);
      } else if (e.status === 409)
        setError({ title: "Sub Company Name is already exists" });
      else if (e.status === 500) setError({ title: "Internal Server Error" });

      handleResponse(e.errors.status);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Fields functions
  const getData = (name) => {
    switch (name) {
      case "sites":
        return sites;
      default:
        return;
    }
  };
  // console.log(sites,'sites')
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSiteList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const getCompanyName = (f) => {
    if (!subCompany && company) return company[f.value];
    else if (!subCompany) {
      if (f.label === "Company Name") {
        return companyName;
      }
      return "";
    } else {
      if (f?.label !== "Company Name") {
        return subCompany[f?.name];
      } else {
        return subCompany?.company.companyName;
      }
    }
  };
  if (!company && subCompany) return;
  return (
    <>
      <Box>
        {/* Body */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            <Typography variant="Poster" component="h4" gutterBottom>
              Sub Company Details
            </Typography>
            {error && (
              <Alert severity="error">
                <ErrorsList error={error} />
              </Alert>
            )}
            {sites?.length === 0 && (
              <Alert severity="warning">
                <Typography variant="Poster" component="span">
                  {" "}
                  You should add a new Site first,{" "}
                  <Typography
                    variant="Poster"
                    component="span"
                    onClick={() => navigate(`/sites/add/${id}`)}
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <strong>Add Site</strong>
                  </Typography>
                </Typography>
              </Alert>
            )}
            <GridGroup>
              {fields?.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;
                inputProps.type = f.type;
                return (
                  <Box key={i}>
                    {f.disabled && f.hasValue ? (
                      <Box>
                        <Typography variant="body2" component="h6" gutterBottom>
                          {f.label}
                        </Typography>
                        {/* Adding sub company => !subcompany */}
                        <Box
                          sx={{
                            borderBottom: "1px solid gray",
                            width: "290px",
                            borderRadius: "8px",
                            textAlign: "center",
                          }}
                        >
                          {getCompanyName(f)}
                        </Box>
                        {/* <hr style={{ marginRight: "30px" }} /> */}
                      </Box>
                    ) : f.switch ? (
                      <Box>
                        <FormGroup>
                          <FormLabel
                            component="legend"
                            sx={{
                              mt: theme.dimensions.mt,
                            }}
                          >
                            {f.label}
                          </FormLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                defaultChecked={
                                  subCompany ? subCompany[f?.name] : true
                                }
                              />
                            }
                            {...register(f?.name)}
                            label="Active"
                          />
                        </FormGroup>
                      </Box>
                    ) : f.select ? (
                      <>
                        <FormControl
                          sx={{
                            width: theme.dimensions.width,
                            mt: theme.dimensions.mt,
                            mb: theme.dimensions.mb,
                          }}
                          size="small"
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            htmlFor="demo-simple-select"
                          >
                            {f.label}
                            {" *"}
                          </InputLabel>
                          <Select
                            label={f.label}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            multiple
                            value={siteList}
                            onChange={handleChangeSelect}
                            required={true}
                            error={!!errors[f.name]?.message}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Chip"
                              />
                            }
                            inputProps={{
                              ...register(f?.name),
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 1,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value?.id} label={value?.name} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getData(f.data)?.map((c, i) => (
                              <MenuItem key={i} value={c}>
                                {c.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            htmlFor="demo-simple-select"
                            sx={{ color: "#d32f2f" }}
                          >
                            {errors[f.name]?.message}
                          </FormHelperText>
                        </FormControl>
                      </>
                    ) : (
                      <>
                        <TextField
                          label={!f.hasValue && f.label}
                          name={f.name}
                          select={f.select}
                          size="small"
                          className={f.class}
                          error={!!errors[f.name]}
                          helperText={errors[f.name]?.message}
                          fullWidth
                          sx={{
                            width: theme.dimensions.width,
                            mt: theme.dimensions.mt,
                            mb: theme.dimensions.mb,
                          }}
                          // if we are adding a sub company and this field is disabled and has a value:
                          {...(!subCompany && company && f.hasValue
                            ? { value: company[f.value] }
                            : {
                                defaultValue: !subCompany
                                  ? f?.label === "Company Name"
                                    ? companyName
                                    : ""
                                  : f.label !== "Company Name"
                                  ? subCompany[f?.name]
                                  : subCompany?.company.companyName,
                              })}
                          // onChange={onChange}
                          disabled={f.disabled}
                          required={f.required}
                          inputProps={{
                            ...register(f?.name),
                          }}
                        ></TextField>
                      </>
                    )}
                  </Box>
                );
              })}
            </GridGroup>
            {/* End Body */}
            {/* Buttons */}
            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                {subCompany ? "Save" : "Add Sub Company"}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(FormSubCompany);
