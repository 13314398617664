// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Material UI
import { Alert, Button, useTheme } from "@mui/material";
// Custom UI
import CustomTable from "../../../ui/CustomTable";
import TabsList from "../../../ui/TabsList";
import ErrorsList from "../../../ui/ErrorsList";
import ModalBox from "../../../ui/ModalBox";
// Providers
import { useMaterialCategory } from "./Provider";
// Utilities
import { handleResponse } from "../../../utilities/functions";
// Services
import { useAuth } from "../../../services/auth";

function List() {
  let navigate = useNavigate();
  const { role, adminOn } = useAuth();
  let theme = useTheme();
  const {
    state: { list: listMaterialCategories, totalSize },
    remove: removeMaterialCategory,
    edit: editMaterialCategory,
    fetchList: fetchMaterialCategories,
    dispatch,
  } = useMaterialCategory();
  const [selectedCols, setSelectedCols] = useState("Categories");
  const [filterClick, setFilterClick] = useState(false);
  const [vState, setVState] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const tabs = [{ title: "Categories", num: totalSize }];

  useEffect(() => {
    // fetchMaterialCategories();
  }, [fetchMaterialCategories]);

  const handleSuccess = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async (e, ID) => {
    try {
      await editMaterialCategory(ID, e[0]);
      fetchMaterialCategories();
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
      if (e.status === 404) setError({ title: e.errors.message });
      else setError({ title: e.errors.message });
      handleResponse(e.errors.status);
    }
  };
  const handleDelete = async (ID) => {
    try {
      await removeMaterialCategory(ID);
      fetchMaterialCategories();
    } catch (error) {
      console.log(error);
    }
  };

  const getCols = () => {
    return [{ label: "Category Name", name: "name", editable: true }];
  };

  const getData = () => listMaterialCategories;

  const getActions = () => {
    return {
      selectedCols: true,
      inTableEdit: true,
      edit: handleEdit,
      delete: handleDelete,
      check: (v) => v.length > 0,
      vState: vState,
      setVState: setVState,
    };
  };
  const handleDetail = (id) => {
    navigate(`detail/${id}`);
  };

  const handleClick = () => {
    // return handleExportExcel();
  };
  return (
    <>
      {/* <Typography variant="Poster" component="h4">
        Select Category or Unit
      </Typography> */}

      {/* <FilterForm setFilterClick={setFilterClick}></FilterForm> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
        {error && (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        )}
        {/* {filterClick && (
          <Button
            sx={{
              border: `1px solid ${theme.color.Screamingreen}`,
              color: theme.color.Screamingreen,
              mt: "5px",
              textTransform: "capitalize",
              "&:hover": { background: `${theme.color.gray50}` },
            }}
            onClick={handleClick}
          >
            Export to Excel
          </Button>
        )} */}
      </div>

      <CustomTable
        empty={getData(selectedCols)?.length === 0 ? true : false}
        cols={getCols()}
        data={getData()}
        actions={role === "Admin" ? getActions() : null}
        // showButton={true}
        // moreOptions={{
        //   detail: handleDetail,
        // }}
        filters={{
          add: role === "Admin" ? () => navigate("add") : null,
        }}
        dispatch={dispatch}
        isPagination={false}
      ></CustomTable>
      <ModalBox
        open={open}
        operation="edit"
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
        // name={`${selectedCols}`}
      />
    </>
  );
}

export default List;
