//react component
import React, { useEffect, useState } from "react";

//mui material component
import {
  Button,
  Switch,
  TextField,
  MenuItem,
  FormControlLabel,
  Box,
  FormGroup,
  Typography,
  Card,
  FormLabel,
  Alert,
} from "@mui/material";

//styled
import styled from "styled-components";

//forms and validation it
import fields from "./fields";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
// fuctions we used
import { CalDate, handleErrorResponse } from "../../utilities/functions";

// context we need
import { useContract } from "./Provider";
import { useSite } from "../Sites/Provider";
import { useTheme } from "@emotion/react";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ErrorsList from "../../ui/ErrorsList";
//import dayjs from "dayjs";
// yup schema
const yupSchema = fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
const GridGroup1 = styled.div`
  width: 98%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 42px;
  grid-template-rows: 100px;
`;
const GridGroup2 = styled.div`
  width: 64%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  gap: 42px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormContract = ({ handleSuccess }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  let theme = useTheme();
  const [error, setError] = useState(null);

  const {
    state: { file: fName },
    create,
    uploadContract,
  } = useContract();
  const {
    state: { list: sites, page, perPage, selected: site },
    fetchList: fetchSites,
    fetchOne: fetchSite,
  } = useSite();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  //useEffect to fetch sites list
  useEffect(() => {
    fetchSites(0, page, perPage, 0);
  }, [fetchSites]);
  useEffect(() => {
    fetchSite(id);
  }, [fetchSite, id]);
  // onSubmit function
  useEffect(() => {
    setValue("siteId", id);
  }, [id]);
  const onSubmit = async (values) => {
    delete values.companyName;
    values.startDate = CalDate(values.startDate);
    values.endDate = CalDate(values.endDate);
    !values.firstReminder
      ? (values.firstReminder = "")
      : (values.firstReminder = CalDate(values.firstReminder));

    !values.secondReminder
      ? (values.secondReminder = "")
      : (values.secondReminder = CalDate(values.secondReminder));
    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      setError({ title: handleErrorResponse(error.status, "Contract") });
    }
  };

  const handleFile = async (file) => {
    try {
      await uploadContract(file);
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    setValue("fileName", fName);
  }, [fName]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" component="h4" mb={3} gutterBottom>
          Contract Details
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        ) : (
          ""
        )}
        <GridGroup1>
          {fields.map((field, index) => {
            if (field.viewOnly) return null;
            const inputProps = { ...field.inputProps };

            if (index === 0) inputProps.autoFocus = true;
            inputProps.type = field.type;
            if (!field.date && !field.switch) {
              return !field.disabled ? (
                <Box key={field.name}>
                  <TextField
                    required
                    error={!!errors[field.name]}
                    sx={{ width: theme.dimensions.width }}
                    size="small"
                    label={!field.file && field.label}
                    select={field.select}
                    inputProps={
                      !field.file && {
                        ...register(field?.name),
                        required: true,
                      }
                    }
                    onChange={(e) =>
                      field.file && handleFile(e.target.files[0])
                    }
                    fullWidth
                    readOnly={true}
                    helperText={
                      field.file
                        ? " Accepts .pdf, .doc, .docx"
                        : errors[field.name]?.message
                    }
                    type={field.file ? "file" : "string"}
                  >
                    {sites?.map((site, index) => (
                      <MenuItem key={site.siteId} value={site.id}>
                        {site?.siteName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              ) : (
                <div>
                  <Typography variant="body2" component="h6" gutterBottom>
                    {field.label}
                  </Typography>

                  <Box
                    sx={{
                      borderBottom: "1px solid gray",
                      width: "290px",
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    {field.label === "Company Name"
                      ? site?.company?.companyName
                      : site?.siteName}
                  </Box>
                </div>
              );
            }
          })}
        </GridGroup1>
        <GridGroup2>
          {fields.map((field, index) => (
            <>
              {field.date && (
                <LocalizationProvider
                  key={field.name}
                  dateAdapter={AdapterDayjs}
                >
                  <div components={["DateRangePicker"]}>
                    <DatePicker
                      label={field.label}
                      onChange={(date) => setValue(field.name, date)}
                      error={!!errors[field.name]}
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          required: field.required,
                          error: errors[field.name]?.message,
                          helperText: (
                            <p style={{ color: "#d32f2f" }}>
                              {errors[field.name]?.message}
                            </p>
                          ),
                          size: "small",
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
              )}
              {field.switch && (
                <Box key={index}>
                  <FormGroup>
                    <FormLabel
                      component="legend"
                      sx={{
                        mt: theme.dimensions.mt,
                      }}
                    >
                      {field.label}
                    </FormLabel>
                    <FormControlLabel
                      sx={{
                        mt: theme.dimensions.mt,
                      }}
                      control={<Switch color="success" defaultChecked />}
                      {...register(field.name)}
                      label="Active"
                    />
                  </FormGroup>
                </Box>
              )}
            </>
          ))}
        </GridGroup2>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Add Contract
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default React.memo(FormContract);
