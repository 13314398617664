import React, { useEffect, useState } from "react";
import TabsList from "../../ui/TabsList";
import CustomTable from "../../ui/CustomTable";
import { useCompany } from "../Companies/Provider";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useEmployees } from "../employee/Provider";
import { useDeduction } from "./provider";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../utilities/functions";
import DueDate from "./render/DueDate";
import { useAuth } from "../../services/auth";

const DeductionsList = () => {
  const { role, adminOn } = useAuth();
  let navigate = useNavigate();
  const [selectedCols, setSelectedCols] = useState("Deductions");
  const cols = [
    {
      label: "Amount",
      name: "amount",
      checkbox: true,
      headTextLeft: true,
      editable: true,
      type: "number",
    },
    {
      label: "Due Date",
      name: "dueDate",
      editable: true,
      type: "date",
      // customRender: DueDate,
    },
  ];
  const {
    state: { Names: companies, count, totalSize, perPage, page },
    fetchNames: fetchCompany,
  } = useCompany();
  const {
    state: { Names: employees },
    fetchNames: fetchEmployees,
  } = useEmployees();
  const {
    state: { list, companyIDAtt, employeeIDAtt },
    fetchList,
    edit,
    remove,
    dispatch,
  } = useDeduction();
  const tabs = [{ title: "Deductions", num: list?.length }];

  // fetch companies list
  useEffect(() => {
    if (role === "Admin") fetchCompany();
  }, [fetchCompany]);
  // fetch employees list
  useEffect(() => {
    if (role === "Admin") {
      if (companyIDAtt) fetchEmployees(companyIDAtt, 0);
    } else if (role === "Company") {
      fetchEmployees(adminOn?.id, 0);
    }
  }, [fetchCompany, companyIDAtt]);
  // fetch employees list
  useEffect(() => {
    if (employeeIDAtt) {
      fetchList(employeeIDAtt);
    }
  }, [fetchList, employeeIDAtt]);
  const handleEdit = async (row, id) => {
    delete row[0].id;
    delete row[0].employee;
    console.log(id, "id edit");
    try {
      await edit(row[0], id);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleDelete = async (PayrollID) => {
    await remove(PayrollID);
  };

  return (
    <>
      <Box
        sx={{
          background: "#f7f9fb",
          padding: "20px",
          width: "70%",
          mb: 3,
        }}
      >
        {role === "Company" && (
          <FormControl mt={1}>
            <span style={{ fontSize: "12px" }}>Company Name</span>
            <Box
              sx={{
                borderBottom: "1px solid gray",
                width: "290px",
                borderRadius: "8px",
                textAlign: "center",
                marginRight: "20px",
                paddingTop: "20px",
              }}
            >
              {adminOn?.companyName}
            </Box>
          </FormControl>
        )}
        {role === "Admin" && (
          <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
            <span style={{ fontSize: "12px" }}>Company Name</span>
            <Select
              onChange={(e) => {
                dispatch({
                  type: "set_companyIDAtt",
                  payload: e.target.value,
                });
              }}
              defaultValue={companyIDAtt}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                autoFocus: true,
              }}
              sx={{
                fontSize: "12px",
                width: "300px",
                height: "40px",
                marginTop: "14px",
              }}
            >
              {companies.map((company) => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
          <span style={{ fontSize: "12px" }}>Employee Name</span>
          <Select
            onChange={(e) => {
              dispatch({
                type: "set_employeeIDAtt",
                payload: e.target.value,
              });
            }}
            defaultValue={employeeIDAtt}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
              autoFocus: true,
            }}
            sx={{
              fontSize: "12px",
              width: "300px",
              height: "40px",
              marginTop: "14px",
            }}
          >
            {employees.map((e) => {
              return (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      />

      <CustomTable
        empty={list?.length === 0 ? true : false}
        cols={cols}
        data={list}
        filters={{
          add: () => navigate(`add/${employeeIDAtt}`),
        }}
        actions={{
          selectedCols: true,
          inTableEdit: true,
          edit: (row, id) => {
            handleEdit(row, id);
          },
          delete: handleDelete,
        }}
      ></CustomTable>
    </>
  );
};

export default DeductionsList;
