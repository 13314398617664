import { Box } from "@mui/material";
import React from "react";

function AdminRole({ row, i }) {
  if (row?.roles?.find((element) => element === "Site")) {
    return (
      <Box sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {"Site Admin"}
        {row?.site && (
          <>
            <br /> Site Name: <strong>{row?.site?.siteName}</strong>
          </>
        )}
      </Box>
    );
  } else if (row?.roles?.find((element) => element === "Company")) {
    return (
      <Box sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {"Company Admin"}
        {row?.company && (
          <>
            <br /> Company Name: <strong>{row?.company?.companyName}</strong>
          </>
        )}
      </Box>
    );
  } else if (row?.roles?.find((element) => element === "SubCompany")) {
    return (
      <Box sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {"Sub Company Admin"}
        {row?.subCompany && (
          <>
            <br /> Sub Company Name:{" "}
            <strong>{row?.subCompany?.subCompanyName}</strong>
          </>
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{ paddingTop: "5px", paddingBottom: "5px" }}>{"Admin"}</Box>
    );
  }
}

export default AdminRole;
