import { Route } from "react-router-dom";

// import { RequireAuth } from "../../services/auth";
import Company from "./Company";
import CompanyList from "./CompanyList";
import InfoCompany from "./InfoCompany/InfoCompany";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";
import AddSecretKeys from "../SecretKeys/AddSecretKeys";
import AddWhiteIps from "../WhiteIps/AddWhiteIps";

export default (
  <Route path="companies" exact element={<Company />}>
    <Route index element={<CompanyList />} />
    <Route path="CompanyDetails/:id" element={<InfoCompany />}>
      {/* <Route path="edit" element={<EditCompany />} /> */}
    </Route>
    <Route path="CompanyDetails/:id/edit" element={<EditCompany />} />
    <Route path="add" element={<AddCompany />} />
    <Route path="CompanyDetails/:id/AddSecretKey" element={<AddSecretKeys />} />
    <Route path="CompanyDetails/:id/AddWhiteIp" element={<AddWhiteIps />} />
  </Route>
);
