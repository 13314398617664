import React from "react";

const EmployeeRoles = ({ row }) => {
  return (
    <div>
      {row.roles.map((role, index) => (
        <div style={{ marginBottom: "10px" }} key={index}>
          {role?.roleTitle}
        </div>
      ))}
    </div>
  );
};

export default EmployeeRoles;
