import React from "react";

const HourlyRate = ({ row }) => {
  return (
    <div>
      {row.roles.map((role, index) => (
        <div
          style={{
            marginBottom: "10px",
            height: "30px",
          }}
        >
          {role.hourlyRate}
        </div>
      ))}
    </div>
  );
};

export default HourlyRate;
