import { Route } from "react-router-dom";

import ListGates from "./ListGates";
import Gates from "./Gates";
import AddGate from "./AddGate";

export default (
  <Route path="gates" exact element={<Gates />}>
    <Route index element={<ListGates />} />
    <Route path="SiteDetail/AddGate/:id" element={<AddGate />} />
  </Route>
);
