import React from 'react'

function SiteCell({row , id}) {
  
  if(!row['currentSite']) return  "not specified"
  return (
    <div>{row?.currentSite.siteName}</div>
  )
}

export default SiteCell