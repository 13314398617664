import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormAttendance from "./FormAttendance";
import ModalBox from "../../ui/ModalBox";
import { useAttendance } from "./Provider";

const UpdateAttendance = () => {
  const {
    state: { selected: AttendanceInfo },
    fetchOne,
  } = useAttendance();
  //state which check open or close modal
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  //var to navigate another page

  const navigate = useNavigate();
  //close modal function
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  //open modal function when  clicked button

  const handleSuccess = () => {
    setOpen(true);
    setReload(!reload);
  };
  useEffect(() => {
    fetchOne(id);
  }, [fetchOne, id, reload]);

  if (!AttendanceInfo) return;
  if (AttendanceInfo?.id != id) return;

  return (
    <div>
      <Typography variant="Poster" component="h4" gutterBottom>
        Update Attendance
      </Typography>
      <FormAttendance
        handleSuccess={handleSuccess}
        attendance={AttendanceInfo}
      />
      <ModalBox
        open={open}
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
        name="Attendance"
      />
    </div>
  );
};

export default React.memo(UpdateAttendance);
