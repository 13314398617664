import React from 'react'
import { Outlet } from 'react-router-dom';
import Layout from '../../layout/Layout';

const MemberShip = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default React.memo(MemberShip);
