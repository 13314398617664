import React from "react";

const RatioRender = ({ row }) => {
  return (
    <div>
      {row.sites.map((s, index) => (
        <div key={index} style={{ paddingBottom: "10px" }}>
          {s?.ratio}
        </div>
      ))}
    </div>
  );
};

export default RatioRender;
