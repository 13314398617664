import MoreOptions from "./tableContainer/MoreOptions";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Pagination,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import React, { useState } from "react";
// import Dots from "../icons/Dots.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterTable from "./FilterTable";
import Actions from "./tableContainer/Actions";
import TableInput from "./tableContainer/TableInput";
//
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useReports } from "../view/Reports/Provider";
import { uniqueArray } from "../utilities/functions";

function CustomTable({
  empty = false,
  cols,
  // objects,
  data = [],
  // showButton = false,
  moreOptions = false,
  actions = false,
  rowsPerPage = 5,
  filters = {},
  dispatch,
  count,
  title = "",
  page = 1,
  isPagination = true,
  renderParams = {},
  name = "",
  setIndex,
  roleId,
  setRoleId,
  ExpandRow = false,
  expandValues,
}) {
  const headStyle = {
    color: "rgba(0, 0, 0, 0.4)",
    fontWeight: "400",
    fontSize: "12px",
    padding: "0px 16px",
    borderRight: "solid 1px rgba(0, 0, 0, 0.1)",
  };

  const handleChangePage = (event, newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };
  const [editing, setEditing] = useState(false);
  const {
    state: { DailySales },
    dispatch: reportDispatch,
  } = useReports();

  const crender = (row, col, i) => {
    const Col = col.customRender;
    return <Col {...data[i]} renderParams={renderParams} row={row} index={i} />;
  };

  const handleMulti = (row, col, i) => {
    col.customRender = false;

    return (
      <>
        {row.roles.map((role, index) => {
          return (
            <>
              {role.roleId === roleId && (
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    key={index}
                    type={col.type}
                    defaultValue={role.hourlyRate}
                    onChange={(e) => {
                      row.roles[index].hourlyRate = e.target.value;
                      setIndex(index);
                    }}
                    inputProps={{
                      style: {
                        width: col.number ? 85 : 185,
                        height: 5,
                      },
                    }}
                  />
                </div>
              )}
              {role.roleId !== roleId && (
                <div style={{ marginBottom: "20px" }}>{role.hourlyRate}</div>
              )}
            </>
          );
        })}
      </>
    );
  };
  if (!cols) return;
  return (
    <>
      {filters !== null && (
        <FilterTable name={name} data={data} filters={filters} />
      )}
      <div style={{ padding: "5px" }}></div>

      <TableContainer sx={{ width: "100%" }}>
        <PerfectScrollbar style={{ maxWidth: "1200px" }}>
          <div>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {cols.map((col, i) => (
                    <TableCell
                      key={`${col.label}${i}`}
                      {...col.props}
                      sx={
                        col.headTextLeft
                          ? { textAlign: "left", width: "auto", ...headStyle }
                          : { textAlign: "center", width: "auto", ...headStyle }
                      }
                    >
                      {col?.label}{" "}
                      {/* {col?.searchDropdown && (
                        <FormControl>
                          <Select
                            onChange={(e) => {
                              reportDispatch({
                                type: "set_selectedSite",
                                payload: e.target.value,
                              });
                            }}
                            sx={{ height: 30, width: 200 }}
                            defaultValue={0}
                          >
                            <MenuItem value={0}>
                              <em>All</em>
                            </MenuItem>
                            {uniqueArray(DailySales)?.map((site) => (
                              <MenuItem value={site?.site?.id}>
                                {site?.site?.siteName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )} */}
                    </TableCell>
                  ))}
                  {actions && (
                    <TableCell
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        textAlign: "center",
                        width: "300px",

                        ...headStyle,
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
                  {moreOptions && (
                    <TableCell
                      key={"MoreDetails"}
                      sx={{
                        color: "rgba(0, 0, 0, 0.4)",
                        fontWeight: "400",
                        padding: "0px 16px",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span>More Details</span>
                        <MoreHorizIcon fontSize="small" />
                        {/* <img alt="Dots" src={Dots} /> */}
                      </div>
                    </TableCell>
                  )}
                  {/* {showButton && (
                <TableCell style={{ cursor: "pointer", fontSize: "12px" }}>
                  <img alt='Dots' src={Dots} />
                </TableCell>
              )} */}
                </TableRow>
              </TableHead>
              {!empty ? (
                <TableBody>
                  {data.map((row, idx) => (
                    <TableRow
                      key={`${row.id || row.roleId || row.siteId}${idx}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        width: "max-content",
                      }}
                    >
                      {cols.map((col, i) => (
                        <TableCell
                          key={`${col.name}${i}`}
                          sx={
                            col.textLeft
                              ? { fontSize: "12px" }
                              : {
                                  textAlign: "center",
                                  fontSize: "12px",
                                }
                          }
                        >
                          {col.multiRow &&
                            editing === row?.siteId &&
                            col.editable && <>{handleMulti(row, col, i)}</>}
                          {/* {col.customRender&& ( <>{crender(row, col, i)}</>)} */}
                          {col.customRender &&
                          (!col.editable || editing !== row.id) ? (
                            <>{crender(row, col, i)}</>
                          ) : col.editable &&
                            editing &&
                            (editing === row.id || editing === row.roleId) ? (
                            <TableInput
                              col={col}
                              index={idx}
                              idx={i}
                              data={data}
                              validations={actions.check}
                              actions={actions}
                              title={title}
                              multiRow={col.multiRow}
                            ></TableInput>
                          ) : (
                            <>
                              {row[col.name] !== null ? (
                                <span>{row[col.name]}</span>
                              ) : (
                                <span style={{ color: "gray" }}>
                                  not specified
                                </span>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                      {actions && (
                        <TableCell
                          sx={{
                            padding: "0px 16px",
                            width: "300px",
                          }}
                        >
                          <Actions
                            editing={editing}
                            setEditing={setEditing}
                            id={row.id || row.roleId || row.siteId}
                            actions={actions}
                            data={data}
                            name={title}
                            row={row}
                            roleId={roleId}
                            setRoleId={setRoleId}
                            col={cols}
                          ></Actions>
                        </TableCell>
                      )}
                      {moreOptions && (
                        <TableCell
                          sx={{
                            padding: "0px 16px",
                          }}
                        >
                          <MoreOptions
                            id={row.id}
                            moreOptions={moreOptions}
                            data={row}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {/* {emptyRows > 0 && (
                <TableRow style={{ height: 50 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )} */}
                  {ExpandRow && (
                    <TableRow>
                      {expandValues.map((val, index) => (
                        <TableCell sx={{ textAlign: "center" }} key={index}>
                          {val}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody></TableBody>
              )}
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={data.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            {empty && (
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                No data available
              </div>
            )}
          </div>
        </PerfectScrollbar>

        {isPagination && (
          <Pagination
            count={count}
            onChange={handleChangePage}
            defaultPage={page}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              marginTop: "17px",
              ".css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root": {
                borderRadius: "8px",
              },
            }}
          />
        )}
      </TableContainer>
    </>
  );
}

export default CustomTable;
