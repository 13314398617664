//react components

import React, { useEffect, useState } from "react";
//react router
import { useNavigate, useParams } from "react-router-dom";
//use form and yup
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import fields from "./fields";

//@material-ui/core components

import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
//site provider
import { useSite } from "../Sites/Provider";
//discount provider
import { useDisCount } from "./Provider";
import {
  handleErrorResponse,
  TransformFromPercent,
} from "../../utilities/functions";
import { useTheme } from "@emotion/react";
import ErrorsList from "../../ui/ErrorsList";
import { useAuth } from "../../services/auth";
// yup schema
const yupSchema = fields.reduce(createYupSchema, {
  discount: Yup.number()
    .min(1, "discount must be greater than zero")
    .max(100, "discount must be later than 100"),
});
const formSchema = Yup.object().shape(yupSchema);

//css styled
const GridGroup = styled.div`
  width: 64%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px;
  gap: 42px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;

  button {
    float: right;
    margin-left: 20px;
  }
`;
const FormDisCount = ({ handleSuccess }) => {
  let navigate = useNavigate();
  const { id } = useParams();
  let theme = useTheme();
  let { role } = useAuth();

  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const {
    state: { list: sites, page, perPage, selected: site },
    fetchList: fetchSites,
    fetchOne: fetchSite,
  } = useSite();
  const { create } = useDisCount();

  //useEffect to fetch sites list
  useEffect(() => {
    if (role === "Admin" || role === "Company" || role === "SubCompany")
      fetchSites(0, page, perPage, 0);
  }, [fetchSites]);
  useEffect(() => {
    fetchSite(id);
  }, [id, fetchSite]);
  useEffect(() => {
    setValue("siteId", id);
  }, [id]);
  const handleCheck = (discount) => {
    if (discount <= 0) return false;
    else return true;
  };
  //onSubmit function
  const onSubmit = async (values) => {
    delete values.companyName;
    values.discount = TransformFromPercent(values.discount, handleCheck);

    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      console.log(error);

      if (values.discount <= 0 || values.discount > 100)
        setError({
          title: "Discount must be greater than zero and later than 100",
        });
      else setError({ title: handleErrorResponse(error.status, "Discount") });
    }
  };
  if (!sites) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
        }}
      >
        <Typography variant="Poster" component="h4" mb={3} gutterBottom>
          Discount Details
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        ) : (
          ""
        )}
        <GridGroup>
          {fields?.map((f, i) => {
            if (f.viewOnly) return null;
            const inputProps = { ...f.inputProps };

            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <div key={i}>
                {f.switch ? (
                  <div style={{ paddingTop: "20px" }}>
                    <FormGroup>
                      <FormLabel
                        component="legend"
                        sx={{
                          mt: theme.dimensions.mt,
                        }}
                      >
                        {f.label}
                      </FormLabel>
                      <FormControlLabel
                        control={<Switch color="success" defaultChecked />}
                        {...register(f?.name)}
                        label="Active"
                      />
                    </FormGroup>
                  </div>
                ) : f.select ? (
                  !f.disabled ? (
                    <>
                      <TextField
                        error={!!errors[f.name]}
                        helperText={errors[f.name]?.message}
                        sx={{ width: theme.dimensions.width }}
                        label={f.label}
                        select
                        required
                        inputProps={{
                          ...register(f?.name),
                          required: true,
                        }}
                        size="small"
                      >
                        {sites.map((site, index) => (
                          <MenuItem key={site.id} value={site.id}>
                            {site.siteName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  ) : (
                    <div>
                      <Typography variant="body2" component="h6" gutterBottom>
                        {f.label}
                      </Typography>

                      <Box
                        sx={{
                          borderBottom: "1px solid gray",
                          width: "290px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {f.label === "Company Name"
                          ? site?.company?.companyName
                          : site?.siteName}
                      </Box>
                    </div>
                  )
                ) : (
                  <>
                    <TextField
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      select={f.select}
                      className={f.class}
                      disabled={f.disabled}
                      size="small"
                      type={f.type === "number" ? "number" : "text"}
                      sx={{ width: theme.dimensions.width }}
                      inputProps={{
                        ...register(f?.name),
                        required: f.required,
                        min: "1",
                        max: "100",
                        step: "1",
                      }}
                      required
                    ></TextField>
                  </>
                )}
              </div>
            );
          })}
        </GridGroup>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              ml: theme.dimensions.ml,
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Add Discount
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: theme.color.grayBackground,
              color: theme.color.dark,
              "&:hover": { background: `${theme.color.gray50}` },
            }}
          >
            Discard
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
};

export default React.memo(FormDisCount);
