import React from "react";

const UpdateDate = ({ row }) => {
  return (
    <div>
      {row?.roles?.map((role, index) => (
        <div key={index} style={{ marginBottom: "10px", height: "30px" }}>
          {role.updateDate}
        </div>
      ))}
    </div>
  );
};

export default UpdateDate;
