import { addDays } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
const fields = [
  {
    name: "companyName",
    label: "Company Name",
    disable:true,
    validationType: "string",
    viewlabel:"companyName",
  },
  {
    name: "siteName",
    label: "Site Name",
    disable:true,
    validationType: "string",
    viewlabel:"siteName",
  },
  {
    name: "memberName",
    label: "Full Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "mobile",
    label: "Mobile Number",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  
  {
    name: "balance",
    label: "Balance",
    validationType: "number",
  },
  {
    name: "expireDate",
    label: "Expire Date",
    validationType: "string",
    date: true,
  },
  {
    name: "isActive",
    label: "Membership Status",
    switch: true,
    validationType: "boolean",
    editOnly:true,
  },
];

export default fields;
