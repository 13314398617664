// React
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// Providers
import { usePayroll } from "./Provider";
//UI component
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import EmployeeName from "./PayrollTable/EmployeeName";
import EmployeeCode from "./PayrollTable/EmployeeCode";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// MUI
import { Alert, Box, Button, useTheme } from "@mui/material";
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LocalPaidDate from "./Render/LocalPaidDate";
import { tolocalDate } from "../../utilities/functions";
// yup schema
const formSchema = Yup.object().shape({});

const ListEmployeePayroll = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let navigate = useNavigate();
  let theme = useTheme();

  let { pid } = useParams();
  const [InTableRender, setInTableRender] = useState(0);
  const [error, setError] = useState(null);

  const {
    state: {
      listEmployeesPayroll: payrollsEmployees,
      listPayrollExcel,
      page,
      perPage,
      count,
      totalSize,
      countPayrollExcel,
    },
    salaryPaid: salaryPaid,
    fetchPayrollEmployees: fetchPayrollEmployees,
    fetchPayrollEmployeesExcel,
    dispatch,
  } = usePayroll();
  const {
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const getCols = (name) => {
    switch (name) {
      case "Employees Payroll":
        return [
          {
            label: "Employee Code",
            name: "employeeCode",
            customRender: EmployeeCode,
            headTextLeft: true,
          },
          {
            label: "Employee Name",
            name: "employeeName",
            customRender: EmployeeName,
          },
          { label: "Paid Date", name: "paidDate", customRender: LocalPaidDate },
          {
            label: "Total Salary",
            name: "totalSalary",
          },
        ];
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Employees Payroll":
        return payrollsEmployees;
    }
  };

  //useEffect to fetch payrollsEmployees list
  useEffect(() => {
    fetchPayrollEmployees(pid, page, perPage);
  }, [pid, InTableRender, page, perPage]);

  /**fetch date to export excel file */
  useEffect(() => {
    if (listPayrollExcel.length != 0) {
      listPayrollExcel.splice(0, listPayrollExcel?.length);
    }
    fetchPayrollEmployeesExcel(pid, page, 100);
  }, [pid]);
  const tabs = [{ title: "Employees Payroll", num: totalSize }];

  const [selectedCols, setSelectedCols] = useState("Employees Payroll");

  //handle detail click
  const handleDetail = (ID) => {
    navigate(`/payroll/EmployeesPayrollList/${pid}/PayrollDetails/${ID}`);
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
    console.log(page, "page employee payroll");
  };

  // handle Pay
  const handlePay = async (peid) => {
    try {
      await salaryPaid(peid);
      reset();
      fetchPayrollEmployees(pid);
    } catch (e) {
      window.scrollTo(0, 0);
      setError("Error Closing The Payroll");
    }
  };
  useEffect(() => {
    console.log(countPayrollExcel, "list payroll excel");
  });
  const handleClick = async () => {
    for (var i = 2; i <= countPayrollExcel; i++) {
      await fetchPayrollEmployeesExcel(pid, i, 100);
    }
    var newArr = new Array();
    for (var i = 0; i < listPayrollExcel.length; i++) {
      newArr = newArr.concat(listPayrollExcel[i]);
    }
    var aa = new Array();
    for (var i = 0; i < newArr?.length; i++) {
      aa.push({
        EmployeeCode: newArr[i].employee.employeeCode,
        EmployeeName: newArr[i].employee.employeeName,
        PaidDate: newArr[i]?.paidDate ? tolocalDate(newArr[i]?.paidDate) : "",
        TotalSalary: newArr[i]?.totalSalary,
      });
    }

    var fileName = "Employees Payroll Report File";
    const ws = XLSX.utils.json_to_sheet(aa, {
      origin: -1,
    });
    ws["!cols"] = [
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 50 },
      { width: 50 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Employees Payroll");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  if (!payrollsEmployees) return;

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ width: "fit-content" }}>
          {error}
        </Alert>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <TabsList
            tabs={tabs}
            setSelectedCols={setSelectedCols}
            selectedCols={selectedCols}
          ></TabsList>
        </div>
        <Button
          sx={{
            border: `1px solid ${theme.color.Screamingreen}`,
            color: theme.color.Screamingreen,
            mr: "350px",
            textTransform: "capitalize",
            "&:hover": { background: `${theme.color.gray50}` },
          }}
          onClick={handleClick}
        >
          {" "}
          Export to Excel
        </Button>
      </Box>

      <CustomTable
        empty={payrollsEmployees?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          search: (e) => {
            console.log(e);
          },
        }}
        actions={{
          paid: (peid) => {
            handlePay(peid);
            // salaryPaid(peid);
          },
          setInTableRender: setInTableRender,
          InTableRender: InTableRender,
        }}
        moreOptions={{
          detail: handleDetail,
        }}
        showButton={true}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
        page={page}
      ></CustomTable>
    </>
  );
};

export default React.memo(ListEmployeePayroll);
